import { UAParser } from 'ua-parser-js';

export class UserAgentInfo {
    ua: string | null;
    uap: UAParser;

    constructor(ua: string | null) {
        this.ua = ua;
        this.uap = new UAParser();
        if (ua) {
            this.uap.setUA(ua);
        }
    }

    // detect known android stock browser versions which need a fix for absolute positioning
    get isProblematicAndroidStockBrowser(): boolean {
        const problematicVersions = ['535.19'];

        // to detect an old problematic android stock browser, we look at the webkit version
        // in general, the android stock browser, Chrome, Safari and Opera use the user agent "webkit" property

        const uapRes = this.uap.getResult();

        // if the ua claims not to be an Android device, this is no old stock browser
        if (uapRes.os.name !== 'Android') {
            return false;
        }

        // if it is an Opera browser, it is no old stock browser
        if (uapRes.browser.name === 'Opera') {
            return false;
        }

        if (uapRes.engine.name === 'WebKit') {
            const webkitV = uapRes.engine.version
                ? parseFloat(uapRes.engine.version)
                : null;
            if (webkitV) {
                for (let i = 0; i < problematicVersions.length; i++) {
                    if (problematicVersions[i] === uapRes.engine.version) {
                        return true;
                    }
                }
                return false;
            }
        }

        return false;
    }
}
