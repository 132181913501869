import { css } from '@emotion/css';
import { ibanToDisplayFormat } from 'dg-web-shared/lib/StringConversions';
import { Language } from 'dg-web-shared/lib/Text';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { DirectDebitAccountChangeRequest } from '../../../common/state/PaymentTypeState';
import { Colors } from 'dg-web-shared/ui/vars';
import { MaterialButton } from '../../../ui/inputs/MaterialButton';
import { portalSlideIn } from '../../root/components/PortalSlidein';
import { DirectDebitSubmittedEarlier } from './PaymentMethodJsxTexts';
import { paymentMethodTexts } from '../PaymentMethodTexts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const DirectDebitReviewSlideIn = portalSlideIn<Props>(DirectDebitReview);

const paragraphStyle = css({
    fontFamily: Typo.robotoRegular.fontFamily,
    fontWeight: Typo.robotoRegular.fontWeight,
    fontSize: '16px',
    lineHeight: '22px',
    color: Colors.action_f,
});

function DirectDebitReview(props: Props) {
    const t = paymentMethodTexts[props.language];

    return (
        <div className={css({ padding: '0px 24px' })}>
            <p className={paragraphStyle}>{t.directDebitReview()}</p>

            <TextField
                onChange={() => {}}
                value={ibanToDisplayFormat(props.iban)}
                labelText={t.activeIban()}
                readOnly={true}
            />

            {!props.directDebitAccountChangeRequest && (
                <div className={css({ textAlign: 'right', width: '100%' })}>
                    <MaterialButton
                        onClick={props.onChangeAccountClick}
                        label={t.changeAccount()}
                    />
                </div>
            )}

            {!!props.directDebitAccountChangeRequest && (
                <div className={paragraphStyle}>
                    <strong>
                        <p>
                            {t.directDebitReviewWithIbanChangePendingP1(
                                props.directDebitAccountChangeRequest
                                    .ibanChangeRequestTimestamp,
                            )}
                        </p>
                    </strong>

                    <TextField
                        onChange={() => {}}
                        value={ibanToDisplayFormat(
                            props.directDebitAccountChangeRequest.requestedIban,
                        )}
                        labelText={t.newIban()}
                        readOnly={true}
                    />

                    <p>{t.directDebitReviewWithIbanChangePendingP2()}</p>
                    <p>{t.directDebitSubmittedEarlierP2()}</p>

                    <DirectDebitSubmittedEarlier
                        language={props.language}
                        authorizationDocumentPdfUri={props.lsvPdfUri}
                    />

                    <p>{t.directDebitReviewWithIbanConfirmation()}</p>
                </div>
            )}
        </div>
    );
}

interface Props {
    language: Language;
    iban: string;
    directDebitAccountChangeRequest: DirectDebitAccountChangeRequest | null;
    lsvPdfUri: string;
    onChangeAccountClick: () => void;
}
