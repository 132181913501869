import { useEffect, useState } from 'react';
import { selectState, Store, Updater } from 'dg-web-shared/lib/Flux';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import { VehicleAdd } from '../../../account/vehicles/VehicleAdd';
import { VehicleAddBadge } from '../../../account/vehicles/VehicleAddBadge';
import {
    UpdateVehicle,
    Vehicles,
} from '../../../account/vehicles/VehicleState';
import { vehicleTexts } from '../../../account/vehicles/VehicleTexts';
import * as SettingsState from '../../../common/state/SettingsState';
import * as EntitySelectionState from '../../state/EntitySelectionState';
import { ParkCreateAddVehicleState, ParkCreateMode } from '../ParkCreate';
import { resetStates } from './PermitEntitySelection';
import { PermitTypeState } from '../permit/Permit';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';

export const AddVehicleSlideIn = selectState<
    {
        mode: ParkCreateMode;
        permitTypeState?: PermitTypeState;
        onVehicleAddSuccess?: () => void;
    },
    {
        settings: SettingsState.State;
        parkVehicle: ParkCreateAddVehicleState.State;
    }
>(
    s => {
        return {
            settings: new SettingsState.StateSlice(s).state,
            parkVehicle: ParkCreateAddVehicleState.get(s),
        };
    },
    p => {
        const entityIsSelectable = p.mode === ParkCreateMode.permit;
        const setPendingCarId = usePendingEntity(
            entityIsSelectable,
            p.update,
            p.permitTypeState,
            p.parkVehicle.addBadge ? () => null : p.onVehicleAddSuccess,
        );
        const vehicleText = vehicleTexts[p.settings.language];
        return (
            <VehicleAdd
                portal={SlideInPortalId.PARK_CREATE}
                title={vehicleText.addVehicle()}
                open={p.parkVehicle.addVehicle}
                onClose={() => {
                    p.update(UpdateVehicle.reset);
                    p.update(store =>
                        ParkCreateAddVehicleState.stateWrite(store, {
                            addVehicle: false,
                        }),
                    );
                }}
                onSuccess={(res, data) => {
                    p.update(store => {
                        switch (p.mode) {
                            case ParkCreateMode.transaction:
                                EntitySelectionState.Dropin.Transaction.stateWrite(
                                    store,
                                    { open: false },
                                );
                                EntitySelectionState.Selection.Transaction.writeSelectedLicenseplate(
                                    store,
                                    res.carId,
                                );

                                break;
                            case ParkCreateMode.permit:
                                setPendingCarId(res.carId);
                                if (p.permitTypeState) {
                                    p.permitTypeState.refetchPermitTypeData();
                                } else {
                                    closePermitEntitiesDropin(store);
                                }
                                break;

                            case ParkCreateMode.badge:
                                closePermitEntitiesDropin(store);
                                break;
                            default:
                                break;
                        }

                        if (data.addBadgeToVehicle) {
                            ParkCreateAddVehicleState.stateWrite(store, {
                                addVehicle: false,
                                addBadge: true,
                                selectedVehicleId: res.vehicleId,
                            });
                        } else if (
                            p.onVehicleAddSuccess &&
                            p.mode === ParkCreateMode.transaction
                        ) {
                            p.onVehicleAddSuccess();
                        } else {
                            ParkCreateAddVehicleState.reset(store);
                        }

                        Vehicles.forceRefetch(store);

                        return 'license-plate-vehilce-add-successfull';
                    });
                }}
            />
        );
    },
);

const closePermitEntitiesDropin = (store: Store) =>
    EntitySelectionState.Dropin.Permit.stateWrite(store, { open: false });

const addPermitEntity = (store: Store, entityId: number) => {
    return EntitySelectionState.Selection.Permit.stateWrite(store, {
        selectedEntityIds: [entityId],
    });
};

export const AddBadgeSlideIn = selectState<
    {
        mode: ParkCreateMode;
        permitTypeState?: PermitTypeState;
        onVehicleAddSuccess?: () => void;
    },
    {
        settings: SettingsState.State;
        parkVehicle: ParkCreateAddVehicleState.State;
        vehicles: Vehicles.State;
    }
>(
    s => {
        return {
            settings: new SettingsState.StateSlice(s).state,
            parkVehicle: ParkCreateAddVehicleState.get(s),
            vehicles: Vehicles.get(s),
        };
    },
    p => {
        const entityIsSelectable = p.mode === ParkCreateMode.badge;
        const setPendingBadgeId = usePendingEntity(
            entityIsSelectable,
            p.update,
            p.permitTypeState,
            p.onVehicleAddSuccess,
        );
        const selectedVehicle = p.vehicles.data.find(
            v => v.customerCarId === p.parkVehicle.selectedVehicleId,
        );
        const vehicleText = vehicleTexts[p.settings.language];
        return (
            <VehicleAddBadge
                portal={SlideInPortalId.PARK_CREATE}
                texts={vehicleText}
                vehicle={selectedVehicle ? selectedVehicle : null}
                title={vehicleText.addVehicle()}
                open={p.parkVehicle.addBadge}
                onClose={() => {
                    p.update(ParkCreateAddVehicleState.reset);
                    if (p.onVehicleAddSuccess) {
                        p.onVehicleAddSuccess();
                    }
                }}
                onSuccess={badgeId => {
                    p.update(store => {
                        if (badgeId) {
                            if (
                                p.mode === ParkCreateMode.badge ||
                                p.mode === ParkCreateMode.permit
                            ) {
                                setPendingBadgeId(badgeId);
                                if (p.permitTypeState) {
                                    p.permitTypeState.refetchPermitTypeData();
                                } else {
                                    closePermitEntitiesDropin(store);
                                }
                            }
                        }
                        Vehicles.forceRefetch(store);
                        ParkCreateAddVehicleState.reset(store);
                        return 'badge-selection-badge-add-success';
                    });
                }}
            />
        );
    },
);

function usePendingEntity(
    entityIsSelectable: boolean,
    update: Updater,
    permitTypeState?: PermitTypeState,
    onVehicleAddSuccess?: () => void,
) {
    const [pendingEntityId, setPendingEntityId] = useState<number | null>(null);
    useEffect(() => {
        if (permitTypeState) {
            if (
                pendingEntityId &&
                permitTypeState.permitTypeData.status === RequestStatus.SUCCESS
            ) {
                setPendingEntityId(null);
                update(store => {
                    if (entityIsSelectable) {
                        addPermitEntity(store, pendingEntityId);
                    }
                    if (onVehicleAddSuccess) {
                        onVehicleAddSuccess();
                    }
                    resetStates(store);
                    closePermitEntitiesDropin(store);
                    return 'refetch-and-permit-entity-add-successfull';
                });
            }
        }
    }, [pendingEntityId, update, onVehicleAddSuccess, permitTypeState]);
    return setPendingEntityId;
}
