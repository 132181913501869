import * as Flux from 'dg-web-shared/lib/Flux';
import { StaticPageLayout } from '../../../layout/utils/StaticPageLayout';
import { TwoColumnsProperties } from '../../../layout/utils/TwoColumnsProperties';
import { ResponsiveProperties } from '../../../layout/utils/ResponsiveProperties';
import { columnsVariant } from '../../../layout/utils/columnsVariant';
import * as LayoutState from '../../../layout/state/LayoutState';
import { Layout } from '../../../layout/components/Layout';
import {
    LayoutContainer,
    LayoutVariantOption,
} from '../../../layout/components/LayoutContainer';
import { LayoutBackground } from '../../../layout/components/LayoutBackground';
import { ColumnBlock } from '../../../layout/components/ColumnBlock';
import { ColumnContainer } from '../../../layout/components/ColumnContainer';
import { HeaderLargeBlock } from '../../../layout/components/header/HeaderLargeBlock';
import { LogoSlogan } from '../../../layout/components/header/logo-slogan/LogoSlogan';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import React from 'react';

export interface StaticPageProps {
    children?: React.ReactNode;
    allState: Flux.Store;
    hideBackground?: boolean;
}

export class StaticPage extends React.Component<StaticPageProps> {
    static displayName = 'StaticPage';

    render() {
        return (
            <Layout allState={this.props.allState}>
                <LayoutContainer
                    allState={this.props.allState}
                    layoutVariant={LayoutVariantOption.StaticPage}
                >
                    {!this.props.hideBackground && (
                        <LayoutBackground
                            allState={this.props.allState}
                            layoutVariant={LayoutVariantOption.StaticPage}
                        />
                    )}
                    <HeaderLargeBlock allState={this.props.allState} />
                    <LogoSlogan
                        layoutVariant={LayoutVariantOption.StaticPage}
                    />
                    <ColumnContainer
                        allState={this.props.allState}
                        relative
                        layoutVariant={LayoutVariantOption.StaticPage}
                    >
                        {this.props.children}
                    </ColumnContainer>
                </LayoutContainer>
            </Layout>
        );
    }
}

export interface LeftColumnState {
    layout: LayoutState.State;
}

export interface LeftColumnProps {
    allState: Flux.Store;
    children?: React.ReactNode;
}

export class LeftColumn extends Flux.ContainerWithProps<
    LeftColumnProps,
    LeftColumnState
> {
    static displayName = 'LeftColumn';

    stateSelector(): LeftColumnState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        const staticPageLayout = new StaticPageLayout(this.state);
        const columnProps = new TwoColumnsProperties(this.state);
        return (
            <ColumnBlock
                width={staticPageLayout.column1Width}
                isEnabled={true}
                hasSpacing={!columnProps.variant.oneColumn}
            >
                <ContentContainer allState={this.props.allState}>
                    {this.props.children}
                </ContentContainer>
            </ColumnBlock>
        );
    }
}

export interface RightColumnState {
    layout: LayoutState.State;
}

export interface RightColumnProps {
    allState: Flux.Store;
    children?: React.ReactNode;
}

export class RightColumn extends Flux.ContainerWithProps<
    RightColumnProps,
    RightColumnState
> {
    static displayName = 'RightColumn';

    stateSelector(): RightColumnState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        const staticPageLayout = new StaticPageLayout(this.state);
        return (
            <ColumnBlock
                width={staticPageLayout.column2Width}
                isEnabled={true}
                hasSpacing={false}
            >
                {this.props.children}
            </ColumnBlock>
        );
    }
}

interface ContentContainerProps {
    children?: React.ReactNode;
    allState: Flux.Store;
}

interface ContentContainerState {
    layout: LayoutState.State;
}

export class ContentContainer extends Flux.ContainerWithProps<
    ContentContainerProps,
    ContentContainerState
> {
    static displayName = 'ContentContainer';

    stateSelector(): ContentContainerState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        const responsiveProps = new ResponsiveProperties(this.state);
        return (
            <div
                className={css([
                    {
                        height: '100%',
                        maxWidth: '688px',
                        marginLeft: '104px',
                        userSelect: 'text',
                    },
                    columnsVariant(this.state) === 'OneColumn' && {
                        paddingBottom: '40px',
                    },
                    responsiveProps.toString() === 'Mobile' && {
                        margin: 0,
                        maxWidth: '100%',
                        padding: '24px',
                        backgroundColor: Colors.background,
                    },
                ])}
            >
                {this.props.children}
            </div>
        );
    }
}

interface HeadingLargeProps {
    first?: boolean;
    children?: React.ReactNode;
}

export class HeadingLarge extends React.Component<HeadingLargeProps> {
    static displayName = 'HeadingLarge';

    render() {
        return (
            <h2
                className={css([
                    {
                        margin: 0,
                        color: Colors.blue,
                        ...Typo.heading3,
                    },
                    !this.props.first && {
                        marginTop: '26px',
                    },
                ])}
                data-first={!!this.props.first}
            >
                {this.props.children}
            </h2>
        );
    }
}
