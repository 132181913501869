import { css } from '@emotion/css';

import { Updater, useUpdate } from 'dg-web-shared/lib/Flux';
import {
    JsxMessage,
    languageFromString,
    Message,
} from 'dg-web-shared/lib/Localized';
import { PermitPostData } from '../../../api/PermitHttp';
import { Localized } from '../../../common/components/Localized';
import { Colors } from 'dg-web-shared/ui/vars';
import { logAction } from '../../../utils/ActionLog';
import {
    PaymentCategory,
    PaymentRequestMode,
} from '../../../common/payment/Payment';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../../root/components/PortalSlidein';
import { AmountChoice, AmountLabel } from './AmountsChoiceGrid';
import { DirectPayButton } from './DirectPayButton';
import { DirectPayWarningAlertBox } from './DirectPayWarning';
import { Failure } from './PaymentModals';
import { ProceedButton } from './ProceedButton';
import { PaymentStateType, ShowPaymentState } from './PaymentBase';
import { PermitPaymentRequest } from './PermitPaymentRequest';
import { refreshStateSlicesOnSuccessfulPermitPurchase } from '../../../park-create/actions/PermitActions';
import { useParkingpayLanguage } from '../../../utils/UseParkingpayLanguage';
import { useTransactionListRefetch } from '../../../transactions-list/TransactionsListContext';
import { useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const PermitPaymentSlideIn = portalSlideIn(PermitPayment);

interface Props {
    portal: SlideInPortalId;
    paymentCategory: PaymentCategory;
    paymentRequestMode: PaymentRequestMode;
    permitPriceChf: number;
    currentBalanceChf: number;
    permitPostData: PermitPostData;
    close: () => void;
    useExistingAlias: boolean;
}

function PermitPayment(props: Props) {
    const update = useUpdate();
    const language = useParkingpayLanguage();
    const refetchTransactionList = useTransactionListRefetch();
    const [remoteRequest, sendRemoteRequest] = PermitPaymentRequest.use();

    switch (remoteRequest.state) {
        case PaymentStateType.NEVER_EXECUTED:
            return (
                <AmountSelectionForm
                    {...props}
                    language={language}
                    update={update}
                    sendPayment={sendRemoteRequest}
                />
            );
        case PaymentStateType.FAILURE_BOOKING_PERMIT:
            return (
                <Failure onConfirm={props.close}>
                    <Localized {...messages.epaydirectFailureBookingPermit} />
                </Failure>
            );
        /*
        we don't need to render anything, as we redirect to datatrans or reset the whole view
        */
        case PaymentStateType.DIRECT_TO_ALIAS_SUCCESS:
            return null;
        default:
            return (
                <ShowPaymentState
                    remoteRequest={remoteRequest}
                    category={props.paymentCategory}
                    mode={props.paymentRequestMode}
                    onClose={() => {
                        refetchTransactionList();
                        update(store => {
                            refreshStateSlicesOnSuccessfulPermitPurchase(
                                store,
                                false,
                            );
                            return 'refresh-state-on-close';
                        });
                        props.close();
                    }}
                />
            );
    }
}

interface FormProps {
    language: string;
    portal: SlideInPortalId;
    paymentCategory: PaymentCategory;
    paymentRequestMode: PaymentRequestMode;
    permitPriceChf: number;
    currentBalanceChf: number;
    permitPostData: PermitPostData;
    update: Updater;
    sendPayment: (
        args: PermitPaymentRequest.PermitPaymentRequestPayload,
    ) => void;
    useExistingAlias: boolean;
}

function AmountSelectionForm(props: FormProps) {
    const language = languageFromString(props.language);
    const balanceIsNegative = props.currentBalanceChf < 0;
    const [selection, setSelection] = useState(
        balanceIsNegative ? SelectionTag.NONE : SelectionTag.PERMIT_PRICE,
    );
    const [directPurchaseWarning, toggleDirectPurchaseWarning] =
        useState(false);

    function log(selection: SelectionTag) {
        props.update(store =>
            logAction(store, 'permit-e-payment-top-up-amount', {
                tag: selection,
            }),
        );
    }

    const amountMissingChf = props.permitPriceChf - props.currentBalanceChf;

    const submit = () =>
        props.sendPayment({
            mode: props.paymentRequestMode,
            amount: selectedAmountChf(
                props.permitPriceChf,
                props.currentBalanceChf,
                selection,
            ),
            language: language,
            permitData: props.permitPostData,
            paymentCategory: props.paymentCategory,
            useExistingAlias: props.useExistingAlias,
        });

    return (
        <div
            className={css({
                backgroundColor: Colors.form,
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            })}
        >
            <div className={css({ padding: '10px 20px 0 20px' })}>
                {!balanceIsNegative && ( // a negative balance is not tolerated, will get messy
                    <AmountSection
                        label={messages.payThePurchasePrice}
                        description={null}
                        amount={props.permitPriceChf}
                        selected={selection === SelectionTag.PERMIT_PRICE}
                        onClick={() => {
                            log(SelectionTag.PERMIT_PRICE);
                            setSelection(SelectionTag.PERMIT_PRICE);
                        }}
                    />
                )}
                {amountMissingChf !== props.permitPriceChf && (
                    <AmountSection
                        label={messages.payTheDifferenceTitle}
                        description={messages.payTheDifferenceDescription}
                        amount={amountMissingChf}
                        selected={
                            selection === SelectionTag.DIFFERENCE_FROM_BALANCE
                        }
                        onClick={() => {
                            log(SelectionTag.DIFFERENCE_FROM_BALANCE);
                            setSelection(SelectionTag.DIFFERENCE_FROM_BALANCE);
                        }}
                    />
                )}
            </div>

            <div className={css({ padding: '20px' })}>
                {props.paymentRequestMode ===
                PaymentRequestMode.DIRECT_TO_ALIAS ? (
                    <>
                        <DirectPayButton
                            disabled={selection === SelectionTag.NONE}
                            onClick={() => toggleDirectPurchaseWarning(true)}
                        />
                        {directPurchaseWarning && (
                            <DirectPayWarningAlertBox
                                paymentCategory={props.paymentCategory}
                                onConfirm={submit}
                                onCancel={() =>
                                    toggleDirectPurchaseWarning(false)
                                }
                            />
                        )}
                    </>
                ) : (
                    <ProceedButton
                        disabled={selection === SelectionTag.NONE}
                        onClick={submit}
                    />
                )}
            </div>
        </div>
    );
}

function AmountSection(p: {
    amount: number;
    selected: boolean;
    label: Message;
    description: Message | null;
    onClick: () => void;
}) {
    return (
        <div className={css({ marginBottom: '20px' })}>
            <AmountLabel text={p.label} />
            {p.description && <AmountDescription text={p.description} />}
            <AmountChoice
                amount={p.amount}
                selected={p.selected}
                enabled={true}
                onClick={p.onClick}
                width="100%"
                displayCurrency={true}
            />
        </div>
    );
}

function AmountDescription(props: { text: Message }) {
    return (
        <div
            className={css({
                margin: '-15px 0 15px 0',
                fontSize: '14px',
                ...Typo.robotoLight,
            })}
        >
            <Localized {...props.text} />
        </div>
    );
}

function selectedAmountChf(
    permitPriceChf: number,
    currentBalanceChf: number,
    selection: SelectionTag,
): number {
    switch (selection) {
        case SelectionTag.NONE:
            return 0;

        case SelectionTag.PERMIT_PRICE:
            return permitPriceChf;

        case SelectionTag.DIFFERENCE_FROM_BALANCE:
            return permitPriceChf - currentBalanceChf;
    }
}

enum SelectionTag {
    NONE = 'NONE',
    PERMIT_PRICE = 'PERMIT_PRICE',
    DIFFERENCE_FROM_BALANCE = 'DIFFERENCE_FROM_BALANCE',
}

const messages: Messages = {
    epaydirectFailureBookingPermit: {
        de: (
            <>
                <p>
                    Die Online-Zahlung wurde erfolgreich ausgeführt, aber die
                    Bewilligung konnte nicht erworben werden. Der bezahlte
                    Betrag ist auf Ihrem Parkingpay-Konto verfügbar.
                </p>
                <p>Bitte versuchen Sie erneut, die Bewilligung zu erwerben.</p>
            </>
        ),
        fr: (
            <>
                <p>
                    Le paiement en ligne a réussi, mais l&apos;autorisation
                    n&apos;a pu être achetée. Le montant payé est disponible
                    dans votre compte Parkingpay.
                </p>
                <p>Veuillez réessayer d&apos;acheter l&apos;autorisation.</p>
            </>
        ),
        it: (
            <>
                <p>
                    Il pagamento online è stato eseguito con successo, tuttavia
                    non è stato possibile acquistare l&apos;autorizzazione.
                    L&apos;importo pagato è a disposizione sul suo conto
                    Parkingpay.
                </p>
                <p>Voglia riprovare ad acquistare l&apos;autorizzazione.</p>
            </>
        ),
        en: (
            <>
                <p>
                    The e-payment was successful, but the permit could not be
                    purchased. The amount paid is available in your Parkingpay
                    account.
                </p>
                <p>Please try again to purchase the permit.</p>
            </>
        ),
    },
    payTheDifferenceDescription: {
        de: 'Der aktueller Saldo wird für die Zahlung berücksichtigt.',
        fr: 'Le solde actuel est pris en compte pour le paiement.',
        it: 'Il saldo attuale viene considerato per il pagamento.',
        en: 'The current account balance will be taken into consideration.',
    },
    payTheDifferenceTitle: {
        de: 'Fehlenden Betrag bezahlen',
        fr: 'Payer le montant manquant',
        it: "Pagare l'importo mancante",
        en: 'Pay the missing amount',
    },
    payThePurchasePrice: {
        de: 'Kaufpreis bezahlen',
        fr: "Payer le prix d'achat",
        it: "Pagare il prezzo d'acquisto",
        en: 'Pay the purchase price',
    },
};

interface Messages {
    epaydirectFailureBookingPermit: JsxMessage;
    payTheDifferenceDescription: Message;
    payTheDifferenceTitle: Message;
    payThePurchasePrice: Message;
}
