import { Ticket } from './TicketSlideIn';

import { GenericError } from '../../account/logins/EditForm';
import { TicketPlusTicketBooked } from '../../common/components/ticketplus/TicketPlusTicketBooked';
import { TicketLayout } from '../../common/components/ticketplus/TicketPlusLayout';
import { ButtonRow } from 'dg-web-shared/tb-ui/buttons/ButtonRow.tsx';
import { BasicButton } from 'dg-web-shared/tb-ui/buttons/BasicButton.tsx';
import { Localized } from '../../common/components/Localized';

export function TicketSuccessDetails({ ticketData }: { ticketData: Ticket }) {
    if (!ticketData.isDetailed) {
        return <GenericError />;
    }

    return (
        <TicketLayout type="contractview">
            <TicketPlusTicketBooked
                ticketApiString={ticketData.apiString}
                amount={ticketData.amount}
                validFrom={ticketData.validFrom}
                validTo={ticketData.validTo}
                zone={ticketData.zone.name}
                city={ticketData.zone.city}
                operator={ticketData.zone.operatorName}
                cost={ticketData.cost}
                additionalContent={
                    <ButtonRow>
                        <a
                            target="_blank"
                            href={`/ui-api/customer-account/ticket/receipt/pdf/v2/${
                                ticketData.contractId
                            }?key=${encodeURIComponent(ticketData.key)}`}
                            rel="noreferrer"
                        >
                            <BasicButton
                                negative={true}
                                label={
                                    <Localized
                                        de="Beleg anzeigen"
                                        fr="Afficher document"
                                        it="Mostra documento"
                                        en="Show document"
                                    />
                                }
                                onClick={null}
                            />
                        </a>
                    </ButtonRow>
                }
            />
        </TicketLayout>
    );
}
