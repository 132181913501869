import { Colors } from 'dg-web-shared/ui/vars';
import { Message } from 'dg-web-shared/lib/Localized';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { css } from '@emotion/css';
import { Localized } from './Localized';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export enum FullWidthBottomButtonColor {
    BLUE,
    GREEN,
    RED,
    WHITE,
}

export function FullWidthBottomButton({
    color,
    onClick,
    label,
    disabled,
    compactThresholdLength,
    allowClickOnDisabled,
    buttonPressedColor,
}: {
    color: FullWidthBottomButtonColor;
    onClick: () => void;
    label: Message;
    disabled?: boolean;
    compactThresholdLength?: number;
    allowClickOnDisabled?: boolean;
    buttonPressedColor?: string;
}) {
    const buttonColor = (color: FullWidthBottomButtonColor) => {
        if (disabled) {
            return Colors.disabledButtonGrey;
        }
        switch (color) {
            case FullWidthBottomButtonColor.RED:
                return Colors.buttonRed;
            case FullWidthBottomButtonColor.BLUE:
                return Colors.darkblue;
            case FullWidthBottomButtonColor.GREEN:
                return Colors.buttonGreen;
            case FullWidthBottomButtonColor.WHITE:
                return Colors.white;
        }
    };

    const regularDevice = `@media (min-height: ${
        compactThresholdLength ? compactThresholdLength : 0
    }px)`;

    return (
        <Clickable
            element="div"
            disabled={allowClickOnDisabled ? false : disabled || false}
            className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '48px',
                ...Typo.robotoBold,
                fontSize: '16px',
                marginTop: '10px',
                boxShadow: '0 1px 4px rgba(0, 0, 0, 0.30)',
                background: buttonColor(color),
                textTransform: 'uppercase',
                letterSpacing: '0.8px',
                color:
                    color === FullWidthBottomButtonColor.WHITE && !disabled
                        ? Colors.darkblue
                        : Colors.white,
                [regularDevice]: {
                    height: '64px',
                },
                '&:active': {
                    padding: disabled ? '' : '2px 0 0 2px',
                    backgroundColor:
                        buttonPressedColor && !disabled
                            ? buttonPressedColor
                            : buttonColor(color),
                },
            })}
            onClick={onClick}
        >
            <Localized {...label} />
        </Clickable>
    );
}
