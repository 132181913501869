import * as Flux from 'dg-web-shared/lib/Flux';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import * as AccountSetupState from '../state/AccountSetupState';
import {
    getSelectionText,
    SingleSelectionSlideinString,
} from '../../ui/slidein/SingleSelectionSlidein';
import { addressTexts } from '../i18n/AddressTexts';
import * as SettingsState from '../../common/state/SettingsState';
import * as AddressCountriesState from '../../common/state/AddressCountriesState';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';

export namespace AddressCountrySlidein {
    interface State {
        settings: SettingsState.State;
        accountSetupLayout: AccountSetupState.Layout.State;
        accountSetupAddress: AccountSetupState.Address.State;
        addressCountries: AddressCountriesState.State;
    }

    const selectAddressCountry = (store: Flux.Store, countryId: string) => {
        AccountSetupState.Address.stateWrite(store, { country: countryId });
        AccountSetupState.Layout.stateWrite(store, {
            addressCountrySlideinOpen: false,
        });
        return 'AccountSetupSlidein-selectAddressCountry';
    };

    interface OptionsParams {
        settings: SettingsState.State;
        addressCountries: AddressCountriesState.State;
    }

    export const getOptions = (p: OptionsParams) =>
        p.addressCountries.data.map(c => ({
            id: c.id,
            displayText: c.name[p.settings.language],
        }));
    export const currentSelection = (
        p: OptionsParams,
        countryId: Maybe<string>,
    ) => getSelectionText(getOptions(p), countryId);
    export const Slidein = Flux.selectState<object, State>(
        store => ({
            settings: new SettingsState.StateSlice(store).state,
            accountSetupLayout: AccountSetupState.Layout.get(store),
            accountSetupAddress: AccountSetupState.Address.get(store),
            addressCountries: new AddressCountriesState.StateSlice(store).state,
        }),
        p => (
            <SingleSelectionSlideinString
                portalId={SlideInPortalId.PARK_CREATE}
                open={p.accountSetupLayout.addressCountrySlideinOpen}
                label={addressTexts[p.settings.language].countryLabel()}
                onClose={() =>
                    p.update(store =>
                        AccountSetupState.Layout.stateWrite(store, {
                            addressCountrySlideinOpen: false,
                        }),
                    )
                }
                onSelect={id =>
                    p.update(store => selectAddressCountry(store, id))
                }
                options={getOptions(p)}
                selection={p.accountSetupAddress.country}
            />
        ),
    );
}
