import * as EmailValidator from 'email-validator';
import { css } from '@emotion/css';

import * as superagent from 'superagent';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    RequestStatus,
    ServerRequestState,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { Localized } from '../../common/components/Localized';
import { Colors } from 'dg-web-shared/ui/vars';
import { MaterialButton } from '../../ui/inputs/MaterialButton';
import { ErrorBlock } from '../root/components/ErrorBlock';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { LoginEmail } from './RequestedEmailReview';
import * as LoginsState from './state/LoginsState';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const UnconfirmedEmailReviewSlideIn = portalSlideIn<{
    login: LoginsState.GetLoginData | null;
}>(UnconfirmedEmailReview);

function UnconfirmedEmailReview(props: {
    portal: SlideInPortalId;
    onClose: () => void;
    login: LoginsState.GetLoginData | null;
}) {
    const { update } = useStore(() => {});
    const [anotherEmail, setAnotherEmail] = useState('');
    const [inputHappened, setInputHappened] = useState(false);

    const [httpPut, doHttpPut, resetHttpPut] = useServerWrite<
        { email: string },
        PutUnconfirmedEmailResponse
    >({
        req: args =>
            superagent
                .put(
                    `/ui-api/customer-account/logins-v2/${
                        props.login?.id ?? -1
                    }/unconfirmed-email`,
                )
                .send(args),

        onResponse: response => {
            if (
                response.status === RequestStatus.SUCCESS &&
                response.data.tag === PutUnconfirmedEmailResponseTag.SUCCESS
            ) {
                update(store => {
                    const loginsSlice = new LoginsState.StateSlice(store);
                    loginsSlice.reset.bind(loginsSlice);
                    return 'reset-login-state';
                });

                props.onClose();
            }
        },
    });

    if (!props.login || httpPut.status === RequestStatus.PENDING) {
        return <Spinner />;
    }

    return (
        <div
            className={css({
                padding: '24px',
                ...Typo.robotoRegular,
                fontSize: '16px',
                lineHeight: '22px',
                color: Colors.action_f,
                backgroundColor: Colors.form,
                overflowY: 'auto',
                height: '100%',
            })}
        >
            <TextField
                context={InputContext.form}
                value={props.login.email}
                readOnly={true}
                labelText={
                    <Localized
                        de="E-Mail"
                        fr="E-mail"
                        it="E-mail"
                        en="E-mail"
                    />
                }
            />

            <div>
                <Localized
                    de="Der Nutzer hat die E-Mail-Adresse noch nicht bestätigt; dafür hatten wir ihm ein Bestätigungslink per E-Mail geschickt."
                    fr="L'utilisateur n'a pas encore confirmé l'adresse e-mail; nous lui avons envoyé un lien de confirmation par e-mail."
                    it="L'utente non ha ancora confermato l'indirizzo e-mail; a questo scopo gli abbiamo inviato per e-mail il link di conferma."
                    en="The user has not yet confirmed the e-mail address; we sent him the confirmation link via e-mail for this purpose."
                />
            </div>

            <LoginEmail.LocalizedResendLinkForUser
                loginId={props.login.id}
                onSuccess={props.onClose}
                portal={props.portal}
            />

            <div className={css({ marginTop: '32px' })}>
                <Localized
                    de="Sollte die angegebene falsch sein, können Sie unten eine neue E-Mail-Adresse für diesen Benutzer eingeben."
                    fr="Dans le cas où celle spécifiée est erronée, vous pouvez entrer une nouvelle adresse e-mail pour cet utilisateur ci-dessous."
                    it="Qual'ora quello specificato fosse errato, qui sotto può inserire un nuovo indirizzo e-mail per questo utente."
                    en="In case the one specified is wrong, you can enter a new e-mail address for this user below."
                />
            </div>

            <TextField
                context={InputContext.form}
                value={anotherEmail}
                maxChars={100}
                labelText={
                    <Localized
                        de="Neue E-Mail-Adresse"
                        fr="Nouvelle adresse e-mail"
                        it="Nuovo indirizzo e-mail"
                        en="New e-mail address"
                    />
                }
                errorText={emailError(
                    props.login.email,
                    anotherEmail,
                    inputHappened,
                    httpPut,
                )}
                onChange={v => {
                    setInputHappened(true);
                    setAnotherEmail(v);

                    if (httpPut.status !== RequestStatus.NEVER_EXECUTED) {
                        resetHttpPut();
                    }
                }}
            />

            <div
                className={css({
                    textAlign: 'right',
                    width: '100%',
                    marginBottom: '16px',
                })}
            >
                <MaterialButton
                    onClick={() => {
                        doHttpPut({ email: anotherEmail });
                    }}
                    label={
                        <Localized
                            de="Neue Bestätigungslink verschicken"
                            fr="Envoyer nouveau lien de confirmation"
                            it="Invia nuovo link di conferma"
                            en="Send new confirmation link"
                        />
                    }
                    disabled={!isSubmittable(props.login.email, anotherEmail)}
                />
            </div>

            {httpPut.status === RequestStatus.ERROR && (
                <ErrorBlock
                    text={
                        <>
                            <Localized
                                de="Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Wenn das Problem weiterhin auftritt, melden Sie sich bei unserem Helpdesk."
                                fr="Une erreur est survenue. Merci de réessayer plus tard. Si le problème persiste, merci de l’annoncer à notre Helpdesk."
                                it="Si è verificato un errore. Vogliate riprovare più tardi. Se il problema dovesse persistere, contattate il nostro Helpdesk."
                                en="An error has occurred. Please try again later. If the problem persists, please contact our Helpdesk."
                            />

                            <p>HTTP {httpPut.httpStatusCode}</p>
                        </>
                    }
                />
            )}
        </div>
    );
}

function emailError(
    currentEmail: string,
    newEmail: string,
    inputHappened: boolean,
    httpPut: ServerRequestState<PutUnconfirmedEmailResponse, object>,
): JSX.Element | null {
    if (!inputHappened) {
        return null;
    }

    if (
        httpPut.status === RequestStatus.SUCCESS &&
        httpPut.data.tag === PutUnconfirmedEmailResponseTag.ADDRESS_IS_OCCUPIED
    ) {
        return (
            <Localized
                de="Diese E-Mail-Adresse ist bereits in einem Konto registriert"
                fr="Cette adresse e-mail est déjà enregistrée dans un compte"
                it="Questo indirizzo e-mail è già registrato in un conto"
                en="This e-mail address is already registered in an account"
            />
        );
    }

    if (equivalent('', newEmail)) {
        return (
            <Localized
                de="Darf nicht leer sein"
                fr="Ne peut pas être vide"
                it="Non può essere vuoto"
                en="Can't be empty"
            />
        );
    }

    if (equivalent(currentEmail, newEmail)) {
        return (
            <Localized
                de="Eine neue E-Mail-Adresse ist erwartet"
                fr="Une nouvelle adresse e-mail est attendue"
                it="È previsto un nuovo indirizzo e-mail"
                en="A new e-mail address is expected"
            />
        );
    }

    if (!EmailValidator.validate(newEmail)) {
        return (
            <Localized
                de="Diese Email-Adresse ist ungültig"
                fr="L'adresse e-mail n'est pas valide"
                it="L'indirizzo e-mail non é valido"
                en="The e-mail address is not valid"
            />
        );
    }

    return null;
}

function isSubmittable(currentEmail: string, newEmail: string): boolean {
    return (
        !equivalent('', newEmail) &&
        !equivalent(currentEmail, newEmail) &&
        EmailValidator.validate(newEmail)
    );
}

function equivalent(s1: string, s2: string): boolean {
    return s1.trim().toLowerCase() === s2.trim().toLowerCase();
}

type PutUnconfirmedEmailResponse = { tag: PutUnconfirmedEmailResponseTag };

enum PutUnconfirmedEmailResponseTag {
    SUCCESS = 'SUCCESS',
    ADDRESS_IS_OCCUPIED = 'ADDRESS_IS_OCCUPIED',
}
