import { ButtonText, ModalErrorBox } from '../../../ui/modals/Confirmable';
import { Localized } from '../Localized';

import {
    ProcessStep,
    TicketContent,
    TicketContentText,
    TicketInfoHeader,
    TicketSubtitle,
} from './TicketPlusLayout';
import { PaymentMethodEnum } from '../../../api/Http.ts';

export function TicketPlusTooManyApprovedTickets({
    activePaymentType,
}: {
    activePaymentType: PaymentMethodEnum;
}): JSX.Element {
    return (
        <TicketContent>
            <TicketInfoHeader>
                <Localized
                    de="Parking"
                    fr="Parking"
                    it="Parcheggio"
                    en="Parking"
                />
            </TicketInfoHeader>
            <TicketSubtitle>
                <TicketPlusTooManyApprovedTicketsModalTitle />
            </TicketSubtitle>
            <TicketContentText>
                <ProcessStep icon="ticket">
                    <TicketPlusTooManyApprovedTicketsModalMessage
                        activePaymentType={activePaymentType}
                    />
                </ProcessStep>
            </TicketContentText>
        </TicketContent>
    );
}

export function TicketPlusTooManyApprovedTicketsModal({
    onClose,
    activePaymentType,
}: {
    onClose: () => void;
    activePaymentType: PaymentMethodEnum;
}) {
    return (
        <ModalErrorBox
            titleCaption={<TicketPlusTooManyApprovedTicketsModalTitle />}
            confirmCaption={ButtonText.CANCEL}
            confirmCallback={onClose}
        >
            <TicketPlusTooManyApprovedTicketsModalMessage
                activePaymentType={activePaymentType}
            />
        </ModalErrorBox>
    );
}

export function TicketPlusTooManyApprovedTicketsModalTitle() {
    return (
        <Localized
            de="Zahlung nicht möglich"
            fr="Paiement pas possible"
            it="Pagamento non possibile"
            en="Payment not possible"
        />
    );
}

function TicketPlusTooManyApprovedTicketsModalMessage({
    activePaymentType,
}: {
    activePaymentType: PaymentMethodEnum;
}): JSX.Element {
    const isInvoiceActivePaymentType =
        activePaymentType === PaymentMethodEnum.INVOICE;
    return (
        <>
            <p>
                <Localized
                    de={`Bei dieser Zahlungsmethode wurde die maximal zulässige Anzahl gleichzeitig genehmigter Tickets (${isInvoiceActivePaymentType ? '10' : '3'}) bereits erreicht.`}
                    fr={`Avec ce mode de paiement, le nombre maximum de tickets (${isInvoiceActivePaymentType ? '10' : '3'}) autorisés simultanément a déjà été atteint.`}
                    it={`Con questo metodo di pagamento, il numero massimo di ticket (${isInvoiceActivePaymentType ? '10' : '3'}) approvati contemporaneamente è già stato raggiunto.`}
                    en={`With this payment method, the maximum number of simultaneously approved tickets (${isInvoiceActivePaymentType ? '10' : '3'}) has already been reached.`}
                />
            </p>
            <p>
                <Localized
                    de="Bitte bezahlen Sie dieses Ticket mit einer anderen Zahlungsmethode."
                    fr="Veuillez payer ce ticket avec une autre mode de paiement."
                    it="Voglia pagare questo ticket con un’altro metodo di pagamento."
                    en="Please pay for this ticket using a different payment method."
                />
            </p>
        </>
    );
}
