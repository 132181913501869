import { MessageTemplate } from 'dg-web-shared/lib/Localized';
import { Gender } from '../../account-setup/state/AccountSetupState';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { UnderlineStatus } from 'dg-web-shared/tb-ui/inputs/internal/Underline.tsx';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { GenderSelect } from 'dg-web-shared/tb-ui/inputs/GenderSelect.tsx';
import { useState } from 'react';
import { EditForm } from './EditForm';
import { writeEditLoginData } from './components/NewsletterEdit';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ErrorBlock } from '../root/components/ErrorBlock';
import { GenericErrorBody } from '../../ui/modals/HandleHttpStati';

export const GenderEditFormSlideIn = portalSlideIn(GenderEditForm);

function GenderEditForm(props: {
    title: React.ReactNode;
    open: boolean;
    currentLogin: CurrentLoginState.CurrentLogin;
    onClose: () => void;
}) {
    const [gender, setGender] = useState(props.currentLogin.gender);

    const editData = {
        ...props.currentLogin,
        gender: gender,
    };

    const [httpPut, doHttpPut] = writeEditLoginData(
        props.onClose,
        false,
        'gender-edit',
    );

    return (
        <EditForm
            isSaveable={!!gender && props.currentLogin.gender !== gender}
            onClose={props.onClose}
            onSave={() => {
                doHttpPut(editData);
            }}
            pending={httpPut.status === RequestStatus.PENDING}
        >
            <GenderSelect
                gender={gender || props.currentLogin.gender}
                underlineStatus={UnderlineStatus.none}
                errorText={''}
                styleContext={InputContext.form}
                writeGender={(gender: 'm' | 'f') => setGender(gender)}
            />
            {httpPut.status === RequestStatus.ERROR && (
                <ErrorBlock text={<GenericErrorBody />} />
            )}
        </EditForm>
    );
}

export const genderMessages: {
    title: MessageTemplate<Gender>;
} = {
    title(g: Gender) {
        if (g === 'm') {
            return {
                de: 'Herr',
                fr: 'Monsieur',
                it: 'Signor',
                en: 'Mr',
            };
        }

        return {
            de: 'Frau',
            fr: 'Madame',
            it: 'Signora',
            en: 'Ms',
        };
    },
};
