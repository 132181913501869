import { css } from '@emotion/css';
import { clearfix } from '../../ui/clearfix';
import React from 'react';

export function ButtonRow(props: React.DOMAttributes<void>) {
    return (
        <div
            className={css([
                {
                    padding: '16px 0',
                },
                clearfix,
            ])}
        >
            {React.Children.map(props.children, c => (
                <div
                    className={css({
                        float: 'right',
                        width: '50%',
                    })}
                >
                    {c}
                </div>
            ))}
        </div>
    );
}
