import { generatePersistentStateSlice } from 'dg-web-shared/lib/PersistentStateSlice';
import { HAS_EVER_LOGGED_IN_STATE_KEY } from 'dg-web-shared/lib/LocalStorage.ts';

export namespace HasEverLoggedInState {
    export interface State {
        hasEver: boolean;
    }

    export const { get, stateWrite } = generatePersistentStateSlice(
        HAS_EVER_LOGGED_IN_STATE_KEY,
        {
            hasEver: false,
        },
    );
}
