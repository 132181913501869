import { css } from '@emotion/css';

import * as superagent from 'superagent';
import { thenElse } from 'dg-web-shared/lib/MaybeV2';
import {
    generateContextualReadStateSlice,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { SingleSelection } from 'dg-web-shared/tb-ui/inputs/SingleSelection.tsx';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import { requestV2 } from '../../AsyncRequest';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { GeolocationState } from '../../common/state/GeolocationState';
import * as SettingsState from '../../common/state/SettingsState';
import * as LayoutState from '../../layout/state/LayoutState';
import { ResponsiveProperties } from '../../layout/utils/ResponsiveProperties';
import * as Geolocation from 'dg-web-shared/common/utils/Geolocation';
import * as DropinActions from '../actions/DropinActions';
import * as ParkCreateTexts from '../i18n/ParkCreateTexts';
import * as CityDropinState from '../state/CityDropinState';
import * as ParkOptionListState from '../state/ParkOptionListState';
import { CitySelectionSlideIn } from './park-option/CityDropin';
import { ZoneSelectionSlideIn } from './park-option/ZoneDropin';
import { ParkVariantSelection } from './ParkVariantSelection';
import { ArrowPosition, InlineInfoBox } from '../../ui/modals/Confirmable';
import * as ParkCreateActions from '../actions/ParkCreateActions';
import { logAction } from '../../utils/ActionLog';
import { envIsProduction, envIsTest } from 'dg-web-shared/lib/Environment';
import { useDocumentVisibilityEffect } from 'dg-web-shared/common/hooks/useDocumentVisibility';
import * as Flux from 'dg-web-shared/lib/Flux';
import { useStore, useUpdate } from 'dg-web-shared/lib/Flux';
import { useParkingpayLanguage } from '../../utils/UseParkingpayLanguage';
import { useTransactionList } from '../../transactions-list/TransactionsListContext';
import { RecentsParkCreate } from './recents/RecentsParkCreate';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { AuthStatusType, useAuthState } from '../../app/state/AuthState';
import { SpinnerModal } from '../../ui/spinner/Spinner.tsx';
import { useEffect, useState } from 'react';

const PRECISION_IN_METERS_BEFORE_STOP_FETCHING_GPS = 100;
const LOCATION_EXPIRATION_TIME = 1000 * 60 * 10; // ms s m

function ZoneSelection({
    selectedCity,
    authenticated,
    location,
}: {
    selectedCity: CitiesState.City;
    authenticated: boolean;
    location: Geolocation.Location | null;
}) {
    const { store, storeState, update } = useStore(s => ({
        zones: ZonesOfCityState.get(s, {
            zipCode: selectedCity.zipCode,
            location: location,
            isAuthed: authenticated,
        }),
        selection: ParkOptionListState.Selection.get(s),
        currentLogin: CurrentLoginState.get(s),
        settings: new SettingsState.StateSlice(s).state,
    }));

    const selectedZone =
        storeState.zones.data &&
        storeState.zones.data.zones.filter(
            z => z.id === storeState.selection.selectedParkOptionId,
        )[0];

    return (
        <div>
            <SpinnerModal
                visible={
                    storeState.zones.status === 'pending' ||
                    storeState.currentLogin.status === 'pending'
                }
                portal={SlideInPortalId.PARK_CREATE}
            />
            <ZoneSelectionDropdown
                selectedCity={selectedCity}
                zones={storeState.zones}
                selectedZone={selectedZone}
                authenticated={authenticated}
            />
            <ParkVariantSelection
                language={storeState.settings.language}
                selectedCity={selectedCity}
                selectedZone={selectedZone}
                permitsCount={storeState.zones.data?.permitsCount ?? 0}
                barrierGateZonesCount={
                    storeState.zones.data?.barrierGateZonesCount ?? 0
                }
                zoneEnforcedCount={
                    storeState.zones.data?.zoneEnforcedCount ?? 0
                }
                selectedVariant={storeState.selection.variant || null}
                update={update}
                allState={store}
            />
            <ZoneSelectionSlideIn
                selectedCity={selectedCity}
                zones={storeState.zones.data?.zones || []}
            />
        </div>
    );
}

interface LocationSelectionDownwardsProps {
    selectedCity: CitiesState.City | null;
    cities: Readonly<CitiesState.City[]>;
    language: string;
    authenticated: boolean;
    mobile: boolean;
    precision?: number;
    updatingLocation: boolean;
    location: Geolocation.Location | null;
}

const ZoneSelectionDropdown = (p: {
    selectedCity: CitiesState.City;
    zones: ZonesOfCityState.State;
    selectedZone: ZonesOfCityState.Zone | null;
    authenticated: boolean;
}) => {
    const update = useUpdate();
    const language = useParkingpayLanguage();
    const txt = ParkCreateTexts.optionListTexts[language];
    return (
        <SingleSelection
            labelText={txt.ParkOption()}
            onClick={
                p.zones.data && p.zones.data.zones.length > 1
                    ? () => update(DropinActions.ZoneDropin.open)
                    : null
            }
            selection={thenElse(
                p.selectedZone,
                po =>
                    `${thenElse(po.extZoneCode, c => c.toString(), '')} ${
                        po.name
                    }`,
                null,
            )}
            context={InputContext.inverted}
        />
    );
};

export namespace CitiesState {
    export interface City {
        zipCode: string;
        names: string[];
        distance: number;
    }

    export interface Context {
        location: Geolocation.Location | null;
        isAuthed: boolean;
    }

    export type State = ServerRequestState<City[]>;

    export const { get, refetchSameContext } = generateContextualReadStateSlice<
        Context,
        City[]
    >({
        key: 'city-selection-cities-list',
        onShouldFetch: (store, context, setResponse) => {
            store.update(
                requestV2(
                    () =>
                        superagent.get('/ui-api/all-cities').query(
                            context.location
                                ? {
                                      lon: context.location.longitude,
                                      lat: context.location.latitude,
                                  }
                                : {},
                        ),
                    (s, res) => {
                        setResponse(s, res);
                    },
                ),
            );
        },
    });
}

export namespace ZonesOfCityState {
    export enum ParkOptionVariant {
        permit = 'permit',
        zoneEnforcedCheckin = 'zoneEnforcedCheckin',
        barrierGateCheckin = 'barrierGateCheckin',
    }

    export interface Zone {
        id: number;
        name: string;
        extZoneCode: number | null;
        distance: number | null;
        zipCode: string;
        city: string;
        variants: ParkOptionVariant[];
        operatorName: string;
    }

    export interface Context {
        zipCode: string;
        location: Geolocation.Location | null;
        isAuthed: boolean;
    }

    export type Data = {
        zones: Zone[];
        permitsCount: number;
        zoneEnforcedCount: number;
        barrierGateZonesCount: number;
    };
    export type State = ServerRequestState<Data>;

    export const { get, refetchSameContext } = generateContextualReadStateSlice<
        Context,
        Data
    >({
        key: 'city-selection-zone-of-city',
        onShouldFetch: (store, context, setResponse) => {
            store.update(
                requestV2(
                    () =>
                        superagent
                            .get(`/ui-api/city-options-v2/${context.zipCode}`)
                            .query(
                                context.location
                                    ? {
                                          lon: context.location.longitude,
                                          lat: context.location.latitude,
                                      }
                                    : {},
                            ),
                    (s, res) => {
                        if (res.body && res.body.zones.length === 1) {
                            s.update(store =>
                                ParkOptionListState.Selection.setParkOptionId(
                                    store,
                                    res.body.zones[0].id,
                                ),
                            );
                        }
                        setResponse(s, res);
                    },
                ),
            );
        },
    });
}

function CitySelection(p: LocationSelectionDownwardsProps) {
    const update = useUpdate();
    const txt = ParkCreateTexts.optionListTexts[p.language];
    if (p.cities.length === 0) {
        return null;
    }

    return (
        <>
            <SingleSelection
                labelText={txt.Location()}
                onClick={() => update(DropinActions.CityDropin.open)}
                selection={thenElse(
                    p.selectedCity,
                    l =>
                        l.names.length === 1
                            ? `${l.zipCode.slice(0, 4)} ${l.names[0]}`
                            : l.names.map(n => (
                                  <div key={l.zipCode + n}>{`${l.zipCode.slice(
                                      0,
                                      4,
                                  )} ${n}`}</div>
                              )),
                    null,
                )}
                context={InputContext.inverted}
            />
            <CitySelectionSlideIn cities={p.cities} />
        </>
    );
}

function isAccurateEnough(accuracy?: number) {
    return (
        (accuracy || Infinity) < PRECISION_IN_METERS_BEFORE_STOP_FETCHING_GPS
    );
}

export function ParkOptionList() {
    const [keepUpdating, setKeepUpdating] = useState(true);
    const [componentCreateTimestamp] = useState(Date.now());
    const [locationAndCitiesInSync, setLocationAndCitiesInSync] =
        useState(false);

    const [usableLocation, setUsableLocation] =
        useState<Geolocation.Location | null>(null);
    const { authStatus } = useAuthState();

    const { storeState, update } = Flux.useStore(s => {
        const geoState = GeolocationState.get(s);

        return {
            settings: new SettingsState.StateSlice(s).state,
            selection: ParkOptionListState.Selection.get(s),
            cities: CitiesState.get(
                s,
                authStatus == AuthStatusType.UNKNOWN
                    ? null
                    : {
                          isAuthed: authStatus === AuthStatusType.OK,
                          location: usableLocation,
                      },
            ),
            cityDropin: new CityDropinState.StateSlice(s).state,
            layout: new LayoutState.StateSlice(s).state,
            geolocation: geoState,
        };
    });

    useEffect(() => {
        switch (storeState.cities.status) {
            case RequestStatus.NEVER_EXECUTED:
            case RequestStatus.PENDING:
            case RequestStatus.ERROR:
                return setLocationAndCitiesInSync(false);
            case RequestStatus.SUCCESS:
                return setLocationAndCitiesInSync(true);
        }
    }, [storeState.cities.status]);

    const [windowLeaveTimestamp, setWindowLeaveTimestamp] = useState<
        number | null
    >(null);

    useDocumentVisibilityEffect(
        visibilityState => {
            if (visibilityState === 'hidden') {
                const now = Date.now();
                setWindowLeaveTimestamp(now);
            } else if (visibilityState === 'visible') {
                // iPhones keep the app always loaded in the background, therefore
                // the position won't get updated when the user reopens the app.
                // watching the window focus event allow us to refetch the location if a considerable amount of time has passed.
                if (
                    windowLeaveTimestamp !== null &&
                    !storeState.cityDropin.open
                ) {
                    const expiredTime = Date.now() - windowLeaveTimestamp;
                    setWindowLeaveTimestamp(null);

                    if (expiredTime >= LOCATION_EXPIRATION_TIME) {
                        update(ParkCreateActions.resetCity);
                        setKeepUpdating(true);
                    }
                }
            }
        },
        [windowLeaveTimestamp],
    );

    useEffect(() => {
        if (keepUpdating) {
            if (usableLocation !== storeState.geolocation.currentLocation) {
                setUsableLocation(storeState.geolocation.currentLocation);
                setLocationAndCitiesInSync(false);
            }

            if (
                isAccurateEnough(
                    storeState.geolocation.currentLocation?.accuracy,
                )
            ) {
                setKeepUpdating(false);
            }
        }
    }, [storeState.geolocation.currentLocation, keepUpdating]);

    useEffect(() => {
        // if the zip code is already selected means the position has changed and the cities list was refetched
        // making the effect above having selected one.
        if (
            !keepUpdating &&
            !storeState.selection.selectedZipCode &&
            storeState.cities.status === RequestStatus.SUCCESS &&
            locationAndCitiesInSync &&
            usableLocation &&
            isAccurateEnough(usableLocation.accuracy)
        ) {
            const zipCode = storeState.cities.data[0]?.zipCode;

            update(s => {
                return logAction(s, 'autoselect-city-from-location', {
                    zipCode: zipCode,
                    elapsedMillisecods: +Date.now() - +componentCreateTimestamp,
                    location,
                });
            });
            update(store => ParkCreateActions.setCity(store, zipCode));
        }
    }, [
        keepUpdating,
        storeState.cities.status,
        storeState.cities.data,
        locationAndCitiesInSync,
    ]);

    useEffect(() => {
        if (storeState.cityDropin.open && keepUpdating) {
            setKeepUpdating(false);
        }
    }, [storeState.cityDropin.open]);

    const selectedCity = storeState.cities.data
        ? storeState.cities.data.find(
              c => c.zipCode === storeState.selection.selectedZipCode,
          ) || null
        : null;

    const txt = ParkCreateTexts.optionListTexts[storeState.settings.language];
    const [transactionList, refetchTransactionList] = useTransactionList();
    const showRecents =
        transactionList.status != RequestStatus.ERROR &&
        transactionList.data &&
        transactionList.data.recents.showRecents;

    return (
        <div
            className={css({
                padding: '10px 24px',
            })}
        >
            <CitySelection
                language={storeState.settings.language}
                selectedCity={selectedCity}
                cities={storeState.cities.data || []}
                authenticated={authStatus === AuthStatusType.OK}
                mobile={new ResponsiveProperties(storeState).mobile}
                location={usableLocation}
                precision={usableLocation?.accuracy}
                updatingLocation={keepUpdating}
            />
            {!envIsProduction() &&
                !envIsTest() &&
                usableLocation?.accuracy +
                    ' meters of precision (not visible in prod/test)'}
            {selectedCity && (
                <ZoneSelection
                    authenticated={authStatus === AuthStatusType.OK}
                    selectedCity={selectedCity}
                    location={usableLocation}
                />
            )}
            {!selectedCity && !showRecents && (
                <InlineInfoBox
                    titleCaption={txt.WelcomeNotificationTitle()}
                    arrowPosition={ArrowPosition.left}
                >
                    <p>{txt.WelcomeNotificationBody()}</p>
                </InlineInfoBox>
            )}
            {showRecents && (
                <RecentsParkCreate
                    recentTransactions={
                        transactionList.data.recents.recentsList
                    }
                    refetchTransactionList={refetchTransactionList}
                />
            )}
        </div>
    );
}
