import { useUpdate } from 'dg-web-shared/lib/Flux';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import { ErrorBlock } from '../../root/components/ErrorBlock';
import { GenericErrorBody } from '../../../ui/modals/HandleHttpStati';

import { useState } from 'react';
import { NotificationsBlock } from './NotificationsBlock';
import { Localized } from '../../../common/components/Localized';
import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { useServerWrite } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { CurrentLoginPutData } from '../../../api/Http';
import * as superagent from 'superagent';
import { logAction } from '../../../utils/ActionLog';
import * as LoginsState from '../../logins/state/LoginsState';

export const NewsletterEditForm = (p: {
    currentLoginData: CurrentLoginState.CurrentLogin;
}) => {
    const currentLoginState = p.currentLoginData.newsletterStatus;
    const [newsletterStatus, setNewsletterStatus] = useState(currentLoginState);
    const [expanded, setExpanded] = useState(false);

    const [httpPut, doHttpPut, resetPut] = writeEditLoginData(
        () => setExpanded(false),
        false,
        'newsletter-edit',
    );

    const isSaveable = newsletterStatus !== p.currentLoginData.newsletterStatus;
    const pending = httpPut.status === RequestStatus.PENDING;
    return (
        <NotificationsBlock
            caption={
                <Localized
                    de="Newsletter"
                    fr="Newsletter"
                    it="Newsletter"
                    en="Newsletter"
                />
            }
            contentElement={
                <>
                    <RenderEditContent
                        currentLoginData={p.currentLoginData}
                        newsletterStatus={newsletterStatus}
                        setNewsletterStatus={setNewsletterStatus}
                    />
                    {httpPut.status === RequestStatus.ERROR && (
                        <ErrorBlock text={<GenericErrorBody />} />
                    )}
                </>
            }
            toggle={() => {
                setNewsletterStatus(currentLoginState);
                setExpanded(!expanded);
                resetPut();
            }}
            expanded={expanded}
            onSave={() => {
                doHttpPut({
                    ...p.currentLoginData,
                    newsletterStatus: newsletterStatus,
                });
            }}
            disabled={!isSaveable}
            text={
                p.currentLoginData.newsletterStatus === 'SUBSCRIBED' ? (
                    <Localized de="Aktiv" fr="Active" it="Attiva" en="Active" />
                ) : (
                    <Localized
                        de="Inaktiv"
                        fr="Inactive"
                        it="Inattiva"
                        en="Disabled"
                    />
                )
            }
            pending={pending}
        />
    );
};

interface RenderEditContentProps {
    currentLoginData: CurrentLoginState.CurrentLogin;
    newsletterStatus: string;
    setNewsletterStatus: (newsletterStatus: string) => void;
}

const RenderEditContent = (p: RenderEditContentProps) => {
    const enabled = p.newsletterStatus === 'SUBSCRIBED';

    const disabled =
        p.currentLoginData.newsletterStatus === 'UNSUBSCRIBED_BY_MAILCHIMP' ||
        p.currentLoginData.newsletterStatus === 'DENIED_BY_MAILCHIMP';

    return (
        <>
            <LabeledToggle
                disabled={disabled}
                context={InputContext.form}
                type={ToggleType.checkbox}
                selected={enabled}
                label={
                    <Localized
                        de="Newsletter"
                        fr="Newsletter"
                        it="Newsletter"
                        en="Newsletter"
                    />
                }
                onClick={() => {
                    toggleNewsletterStatus(!enabled, p.setNewsletterStatus);
                }}
            />
            {disabled && (
                <Localized
                    de="Der Newsletter kann aktuell nicht aktiviert werden. Bitte kontaktieren Sie unseren Helpdesk."
                    fr="La newsletter ne peut actuellement pas être activée. Veuillez contacter notre helpdesk."
                    it="La newsletter al momento non può essere attivata. Vogliate contattare il nostro helpdesk."
                    en="The newsletter can not be activated at the moment. Please contact our helpdesk."
                />
            )}
        </>
    );
};

const toggleNewsletterStatus = (
    value: boolean,
    setNewsletterStatus: (newsletterStatus: string) => void,
) => {
    if (value) {
        setNewsletterStatus('SUBSCRIBED');
    } else {
        setNewsletterStatus('UNSUBSCRIBED_BY_US');
    }
};

export function writeEditLoginData(
    toggleSlidein: () => void,
    requestEmail: boolean,
    logActionName: string,
) {
    const update = useUpdate();
    return useServerWrite<CurrentLoginPutData, object, { code: number }>({
        req: (putData: CurrentLoginPutData) => {
            return superagent
                .put('/ui-api/customer-account/current-login-v2')
                .send({
                    ...putData,
                    requestedEmail: requestEmail
                        ? putData.requestedEmail
                        : null,
                });
        },
        onResponse: (res, payload) => {
            if (res.status === RequestStatus.SUCCESS) {
                toggleSlidein();
                update(store => {
                    const server = CurrentLoginState.get(store);
                    logAction(store, logActionName, {
                        old: server,
                        new: payload,
                    });
                    CurrentLoginState.reset(store);
                    new LoginsState.StateSlice(store).reset();
                    return 'reset-read-state-after-reset';
                });
            }
        },
    });
}
