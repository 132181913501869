import { css } from '@emotion/css';

import { useState } from 'react';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { Localized } from '../../common/components/Localized';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { Colors } from 'dg-web-shared/ui/vars';
import * as Fields from '../../utils/Fields';
import { Email, EmailState, hasState } from '../../utils/Fields';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { EditForm } from './EditForm';
import { writeEditLoginData } from './components/NewsletterEdit';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ErrorBlock } from '../root/components/ErrorBlock';
import { GenericErrorBody } from '../../ui/modals/HandleHttpStati';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const EmailEditFormSlideIn = portalSlideIn(EmailEditForm);

function emailErrorText(field: Email) {
    if (hasState(field, EmailState.INVALID)) {
        return (
            <Localized
                de="Diese Email-Adresse ist ungültig."
                fr="L'adresse e-mail n'est pas valide."
                it="L'indirizzo e-mail non é valido."
                en="The e-mail address is not valid."
            />
        );
    }
    return '';
}

function EmailEditForm(props: {
    title: React.ReactNode;
    open: boolean;
    currentLogin: CurrentLoginState.CurrentLogin;
    onClose: () => void;
}) {
    const [email, setEmail] = useState('');

    const email1 = new Fields.Email(email);

    const editData = {
        ...props.currentLogin,
        requestedEmail: email,
    };

    const [httpPut, doHttpPut] = writeEditLoginData(
        props.onClose,
        true,
        'email-edit',
    );

    return (
        <EditForm
            isSaveable={
                props.currentLogin.email !== email1.value.toLocaleLowerCase() &&
                Fields.noStates(email1) &&
                !email1.isEmpty
            }
            onClose={props.onClose}
            onSave={() => {
                doHttpPut(editData);
            }}
            pending={httpPut.status === RequestStatus.PENDING}
        >
            <TextField
                context={InputContext.form}
                value={email1.value}
                labelText={
                    <Localized
                        de="Neue E-Mail-Adresse"
                        fr="Nouvelle adresse e-mail"
                        it="Nuovo indirizzo e-mail"
                        en="New e-mail address"
                    />
                }
                errorText={emailErrorText(email1)}
                onChange={v => setEmail(v)}
            />

            <p
                className={css({
                    ...Typo.robotoRegular,
                    color: Colors.action_f,
                    fontSize: '16px',
                    lineHeight: '22px',
                    marginBottom: '18px',
                })}
            >
                <Localized
                    de="Ein Bestätigungslink wird per E-Mail an die neue Adresse gesendet. Bitte öffnen Sie den Link, um die Änderung der E-Mail-Adresse zu bestätigen."
                    fr="Le lien de confirmation sera envoyé par e-mail à la nouvelle adresse. Veuillez ouvrir le lien afin de finaliser le changement d'adresse e-mail."
                    it="Il link di conferma verrà inviato via e-mail al nuovo indirizzo. La preghiamo di aprire il link per confermare la modifica dell'indirizzo e-mail."
                    en="The e-mail confirmation link will be sent to the new address. Please open the link in order to confirm the e-mail address change."
                />
            </p>
            <p
                className={css({
                    ...Typo.robotoRegular,
                    color: Colors.action_f,
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '22px',
                    marginBottom: '18px',
                })}
            >
                <Localized
                    de="Bis zur Bestätigung bleibt die aktuelle E-Mail-Adresse gültig."
                    fr="L'adresse e-mail actuelle reste valide jusqu'à confirmation."
                    it="L'indirizzo e-mail attuale rimane valido fino alla conferma."
                    en="The current e-mail address remains valid until confirmation."
                />
            </p>
            {httpPut.status === RequestStatus.ERROR &&
                (httpPut.data?.code === -2016 ? (
                    <ErrorBlock
                        text={
                            <Localized
                                de="Diese E-Mail-Adresse ist bereits in einem Konto registriert."
                                fr="Cette adresse e-mail est déjà enregistrée dans un compte."
                                it="Questo indirizzo e-mail è già registrato in un conto."
                                en="This e-mail address is already registered in an account."
                            />
                        }
                    />
                ) : (
                    <ErrorBlock text={<GenericErrorBody />} />
                ))}
        </EditForm>
    );
}
