import { DateTime } from 'luxon';

import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice';
import { generateState, Store, useStore } from 'dg-web-shared/lib/Flux';
import { AccountSetup } from '../../account-setup/components/AccountSetup';
import * as MetaServerState from '../../account/meta/state/MetaServerState';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import * as AsyncRequest from '../../AsyncRequest';
import * as Text from '../../common/i18n/Text';
import * as SettingsState from '../../common/state/SettingsState';
import { MobileHeader } from '../../layout/components/header/HeaderMobile';
import * as LayoutState from '../../layout/state/LayoutState';
import { columnsVariant } from '../../layout/utils/columnsVariant';
import { ResponsiveProperties } from '../../layout/utils/ResponsiveProperties';
import { responsiveVariant } from '../../layout/utils/responsiveVariant';
import { RunningCheckinByPlate } from '../../transactions-list/checkin-by-plate/RunningCheckinByPlate';
import { CheckinByPlateDetailState } from '../../transactions-list/state/TransactionsListState';
import { isProd } from '../../utils/Env';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import * as ParkCreateTexts from '../i18n/ParkCreateTexts';
import { ParkOptionList } from './ParkOptionList';
import { PaymentCallback } from '../../common/components/PaymentCallback';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { TICKET_SCAN_BAR_HEIGHT_PX } from '../ticket/ParkCreateTicketPlus';
import { useParkingpayLanguage } from '../../utils/UseParkingpayLanguage';
import { AuthStatusType, useAuthState } from '../../app/state/AuthState';
import { CoreAppOutlet } from '../../app/components/CoreAppOutletContext';
import { QrCodeScanner } from './QrCodeScanner';
import { EmptyBackground, SpinnerModal } from '../../ui/spinner/Spinner.tsx';

export interface Texts {
    Title: Text.Translation;
    TitleUnauth: Text.Translation;
}

export interface ParkCreateState {
    layout: LayoutState.State;
    settings: SettingsState.State;
    meta: MetaServerState.State;
    runningCheckinByPlateId: StartedCheckinByPlateOnThisDeviceState.State;
    runningCheckinByPlate: CheckinByPlateDetailState.State | null;
}

export enum ParkCreateMode {
    transaction = 'transaction',
    permit = 'permit',
    badge = 'badge',
}

export function ParkCreate() {
    const { storeState } = useStore<ParkCreateState>(store => {
        const checkinById = StartedCheckinByPlateOnThisDeviceState.get(store);
        return {
            layout: new LayoutState.StateSlice(store).state,
            settings: new SettingsState.StateSlice(store).state,
            meta: new MetaServerState.StateSlice(store).state,
            runningCheckinByPlateId: checkinById,
            runningCheckinByPlate:
                checkinById.checkinByPlateId != null
                    ? StartedCheckinByPlateOnThisDeviceDetailState.get(store, {
                          id: checkinById.checkinByPlateId,
                      })
                    : null,
        };
    });
    const { authStatus } = useAuthState();

    const isMobile = new ResponsiveProperties(storeState).mobile;
    const renderAccountSetup =
        authStatus === AuthStatusType.OK &&
        storeState.meta.data &&
        !storeState.meta.data.registrationComplete;

    const slicesPending =
        storeState.meta.pending ||
        !!(
            storeState.runningCheckinByPlate &&
            storeState.runningCheckinByPlate.pending &&
            !storeState.runningCheckinByPlate.data
        );

    const runningCheckinByPlateData = storeState.runningCheckinByPlate?.data;
    const hasRunningCheckinByPlate = !!(
        storeState.runningCheckinByPlateId &&
        storeState.runningCheckinByPlate &&
        runningCheckinByPlateData &&
        runningCheckinByPlateData.isRunning &&
        runningCheckinByPlateData.autoTerminationTime &&
        DateTime.fromISO(runningCheckinByPlateData.autoTerminationTime) >
            DateTime.local()
    );

    // no idea where this comes from :(
    const bottomOffsetPixels =
        authStatus === AuthStatusType.OK && isMobile ? 48 : 0;

    const bottomScanBarOffsetPixel =
        isMobile && !hasRunningCheckinByPlate ? TICKET_SCAN_BAR_HEIGHT_PX : 0;

    return (
        <ParkCreateContainer layout={storeState.layout}>
            <SpinnerModal
                visible={slicesPending}
                portal={SlideInPortalId.PARK_CREATE}
            />
            {slicesPending ? (
                <EmptyBackground />
            ) : renderAccountSetup ? (
                <AccountSetup />
            ) : (
                <div>
                    <ParkCreateHeader />
                    <div
                        className={css({
                            position: 'absolute',
                            width: '100%',
                            top:
                                authStatus !== AuthStatusType.OK &&
                                responsiveVariant(storeState) === 'Mobile'
                                    ? '64px'
                                    : '48px',
                            bottom: `  ${
                                bottomOffsetPixels + bottomScanBarOffsetPixel
                            }px`,
                        })}
                    >
                        <div
                            className={css({
                                position: 'relative',
                                height: '100%',
                                overflowX: 'hidden',
                                overflowY: 'auto',
                                WebkitOverflowScrolling: 'touch',
                            })}
                        >
                            {hasRunningCheckinByPlate ? (
                                <RunningCheckinByPlate
                                    isPrimaryTransactionInParkCreate={true}
                                    transaction={runningCheckinByPlateData}
                                    portal={SlideInPortalId.PARK_CREATE}
                                />
                            ) : (
                                <ParkOptionList />
                            )}
                        </div>
                    </div>
                    {!hasRunningCheckinByPlate && (
                        <QrCodeScanner isMobile={isMobile} />
                    )}
                    <CoreAppOutlet
                        context={{
                            hasRunningCheckinByPlate,
                        }}
                    />
                </div>
            )}
            {
                /* it's a bit strange and arbitrary to mount the datatrans callback here, but
                    otherwise it will not render since the modal div has not rendered yet
                    */
                <PaymentCallback />
            }
        </ParkCreateContainer>
    );
}

const ParkCreateContainer = (p: {
    layout: LayoutState.State;
    children?: JSX.Element[];
}) => {
    return (
        <div
            className={css({
                position: 'relative',
                background: Colors.blue,
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
                ...paper(columnsVariant(p) === 'OneColumn' ? 0 : 4),
                borderRadius: columnsVariant(p) === 'OneColumn' ? 0 : '2px',
            })}
        >
            {p.children}
            <ParkCreateSlideIns />
        </div>
    );
};

const ParkCreateSlideIns = () => {
    return <div id={SlideInPortalId.PARK_CREATE} />;
};

function ParkCreateHeader() {
    const { storeState } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        layout: new LayoutState.StateSlice(store).state,
    }));
    const responsiveProperties = new ResponsiveProperties(storeState);

    if (responsiveProperties.mobile) {
        return <MobileHeader />;
    }

    if (!responsiveProperties.mobile) {
        return <DesktopHeader />;
    }
    return null;
}

function DesktopHeader() {
    const language = useParkingpayLanguage();
    const texts = ParkCreateTexts.parkCreateTexts[language];

    return (
        <div
            className={css({
                background: isProd() ? Colors.darkblue : Colors.yellow,
                height: '48px',
                color: Colors.white,
                ...Typo.heading1,
                paddingTop: 14 - Typo.heading1TopCorrection,
                paddingLeft: '24px',
            })}
        >
            {texts.Title()}
        </div>
    );
}

export namespace StartedCheckinByPlateOnThisDeviceDetailState {
    export const { get, getState, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<
            CheckinByPlateDetailState.Context,
            CheckinByPlateDetailState.CheckinByPlate
        >({
            key: 'park-create runningcheckinByPlateState',
            requestGenerator: CheckinByPlateDetailState.requestGenerator,
            request: AsyncRequest.requestV2,
            parseBody: b => b,
            refreshIntervalSecs: 60,
            onRequest: (
                store: Store,
                state: CheckinByPlateDetailState.State,
            ) => {
                if (state.data && !state.data.isRunning) {
                    // this call seems to be related to a double-render issue that happens when
                    // the GPS location sets the city automatically with a delay
                    // to reproduce the issue: refresh the app until the city is not initially set
                    // wait the city to autoselect and see the error 'Cannot update during an existing state transition'
                    StartedCheckinByPlateOnThisDeviceState.clear(store);
                }
            },
        });
}

export namespace ParkCreateAddVehicleState {
    export interface State {
        addVehicle: boolean;
        addBadge: boolean;
        selectedVehicleId: number | null;
    }

    export const { get, reset, stateWrite } = generateState<State>(
        'license-plate-selection-vehicle',
        {
            addVehicle: false,
            addBadge: false,
            selectedVehicleId: null,
        },
    );
}

export namespace StartedCheckinByPlateOnThisDeviceState {
    export interface State {
        checkinByPlateId: number | null;
    }

    const s = generateState<State>('park-create-started-checkin', () => {
        const s = LocalStorage.getSerializableItem(
            LocalStorage.Items.startedCeckinByPlateOnThisDevice,
        ) as State;
        if (s) {
            return s;
        }
        return { checkinByPlateId: null };
    });
    export const get = s.get;
    export const clear = (store: Store) => {
        LocalStorage.setSerializableItem(
            LocalStorage.Items.startedCeckinByPlateOnThisDevice,
            null,
        );
        return s.reset(store);
    };
    export const setCreatedTransaction = (store: Store, id: number) => {
        const name = s.stateWrite(store, { checkinByPlateId: id });
        LocalStorage.setSerializableItem(
            LocalStorage.Items.startedCeckinByPlateOnThisDevice,
            s.get(store),
        );
        return name;
    };
}
