import * as superagent from 'superagent';
import { Localized } from '../../common/components/Localized';
import {
    PendingState,
    RequestStatus,
    SuccessState,
    useServerReadAndUpdate,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { SlideInForm } from '../root/components/SlideInForm';
import { FieldItemBlock } from '../root/components/ItemBlock';
import {
    portalSlideIn,
    PortalSlideInProps,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { GenericError } from '../logins/EditForm';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import * as LoginsState from '../logins/state/LoginsState';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    MetaFormsVisibilityStateSlice,
    toggleMetaFormsVisibility,
} from '../meta/MetaForms';
import { AddressBlock } from '../../park-create/components/license-plate/InfoBoxes';
import * as MetaServerState from '../meta/state/MetaServerState';
import { css } from '@emotion/css';
import { AdminFeeAnnotation } from '../payment-method/DirectMethod';
import { logAction } from '../../utils/ActionLog';
import {
    DropdownSlideIn,
    SlideInDisplay,
} from '../root/components/DropdownSlideIn';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import { FormValidators } from 'dg-web-shared/lib/FormValidation';
import {
    INVOICE_MAIL_FEE,
    INVOICE_MONTHLY_FEE,
    INVOICE_MONTHLY_FEE_PAPER,
} from '../payment-method/invoice/InvoiceRequestForm';
import { useParkingpayLanguage } from '../../utils/UseParkingpayLanguage';
import { useState } from 'react';
import { InvoiceChannelType } from 'dg-web-shared/dto/Invoice.ts';

interface InvoiceSettings {
    reference: string | null;
    channel: InvoiceChannelType;
    receiving_email: string | null;
}

export function Paper() {
    return (
        <Localized
            de="In Papierform (per Post)"
            fr="Sur papier (par courrier)"
            it="Su carta (per posta)"
            en="In paper form (by mail)"
        />
    );
}

export function Email() {
    return (
        <Localized
            de="PDF (per E-Mail)"
            fr="PDF (par e-mail)"
            it="PDF (per e-mai)"
            en="PDF (by E-mail)"
        />
    );
}

export const Channel = (
    <Localized
        de="Versandart"
        en="Shipping method"
        fr="Mode d'envoi"
        it="Modalità d'invio"
    />
);

const Receiver = (
    <Localized
        de="PDF Empfänger"
        fr="Destinataire PDF"
        it="Destinatario PDF"
        en="PDF Recipient"
    />
);

export function useInvoiceSettings() {
    return useServerReadAndUpdate<InvoiceSettings, Record<string, never>>(
        {
            read: () => {
                return superagent.get(
                    '/ui-api/customer-account/deposits/invoiceSettings',
                );
            },
            update: args => {
                return superagent
                    .put('/ui-api/customer-account/deposits/invoiceSettings')
                    .send(args);
            },
        },
        {},
    );
}

export function InvoicesSettingsMenu() {
    const [invoiceReferenceOpen, setInvoiceReferenceOpen] = useState(false);
    const [invoiceChannelOpen, setInvoiceChannelOpen] = useState(false);
    const [invoiceReceiverOpen, setInvoiceReceiverOpen] = useState(false);

    const { storeState, store } = useStore(s => ({
        currentLogin: CurrentLoginState.get(s),
        logins: new LoginsState.StateSlice(s).state,
        meta: new MetaServerState.StateSlice(s).state,
        metaVisible: new MetaFormsVisibilityStateSlice(s).state,
    }));

    const [invoiceSettigs, putInvoiceSettings, refetchInvoiceSettings] =
        useInvoiceSettings();

    if (
        invoiceSettigs.data === null ||
        storeState.logins.data === null ||
        storeState.currentLogin.data === null
    ) {
        return null; // Wait till we have data…
    }

    if (invoiceSettigs.status === RequestStatus.ERROR) {
        return <GenericError />;
    }
    const invoiceData = invoiceSettigs.data;

    return (
        <>
            <FieldItemBlock
                onClick={() => setInvoiceReferenceOpen(true)}
                label={getTitle()}
                content={invoiceData.reference || '-'}
            />

            <FieldItemBlock
                onClick={() => setInvoiceChannelOpen(true)}
                label={Channel}
                content={
                    <ChannelRepresentation channel={invoiceData.channel} />
                }
            />

            <InvoiceChannelDropdownSlidein
                open={invoiceChannelOpen}
                onClose={() => setInvoiceChannelOpen(false)}
                selected={invoiceData.channel}
                onSelected={(channel: InvoiceChannelType) => {
                    const receiver =
                        channel === InvoiceChannelType.PAPER
                            ? null
                            : invoiceData.receiving_email ||
                              storeState.currentLogin.data?.email ||
                              null;
                    const payload = {
                        ...invoiceData,
                        receiving_email: receiver,
                        channel: channel,
                    };
                    logAction(store, 'Invoice channel changed', payload);
                    putInvoiceSettings(payload);
                    setInvoiceChannelOpen(false);
                }}
            />

            {invoiceData.channel === InvoiceChannelType.EMAIL && (
                <FieldItemBlock
                    onClick={() => setInvoiceReceiverOpen(true)}
                    label={Receiver}
                    content={invoiceData.receiving_email}
                />
            )}

            {invoiceData.channel === InvoiceChannelType.PAPER && (
                <FieldItemBlock
                    className={css({
                        height: 'auto !important',
                        padding: '12px 24px !important',
                    })}
                    onClick={() =>
                        store.legacyUpdater(toggleMetaFormsVisibility)
                    }
                    label={
                        <Localized
                            de="Rechnungsadresse"
                            fr="Adresse de facturation"
                            it="Indirizzo di fatturazione"
                            en="Billing address"
                        />
                    }
                    content={
                        <AddressBlock
                            address={storeState.meta.data.address}
                            shippingAddress={
                                storeState.meta.data.shippingAddress
                            }
                            isBillingAddress={true}
                        />
                    }
                />
            )}

            {invoiceData.channel === InvoiceChannelType.EMAIL && (
                <InvoiceReceiverDropdownSliden
                    open={invoiceReceiverOpen}
                    onClose={() => setInvoiceReceiverOpen(false)}
                    receiver={
                        invoiceData.receiving_email ||
                        storeState.currentLogin.data?.email ||
                        ''
                    }
                    onSave={(email: string) => {
                        logAction(store, 'Invoice receiver changed', email);
                        putInvoiceSettings({
                            ...invoiceData,
                            receiving_email: email,
                        });
                        setInvoiceReceiverOpen(false);
                    }}
                />
            )}

            <InvoiceReferenceSlidein
                open={invoiceReferenceOpen}
                portal={SlideInPortalId.USER_ACCOUNT}
                title={getTitle()}
                invoiceSettings={invoiceSettigs}
                putInvoiceSettings={putInvoiceSettings}
                refetchInvoiceSettings={refetchInvoiceSettings}
                onClose={() => {
                    setInvoiceReferenceOpen(false);
                }}
            />

            <FieldItemBlock
                label={
                    <Localized
                        de="Administrationsgebühr"
                        fr="Frais administratifs"
                        it="Tassa amministrativa"
                        en="Administration fee"
                    />
                }
                content={`CHF ${
                    invoiceData.channel === InvoiceChannelType.EMAIL
                        ? INVOICE_MONTHLY_FEE
                        : INVOICE_MONTHLY_FEE_PAPER
                }`}
                onClick={null}
            />
        </>
    );
}

export function ChannelRepresentation(p: { channel: InvoiceChannelType }) {
    return p.channel === InvoiceChannelType.EMAIL ? <Email /> : <Paper />;
}

interface InvoiceProps {
    invoiceSettings:
        | PendingState<InvoiceSettings>
        | SuccessState<InvoiceSettings>;
    putInvoiceSettings: (args: InvoiceSettings) => void;
    refetchInvoiceSettings: () => void;
}

export const InvoiceReferenceSlidein = portalSlideIn(InvoiceReference);

function InvoiceReference(props: InvoiceProps & PortalSlideInProps) {
    const invoiceSettings = props.invoiceSettings.data;
    const [invoiceReference, setInvoiceReference] = useState(
        invoiceSettings?.reference || null,
    );
    const { store } = useStore(() => null);

    if (invoiceSettings === null) {
        return null;
    }

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: props.onClose,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                onClick: () => {
                    logAction(
                        store,
                        'Invoice reference changed',
                        invoiceReference,
                    );
                    props.putInvoiceSettings({
                        ...invoiceSettings,
                        reference: invoiceReference,
                    });
                    props.onClose();
                },
                disabled:
                    props.invoiceSettings.status === RequestStatus.PENDING,
            }}
        >
            <TextField
                context={InputContext.form}
                value={invoiceReference}
                labelText={getTitle()}
                onChange={setInvoiceReference}
            />
        </SlideInForm>
    );
}

function getTitle() {
    return (
        <Localized
            de="Rechnungsreferenz"
            fr="Référence facture"
            it="Riferimento fattura"
            en="Invoice reference"
        />
    );
}

export function InvoiceReceiverDropdownSliden(p: {
    open: boolean;
    onClose: () => void;
    receiver: string;
    onSave: (email: string) => void;
}) {
    return (
        <InvoiceReceiverDropdown
            portal={SlideInPortalId.USER_ACCOUNT}
            title={Receiver}
            {...p}
        />
    );
}

const InvoiceReceiverDropdown = portalSlideIn(
    ({
        onClose,
        receiver,
        onSave,
    }: {
        onClose: () => void;
        receiver: string;
        onSave: (email: string) => void;
    }) => {
        const [email, setEmail] = useState(receiver);
        const language = useParkingpayLanguage();
        const errorText = FormValidators.EmailValid(email, language);
        return (
            <SlideInForm
                secondaryButton={{
                    label: <Localized {...GeneralTexts.cancel} />,
                    onClick: onClose,
                }}
                primaryButton={{
                    label: <Localized {...GeneralTexts.save} />,
                    disabled:
                        email.length === 0 ||
                        email === receiver ||
                        errorText !== null,
                    onClick: () => {
                        onSave(email);
                        onClose();
                    },
                }}
            >
                <TextField
                    errorText={errorText && errorText()}
                    context={InputContext.form}
                    inputType={'email'}
                    required={true}
                    value={email}
                    tabIndex={0}
                    labelText={Receiver}
                    onChange={setEmail}
                />
            </SlideInForm>
        );
    },
);

export function InvoiceChannelDropdownSlidein(p: {
    open: boolean;
    onClose: () => void;
    selected: InvoiceChannelType;
    onSelected: (channel: InvoiceChannelType) => void;
}) {
    return (
        <DropdownSlideIn
            portal={SlideInPortalId.USER_ACCOUNT}
            open={p.open}
            title={Channel}
            onClose={p.onClose}
            selected={p.selected}
            onSelected={p.onSelected}
            options={[
                {
                    id: InvoiceChannelType.PAPER,
                    display: (
                        <SlideInDisplay
                            firstRowText={
                                <>
                                    <Paper />
                                    {' *'}
                                </>
                            }
                        />
                    ),
                },
                {
                    id: InvoiceChannelType.EMAIL,
                    display: <SlideInDisplay firstRowText={<Email />} />,
                },
            ]}
            additionalContent={
                <AdminFeeAnnotation
                    title={
                        <Localized
                            de="Versandkosten"
                            fr="Frais d'envoi"
                            it="Spese d'invio"
                            en="Shipping costs"
                        />
                    }
                    text={
                        <Localized
                            de={`Für jede Zusendung einer Rechnung per Post wird eine zusätzliche Gebühr von CHF ${INVOICE_MAIL_FEE} erhoben.`}
                            fr={`Chaque envoi d'une facture par courrier est soumis à une taxe supplémentaire de CHF ${INVOICE_MAIL_FEE}.`}
                            it={`Ogni invio per posta di una fattura è soggetto ad una tassa supplementare di CHF ${INVOICE_MAIL_FEE}.`}
                            en={`Each sending of an invoice by post is subject to an additional fee of CHF ${INVOICE_MAIL_FEE}.`}
                        />
                    }
                />
            }
        />
    );
}
