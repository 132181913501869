import {
    aliasIsValid,
    navCategoryForPaymentAlias,
    paymentCategoryFromAliasPaymentMethod,
} from '../../account/top-up-balance/EPaymentMode';
import { PaymentStatus } from '../components/PaymentCallback';
import { PendingTwintPairing } from 'dg-web-shared/common/utils/TwintPairing';
import {
    CreditCardAlias,
    SavedCreditCardAliasResponse,
    SavedCreditCardAliasResponseTag,
} from 'dg-web-shared/model/PaymentAlias';
import { UiPaymentOrigin } from 'dg-web-shared/common/utils/TwintPairingParkingpay';

export enum PaymentProviderType {
    DATATRANS = 'DATATRANS',
    TWINT = 'TWINT',
    WALLEE = 'WALLEE',
}

export type PaymentAttemptResponseParams =
    | PaymentAttemptResponseDatatrans
    | PaymentAttemptResponseTwintUrl;

export interface PaymentAttemptResponseDatatrans {
    readonly paymentProviderType: PaymentProviderType.DATATRANS;
    uri: string;
    refno: string;
}

export interface PaymentAttemptResponseTwintUrl {
    readonly paymentProviderType: PaymentProviderType.TWINT;
    readonly twintUrl: string;
}

export interface ResponseParameters {
    permitId: number | null;
    pmethod: string | null;
    status: PaymentStatus | null;
    uiPaymentOrigin: UiPaymentOrigin | null;
    mode?: PendingTwintPairing.TwintOrderMethodType | null;
    providerType?: PaymentProviderType;
    reference?: string;
    ticketString?: string;
    twintPaymentAttemptId: number | null;
}

export enum SelectedPaymentFlow {
    METHODS = 'METHODS',
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
    POSTFINANCE = 'POSTFINANCE',
    TWINT = 'TWINT',
    BANK_TRANSFER = 'BANK_TRANSFER',
    CREDIT_CARDS = 'CREDIT_CARDS',
}

export enum PaymentRequestMode {
    NO_ALIAS = 'NO_ALIAS',
    INVALID_ALIAS = 'INVALID_ALIAS',
    DIRECT_TO_ALIAS = 'DIRECT_TO_ALIAS',
}

export function derivePaymentRequestMode(
    selectedPaymentFlow: SelectedPaymentFlow,
    alias: SavedCreditCardAliasResponse,
): PaymentRequestMode {
    if (
        alias?.tag === SavedCreditCardAliasResponseTag.FOUND &&
        navCategoryForPaymentAlias(alias.creditCardAlias) ===
            selectedPaymentFlow
    ) {
        if (aliasIsValid(alias.creditCardAlias)) {
            return PaymentRequestMode.DIRECT_TO_ALIAS;
        } else {
            return PaymentRequestMode.INVALID_ALIAS;
        }
    } else {
        return PaymentRequestMode.NO_ALIAS;
    }
}

export function derivePaymentCategory(
    mode: PaymentRequestMode,
    alias: CreditCardAlias | null,
    selectedPaymentFlow: SelectedPaymentFlow,
): PaymentCategory {
    return mode === PaymentRequestMode.DIRECT_TO_ALIAS && alias
        ? paymentCategoryFromAliasPaymentMethod(alias.paymentMethod)
        : paymentCategoryFromSelectedPaymentFlow(selectedPaymentFlow);
}

export function isEpaymentPaymentFlow(
    selectedPaymentFlow: SelectedPaymentFlow,
) {
    switch (selectedPaymentFlow) {
        case SelectedPaymentFlow.VISA:
        case SelectedPaymentFlow.MASTERCARD:
        case SelectedPaymentFlow.AMERICAN_EXPRESS:
        case SelectedPaymentFlow.POSTFINANCE:
        case SelectedPaymentFlow.TWINT:
        case SelectedPaymentFlow.CREDIT_CARDS:
            return true;
        default:
            return false;
    }
}

export enum PaymentCategory {
    POSTFINANCE = 'POSTFINANCE',
    TWINT = 'TWINT',
    CARDS = 'CARDS',
    UNKNOWN = 'UNKNOWN',
}

export function paymentCategoryFromSelectedPaymentFlow(
    selectedPaymentFlow: SelectedPaymentFlow,
): PaymentCategory {
    switch (selectedPaymentFlow) {
        case SelectedPaymentFlow.POSTFINANCE:
            return PaymentCategory.POSTFINANCE;

        case SelectedPaymentFlow.VISA:
            return PaymentCategory.CARDS;

        case SelectedPaymentFlow.MASTERCARD:
            return PaymentCategory.CARDS;

        case SelectedPaymentFlow.AMERICAN_EXPRESS:
            return PaymentCategory.CARDS;

        case SelectedPaymentFlow.TWINT:
            return PaymentCategory.TWINT;

        case SelectedPaymentFlow.METHODS:
        case SelectedPaymentFlow.BANK_TRANSFER:
            return PaymentCategory.UNKNOWN;

        case SelectedPaymentFlow.CREDIT_CARDS:
            return PaymentCategory.CARDS;
    }
}

export function providerTypeFromPaymentCategory(
    paymentCategory: PaymentCategory,
): PaymentProviderType {
    switch (paymentCategory) {
        case PaymentCategory.POSTFINANCE:
        case PaymentCategory.CARDS:
        case PaymentCategory.UNKNOWN:
            return PaymentProviderType.DATATRANS;
        case PaymentCategory.TWINT:
            return PaymentProviderType.TWINT;
    }
}
