import {
    localState,
    selectState,
    SelectStateProps,
} from 'dg-web-shared/lib/Flux';
import { Language } from 'dg-web-shared/lib/Localized';
import { Localized } from '../../common/components/Localized';
import { logAction } from '../../utils/ActionLog';
import {
    derivePaymentRequestMode,
    isEpaymentPaymentFlow,
    paymentCategoryFromSelectedPaymentFlow,
    SelectedPaymentFlow,
} from '../../common/payment/Payment';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { BankTransferTopUpSlideIn } from './BankTransferTopUp';
import { DirectOrTopupSwitch } from '../../park-create/components/add-payment-method/DirectOrTopupSwitch';
import { SavedCreditCardState } from '../shared/SavedCreditCardAliasState';
import { TopUpBalanceMethodsSlideIn } from './TopUpBalanceMethods';
import { TopUpBalanceNavigationState } from './TopUpBalanceNavigationState';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { MainMenuItemBlock } from '../root/components/ItemBlock';
import { PaymentOriginType } from '../../park-create/components/add-payment-method/AddPaymentMethodParkCreateMenu';

export const TopUpBalanceMenu = selectState<Props, ConnectedState>(
    store => ({
        topUpBalanceMethodsNavigation: TopUpBalanceNavigationState.get(store),
        ccAliasGetRequest: SavedCreditCardState.get(store),
    }),
    localState<
        SelectStateProps<Props, ConnectedState>,
        { selected: SelectedPaymentFlow }
    >({ selected: SelectedPaymentFlow.METHODS }, props => {
        function nav(selected: SelectedPaymentFlow) {
            props.update(store =>
                logAction(store, 'topup-navigation', selected),
            );
            props.setState({ selected });
        }

        const closeSecondLevelSlideIn = () => {
            nav(SelectedPaymentFlow.METHODS);
        };

        const closeTopUpSlideIn = () => {
            props.update(store =>
                TopUpBalanceNavigationState.stateWrite(store, {
                    displayMethods: false,
                }),
            );
        };

        if (props.ccAliasGetRequest.status !== RequestStatus.SUCCESS) {
            return null;
        }
        const paymentRequestMode = derivePaymentRequestMode(
            props.state.selected,
            props.ccAliasGetRequest.data,
        );
        return (
            <div>
                <MainMenuItemBlock
                    onClick={() =>
                        props.update(store =>
                            TopUpBalanceNavigationState.stateWrite(store, {
                                displayMethods: true,
                            }),
                        )
                    }
                    title={
                        <Localized
                            {...{
                                de: 'Konto laden',
                                fr: 'Alimenter le compte',
                                it: 'Ricaricare il conto',
                                en: 'Top up account',
                            }}
                        />
                    }
                />

                <TopUpBalanceMethodsSlideIn
                    portal={SlideInPortalId.USER_ACCOUNT}
                    open={props.topUpBalanceMethodsNavigation.displayMethods}
                    title={
                        <Localized
                            {...{
                                de: 'Zahlungsmittel',
                                fr: 'Moyen de paiement',
                                it: 'Mezzo di pagamento',
                                en: 'Means of payment',
                            }}
                        />
                    }
                    onClose={closeTopUpSlideIn}
                    setPaymentCategory={(p: SelectedPaymentFlow) => nav(p)}
                    ccAlias={props.ccAliasGetRequest.data}
                />

                <DirectOrTopupSwitch
                    open={isEpaymentPaymentFlow(props.state.selected)}
                    onClose={closeSecondLevelSlideIn}
                    aliasData={props.ccAliasGetRequest.data}
                    paymentRequestMode={paymentRequestMode}
                    paymentCategory={paymentCategoryFromSelectedPaymentFlow(
                        props.state.selected,
                    )}
                    paymentOriginInfo={{
                        type: PaymentOriginType.ACCOUNT,
                    }}
                    portal={SlideInPortalId.USER_ACCOUNT}
                />

                <BankTransferTopUpSlideIn
                    portal={SlideInPortalId.USER_ACCOUNT}
                    open={
                        props.state.selected ===
                        SelectedPaymentFlow.BANK_TRANSFER
                    }
                    title={
                        <Localized
                            {...{
                                de: 'Zahlungsangaben',
                                fr: 'Détails du paiement',
                                it: 'Dettagli di pagamento',
                                en: 'Payment details',
                            }}
                        />
                    }
                    onClose={closeSecondLevelSlideIn}
                />
            </div>
        );
    }),
);

interface Props {
    language: Language;
}

interface ConnectedState {
    topUpBalanceMethodsNavigation: TopUpBalanceNavigationState.State;
    ccAliasGetRequest: SavedCreditCardState.State;
}
