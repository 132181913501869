import { InputContext, inputContextToColor } from '../InputContext';
import { Colors } from '../../../ui/vars';
import { css } from '@emotion/css';
import React from 'react';
import { Typo } from '../../../ui/typo.ts';

interface Props {
    text: React.ReactNode;
    rightText?: React.ReactNode;
    elevated: boolean;
    context: InputContext;
    focused?: boolean;
    hidden?: boolean;
    key?: string;
    hideHeight?: number;
    marginBottom?: boolean;
}

function getLabelColor(p: { elevated: boolean; context: InputContext }) {
    const opacity = 0.7;
    return Colors.rgba(inputContextToColor(p.context), opacity);
}

export class Label extends React.Component<Props> {
    render() {
        if (!this.props.text) {
            return null;
        }
        const LabelStyles = css({
            position: 'relative',
            whiteSpace: 'nowrap',
            display: 'block',
            cursor: 'default',
            height: '18px',
            overflow: 'visible',
            [`@media(max-height: ${this.props.hideHeight || 0}px)`]: this.props
                .elevated
                ? {
                      display: 'none',
                  }
                : null,
            marginBottom: this.props.marginBottom ? '12px' : undefined,
        });
        const typo = this.props.elevated ? Typo.captionC4 : Typo.body;
        return (
            <label className={LabelStyles}>
                <div
                    className={css({
                        display: 'block',
                        position: 'absolute',
                        width: '100%',
                        top: this.props.elevated ? '0' : '18px',
                        visibility: this.props.hidden ? 'hidden' : 'visible',
                        cursor: this.props.elevated ? 'default' : 'text',
                        color: getLabelColor(this.props),
                        ...typo,
                    })}
                >
                    {this.props.rightText && (
                        <div className={css({ float: 'right' })}>
                            {this.props.rightText}
                        </div>
                    )}
                    <div>{this.props.text}</div>
                </div>
            </label>
        );
    }
}

export const Factory = React.createFactory<Props>(Label);
