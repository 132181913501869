import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { ButtonRow } from 'dg-web-shared/tb-ui/buttons/ButtonRow.tsx';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../common/components/FullWidthBottomButton';
import { css } from '@emotion/css';
import { Localized } from '../../common/components/Localized';
import {
    RequestStatus,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import * as superagent from 'superagent';
import * as emailValidator from 'email-validator';
import { Colors } from 'dg-web-shared/ui/vars';
import { portalOrModalSlideIn } from '../../pages/home-unauthenticated/PortalSlideInOrModal';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import * as LayoutState from '../../layout/state/LayoutState';
import { useStore } from 'dg-web-shared/lib/Flux';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { ParkingcardTypo } from '../../style/parkingcardTypo.ts';

const MessageSentEmailSuccessfully = portalOrModalSlideIn(() => {
    return (
        <div
            className={css({
                ...Typo.body,
                padding: '25px',
                color: Colors.blue,
            })}
        >
            <p>
                <Localized
                    de="Wir haben Ihnen eine E-Mail, mit einem Link zum Ändern des Passworts gesendet."
                    fr="Nous vous avons envoyé un e-mail avec un lien pour changer votre mot de passe."
                    it="Le abbiamo inviato una e-mail con un link per modificare la sua password."
                    en="We have sent you an e-mail with a link to change your password."
                />
            </p>
        </div>
    );
});

interface ForgotPasswordProps {
    closeForgotPassword: () => void;
    email: string;
    setEmail: (email: string) => void;
}

export function ForgotPassword(p: ForgotPasswordProps) {
    const [httpPost, doHttpPost] = useServerWrite({
        req(body: SendPasswordLinkChangeRequest) {
            return superagent.post('/ui-api/reset-password-email').send(body);
        },
    });

    const { storeState } = useStore(s => ({
        layout: new LayoutState.StateSlice(s).state,
    }));

    const formIsValid = p.email.length > 0 && emailValidator.validate(p.email);

    return (
        <div
            className={css({
                padding: '0 24px',
            })}
        >
            <div
                className={css({
                    ...ParkingcardTypo.heading3,
                    fontWeight: 500,
                    color: Colors.blue,
                })}
            >
                <Localized
                    de="Passwort ersetzen"
                    fr="Réinitialiser MDP"
                    it="Reimposta password"
                    en="Reset password"
                />
            </div>
            <div
                className={css({
                    ...ParkingcardTypo.caption,
                    fontSize: '15px',
                    fontWeight: 500,
                    color: Colors.action_w,
                    marginTop: '16px',
                    marginBottom: '32px',
                })}
            >
                <Localized
                    de="Geben Sie Ihre Email-Adresse ein und klicken Sie auf «Senden». Wir senden daraufhin einen Link zum Ändern des Passworts."
                    fr="Entrez votre adresse email et cliquez sur «Envoyer». Nous vous enverrons un lien pour changer votre mot de passe."
                    it="Inserisca il suo indirizzo e-mail e prema «Invia». Le invieremo un link per modificare la password."
                    en="Enter your e-mail address and press «Send». We will send you a link to change your password."
                />
            </div>
            <TextField
                context={InputContext.regular}
                value={p.email}
                inputType="email"
                labelText={
                    <Localized
                        de="E-Mail"
                        fr="E-mail"
                        it="E-mail"
                        en="E-mail"
                    />
                }
                errorText={
                    httpPost.status === RequestStatus.ERROR ? (
                        <Localized
                            de="Diese E-Mail ist nicht als Benutzername registriert."
                            fr="L'e-mail ne correspond pas à un utilisateur enregistré."
                            it="L'indirizzo e-mail inserito non corrisponde ad alcun utente registrato."
                            en="The e-mail address you entered does not correspond to any registered user."
                        />
                    ) : p.email && !emailValidator.validate(p.email) ? (
                        <Localized
                            de="Diese Email-Adresse ist ungültig."
                            fr="L'adresse e-mail n'est pas valide."
                            it="L'indirizzo e-mail non é valido."
                            en="The e-mail address is not valid."
                        />
                    ) : (
                        ''
                    )
                }
                onChange={(value: string) => p.setEmail(value)}
            />
            <ButtonRow>
                <div
                    className={css({
                        float: 'right',
                        width: '100%',
                        paddingLeft: '3%',
                    })}
                >
                    <FullWidthBottomButton
                        color={FullWidthBottomButtonColor.BLUE}
                        onClick={() => {
                            doHttpPost({ email: p.email });
                        }}
                        label={{
                            de: 'Senden',
                            fr: 'Envoyer',
                            it: 'Invia',
                            en: 'Send',
                        }}
                        disabled={!formIsValid}
                    />
                </div>
                <div
                    className={css({
                        float: 'right',
                        width: '100%',
                        paddingRight: '3%',
                    })}
                >
                    <FullWidthBottomButton
                        color={FullWidthBottomButtonColor.WHITE}
                        onClick={() => p.closeForgotPassword()}
                        label={{
                            de: 'Abbrechen',
                            fr: 'Annuler',
                            it: 'Annulla',
                            en: 'Cancel',
                        }}
                    />
                </div>
            </ButtonRow>

            <MessageSentEmailSuccessfully
                onClose={p.closeForgotPassword}
                portal={SlideInPortalId.UNAUTH_LANDING_PAGE}
                open={httpPost.status === RequestStatus.SUCCESS}
                title={
                    <Localized
                        de={'E-Mail erfolgreich gesendet'}
                        fr={'E-mail envoyé avec succès'}
                        it={'E-mail inviato con successo'}
                        en={'E-mail has been sent successfully'}
                    />
                }
                viewportWidth={storeState.layout.viewportWidth}
            />
        </div>
    );
}

type SendPasswordLinkChangeRequest = {
    email: string;
};
