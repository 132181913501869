import { useState } from 'react';
import { isDefined } from 'dg-web-shared/lib/MaybeV2';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import { ErrorBlock } from '../../root/components/ErrorBlock';
import { useStore } from 'dg-web-shared/lib/Flux';
import { NotificationsBlock } from './NotificationsBlock';
import * as AccountBalanceState from '../../../common/state/AccountBalanceState';
import { writeEditLoginData } from './NewsletterEdit';
import { Localized } from '../../../common/components/Localized';
import { GenericErrorBody } from '../../../ui/modals/HandleHttpStati';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import LoginRole = CurrentLoginState.LoginRole;

const balanceWarningAmountToString = (balanceWarningAmount: number | null) => {
    if (!balanceWarningAmount) {
        return '';
    }
    return balanceWarningAmount + '';
};

interface EditState {
    balanceWarningEnabled: boolean;
    balanceWarningAmount: number | null;
}

export const BalanceEditForm = (p: {
    currentLoginData: CurrentLoginState.CurrentLogin;
}) => {
    const { storeState } = useStore(s => ({
        balance: new AccountBalanceState.StateSlice(s).state,
    }));

    const initialState = {
        balanceWarningEnabled: p.currentLoginData.balanceWarningEnabled,
        balanceWarningAmount: p.currentLoginData.balanceWarningAmount ?? null,
    };

    const [editState, setEditState] = useState<EditState>(initialState);

    const [expanded, setExpanded] = useState(false);

    const [httpPut, doHttpPut, resetPut] = writeEditLoginData(
        () => setExpanded(false),
        false,
        'balance-edit',
    );

    const hasCredit =
        isDefined(storeState.balance.data) &&
        storeState.balance.data.creditIsAllowed;
    if (p.currentLoginData.loginRole === LoginRole.RESTRICTED || hasCredit) {
        return null;
    }

    const balanceWarningAmount = () => {
        if (editState.balanceWarningEnabled) {
            return editState.balanceWarningAmount;
        }
        return null;
    };

    const amount = balanceWarningAmountToString(editState.balanceWarningAmount);

    const isSaveable = p.currentLoginData.balanceWarningEnabled
        ? p.currentLoginData.balanceWarningEnabled !==
              editState.balanceWarningEnabled ||
          (p.currentLoginData.balanceWarningAmount !==
              editState.balanceWarningAmount &&
              editState.balanceWarningAmount)
        : editState.balanceWarningAmount &&
          p.currentLoginData.balanceWarningEnabled !==
              editState.balanceWarningEnabled;

    const pending = httpPut.status === RequestStatus.PENDING;

    return (
        <NotificationsBlock
            caption={
                <Localized de="Saldo" fr="Solde" it="Saldo" en="Balance" />
            }
            contentElement={
                <>
                    <RenderEditContent
                        currentLoginData={p.currentLoginData}
                        setEditState={setEditState}
                        editState={editState}
                    />
                    {httpPut.status === RequestStatus.ERROR && (
                        <ErrorBlock text={<GenericErrorBody />} />
                    )}
                </>
            }
            toggle={() => {
                setEditState(initialState);
                setExpanded(!expanded);
                resetPut();
            }}
            expanded={expanded}
            onSave={() => {
                doHttpPut({
                    ...p.currentLoginData,
                    balanceWarningAmount: balanceWarningAmount(),
                });
            }}
            disabled={!isSaveable}
            text={
                p.currentLoginData.balanceWarningEnabled ? (
                    <Localized
                        de={`CHF ${amount}`}
                        fr={`CHF ${amount}`}
                        it={`CHF ${amount}`}
                        en={`CHF ${amount}`}
                    />
                ) : (
                    <Localized de="—" fr="—" it="—" en="—" />
                )
            }
            pending={pending}
        />
    );
};

const getMandatoryText = (amount: string) => {
    if (!amount) {
        return (
            <Localized
                de="Pflichtfeld"
                fr="Ce champ est obligatoire"
                it="Campo obbligatorio"
                en="Mandatory field"
            />
        );
    } else {
        return '';
    }
};

interface RenderEditContentProps {
    currentLoginData: CurrentLoginState.CurrentLogin;
    setEditState: (edit: EditState) => void;
    editState: EditState;
}

const RenderEditContent = (p: RenderEditContentProps) => {
    const amount = balanceWarningAmountToString(
        p.editState.balanceWarningAmount,
    );

    return (
        <>
            <LabeledToggle
                context={InputContext.form}
                type={ToggleType.checkbox}
                selected={p.editState.balanceWarningEnabled}
                label={
                    <Localized
                        de="Wenn minimaler Saldo unterschritten wird"
                        fr="Si le solde descend au-dessous du minimum"
                        it="Se il saldo scende sotto il minimo"
                        en="If the balance falls below the minimum"
                    />
                }
                onClick={() => {
                    p.setEditState({
                        ...p.editState,
                        balanceWarningEnabled:
                            !p.editState.balanceWarningEnabled,
                    });
                }}
            />
            {p.editState.balanceWarningEnabled && (
                <TextField
                    context={InputContext.form}
                    value={amount}
                    labelText={
                        <Localized
                            de="Minimaler Saldo (CHF)"
                            fr="Solde minimum (CHF)"
                            it="Saldo minimo (CHF)"
                            en="Minimum balance (CHF)"
                        />
                    }
                    errorText={
                        p.editState.balanceWarningEnabled
                            ? getMandatoryText(amount)
                            : ''
                    }
                    onChange={(v: string) => {
                        p.setEditState({
                            ...p.editState,
                            balanceWarningAmount: parseInt(v, 10),
                        });
                    }}
                />
            )}
        </>
    );
};
