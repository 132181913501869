import * as Flux from 'dg-web-shared/lib/Flux';
import { Geolocation, Location } from 'dg-web-shared/common/utils/Geolocation';
import { isRunningInNativeWrapper } from '../../Native';
import { isIos } from 'dg-web-shared/common/utils/BrowserOrigin';

export namespace GeolocationState {
    export interface State {
        positionError: boolean;
        currentLocation: Location | null;
        watchId: number | null;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'common/state/GeolocationState.GeolocationState',
        {
            positionError: false,
            currentLocation: null,
            watchId: null,
        },
        (store, state) => {
            const isNativeiOs = isRunningInNativeWrapper() && isIos();

            // This could be done over the router but we don't have hooks here
            const currentPath = new URL(window.location.href).pathname;
            const isRouteWithoutGeolocation = [
                '/t/',
                '/z/',
                '/p/',
                '/quickcheckout/',
                '/ticket-plus-guide',
            ]
                .map(noGeoPathStart => currentPath.startsWith(noGeoPathStart))
                .some(isNoGeoRouteMatch => isNoGeoRouteMatch);

            if (
                !isNativeiOs &&
                Geolocation.hasGeolocation() &&
                state.watchId == null &&
                !state.positionError &&
                !isRouteWithoutGeolocation
            ) {
                const watchId = Geolocation.watchPosition(
                    pos => {
                        store.update(store => setCurrentPosition(store, pos));
                    },
                    err => store.update(store => writeError(store, err)),
                );
                stateWrite(store, { watchId: watchId });
            }
        },
    );

    export const setCoordinatesIfChanged = (
        store: Flux.Store,
        location: Location,
    ): string => {
        const currentPosition = get(store).currentLocation;
        if (
            currentPosition === null ||
            Math.abs(Geolocation.distance(location, currentPosition)) > 50
        ) {
            stateWrite(store, {
                positionError: false,
                currentLocation: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                    accuracy: location.accuracy,
                },
            });
        }
        return 'GeolocationState.setCoordinatesIfChanged';
    };

    export const setError = (store: Flux.Store): string => {
        stateWrite(store, {
            positionError: true,
            currentLocation: null,
        });
        return 'GeolocationState.setError';
    };

    const setCurrentPosition = (
        store: Flux.Store,
        pos: GeolocationPosition,
    ) => {
        const currentPosition = get(store).currentLocation;
        if (
            currentPosition === null ||
            Math.abs(Geolocation.distance(pos.coords, currentPosition)) > 50
        ) {
            stateWrite(store, {
                positionError: false,
                currentLocation: {
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude,
                    accuracy: pos.coords.accuracy,
                },
            });
        }
        return 'GeolocationState.setCurrentPosition';
    };

    export const writeError = (
        store: Flux.Store,
        err: GeolocationPositionError,
    ) => {
        const watchId = get(store).watchId;
        if (watchId && err.code < 3) {
            Geolocation.clearWatch(watchId);
            return stateWrite(store, {
                positionError: true,
                currentLocation: null,
            });
        } else {
            return stateWrite(store, {
                currentLocation: null,
            });
        }
    };

    export const hasPosition = (s: State) =>
        Geolocation.hasGeolocation() &&
        !s.positionError &&
        s.currentLocation !== null;

    export const isAcquiringPosition = (s: State) =>
        Geolocation.hasGeolocation() &&
        !s.positionError &&
        s.currentLocation === null;

    export const isError = (s: State) =>
        !Geolocation.hasGeolocation || s.positionError;
}
