import * as Flux from 'dg-web-shared/lib/Flux';
import * as HttpResponse from 'dg-web-shared/lib/HttpResponse';
import { Response } from 'dg-web-shared/lib/HttpResponse';

export interface State<D, E> {
    pending: boolean;
    shouldFetch: boolean;
    data: D;
    errorData: E;
    statusCode: HttpResponse.StatusCode;
    retries: number;
}

export interface EmptyData<D, E> {
    data: D;
    errorData: E;
}

/**
 * @deprecated
 */
export abstract class LegacyServerStateSlice<D, E> extends Flux.StateSlice<
    State<D, E>
> {
    abstract getEmptyData(): EmptyData<D, E>;

    getInitialState(): State<D, E> {
        const emptyData = this.getEmptyData();
        return {
            pending: false,
            shouldFetch: true,
            data: emptyData.data,
            errorData: emptyData.errorData,
            statusCode: new HttpResponse.StatusCode(null),
            retries: 0,
        };
    }

    writePending(): void {
        this.set((s: State<D, E>): State<D, E> => {
            const emptyData = this.getEmptyData();
            s.pending = true;
            s.shouldFetch = false;
            s.data = emptyData.data;
            s.errorData = emptyData.errorData;
            s.statusCode = new HttpResponse.StatusCode(null);
            return s;
        });
    }

    writeSuccess(res: HttpResponse.Response): void {
        this.set((s: State<D, E>): State<D, E> => {
            const emptyData = this.getEmptyData();
            s.pending = false;
            s.shouldFetch = false;
            s.data = res.body || emptyData.errorData;
            s.errorData = emptyData.errorData;
            s.statusCode = res.statusCode;
            return s;
        });
    }

    writeTimeout(res: HttpResponse.Response): void {
        this.set((s: State<D, E>): State<D, E> => {
            const emptyData = this.getEmptyData();
            s.pending = false;
            s.shouldFetch = s.retries <= 3;
            s.retries += 1;
            s.data = emptyData.data;
            s.errorData = emptyData.errorData;
            s.statusCode = res.statusCode;
            return s;
        });
    }

    writeUnauthorized(): void {
        this.set((s: State<D, E>): State<D, E> => {
            const emptyData = this.getEmptyData();
            s.pending = false;
            s.shouldFetch = true;
            s.data = emptyData.data;
            s.errorData = emptyData.errorData;
            return s;
        });
    }

    writeServerError(res: HttpResponse.Response): void {
        this.set((s: State<D, E>): State<D, E> => {
            const emptyData = this.getEmptyData();
            s.pending = false;
            s.shouldFetch = false;
            s.statusCode = res.statusCode;
            s.data = emptyData.data;
            s.errorData = res.body || emptyData.errorData;
            return s;
        });
    }

    writeError(res: HttpResponse.Response): void {
        if (res.statusCode.timeout) {
            this.writeTimeout(res);
        } else if (res.statusCode.unauthorized || res.statusCode.forbidden) {
            this.writeUnauthorized();
        } else {
            this.writeServerError(res);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    writeRequest(res: Response, ..._args: any[]): void {
        if (res.pending) {
            this.writePending();
        } else if (res.statusCode.cls.success) {
            this.writeSuccess(res);
        } else {
            this.writeError(res);
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function someArePending(...stati: State<any, any>[]): boolean {
    for (const s of stati) {
        if (s.pending || s.shouldFetch) {
            return true;
        }
    }
    return false;
}
