import superagent from 'superagent';
import { LicensePlateCountry } from 'dg-web-shared/dto/LicensePlateCountry';
import { MultiLangString } from 'dg-web-shared/dto/MultiLangString';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import {
    Address,
    Gender,
    LicensePlate,
} from '../account-setup/state/AccountSetupState';
import { device, DEVICE_UUID } from '../utils/ActionLog';
import { CustomerAccountType } from './CustomerAccountType';
import { DateTime } from 'luxon';
import { AddressCountry } from 'dg-web-shared/dto/AddressCountry.ts';
import { Language } from 'dg-web-shared/dto/Language.ts';

export type { MultiLangString } from 'dg-web-shared/dto/MultiLangString';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Request = superagent.Request<any, any>;

export interface BadgeType {
    id: number;
    name: MultiLangString;
}

export interface Badge {
    id: number;
    labelNr: string;
    typeId: number;
    active: boolean;
    remarks: string;
    loginId: number | null;
}

export interface Address {
    gender: number;
    company1?: string;
    company2?: string;
    companyUid?: string;
    firstName?: string;
    lastName?: string;
    street1: string;
    street2?: string;
    zipCode: string;
    city: string;
    countryId: string;
}

export interface ShippingAddress {
    line1: string;
    line2: string;
    zipCode: string;
    city: string;
    countryId: string;
    invoiceOnly: boolean;
}

export function getAddressCountries(): superagent.Request<
    unknown,
    AddressCountry[]
> {
    return superagent.get('/ui-api/meta/address-countries');
}

export function getBadgeTypes(): superagent.Request<unknown, BadgeType[]> {
    return superagent.get('/ui-api/meta/badge-types');
}

export function getLanguages(): superagent.Request<unknown, Language[]> {
    return superagent.get('/ui-api/meta/languages');
}

export function getLicensePlateCountries(): superagent.Request<
    unknown,
    LicensePlateCountry[]
> {
    return superagent.get('/ui-api/meta/license-plate-countries');
}

export function getLicensePlates(): Request {
    return superagent.get('/ui-api/customer-account/license-plates');
}

export const getOffstreetZoneTransactionInfo = (args: {
    zoneId: number;
}): Request => {
    return superagent.get(`/ui-api/park-options/offstreet-info/${args.zoneId}`);
};

export namespace TransactionsList {
    export namespace Detail {
        export namespace ParkTransaction {
            export const get = (id: number) =>
                superagent.get(
                    `/ui-api/customer-account/transactions-list/parktransaction-detail/${id}`,
                );
        }
        export namespace Permit {
            export const get = (id: number) =>
                superagent.get(
                    `/ui-api/customer-account/transactions-list/permit-detail/${id}`,
                );
        }
        export namespace OffstreetTransaction {
            export const get = (id: number) =>
                superagent.get(
                    `/ui-api/customer-account/transactions-list/offstreettransaction-detail/${id}`,
                );
        }
    }
}

export function logout(): superagent.Request<unknown, void> {
    return superagent.del(`/ui-api/auth-token-v2?uuid=${DEVICE_UUID}`);
}

export interface AccountMetaDataUpdate {
    correspondenceLanguageId: string;
    customerAccountType: CustomerAccountType;
    address: Address;
    refNo: string | null;
    shippingAddress: ShippingAddress | null;
    nonAdminTransactionsVisible: boolean;
}

export function getAccountMetaData(): Request {
    return superagent.get('/ui-api/customer-account/meta');
}

export function putMetaData(
    data: AccountMetaDataUpdate,
): superagent.Request<object, void> {
    return superagent.put('/ui-api/customer-account/meta').send(data);
}

export function getMonthlyTransactions(args: {
    id: string;
    lang: string;
}): Request {
    return superagent.get(
        `/ui-api/customer-account/deposits/monthly-reports-v2/detail-v2/${args.id}?language=${args.lang}`,
    );
}

export function getMonthlyReportPDFURL(month: DateTime, key: string): string {
    return `/ui-api/customer-account/monthly-report/${month.year}/${
        month.month
    }?key=${encodeURIComponent(key)}`;
}

export function getContractReceiptPDFURL(
    contractId: string,
    key: string,
): string {
    return `/ui-api/customer-account/receipt/contract/pdf/${contractId}?key=${encodeURIComponent(
        key,
    )}`;
}

export function getContractReceiptOnstreetBookingPDFURL(
    parkId: string,
    key: string,
): string {
    return `/ui-api/customer-account/receipt/booking/onstreet/pdf/${parkId}?key=${encodeURIComponent(
        key,
    )}`;
}

export function getContractReceiptOffstreetBookingPDFURL(
    offstreetHistoryId: string,
    key: string,
): string {
    return `/ui-api/customer-account/receipt/booking/offstreet/pdf/${offstreetHistoryId}?key=${encodeURIComponent(
        key,
    )}`;
}

export interface Balance {
    creditIsAllowed: boolean;
    balance: number;
    overdueLevel: number;
}

export function getBalance(): superagent.Request<unknown, Balance> {
    return superagent.get('/ui-api/customer-account/deposits/balance');
}

export enum PaymentMethodEnum {
    PREPAID = 'prepaid',
    LSV = 'lsv',
    INVOICE = 'invoice',
    DIRECT = 'direct',
}

export function getPaymentType(): Request {
    return superagent.get('/ui-api/customer-account/payment-type');
}

export interface CurrentLoginPutData {
    requestedEmail: string | null;
    firstName: string | null;
    lastName: string | null;
    gender: Gender | null;
    balanceWarningAmount: number | null;
    runningTransactionsWarningHour: number | null;
    sendRunningTransactionsAutoTerminate: boolean;
    sendEmailNotifications: boolean;
    newsletterStatus: string | null;
}

export function putCurrentLoginPassword(
    oldPassword: string,
    newPassword: string,
): superagent.Request<unknown, void> {
    return superagent
        .put('/ui-api/customer-account/current-login/password')
        .send({
            oldPassword: oldPassword,
            newPassword: newPassword,
        });
}

export function getCheckinZone(args: { permitZoneId: number }): Request {
    return superagent.get(
        `/ui-api/customer-account/park-options/park-transaction/${args.permitZoneId}`,
    );
}

export interface Login {
    id: number;
    email: string;
    roleId: number;
    firstName: string | null;
    lastName: string | null;
    deactivated: boolean;
    gender: Gender | null;
}

export function putLogin(login: Login): superagent.Request<unknown, void> {
    return superagent
        .put(`/ui-api/customer-account/logins-v2/${login.id}`)
        .send(login);
}

export function putLoginPassword(
    id: number,
    oldPassword: string,
    newPassword: string,
): superagent.Request<unknown, void> {
    return superagent
        .put(`/ui-api/customer-account/logins-v2/${id}/password`)
        .send({
            currentLoginPassword: oldPassword,
            newPassword: newPassword,
        });
}

export namespace Signup {
    export interface SignupDTO {
        email: string;
        password: string;
        language: string;
        selectedZipCode: string | null;
        selectedParkOptionId: number | null;
        selectedParkVariant: string | null;
        selectedPermitTypeId: number | null;
        subscribeToNewsletter: boolean;
    }

    export const signup = (payload: SignupDTO) =>
        superagent.post('/ui-api/customer-account/signup/create-account').send({
            ...payload,
            device,
            appVersion: SENTRY_RELEASE.id,
            uuid: DEVICE_UUID,
        });
}

export interface SetupData {
    accountType: CustomerAccountType;
    address: Address.State;
    orderBadge: boolean;
    existingBadge: Maybe<{
        badgeNr?: Maybe<string>;
        badgeType?: Maybe<number>;
    }>;
    licensePlate: LicensePlate.State;
    selectedPermitTypeId: Maybe<number>;
    selectedParkOptionId: Maybe<number>;
}

export const postAccountSetup = (data: SetupData): Request => {
    return superagent.post('/ui-api/customer-account/account-setup').send(data);
};

export namespace LicensePlateTypes {
    export namespace List {
        export const get = () =>
            superagent.get('/ui-api/customer-account/license-plate-types');
    }
}

function customerUrl(path: string): string {
    return `/ui-api/customer-account/${path}`;
}

export function apiGet(path: string): Request {
    return superagent.get(customerUrl(path));
}

export function apiPut(path: string): Request {
    return superagent.put(customerUrl(path));
}

export function apiPost(path: string): Request {
    return superagent.post(customerUrl(path));
}

export function apiDelete(path: string): Request {
    return superagent.del(customerUrl(path));
}

export function reqGen(req: Request): () => Request {
    return () => req;
}

export function apiGetReq(path: string): () => Request {
    return reqGen(apiGet(path));
}
