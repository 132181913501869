/* tslint:disable:no-unused-variable */

/* tslint:enable:no-unused-variable */
import { AddressTexts } from '../components/Address';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars';
import { Translations } from '../../../common/i18n/Text.ts';

const linkCss = css({
    ...Typo.body,
    color: Colors.rgba(Colors.action_b, 0.8),
    textDecoration: 'none',
});

export const texts: Translations<AddressTexts> = {
    de: {
        content: (): JSX.Element => {
            return (
                <div>
                    Digitalparking AG
                    <br />
                    Rütistrasse 13
                    <br />
                    8952 Schlieren
                    <br />
                    0848 330 555
                    <br />
                    <a
                        className={linkCss}
                        href="mailto:info@parkingpay.ch"
                        tabIndex={-1}
                    >
                        info@parkingpay.ch
                    </a>
                </div>
            );
        },
    },
    fr: {
        content: (): JSX.Element => {
            return (
                <div>
                    Digitalparking AG
                    <br />
                    Rütistrasse 13
                    <br />
                    8952 Schlieren
                    <br />
                    0848 330 555
                    <br />
                    <a
                        className={linkCss}
                        href="mailto:info@parkingpay.ch"
                        tabIndex={-1}
                    >
                        info@parkingpay.ch
                    </a>
                </div>
            );
        },
    },
    it: {
        content: (): JSX.Element => {
            return (
                <div>
                    Digitalparking AG
                    <br />
                    Rütistrasse 13
                    <br />
                    8952 Schlieren
                    <br />
                    0848 330 555
                    <br />
                    <a
                        className={linkCss}
                        href="mailto:info@parkingpay.ch"
                        tabIndex={-1}
                    >
                        info@parkingpay.ch
                    </a>
                </div>
            );
        },
    },
    en: {
        content: (): JSX.Element => {
            return (
                <div>
                    Digitalparking Ltd
                    <br />
                    Rütistrasse 13
                    <br />
                    8952 Schlieren
                    <br />
                    0848 330 555
                    <br />
                    <a
                        className={linkCss}
                        href="mailto:info@parkingpay.ch"
                        tabIndex={-1}
                    >
                        info@parkingpay.ch
                    </a>
                </div>
            );
        },
    },
};
