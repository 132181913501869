import * as Flux from 'dg-web-shared/lib/Flux';
import { useStore } from 'dg-web-shared/lib/Flux';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import * as AccountTexts from '../../../account/root/i18n/AccountTexts';
import * as I18nActions from '../../../common/actions/I18nActions';
import * as Text from '../../../common/i18n/Text';
import * as SettingsState from '../../../common/state/SettingsState';
import { languages } from '../../i18n/Languages';
import { texts } from '../../i18n/TopNavTexts';
import * as TopNavDropdown from './TopNavDropdown';
import { PrivacyLink } from '../../../legal/PrivacyPolicyFiles';
import { TosLink } from '../../../legal/TosFiles';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { useMatch, useNavigate } from 'react-router-dom';
import { Icon } from 'dg-web-shared/ui/icons/Icon';
import { Language } from 'dg-web-shared/dto/Language.ts';

interface LanguageState {
    settings: SettingsState.State;
}

class LanguageSetting extends Flux.Container<LanguageState> {
    static displayName = 'LanguageSetting';

    stateSelector(): LanguageState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        return TopNavDropdown.StringIdFactory({
            selection: this.state.settings.language,
            optionsGenerator: (o: Language): TopNavDropdown.Option<string> => {
                return { id: o.shortcut, displayText: o.name[o.shortcut] };
            },
            data: languages,
            onChange: (value: string): void =>
                this.props.allState.legacyUpdater(
                    args => I18nActions.setLanguage(args!),
                    value,
                ),
        });
    }
}

function TopNavHome() {
    const { storeState } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
    }));
    const navigate = useNavigate();
    const topNavTexts: TopNavTexts = texts[storeState.settings.language];
    // TODO: What do we do herre?

    return (
        <Clickable
            element="div"
            className={css({ float: 'left' })}
            onClick={() => navigate('/')}
        >
            <div
                className={css({
                    color: Colors.blue,
                    float: 'left',
                    marginTop: '14px',
                    marginRight: '5px',
                })}
            >
                <Icon icon="home" />
            </div>
            <div
                className={css({
                    float: 'left',
                    color: Colors.blue,
                    marginTop: '18px',
                    ...Typo.navbutton,
                })}
            >
                {topNavTexts.home()}
            </div>
        </Clickable>
    );
}

export interface TopNavTexts {
    aboutUs: Text.Translation;
    home: Text.Translation;
    privacy: Text.Translation;
    provider: Text.Translation;
    spread: Text.Translation;
    tos: Text.Translation;
}

function getMenuItemStyle(active: boolean) {
    return css([
        {
            height: '40px',
            float: 'left',
            cursor: 'pointer',
            padding: '0 16px',
            position: 'relative',
            zIndex: 10,
            background: Colors.white,
            borderTop: `2px solid ${Colors.white}`,
        },
        active && {
            borderBottom: `4px solid ${Colors.blue}`,
        },
    ]);
}

export function TopNav() {
    const { storeState, store } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
    }));
    const navigate = useNavigate();
    const isFaq = useMatch('/faq') !== null;
    const topNavTexts: TopNavTexts = texts[storeState.settings.language];
    const menuItemLinkStyle = css({
        display: 'block',
        textDecoration: 'none',
        color: Colors.blue,
        marginTop: '12px',
        ...Typo.navbutton,
    });
    return (
        <div
            className={css({
                height: '48px',
                margin: '0 80px',
                position: 'relative',
            })}
        >
            <div className={css({ float: 'right', marginTop: '4px' })}>
                <div className={getMenuItemStyle(isFaq)}>
                    <Clickable
                        element="div"
                        className={menuItemLinkStyle}
                        onClick={() => navigate('/faq')}
                    >
                        {AccountTexts.texts[
                            storeState.settings.language
                        ].Help()}
                    </Clickable>
                </div>
                <div className={getMenuItemStyle(false)}>
                    <TosLink className={menuItemLinkStyle}>
                        {topNavTexts.tos()}
                    </TosLink>
                </div>
                <div className={getMenuItemStyle(false)}>
                    <PrivacyLink className={menuItemLinkStyle}>
                        {topNavTexts.privacy()}
                    </PrivacyLink>
                </div>
                <div className={getMenuItemStyle(false)}>
                    <LanguageSetting allState={store} />
                </div>
            </div>
            <TopNavHome />
            <div
                className={css({
                    background: Colors.blue,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '4px',
                    zIndex: 0,
                })}
            />
        </div>
    );
}
