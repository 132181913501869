import * as Flux from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../state/SettingsState';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { Language as Language1 } from 'dg-web-shared/dto/Language.ts';

export type Language = Language1;

export function setLanguageToAllStores(
    store: Flux.Store,
    language: string,
): void {
    new SettingsState.StateSlice(store).writeLanguage(language);
}

export function setLanguage(language: string): Flux.Write {
    return (store: Flux.Store): void => {
        language = language.toLowerCase();
        if (['de', 'fr', 'it', 'en'].indexOf(language) === -1) {
            language = 'de';
        }
        setLanguageToAllStores(store, language);
        LocalStorage.Settings.setUiLanguage(language);
    };
}
