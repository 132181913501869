import { useStore } from 'dg-web-shared/lib/Flux';
import { isDefinedAndDiffersFrom } from 'dg-web-shared/lib/MaybeV2';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import * as Http from '../../api/Http';
import { Localized } from '../../common/components/Localized';
import * as SettingsState from '../../common/state/SettingsState';
import * as Fields from '../../utils/Fields';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import { updateMetaData } from './actions/MetaServerActions';
import { AddressFormError } from './AddressFormError';
import { AccountMeta } from './state/MetaServerState';
import * as MetaState from './state/MetaState';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import { SingleSelection } from 'dg-web-shared/tb-ui/inputs/SingleSelection.tsx';
import {
    DropdownSlideIn,
    SlideInDisplay,
} from '../root/components/DropdownSlideIn';
import { useEffect, useState } from 'react';
import * as PaymentTypeState from '../../common/state/PaymentTypeState';
import { AddressCountry } from 'dg-web-shared/dto/AddressCountry.ts';
import { Field } from 'dg-web-shared/model/Iban.ts';

export const AddressEditFormSlideIn = portalSlideIn(AddressEditForm);

function AddressEditForm(props: {
    portal: SlideInPortalId;
    addressCountries: AddressCountry[];
    accountMeta: AccountMeta;
    onClose: () => void;
}) {
    const { storeState, store } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        paymentType: new PaymentTypeState.StateSlice(s).state,
        meta: MetaState.getMetaState(s),
    }));

    const address = props.accountMeta.address;

    const [form, setForm] = useState<FormState>({
        street1: address.street1,
        countryId: address.countryId,
        city: address.city,
        zipCode: address.zipCode,
    });

    const [countrySelect, setCountrySelect] = useState(false);
    const [showAddressError, setShowAddressError] = useState(false);

    useEffect(() => {
        if (
            !storeState.meta.response.pending &&
            storeState.meta.source === MetaState.Origin.ADDRESS &&
            storeState.meta.response.apiError ===
                'COUNTRY_CHANGE_BLOCKED_BY_PAYMENT_METHOD'
        ) {
            setShowAddressError(true);
        }
    }, [storeState.meta.response]);

    const isSaveable =
        !storeState.meta.response.pending &&
        formValuesChanged(props.accountMeta.address, form) &&
        formIsValid(form);

    function onClose() {
        new MetaState.LocalStateSlice(store).reset();
        setShowAddressError(false);
        props.onClose();
    }

    const country = props.addressCountries?.find(
        country => country.id === form.countryId,
    );

    const countryName =
        typeof country === 'undefined'
            ? ''
            : country.name[storeState.settings.language];

    return (
        <SlideInForm
            secondaryButton={{
                label: <Localized {...GeneralTexts.cancel} />,
                onClick: onClose,
            }}
            primaryButton={{
                label: <Localized {...GeneralTexts.save} />,
                disabled: !isSaveable,
                onClick: () => {
                    store.legacyUpdater(args => updateMetaData(args!), {
                        payload: {
                            ...props.accountMeta,
                            address: {
                                ...props.accountMeta.address,
                                street1: form.street1 || '',
                                zipCode: form.zipCode || '',
                                city: form.city || '',
                                countryId: form.countryId as string,
                            },
                        },
                        origin: MetaState.Origin.ADDRESS,
                        onSuccess: onClose,
                    });
                },
            }}
        >
            <TextField
                context={InputContext.form}
                value={form.street1}
                tabIndex={0}
                labelText={<Localized {...GeneralTexts.street} />}
                name="address"
                autocomplete="address-line1"
                errorText={mandatoryErrorText(
                    new Fields.GenericField(form.street1 || ''),
                )}
                onChange={(street1: string): void => {
                    setForm({ ...form, street1 });
                }}
            />

            <TextField
                context={InputContext.form}
                value={form.zipCode}
                tabIndex={0}
                labelText={<Localized {...GeneralTexts.zip} />}
                name="postal-code"
                autocomplete="postal-code"
                errorText={mandatoryErrorText(
                    new Fields.GenericField(form.zipCode || ''),
                )}
                onChange={(zipCode: string): void => {
                    setForm({ ...form, zipCode });
                }}
            />

            <TextField
                context={InputContext.form}
                value={form.city}
                tabIndex={0}
                labelText={<Localized {...GeneralTexts.city} />}
                name="city"
                autocomplete="address-level2"
                errorText={mandatoryErrorText(
                    new Fields.GenericField(form.city || ''),
                )}
                onChange={(city: string): void => {
                    setForm({ ...form, city });
                }}
            />

            <SingleSelection
                labelText={GeneralTexts.country}
                selection={countryName}
                onClick={() => {
                    setCountrySelect(true);
                }}
                context={InputContext.form}
            />

            <DropdownSlideIn
                portal={props.portal}
                open={countrySelect}
                title={
                    <Localized de="Land" fr="Pays" it="Nazione" en="Country" />
                }
                onClose={() => {
                    setCountrySelect(false);
                }}
                selected={form.countryId}
                onSelected={(countryId: string) => {
                    setCountrySelect(false);
                    setForm({ ...form, countryId });
                }}
                topOptions={countriesAsOptions(
                    props.addressCountries.filter(c => c.id === 'CH'),
                    storeState.settings.language,
                )}
                options={countriesAsOptions(
                    props.addressCountries.filter(c => c.id !== 'CH'),
                    storeState.settings.language,
                )}
            />

            {showAddressError && (
                <AddressFormError
                    onClose={() => setShowAddressError(false)}
                    paymentMethod={
                        storeState.paymentType.data.activePaymentMethod
                            .activePaymentType
                    }
                />
            )}
        </SlideInForm>
    );
}

export function countriesAsOptions(
    countries: AddressCountry[],
    language: string,
) {
    return countries.map(country => {
        return {
            id: country.id,
            display: (
                <SlideInDisplay
                    firstRowText={country.id}
                    secondRowText={country.name[language]}
                />
            ),
        };
    });
}

function formIsValid(form: FormState): boolean {
    const values = makeFormValues(form);

    return Fields.noStatesAndNotEmpty(
        values.street1,
        values.city,
        values.zipCode,
    );
}

function makeFormValues(s: FormState): FormValues {
    return {
        street1: new Fields.GenericField(s.street1 || ''),
        zipCode: new Fields.NumberField(s.zipCode || ''),
        city: new Fields.GenericField(s.city || ''),
        countryId: s.countryId || '',
    };
}

function formValuesChanged(cloud: Http.Address, local: FormState): boolean {
    return [
        [local.street1, cloud.street1],
        [local.zipCode, cloud.zipCode],
        [local.city, cloud.city],
        [local.countryId, cloud.countryId],
    ]
        .map(([a, b]) => isDefinedAndDiffersFrom(a, b))
        .reduce((a, b) => a || b);
}

function mandatoryErrorText(field: Field<void>): React.ReactElement | null {
    if (field.isEmpty) {
        return <Localized {...GeneralTexts.mandatoryField} />;
    }

    return null;
}

interface FormValues {
    street1: Fields.GenericField;
    zipCode: Fields.NumberField;
    city: Fields.GenericField;
    countryId: string;
}

interface FormState {
    street1: string | null;
    countryId: string | null;
    city: string | null;
    zipCode: string | null;
}
