import moment from 'moment';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16';
import {
    getOrElse,
    isDefined,
    isUndefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2';
import { Formatter } from 'dg-web-shared/lib/Date';
import { range } from 'dg-web-shared/lib/ArrayUtil';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors, rgba } from 'dg-web-shared/ui/vars';
import React from 'react';
import { Icon16 } from 'dg-web-shared/ui/icons/Icon';

interface WeekdayProps {
    day: moment.Moment;
    language: string;
}

const Weekday = (p: WeekdayProps): JSX.Element => (
    <div
        className={css({
            ...Typo.caption,
            color: Colors.rgba(Colors.action_w, 0.6),
            float: 'left',
            width: '44px',
            height: '44px',
            textAlign: 'center',
            paddingTop: '13px',
        })}
    >
        {Formatter.dayOfWeekAbbr(
            p.day,
            Formatter.getLocaleFromString(p.language),
        ).substring(0, 1)}
    </div>
);

interface WeekdaysProps {
    day: moment.Moment;
    language: string;
}

const Weekdays = (p: WeekdaysProps): JSX.Element => (
    <div
        className={css({
            height: '44px',
            width: '100%',
            position: 'absolute',
            top: '44px',
        })}
    >
        {range(7).map(i => (
            <Weekday
                day={p.day.clone().add(i, 'days')}
                language={p.language}
                key={i}
            />
        ))}
    </div>
);

interface MonthYearNavigationProps {
    visibleDate: moment.Moment;
    onPrev: Maybe<ClickHandler>;
    onNext: Maybe<ClickHandler>;
    language: string;
}

function getIconButtonStyle(disabled: boolean, type: 'prev' | 'next'): string {
    return css([
        {
            position: 'absolute',
            width: '40px',
            height: '100%',
            padding: '12px',
        },
        disabled && {
            color: rgba(Colors.darkblue, 0.4),
        },
        !disabled && {
            '&:hover': {
                background: Colors.lightblue,
                color: Colors.white,
            },
        },
        type === 'prev' && {
            left: 0,
        },
        type === 'next' && {
            right: 0,
        },
    ]);
}

const MonthYearNavigation = (p: MonthYearNavigationProps): JSX.Element => {
    return (
        <div
            className={css({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '40px',
                color: Colors.blue,
            })}
        >
            <Clickable
                element="div"
                className={getIconButtonStyle(isUndefined(p.onPrev), 'prev')}
                onClick={p.onPrev}
                disabled={isUndefined(p.onPrev)}
            >
                <Icon16 icon={Icons16.chevronLeft} />
            </Clickable>
            <Clickable
                element="div"
                className={getIconButtonStyle(isUndefined(p.onNext), 'next')}
                onClick={p.onNext}
                disabled={isUndefined(p.onNext)}
            >
                <Icon16 icon={Icons16.chevronRight} />
            </Clickable>
            <div
                className={css([
                    {
                        margin: '0 40px',
                        textAlign: 'center',
                        height: '100%',
                        paddingTop: '11px',
                        ...Typo.body,
                    },
                ])}
            >
                {Formatter.monthNameYear(
                    p.visibleDate,
                    Formatter.getLocaleFromString(p.language),
                )}
            </div>
        </div>
    );
};

export type DateSelectHandler = (d: moment.Moment) => void;

interface CalendarElementProps {
    day: moment.Moment;
    onSelect: DateSelectHandler;
    currentDate: moment.Moment;
    selectedDate: Maybe<moment.Moment>;
    minDate: Maybe<moment.Moment>;
    maxDate: Maybe<moment.Moment>;
}

interface DayProps extends CalendarElementProps {
    invalid: boolean;
    inactive: boolean;
}

interface DayState {
    hover: boolean;
}

class Day extends React.Component<DayProps, DayState> {
    constructor(props: DayProps) {
        super(props);
        this.state = { hover: false };
    }

    getState(): string {
        if (this.props.invalid) {
            return 'invalid';
        } else if (this.props.inactive) {
            return 'inactive';
        } else {
            return 'active';
        }
    }

    render() {
        const current = this.props.day.isSame(this.props.currentDate, 'day');
        const selected = thenElse(
            this.props.selectedDate,
            d => this.props.day.isSame(d, 'day'),
            false,
        );
        return (
            <Clickable
                element="div"
                className={css([
                    {
                        ...Typo.body,
                        margin: '4px',
                        color: Colors.blue,
                        width: '36px',
                        height: '36px',
                        float: 'left',
                        textAlign: 'center',
                        borderRadius: '50%',
                        position: 'relative',
                        border: `2px solid ${Colors.rgba('#000', 0)}`,
                    },
                    this.getState() === 'active' && [
                        this.state.hover && {
                            color: Colors.white,
                            background: Colors.lightblue,
                        },
                        !selected && [
                            current && [
                                {
                                    borderColor: Colors.blue,
                                },
                                this.state.hover && {
                                    borderColor: Colors.lightblue,
                                },
                            ],
                        ],
                        selected && {
                            background: Colors.blue,
                            color: Colors.white,
                        },
                    ],
                ])}
                disabled={this.props.invalid || this.props.inactive}
                onClick={() => this.props.onSelect(this.props.day)}
                onMouseOver={() => this.setState({ hover: true })}
                onMouseOut={() => this.setState({ hover: false })}
            >
                <div
                    className={css([
                        {
                            paddingTop: '4px',
                            height: '100%',
                            width: '100%',
                            border: `2px solid ${Colors.rgba('#000', 0)}`,
                            borderRadius: '50%',
                        },
                        this.getState() === 'inactive' && {
                            color: Colors.rgba(Colors.action_w, 0.6),
                        },
                        !this.props.invalid && [
                            current && [
                                this.state.hover && {
                                    borderColor: Colors.white,
                                },
                            ],
                            selected && [
                                { borderColor: Colors.darkblue },
                                current && { borderColor: Colors.white },
                            ],
                        ],
                    ])}
                >
                    {this.props.invalid ? '' : this.props.day.format('D')}
                </div>
            </Clickable>
        );
    }
}

interface WeekProps extends CalendarElementProps {
    month: moment.Moment;
}

const Week = (p: WeekProps): JSX.Element => {
    const { minDate, maxDate } = p;
    return (
        <div className={css({ height: '44px', width: '100%' })}>
            {range(7).map((d, i) => {
                const day = p.day.clone().add(d, 'day');

                const outsideRange =
                    (isDefined(minDate) && day.isBefore(minDate)) ||
                    (isDefined(maxDate) &&
                        day.isAfter(maxDate.clone().endOf('day')));

                return (
                    <Day
                        {...p}
                        key={i}
                        day={day}
                        invalid={!day.isSame(p.month, 'month')}
                        inactive={outsideRange}
                    />
                );
            })}
        </div>
    );
};

type MonthProps = CalendarElementProps;

const Month = (p: MonthProps): JSX.Element => (
    <div
        className={css({
            position: 'absolute',
            top: '88px',
            bottom: 0,
            width: '100%',
        })}
    >
        {range(6)
            .map(w => p.day.clone().startOf('isoWeek').add(w, 'week'))
            .map((d, i) => (
                <Week {...p} key={i} day={d} month={p.day} />
            ))}
    </div>
);

export interface DatePickerProps {
    currentDate: moment.Moment;
    selectedDate: Maybe<moment.Moment>;
    onSelect: DateSelectHandler;
    minDate?: Maybe<moment.Moment>;
    maxDate?: Maybe<moment.Moment>;
    language: string;
    resetVisibleDate?: Maybe<boolean>;
}

interface DatePickerState {
    visibleDate: moment.Moment;
}

const getVisibleDate = (p: DatePickerProps) =>
    moment.max(
        getOrElse(p.selectedDate, p.currentDate),
        getOrElse(p.minDate, p.currentDate),
    );

export class DatePicker extends React.Component<
    DatePickerProps,
    DatePickerState
> {
    constructor(props: DatePickerProps) {
        super(props);
        this.state = {
            visibleDate: getVisibleDate(props),
        };
    }

    UNSAFE_componentWillReceiveProps(next: DatePickerProps): void {
        if (next.resetVisibleDate !== this.props.resetVisibleDate) {
            this.setState({
                visibleDate: getVisibleDate(next),
            });
        }
    }

    getPrevMonthHandler(): Maybe<ClickHandler> {
        const { minDate } = this.props;
        if (
            isDefined(minDate) &&
            this.state.visibleDate.clone().startOf('month').isBefore(minDate)
        ) {
            return null;
        } else {
            return () =>
                this.setState({
                    visibleDate: this.state.visibleDate
                        .clone()
                        .subtract(1, 'month'),
                });
        }
    }

    getNextMonthHandler(): Maybe<ClickHandler> {
        const { maxDate } = this.props;
        if (
            isDefined(maxDate) &&
            this.state.visibleDate.clone().endOf('month').isAfter(maxDate)
        ) {
            return null;
        } else {
            return () =>
                this.setState({
                    visibleDate: this.state.visibleDate.clone().add(1, 'month'),
                });
        }
    }

    render() {
        return (
            <div
                className={css({
                    height: '352px',
                    width: '308px',
                    position: 'relative',
                })}
            >
                <MonthYearNavigation
                    visibleDate={this.state.visibleDate}
                    onPrev={this.getPrevMonthHandler()}
                    onNext={this.getNextMonthHandler()}
                    language={this.props.language}
                />
                <Weekdays
                    day={moment().startOf('isoWeek')}
                    language={this.props.language}
                />
                <Month
                    currentDate={this.props.currentDate}
                    selectedDate={this.props.selectedDate}
                    day={this.state.visibleDate.clone().startOf('month')}
                    onSelect={this.props.onSelect}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                />
            </div>
        );
    }
}
