import { BackendTranslation } from 'dg-web-shared/lib/Text';
import { DropdownSlideInOption } from '../root/components/DropdownSlideIn';
import { VehicleIcon } from './VehicleRow';
import { css } from '@emotion/css';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';

interface Props {
    id: string;
    name: string;
}

const VehicleTypeDisplay = (p: Props) => (
    <div
        className={css({
            display: 'flex',
        })}
    >
        <div
            className={css({
                margin: '0 24px',
                flex: 2,
            })}
        >
            <VehicleIcon type={p.id} />
        </div>
        <div>{p.name}</div>
    </div>
);

export const getLisencePlateTypeOptions = (
    lang: string,
    types: BackendTranslation[],
): DropdownSlideInOption<LicensePlateType>[] =>
    types.map(
        (
            type: BackendTranslation,
        ): DropdownSlideInOption<LicensePlateType> => ({
            id: type.id as LicensePlateType,
            display: (
                <VehicleTypeDisplay
                    key={`vehicle-type=${type.id}`}
                    id={type.id}
                    name={type.name[lang]}
                />
            ),
        }),
    );
