import { css } from '@emotion/css';

import { generateState, selectState } from 'dg-web-shared/lib/Flux';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import * as SettingsState from '../../common/state/SettingsState';
import { languages } from '../../layout/i18n/Languages';
import { PrivacyLink } from '../../legal/PrivacyPolicyFiles';
import { setLanguage } from '../../shared/components/LanguageSwitch';
import { Colors } from 'dg-web-shared/ui/vars';
import { Localized } from '../../common/components/Localized';
import { TosLink } from '../../legal/TosFiles';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export enum LandingPageAnchor {
    app = 'anchor-app',
    faq = 'anchor-faq',
}

export function LandingPageFooter() {
    return (
        <div
            className={css({
                height: '48px',
                display: 'flex',
                flexShrink: 0,
                flexGrow: 0,
                position: 'relative',
                backgroundImage: Colors.blackGradient,
            })}
        >
            <Privacy />
            <Tos />
            <LanguageSwitch />
        </div>
    );
}

function Privacy() {
    return (
        <FooterItem>
            <PrivacyLink>
                <Localized
                    de="Privacy"
                    fr="Privacy"
                    it="Privacy"
                    en="Privacy"
                />
            </PrivacyLink>
        </FooterItem>
    );
}

function Tos() {
    return (
        <FooterItem>
            <TosLink>
                <Localized de="AGB" fr="CGV" it="CG" en="GTC" />
            </TosLink>
        </FooterItem>
    );
}

const FooterItem = (p: {
    children?: React.ReactNode;
    onClick?: () => void;
}) => {
    return (
        <Clickable
            element="div"
            onClick={p.onClick}
            className={css({
                color: Colors.white,
                fontSize: '13px',
                ...Typo.robotoRegular,
                height: '48px',
                lineHeight: '48px',
                marginLeft: '20px',
                a: {
                    color: Colors.white,
                    fontSize: '13px',
                    ...Typo.robotoRegular,
                    textDecoration: 'none',
                },
            })}
        >
            {p.children}
        </Clickable>
    );
};

namespace LanguageSwitchState {
    export interface State {
        open: boolean;
    }

    export const { get, stateWrite } = generateState<State>(
        'landing-page-footer-language-switch',
        { open: false },
    );
}

const LanguageSwitch = selectState(
    s => ({
        settings: new SettingsState.StateSlice(s).state,
        switch: LanguageSwitchState.get(s),
    }),
    p => {
        return (
            <div
                className={css({
                    position: 'absolute',
                    right: '20px',
                    top: '12px',
                    display: 'flex',
                    height: '24px',
                    border: `1px solid ${Colors.white}`,
                    borderRadius: '2px',
                    background: Colors.blackGradient,
                })}
            >
                {languages.map(l => {
                    const isCurrent = p.settings.language === l.shortcut;
                    return p.switch.open || isCurrent ? (
                        <Clickable
                            element="div"
                            className={css({
                                fontSize: '13px',
                                letterSpacing: '1px',
                                lineHeight: '24px',
                                ...Typo.robotoLight,
                                color: Colors.white,
                                margin: '0 7px',
                            })}
                            onClick={() => {
                                if (p.switch.open) {
                                    p.update(store =>
                                        setLanguage(store, l.shortcut),
                                    );
                                    p.update(store =>
                                        LanguageSwitchState.stateWrite(store, {
                                            open: false,
                                        }),
                                    );
                                } else {
                                    p.update(store =>
                                        LanguageSwitchState.stateWrite(store, {
                                            open: true,
                                        }),
                                    );
                                }
                            }}
                        >
                            {l.shortcut.toLocaleUpperCase()}
                        </Clickable>
                    ) : null;
                })}
            </div>
        );
    },
);
