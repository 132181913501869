import { DateTime } from 'luxon';
import * as Flux from 'dg-web-shared/lib/Flux';
import { useStore } from 'dg-web-shared/lib/Flux';
import { PaymentMethodEnum } from '../../api/Http';
import * as CommonQueryActions from '../actions/CommonQueryActions';
import * as ServerStateSlice from 'dg-web-shared/common/state/ServerStateSliceV2.ts';

export interface PaymentType {
    activePaymentMethod: ActivePaymentMethod;
    paymentMethodChangeRequest: PaymentMethodChangeRequest | null;
    validBankClearingNumbers: Set<number>;
}

export type ActivePaymentMethod =
    | ActivePaymentMethodPrepaid
    | ActivePaymentMethodLsv
    | ActivePaymentMethodInvoice
    | ActivePaymentMethodDirect;

interface ActivePaymentMethodPrepaid {
    activePaymentType: PaymentMethodEnum.PREPAID;
}

interface ActivePaymentMethodLsv {
    activePaymentType: PaymentMethodEnum.LSV;
    iban: string;
    directDebitAccountChangeRequest: DirectDebitAccountChangeRequest | null;
}

interface ActivePaymentMethodInvoice {
    activePaymentType: PaymentMethodEnum.INVOICE;
}

interface ActivePaymentMethodDirect {
    activePaymentType: PaymentMethodEnum.DIRECT;
    validAlias: boolean;
}

type PaymentMethodChangeRequest =
    | PaymentMethodChangeRequestLsv
    | PaymentMethodChangeRequestInvoice;

interface PaymentMethodChangeRequestLsv {
    requestedPaymentType: PaymentMethodEnum.LSV;
    paymentMethodRequestTimestamp: DateTime | null;
    requestedIban: string;
}

interface PaymentMethodChangeRequestInvoice {
    requestedPaymentType: PaymentMethodEnum.INVOICE;
    paymentMethodRequestTimestamp: DateTime | null;
}

export interface DirectDebitAccountChangeRequest {
    requestedIban: string;
    ibanChangeRequestTimestamp: DateTime;
}

export type State = ServerStateSlice.State<PaymentType>;

export class StateSlice extends ServerStateSlice.LegacyServerStateSlice<PaymentType> {
    key(): string {
        return 'common-PaymentTypeState';
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.shouldFetch) {
            store.legacyUpdater(CommonQueryActions.receivePaymentType);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseBody(body: any): PaymentType {
        const validBankClearingNumbers: Set<number> = new Set(
            body.validBankClearingNumbers,
        );

        return {
            activePaymentMethod: activePaymentMethod(body.activePaymentMethod),
            paymentMethodChangeRequest: paymentMethodChangeRequest(
                body.paymentMethodChangeRequest,
            ),
            validBankClearingNumbers,
        };
    }

    getEmptyData(): PaymentType {
        return {
            activePaymentMethod: {
                activePaymentType: PaymentMethodEnum.PREPAID,
            },
            paymentMethodChangeRequest: null,
            validBankClearingNumbers: new Set([]),
        };
    }
}

export const usePaymentTypeState = (): [State, () => void] => {
    const { storeState, update } = useStore(s => ({
        state: new StateSlice(s).state,
    }));
    return [
        storeState.state,
        () =>
            update(s => {
                new StateSlice(s).reset();
                return 'reset-payment-type-state';
            }),
    ];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function activePaymentMethod(src: any): ActivePaymentMethod {
    const activePaymentType = src.activePaymentType as PaymentMethodEnum;

    switch (activePaymentType) {
        case PaymentMethodEnum.LSV:
            return {
                activePaymentType,
                iban: src.iban,
                directDebitAccountChangeRequest:
                    directDebitAccountChangeRequest(
                        src.directDebitAccountChangeRequest,
                    ),
            };

        case PaymentMethodEnum.PREPAID:
            return { activePaymentType };

        case PaymentMethodEnum.INVOICE:
            return { activePaymentType };

        case PaymentMethodEnum.DIRECT:
            return { activePaymentType, validAlias: src.validAlias };
    }
}

function paymentMethodChangeRequest(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    src: any,
): PaymentMethodChangeRequest | null {
    if (!src) {
        return null;
    }

    const requestedPaymentType = src.requestedPaymentType as PaymentMethodEnum;

    switch (requestedPaymentType) {
        case PaymentMethodEnum.PREPAID:
            // That should not be possible. No need to request the “prepaid”.
            return null;

        case PaymentMethodEnum.DIRECT:
            // That should not be possible. No need to request the “direct”.
            return null;

        case PaymentMethodEnum.INVOICE:
            return {
                requestedPaymentType,
                paymentMethodRequestTimestamp: paymentMethodRequestTimestamp(
                    src.paymentMethodRequestTimestamp,
                ),
            };

        case PaymentMethodEnum.LSV:
            return {
                requestedPaymentType,
                paymentMethodRequestTimestamp: paymentMethodRequestTimestamp(
                    src.paymentMethodRequestTimestamp,
                ),
                requestedIban: src.requestedIban,
            };
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function paymentMethodRequestTimestamp(src: any): DateTime | null {
    return src ? DateTime.fromISO(src, { locale: 'de-CH' }) : null;
}

function directDebitAccountChangeRequest(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    src: any,
): DirectDebitAccountChangeRequest | null {
    return src
        ? {
              requestedIban: src.requestedIban,
              ibanChangeRequestTimestamp: DateTime.fromISO(
                  src.ibanChangeRequestTimestamp,
                  { locale: 'de-CH' },
              ),
          }
        : null;
}
