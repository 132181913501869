import { useState } from 'react';
import { Input } from 'dg-web-shared/tb-ui/inputs/internal/Input.ts';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import {
    Underline,
    UnderlineStatus,
} from 'dg-web-shared/tb-ui/inputs/internal/Underline.tsx';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { ParkingcardTypo } from '../style/parkingcardTypo.ts';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

export enum SearchFieldStyle {
    BLUE_GRAY,
    WHITE_WHITE,
}

interface SearchFieldProps {
    onChange: (value: string) => void;
    onClear: () => void;
    value: string;
    labelText: string | JSX.Element;
    elevatedLabelText: string | JSX.Element;
    disabled: boolean;
    searchFieldStyle: SearchFieldStyle;
}

export const SearchField = (props: SearchFieldProps) => {
    const [focused, setFocused] = useState(false);

    const active = focused || !!props.value;
    let _input: Input;

    const onActivateClickHandler = () => {
        if (!focused && _input) {
            _input.focus();
            setFocused(true);
        }
    };

    const onClearClickHandler = () => {
        props.onClear();
        _input.blur();
    };

    return (
        <div
            className={css({
                position: 'relative',
            })}
        >
            <Clickable
                element="div"
                className={css({
                    paddingRight: '48px',
                })}
                onClick={onActivateClickHandler}
            >
                <LabelSearchField
                    active={active}
                    elevatedLabelText={props.elevatedLabelText}
                    labelText={props.labelText}
                    searchFieldStyle={props.searchFieldStyle}
                />
                <Input
                    inputType={'text'}
                    context={(() => {
                        switch (props.searchFieldStyle) {
                            case SearchFieldStyle.BLUE_GRAY:
                                return InputContext.form;
                            case SearchFieldStyle.WHITE_WHITE:
                                return InputContext.inverted;
                        }
                    })()}
                    maxChars={40}
                    autoCapitalize={undefined}
                    autoCorrect={undefined}
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}
                    onChange={props.onChange}
                    value={props.value}
                    ref={(ref: Input) => (_input = ref)}
                    disabled={props.disabled}
                />
            </Clickable>
            <RenderIcon
                active={active}
                onActivateClickHandler={() => onActivateClickHandler()}
                onClearClickHandler={() => onClearClickHandler()}
                searchFieldStyle={props.searchFieldStyle}
            />
            <Underline
                status={focused ? UnderlineStatus.focus : UnderlineStatus.blur}
                context={(() => {
                    switch (props.searchFieldStyle) {
                        case SearchFieldStyle.BLUE_GRAY:
                            return InputContext.form;
                        case SearchFieldStyle.WHITE_WHITE:
                            return InputContext.inverted;
                    }
                })()}
            />
        </div>
    );
};

interface LabelSearchFieldProps {
    elevatedLabelText: string | JSX.Element;
    labelText: string | JSX.Element;
    active: boolean;
    searchFieldStyle: SearchFieldStyle;
}

const LabelSearchField = (props: LabelSearchFieldProps) => {
    const text = props.active ? props.elevatedLabelText : props.labelText;
    return (
        <label
            className={css({
                position: 'relative',
                display: 'block',
                cursor: 'pointer',
                height: '16px',
                overflow: 'visible',
            })}
        >
            <span
                className={css({
                    ...ParkingcardTypo.tBody,
                    fontWeight: 500,
                    display: 'block',
                    position: 'absolute',
                    cursor: props.active ? 'default ' : 'text',
                    top: '11px',
                    right: '0',
                    left: '0',
                    color: props.active
                        ? (() => {
                              switch (props.searchFieldStyle) {
                                  case SearchFieldStyle.BLUE_GRAY:
                                      return `${Colors.rgba(
                                          Colors.action_w,
                                          0.4,
                                      )}`;
                                  case SearchFieldStyle.WHITE_WHITE:
                                      return Colors.white;
                              }
                          })()
                        : (() => {
                              switch (props.searchFieldStyle) {
                                  case SearchFieldStyle.BLUE_GRAY:
                                      return Colors.lightblue;
                                  case SearchFieldStyle.WHITE_WHITE:
                                      return Colors.white;
                              }
                          })(),
                    fontSize: props.active ? '12px' : '16px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    transitionProperty: 'transform,font-size',
                    transitionDuration: '300ms',
                    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    transform: props.active ? 'translate(0, -15px)' : '',
                })}
            >
                {text}
            </span>
        </label>
    );
};

interface RenderIconProps {
    active: boolean;
    onClearClickHandler: () => void;
    onActivateClickHandler: () => void;
    searchFieldStyle: SearchFieldStyle;
}

const RenderIcon = (props: RenderIconProps) => {
    return (
        <Clickable
            element="div"
            className={css({
                position: 'absolute',
                top: '-8px',
                right: (() => {
                    switch (props.searchFieldStyle) {
                        case SearchFieldStyle.BLUE_GRAY:
                            return '-16px';
                        case SearchFieldStyle.WHITE_WHITE:
                            return '-12px';
                    }
                })(),
                width: '48px',
                height: '48px',
                paddingTop: '12px',
                paddingLeft: '12px',
                color: props.active
                    ? (() => {
                          switch (props.searchFieldStyle) {
                              case SearchFieldStyle.BLUE_GRAY:
                                  return Colors.action_w;
                              case SearchFieldStyle.WHITE_WHITE:
                                  return Colors.white;
                          }
                      })()
                    : (() => {
                          switch (props.searchFieldStyle) {
                              case SearchFieldStyle.BLUE_GRAY:
                                  return Colors.lightblue;
                              case SearchFieldStyle.WHITE_WHITE:
                                  return Colors.white;
                          }
                      })(),
            })}
            data-clear={true}
            onClick={
                props.active
                    ? () => props.onClearClickHandler()
                    : () => props.onActivateClickHandler()
            }
            key={props.active ? 'clear' : 'search'}
        >
            {props.active ? <Icon icon="clear" /> : <Icon icon="search" />}
        </Clickable>
    );
};
