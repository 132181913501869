import { DurationType } from 'dg-web-shared/dto/PermitTimeUnit';
import { nl2br } from 'dg-web-shared/lib/ReactHelpers';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { SingleSelection } from 'dg-web-shared/tb-ui/inputs/SingleSelection.tsx';
import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { LabeledText } from 'dg-web-shared/tb-ui/typo/LabeledText.tsx';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import * as Text from '../../../common/i18n/Text';
import * as Date from '../../../utils/Date';
import { Formatter, Parser } from '../../../utils/Date';
import * as NumberFormatter from '../../../utils/NumberFormatter';
import * as PermitTexts from '../../i18n/PermitTexts';
import * as PermitPurchaseState from '../../state/permit/PermitPurchaseState';
import { Zones, zonesAreSelectable } from './Zones';
import { getDurationOptionDisplayText } from './DateSelectionSlideins';
import { PermitPurchase } from './PermitPurchase';
import {
    ContractQuotaInfos,
    ContractQuotaInfosPerEntity,
    ContractQuotaInfoType,
    keyForQuotaInfo,
} from 'dg-web-shared/common/components/quota-info/ContractQuotaInfos';
import {
    ArrowPosition,
    Color,
    InlineComfirmable,
} from '../../../ui/modals/Confirmable';
import { Localized } from '../../../common/components/Localized';
import { IntradayValidityWeekProfileDisplay } from 'dg-web-shared/common/components/intraday-validity-week-profile-display/IntradayValidityWeekProfileDisplay';
import { ErrorBlock } from '../ErrorBlock';
import {
    RequestMethod,
    RequestStatus,
    ServerRequestState,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    ErrorDefinition,
    PermitPriceCalculation,
    PermitPriceCalculationPayload,
} from './PriceCalculations';
import { PermitPayload } from './PermitPayloadContext';
import { ParkingpayAsyncLoadedSection } from '../../../common/components/ParkingpayAsyncLoadedSection';
import { Colors } from 'dg-web-shared/ui/vars';
import { css } from '@emotion/css';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { logAction } from '../../../utils/ActionLog';
import { useServerFetch } from '../../../hooks/ServerStateHooks';
import { SpinnerModal } from '../../../ui/spinner/Spinner.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { validityIntervalTexts } from 'dg-web-shared/common/components/translations/PermitValidityIntervalTexts.ts';

export interface Texts {
    ReminderLabel: Text.Translation;
    ReminderCaption: (days: number, email: string) => string;
    Price: Text.Translation;
    AdditionalInformation: Text.Translation;
    ValidTo: Text.Translation;
}

interface PriceProps {
    type: PermitPurchaseState.TypeData.Type;
    validFrom: DateTime | null;
    toDate: DateTime | null;
    durationType: DurationType | null;
    duration: number | null;
    entityIds: number[];
    language: string;
    useReminder: boolean;
    selectedZoneIds: number[];
    forcePurchase: boolean;
    onToggleMultipleZones: (arg: { id: number; max: 1 | null }) => void;
}

export function PriceAndNextElement({
    type,
    validFrom,
    toDate,
    duration,
    durationType,
    entityIds,
    language,
    selectedZoneIds,
    forcePurchase,
    onToggleMultipleZones,
}: PriceProps) {
    const [calcState] = useServerFetch<
        PermitPriceCalculation,
        PermitPriceCalculationPayload,
        ErrorDefinition
    >(
        payload => ({
            url: `/ui-api/customer-account/permits/purchase/${payload.permitTypeId}/price-calculation`,
            method: RequestMethod.POST,
            body: payload,
        }),
        {
            permitTypeId: type.permitTypeId,
            fromDate: validFrom ? Date.Formatter.isoString(validFrom) : null,
            validFrom: validFrom ? Date.Formatter.isoString(validFrom) : null,
            toDate: toDate ? Date.Formatter.isoYearMonthDay(toDate) : null,
            duration,
            durationType,
            entityIds,
        },
    );
    const texts = PermitTexts.configTexts[language];
    const zoneAreValid =
        selectedZoneIds.length > 0 || !zonesAreSelectable(type);
    const [useReminder, setUseReminder] = useState(true);

    if (
        calcState.status === RequestStatus.NEVER_EXECUTED ||
        calcState.status === RequestStatus.PENDING
    ) {
        return <SpinnerModal visible portal={SlideInPortalId.PARK_CREATE} />;
    }

    if (calcState.status === RequestStatus.ERROR) {
        return (
            <ErrorBlock
                apiErrorCode={calcState.data?.code || null}
                onClose={() => null}
            />
        );
    }

    if (calcState.data.quotaInfos) {
        return (
            <>
                {calcState.data.quotaInfos.map(infoItem => (
                    <QuotaReachedInfo
                        key={keyForQuotaInfo(infoItem)}
                        quotaInfo={infoItem}
                    />
                ))}
            </>
        );
    }
    return (
        <div>
            {
                <LabeledText
                    context={InputContext.inverted}
                    label={texts.ValidTo()}
                >
                    <>
                        {Formatter.dayMonthYearHourMinute(
                            Parser.isoToMoment(calcState.data.to),
                        )}
                        {!calcState.data.overlappingPermitId &&
                            !forcePurchase &&
                            calcState.data.showSameDayNot24hWarning && (
                                <div
                                    className={css({
                                        color: Colors.action_b,
                                        fontSize: '13px',
                                        lineHeight: '16px',
                                        letterSpacing: '0.015em',
                                        ...Typo.robotoRegular,
                                        paddingTop: '8px',
                                    })}
                                >
                                    <Localized
                                        de="Diese Bewilligung ist nur für Kalendertage gültig und endet daher um Mitternacht."
                                        fr="Cette autorisation n'est valable que pour des jours calendaires et est donc terminée à minuit."
                                        it="Questa autorizzazione è valida solo per giorni di calendario e viene quindi terminata a mezzanotte."
                                        en="This permit is only valid for calendar days and is therefore terminated at midnight."
                                    />
                                </div>
                            )}
                    </>
                </LabeledText>
            }
            {calcState.data.intradayValidityWeekProfile &&
                !calcState.data.withinOneIntradayValidityInterval && (
                    <LabeledText
                        context={InputContext.inverted}
                        label={{
                            de: 'Tägliche Gültigkeit',
                            fr: 'Validité journalière',
                            it: 'Validità giornaliera',
                            en: 'Daily validity',
                        }}
                    >
                        <IntradayValidityWeekProfileDisplay
                            localizedComponent={Localized}
                            weekProfile={
                                calcState.data.intradayValidityWeekProfile
                            }
                        />
                    </LabeledText>
                )}
            <LabeledText context={InputContext.inverted} label={texts.Price()}>
                {calcState.data.price
                    ? NumberFormatter.numberToPrice(calcState.data.price)
                    : '...'}
            </LabeledText>
            <Zones
                type={type}
                language={language}
                selectedZoneIds={selectedZoneIds}
                onToggleMultiple={onToggleMultipleZones}
            />
            {zoneAreValid && calcState.data.reminderDays && (
                <LabeledToggle
                    context={InputContext.inverted}
                    type={ToggleType.checkbox}
                    label={texts.ReminderLabel()}
                    caption={texts.ReminderCaption(
                        calcState.data.reminderDays,
                        calcState.data.reminderEmail,
                    )}
                    selected={useReminder}
                    onClick={() => setUseReminder(r => !r)}
                />
            )}
            {zoneAreValid && (
                <PermitPurchase
                    calc={calcState.data}
                    fromDate={validFrom}
                    toDate={toDate}
                    duration={duration}
                    durationType={durationType}
                    language={language}
                    entityIds={entityIds}
                    typeData={type}
                    useReminder={useReminder}
                    selectedZoneIds={selectedZoneIds}
                />
            )}
        </div>
    );
}

interface Props {
    type: PermitPurchaseState.TypeData.Type;
    dateValidationState: ServerRequestState<PermitPurchaseState.DateValidation.Data>;
    permitConfig: PermitPayload;
    entityIdSelection: number[];
    language: string;
    forcePurchase: boolean;
    onFromDateSlideInOpen: () => void;
    onDurationSlideInOpen: () => void;
    onToggleMultipleZones: (arg: { id: number; max: 1 | null }) => void;
}

export const DurationSelection = ({
    language,
    dateValidationState,
    onDurationSlideInOpen,
    config,
}: {
    language: string;
    dateValidationState: PermitPurchaseState.DateValidation.WithDuration;
    onDurationSlideInOpen: () => void;
    config: PermitPayload;
}): JSX.Element => {
    return (
        <SingleSelection
            labelText={validityIntervalTexts[language].Duration()}
            context={InputContext.inverted}
            onClick={
                dateValidationState.allowedDurations.length > 1
                    ? onDurationSlideInOpen
                    : null
            }
            selection={
                config.duration && config.durationType
                    ? getDurationOptionDisplayText(
                          config.duration,
                          config.durationType,
                          validityIntervalTexts[language],
                      )
                    : null
            }
        />
    );
};

export function PermitConfig({
    dateValidationState,
    permitConfig,
    language,
    type,
    forcePurchase,
    onFromDateSlideInOpen,
    onDurationSlideInOpen,
    entityIdSelection,
    onToggleMultipleZones,
}: Props) {
    const [showDateChangedWarning, setShowDateChangedWarning] = useState(false);
    useServerSuccessEffect(dateValidationState, data => {
        setShowDateChangedWarning(data.showDateChangeWarning);
    });
    return (
        <ParkingpayAsyncLoadedSection
            state={dateValidationState}
            showSpinnerOnRefetch
            renderSuccess={data => (
                <>
                    {data.addInfo && (
                        <LabeledText
                            context={InputContext.inverted}
                            label={PermitTexts.configTexts[
                                language
                            ].AdditionalInformation()}
                        >
                            {nl2br(data.addInfo)}
                        </LabeledText>
                    )}
                    <SingleSelection
                        labelText={validityIntervalTexts[language].FromDate()}
                        context={InputContext.inverted}
                        onClick={onFromDateSlideInOpen}
                        selection={Formatter.dayMonthYearHourMinute(
                            data.validFrom
                                ? DateTime.fromISO(data.validFrom)
                                : DateTime.now(),
                        )}
                    />
                    {data.validFrom !== null &&
                        DateTime.now().toISODate() ===
                            DateTime.fromISO(data.validFrom).toISODate() && (
                            <div
                                className={css({
                                    color: Colors.action_b,
                                    fontSize: '13px',
                                    lineHeight: '16px',
                                    letterSpacing: '0.015em',
                                    ...Typo.robotoRegular,
                                    marginTop: '-12px',
                                    paddingBottom: '8px',
                                })}
                            >
                                <Localized
                                    de="ACHTUNG: aufgrund von Zeitbeschränkungen wird die Bewilligung NICHT sofort gültig sein."
                                    fr="ATTENTION: en raison des restrictions temporelles, l'autorisation n'est PAS valable immédiatement."
                                    it="ATTENZIONE: a seguito delle restrizioni d'orario, l'autorizzazione NON sarà immediatamente valida."
                                    en="ATTENTION: due to time restrictions, the permit will NOT be valid immediately."
                                />
                            </div>
                        )}
                    {showDateChangedWarning && (
                        <DateChangeWarning
                            onAcknowledge={() => {
                                setShowDateChangedWarning(false);
                                logAction(
                                    null,
                                    'user-acknowledged-change-of-permit-date',
                                    { newDate: data.validFrom },
                                );
                            }}
                        />
                    )}
                    {type.timeSelectionType === 'duration' &&
                        data.selectionType === 'duration' &&
                        data.error === undefined &&
                        !showDateChangedWarning && (
                            <DurationSelection
                                config={permitConfig}
                                dateValidationState={data}
                                language={language}
                                onDurationSlideInOpen={onDurationSlideInOpen}
                            />
                        )}
                    {(permitConfig.toDate ||
                        (permitConfig.duration && !showDateChangedWarning)) && (
                        <div>
                            <PriceAndNextElement
                                duration={permitConfig.duration}
                                durationType={permitConfig.durationType}
                                entityIds={entityIdSelection}
                                validFrom={
                                    data.validFrom
                                        ? DateTime.fromISO(data.validFrom)
                                        : null
                                }
                                toDate={permitConfig.toDate}
                                language={language}
                                type={type}
                                useReminder={permitConfig.useReminder}
                                selectedZoneIds={permitConfig.selectedZoneIds}
                                onToggleMultipleZones={onToggleMultipleZones}
                                forcePurchase={forcePurchase}
                            />
                        </div>
                    )}
                </>
            )}
            portal={SlideInPortalId.PARK_CREATE}
        />
    );
}

function QuotaReachedInfo({ quotaInfo }: { quotaInfo: ContractQuotaInfos }) {
    switch (quotaInfo.type) {
        case ContractQuotaInfoType.CONTRACT_PURCHASE_OVERLAPPING:
            return (
                <InlineComfirmable
                    title={{
                        color: Color.error,
                        caption: <ContractQuotaInfoPerPeriodTitle />,
                    }}
                    arrowPosition={ArrowPosition.left}
                >
                    <ContractQuotaInfoPerPeriodBody />
                </InlineComfirmable>
            );
        case ContractQuotaInfoType.CONTRACT_PURCHASE_PER_ENTITY: {
            const fullyReached = quotaInfo.quotaReachingEntities.every(
                e => e.daysLeft === 0,
            );
            return (
                <InlineComfirmable
                    title={{
                        color: fullyReached ? Color.error : Color.yellow,
                        caption: (
                            <ContractQuotaInfoPerEntityTitle
                                fullyReached={fullyReached}
                            />
                        ),
                    }}
                    arrowPosition={ArrowPosition.left}
                >
                    <ContractQuotaInfoPerEntityBody
                        quotaInfo={quotaInfo}
                        fullyReached={fullyReached}
                    />
                </InlineComfirmable>
            );
        }
        default:
            console.error(
                `Unknown ContractQuotaInfo '${quotaInfo}'. Contract purchase is blocked without a user message.`,
            );
            return null;
    }
}

function DateChangeWarning({ onAcknowledge }: { onAcknowledge: () => void }) {
    return (
        <InlineComfirmable
            title={{
                color: Color.darkblue,
                caption: (
                    <Localized
                        de="Datum angepasst"
                        fr="Date adaptée"
                        it="Data modificata"
                        en="Date adjusted"
                    />
                ),
            }}
            arrowPosition={ArrowPosition.left}
            confirm={{
                caption: (
                    <Localized
                        de="Weiter"
                        fr="Continuer"
                        it="Avanti"
                        en="Proceed"
                    />
                ),
                callback: onAcknowledge,
            }}
        >
            <p className={css({ display: 'flex', alignItems: 'center' })}>
                <Localized
                    de="Das ausgewählte Datum ist nicht verfügbar, daher wurde auf das nächste verfügbare Datum umgestellt."
                    fr="La date sélectionnée n'est pas disponible, elle a donc été modifiée à la prochaine date disponible."
                    it="La data selezionata non è disponibile, quindi è stata cambiata sulla prossima data disponibile."
                    en="The selected date is not available, therefore it has been changed to the next available date."
                />
            </p>
        </InlineComfirmable>
    );
}

function ContractQuotaInfoPerEntityTitle({
    fullyReached,
}: {
    fullyReached: boolean;
}) {
    return fullyReached ? (
        <Localized
            de="Kontingent erschöpft"
            fr="Contingent épuisé"
            it="Contingente esaurito"
            en="Quota exhausted"
        />
    ) : (
        <Localized
            de="Ungenügendes Kontingent"
            fr="Contingent insuffisant"
            it="Contingente insufficiente"
            en="Insufficient quota"
        />
    );
}

function ContractQuotaInfoPerEntityBody({
    quotaInfo,
    fullyReached,
}: {
    quotaInfo: ContractQuotaInfosPerEntity;
    fullyReached: boolean;
}) {
    return (
        <>
            <p>
                <Localized
                    de={`Diese Bewilligungsart unterliegt einer Kontingentierung und kann daher vom Benutzer nur für ${
                        quotaInfo.days
                    } Tage ${
                        quotaInfo.period === 'CALENDAR_MONTH'
                            ? 'pro Monat'
                            : 'pro Jahr'
                    } erworben werden.`}
                    fr={`Ce type d'autorisation est soumis à un contingent et ne peut donc être acheté par l'utilisateur que pour ${
                        quotaInfo.days
                    } jours ${
                        quotaInfo.period === 'CALENDAR_MONTH'
                            ? 'par mois'
                            : 'par année'
                    }.`}
                    it={`Questo tipo di autorizzazione è soggetta a un contingente e quindi può essere acquistata dall'utente solo per ${
                        quotaInfo.days
                    } giorni ${
                        quotaInfo.period === 'CALENDAR_MONTH'
                            ? 'al mese'
                            : "all'anno"
                    }.`}
                    en={`This type of permit is subject to a quota and can therefore only be purchased by the user for ${
                        quotaInfo.days
                    } days ${
                        quotaInfo.period === 'CALENDAR_MONTH'
                            ? 'per month'
                            : 'per year'
                    }.`}
                />
            </p>
            {!fullyReached && (
                <>
                    <p>
                        <Localized
                            de={`Das Kontingent für die folgenden ${
                                quotaInfo.entityType === 'LICENSE_PLATE'
                                    ? 'Kennzeichen'
                                    : 'Badge'
                            } ist unzureichend:`}
                            fr={`Le contingent pour ${
                                quotaInfo.entityType === 'LICENSE_PLATE'
                                    ? 'les plaques suivantes'
                                    : 'les badges suivants'
                            } est insuffisant.`}
                            it={`Il contingente per ${
                                quotaInfo.entityType === 'LICENSE_PLATE'
                                    ? 'le seguenti targhe'
                                    : 'i seguenti badges'
                            } è insufficiente:`}
                            en={`The quota for the following ${
                                quotaInfo.entityType === 'LICENSE_PLATE'
                                    ? 'license plates'
                                    : 'badges'
                            } is insufficient:`}
                        />
                    </p>
                    <p>
                        {quotaInfo.quotaReachingEntities
                            .filter(e => e.notEnoughQuota)
                            .map(e => (
                                <div key={e.description}>
                                    <strong>{e.description}</strong>
                                    {' – '}
                                    {e.daysLeft > 0 ? (
                                        <Localized
                                            de={`${e.daysLeft} ${
                                                e.daysLeft > 1 ? 'Tage' : 'Tag'
                                            } verfügbar`}
                                            fr={`${e.daysLeft} ${
                                                e.daysLeft > 1
                                                    ? 'jours disponibles'
                                                    : 'jour disponible'
                                            }`}
                                            it={`${e.daysLeft} ${
                                                e.daysLeft > 1
                                                    ? 'giorni disponibili'
                                                    : 'giorno disponibile'
                                            }`}
                                            en={`${e.daysLeft} ${
                                                e.daysLeft > 1 ? 'days' : 'day'
                                            } available`}
                                        />
                                    ) : (
                                        <Localized
                                            de="verfügbare Tage verbraucht"
                                            fr="jours disponibles épuisés"
                                            it="giorni a disposizione esauriti"
                                            en="days available sold out"
                                        />
                                    )}
                                </div>
                            ))}
                    </p>
                </>
            )}
        </>
    );
}

function ContractQuotaInfoPerPeriodTitle() {
    return (
        <Localized
            de="Kontingent erschöpft"
            fr="Contingent épuisé"
            it="Contingente esaurito"
            en="Quota exhausted"
        />
    );
}

function ContractQuotaInfoPerPeriodBody() {
    return (
        <p>
            <Localized
                de="Das Kontingent für diese Bewilligung ist für mindestends einen Tag im ausgewählten Zeitraum erschöpft."
                fr="Le contingent pour cette autorisation est épuisé pour au moins un jour dans la période sélectionnée."
                it="Il contingente per questa autorizzazione è esaurito per almeno un giorno nel periodo selezionato."
                en="The quota for this permit is exhausted for at least one day in the selected period."
            />
        </p>
    );
}
