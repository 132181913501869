import { useEffect, useState } from 'react';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Translation } from 'dg-web-shared/lib/Text';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import * as SettingsState from '../../common/state/SettingsState';
import { addressTexts } from '../i18n/AddressTexts';
import { navigationTextsFor } from '../i18n/NavigationTexts';
import * as AccountSetupState from '../state/AccountSetupState';
import { getOrElse, isDefined, thenElse } from 'dg-web-shared/lib/MaybeV2';
import { AccountSetupBlock, Para } from './AccountSetupBlock';
import { SingleSelection } from 'dg-web-shared/tb-ui/inputs/SingleSelection.tsx';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { AddressCountrySlidein } from './AccountSetupSlideins';
import * as AddressCountriesState from '../../common/state/AddressCountriesState';
import * as Validation from './Validation';
import { Validators } from './Validation';
import * as StringConversions from 'dg-web-shared/lib/StringConversions';

import { UnderlineStatus } from 'dg-web-shared/tb-ui/inputs/internal/Underline.tsx';

import { logAction } from '../../utils/ActionLog';
import * as MetaServerState from '../../account/meta/state/MetaServerState';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import { GenderSelect } from 'dg-web-shared/tb-ui/inputs/GenderSelect.tsx';
import {
    RequestMethod,
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { isValidCompanyUid } from '../../account/payment-method/invoice/InvoiceRequestForm';
import { Localized } from '../../common/components/Localized';
import { CompanyUidValidationError } from '../../account/meta/CompanyEditForm';
import { css } from '@emotion/css';

export interface AccountSetupAddressTexts {
    gender: Translation;
    genderMaleLabel: Translation;
    genderFemaleLabel: Translation;
    firstNameLabel: Translation;
    lastNameLabel: Translation;
    contactPerson: Translation;
    registerPrivateAccountLabel: Translation;
    registerCompanyAccountLabel: Translation;
    registerCompanyAccountWarning: Translation;
    company1Label: Translation;
    company2Label: Translation;
    companyUidLabel: Translation;
    streetLabel: Translation;
    zipCodeLabel: Translation;
    cityLabel: Translation;
    countryLabel: Translation;
    logout: Translation;
    welcome1: Translation;
    welcome2: Translation;
}

const texts = (s: { settings: SettingsState.State }) =>
    addressTexts[s.settings.language];

const writeGender = (store: Flux.Store, gender: AccountSetupState.Gender) =>
    AccountSetupState.Address.stateWrite(store, { gender: gender });

interface AccountSetupAddressState {
    settings: SettingsState.State;
    accountSetupAddress: AccountSetupState.Address.State;
    accountSetupNavigation: AccountSetupState.Navigation.State;
    accountSetupConfiguration: AccountSetupState.Configuration.State;
    addressCountries: AddressCountriesState.State;
    meta: MetaServerState.State;
}

const selectState = (store: Flux.Store): AccountSetupAddressState => ({
    settings: new SettingsState.StateSlice(store).state,
    accountSetupAddress: AccountSetupState.Address.get(store),
    accountSetupNavigation: AccountSetupState.Navigation.get(store),
    accountSetupConfiguration: AccountSetupState.Configuration.get(store),
    addressCountries: new AddressCountriesState.StateSlice(store).state,
    meta: new MetaServerState.StateSlice(store).state,
});

const addressValidators: Validation.Form<AccountSetupState.Address.State> = {
    gender: [Validators.NotEmpty],
    firstName: [Validators.NotEmpty],
    lastName: [Validators.NotEmpty],
    street: [Validators.NotEmpty],
    zipCode: [Validators.NotEmpty, Validators.ZipCode],
    city: [Validators.NotEmpty],
    country: [Validators.NotEmpty],
};

const companyAddressValidators: Validation.Form<AccountSetupState.Address.State> =
    {
        ...addressValidators,
        company1: [Validators.NotEmpty],
    };

const form = (p: AccountSetupAddressState) =>
    p.accountSetupConfiguration.accountType === CustomerAccountType.PRIVATE
        ? addressValidators
        : companyAddressValidators;

const showErrors = (p: AccountSetupAddressState) =>
    p.accountSetupNavigation.showErrorsAddress;

const validateForm = (p: AccountSetupAddressState) =>
    Validation.validate(p.accountSetupAddress, form(p), p.settings.language);

export const GenderSelectAccountSetup = Flux.selectState<
    object,
    AccountSetupAddressState
>(
    store => selectState(store),

    p => (
        <GenderSelect
            gender={p.accountSetupAddress.gender}
            writeGender={(gender: 'm' | 'f') =>
                p.update(store => writeGender(store, gender))
            }
            styleContext={InputContext.regular}
            underlineStatus={
                showErrors(p) && isDefined(validateForm(p).errorTexts.gender)
                    ? UnderlineStatus.error
                    : UnderlineStatus.none
            }
            errorText={getOrElse(
                Validation.errorText(
                    showErrors(p),
                    validateForm(p).errorTexts,
                    t => t.gender,
                ),
                '',
            )}
        />
    ),
);

export const AccountSetupAddress = Flux.selectState<
    object,
    AccountSetupAddressState
>(
    store => selectState(store),
    p => {
        const accountType = p.accountSetupConfiguration.accountType;
        const [companyUidEdited, setCompanyUidEdited] = useState(false);
        const [companyUidIsValidated, setCompanyUidIsValidated] =
            useState(false);
        const [showCompanyUidError, setShowCompanyUidError] = useState(false);

        const [isCompanyUidValidState, fetchIsCompanyUidValid] = useServerWrite<
            { company1: string; companyUid: string },
            { isCompanyUidValid: boolean }
        >(() => ({
            url: `/ui-api/customer-account/account-setup/is-company-uid-valid`,
            method: RequestMethod.POST,
        }));
        const isPending =
            isCompanyUidValidState.status === RequestStatus.PENDING;

        const companyUidInvalid =
            companyUidEdited &&
            p.accountSetupAddress.companyUid &&
            !isValidCompanyUid(p.accountSetupAddress.companyUid);

        useServerSuccessEffect(isCompanyUidValidState, data => {
            data.isCompanyUidValid
                ? setCompanyUidIsValidated(true)
                : setCompanyUidInvalid();
        });

        useServerErrorEffect(isCompanyUidValidState, () => {
            setCompanyUidInvalid();
        });

        useEffect(() => {
            if (companyUidIsValidated) {
                onNextValidation();
            }
        }, [companyUidIsValidated]);

        function setCompanyUidInvalid() {
            setShowCompanyUidError(true);
            setCompanyUidIsValidated(false);
        }

        function onNextValidation() {
            const formIsValid = validateForm(p).isValid;

            if (formIsValid) {
                p.update(store =>
                    logAction(
                        store,
                        'accountSetup-finishAddress',
                        p.accountSetupAddress,
                    ),
                );
                p.update((store: Flux.Store): string => {
                    AccountSetupState.Navigation.stateWrite(store, {
                        currentStep: 'AddLicensePlate',
                    });
                    return 'finishAccountSetupAddress';
                });
            } else {
                setCompanyUidIsValidated(false);
                p.update(store =>
                    logAction(
                        store,
                        'accountSetup-finishInvalidAddress',
                        p.accountSetupAddress,
                    ),
                );
                p.update(store =>
                    AccountSetupState.Navigation.stateWrite(store, {
                        showErrorsAddress: true,
                    }),
                );
            }
        }

        const genderFirstLastName = (
            <div>
                <GenderSelectAccountSetup />
                <TextField
                    context={InputContext.regular}
                    name="first-name"
                    autocomplete="given-name"
                    tabIndex={0}
                    labelText={texts(p).firstNameLabel()}
                    value={getOrElse(p.accountSetupAddress.firstName, '')}
                    onChange={v => {
                        if (v && v.length === 1) {
                            p.update(store =>
                                logAction(
                                    store,
                                    'accountSetup-enterFirstName',
                                    null,
                                ),
                            );
                        }
                        p.update(store =>
                            AccountSetupState.Address.stateWrite(store, {
                                firstName: v,
                            }),
                        );
                    }}
                    errorText={Validation.errorText(
                        showErrors(p),
                        validateForm(p).errorTexts,
                        t => t.firstName,
                    )}
                />
                <TextField
                    context={InputContext.regular}
                    tabIndex={0}
                    name="last-name"
                    autocomplete="family-name"
                    labelText={texts(p).lastNameLabel()}
                    value={getOrElse(p.accountSetupAddress.lastName, '')}
                    onChange={v =>
                        p.update(store =>
                            AccountSetupState.Address.stateWrite(store, {
                                lastName: v,
                            }),
                        )
                    }
                    errorText={Validation.errorText(
                        showErrors(p),
                        validateForm(p).errorTexts,
                        t => t.lastName,
                    )}
                />
            </div>
        );

        return (
            <AccountSetupBlock
                title={navigationTextsFor(p).headerLabel()}
                nextLabel={
                    isPending
                        ? navigationTextsFor(p).pending()
                        : navigationTextsFor(p).next()
                }
                nextDisabled={isPending}
                onPrevious={() =>
                    p.update(store =>
                        AccountSetupState.Navigation.stateWrite(store, {
                            currentStep: 'Type',
                        }),
                    )
                }
                previousLabel={navigationTextsFor(p).previous()}
                onNext={() => {
                    if (
                        p.accountSetupAddress.company1 &&
                        p.accountSetupAddress.companyUid
                    ) {
                        if (companyUidInvalid) {
                            p.update(store =>
                                AccountSetupState.Navigation.stateWrite(store, {
                                    showErrorsAddress: true,
                                }),
                            );
                        } else {
                            fetchIsCompanyUidValid({
                                company1: p.accountSetupAddress.company1,
                                companyUid: p.accountSetupAddress.companyUid,
                            });
                        }
                    } else {
                        onNextValidation();
                    }
                }}
            >
                {accountType === CustomerAccountType.PRIVATE &&
                    genderFirstLastName}
                {accountType === CustomerAccountType.COMPANY && (
                    <div>
                        <TextField
                            context={InputContext.regular}
                            tabIndex={0}
                            labelText={texts(p).company1Label()}
                            name="company"
                            autocomplete="organization"
                            value={getOrElse(
                                p.accountSetupAddress.company1,
                                '',
                            )}
                            onChange={v =>
                                p.update(store =>
                                    AccountSetupState.Address.stateWrite(
                                        store,
                                        {
                                            company1: v,
                                        },
                                    ),
                                )
                            }
                            errorText={Validation.errorText(
                                showErrors(p),
                                validateForm(p).errorTexts,
                                t => t.company1,
                            )}
                        />
                        <TextField
                            context={InputContext.regular}
                            tabIndex={0}
                            labelText={texts(p).company2Label()}
                            value={getOrElse(
                                p.accountSetupAddress.company2,
                                '',
                            )}
                            onChange={v =>
                                p.update(store =>
                                    AccountSetupState.Address.stateWrite(
                                        store,
                                        {
                                            company2: v,
                                        },
                                    ),
                                )
                            }
                            errorText={Validation.errorText(
                                showErrors(p),
                                validateForm(p).errorTexts,
                                t => t.company2,
                            )}
                        />
                        <TextField
                            context={InputContext.regular}
                            tabIndex={0}
                            labelText={texts(p).companyUidLabel()}
                            value={getOrElse(
                                p.accountSetupAddress.companyUid,
                                '',
                            )}
                            onChange={v => {
                                p.update(store =>
                                    AccountSetupState.Address.stateWrite(
                                        store,
                                        {
                                            companyUid: v,
                                        },
                                    ),
                                );
                                setShowCompanyUidError(false);
                            }}
                            onBlur={() => {
                                setCompanyUidEdited(true);
                                p.update(store =>
                                    AccountSetupState.Address.stateWrite(
                                        store,
                                        {
                                            companyUid:
                                                p.accountSetupAddress.companyUid
                                                    ?.toUpperCase()
                                                    .trim(),
                                        },
                                    ),
                                );
                            }}
                            errorText={
                                companyUidInvalid ? (
                                    <Localized
                                        de="Ungültige UID"
                                        fr="IDE pas valable"
                                        it="IDI non valido"
                                        en="Invalid UID"
                                    />
                                ) : (
                                    ''
                                )
                            }
                        />
                        {showCompanyUidError && (
                            <CompanyUidValidationError
                                onConfirm={() => setShowCompanyUidError(false)}
                            />
                        )}
                    </div>
                )}
                <TextField
                    context={InputContext.regular}
                    tabIndex={0}
                    labelText={texts(p).streetLabel()}
                    name="address"
                    autocomplete="address-line1"
                    value={getOrElse(p.accountSetupAddress.street, '')}
                    onChange={v => {
                        if (v && v.length === 1) {
                            p.update(store =>
                                logAction(
                                    store,
                                    'accountSetup-enterAddress',
                                    null,
                                ),
                            );
                        }
                        p.update(store =>
                            AccountSetupState.Address.stateWrite(store, {
                                street: v,
                            }),
                        );
                    }}
                    errorText={Validation.errorText(
                        showErrors(p),
                        validateForm(p).errorTexts,
                        t => t.street,
                    )}
                />
                <div className={css({ display: 'flex' })}>
                    <div className={css({ flex: 2, marginRight: '10px' })}>
                        <TextField
                            context={InputContext.regular}
                            tabIndex={0}
                            labelText={texts(p).zipCodeLabel()}
                            name="postal-code"
                            autocomplete="postal-code"
                            value={thenElse(
                                p.accountSetupAddress.zipCode,
                                z => z.toString(),
                                '',
                            )}
                            onChange={v =>
                                p.update(store =>
                                    AccountSetupState.Address.stateWrite(
                                        store,
                                        {
                                            zipCode:
                                                StringConversions.stripNonNumericCharacters(
                                                    v,
                                                ),
                                        },
                                    ),
                                )
                            }
                            errorText={Validation.errorText(
                                showErrors(p),
                                validateForm(p).errorTexts,
                                t => t.zipCode,
                            )}
                        />
                    </div>
                    <div className={css({ flex: 8 })}>
                        <TextField
                            context={InputContext.regular}
                            tabIndex={0}
                            labelText={texts(p).cityLabel()}
                            name="city"
                            autocomplete="address-level2"
                            value={getOrElse(p.accountSetupAddress.city, '')}
                            onChange={v =>
                                p.update(store =>
                                    AccountSetupState.Address.stateWrite(
                                        store,
                                        {
                                            city: v,
                                        },
                                    ),
                                )
                            }
                            errorText={Validation.errorText(
                                showErrors(p),
                                validateForm(p).errorTexts,
                                t => t.city,
                            )}
                        />
                    </div>
                </div>
                <SingleSelection
                    context={InputContext.regular}
                    onClick={() =>
                        p.update(store =>
                            AccountSetupState.Layout.stateWrite(store, {
                                addressCountrySlideinOpen: true,
                            }),
                        )
                    }
                    selection={AddressCountrySlidein.currentSelection(
                        p,
                        p.accountSetupAddress.country,
                    )}
                    labelText={texts(p).countryLabel()}
                />
                {accountType === CustomerAccountType.COMPANY && (
                    <div>
                        <Para>{texts(p).contactPerson()}</Para>
                        {genderFirstLastName}
                    </div>
                )}
            </AccountSetupBlock>
        );
    },
);
