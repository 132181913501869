import * as ReactDOM from 'react-dom';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import React, { ReactNode } from 'react';

interface ModalProps {
    portal: SlideInPortalId;
    children: ReactNode;
}

/*
this modal renders checks itself when the target appears in the dom
and mount's itself. this is necessary if the modal must render on the
first render of the dom tree (for example spinner)
*/
export class Modal extends React.Component<
    ModalProps,
    { rootPresent: boolean }
> {
    _isMounted: boolean | undefined;

    constructor(props: ModalProps) {
        super(props);
        this.state = { rootPresent: false };
        const checkForElement = () => {
            if (document.getElementById(this.props.portal) == null) {
                setTimeout(checkForElement, 8);
            } else {
                if (!this._isMounted) {
                    this.state = { rootPresent: true };
                } else {
                    this.setState({ rootPresent: true });
                }
            }
        };
        checkForElement();
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (document.getElementById(this.props.portal)) {
            return ReactDOM.createPortal(
                this.props.children,
                document.getElementById(this.props.portal) as HTMLElement,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any;
        } else {
            return null;
        }
    }
}
