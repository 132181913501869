import { Ellipsis } from 'dg-web-shared/lib/Punctuation';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import * as Text from '../../common/i18n/Text';
import { Translation } from '../../common/i18n/Text';
import * as CityDropin from '../components/park-option/CityDropin';
import * as ZoneDropin from '../components/park-option/ZoneDropin';
import * as PermitTypeDropin from '../components/permit/PermitTypeDropin';

export const cityDropinTexts: Text.Translations<CityDropin.Texts> = {
    de: {
        Location: () => GeneralTexts.texts.de.ZipcodeCity(),
        LocationSearch: () => 'Suche',
        Zipcode: () => GeneralTexts.texts.de.Zipcode(),
        City: () => GeneralTexts.texts.de.City(),
        AcquiringPosition: () =>
            'Ihr aktueller Standort wird ermittelt' + Ellipsis,
        CancelAcquisition: () => 'Abbrechen',
        GeolocationErrorTitle: () => 'Standortbestimmung',
        GeolocationErrorText: () =>
            'Der aktuelle Standort konnte nicht ermittelt werden. Bitte überprüfen Sie die Ortungsdienste Ihres Gerätes.',
    },

    fr: {
        Location: () => GeneralTexts.texts.fr.ZipcodeCity(),
        LocationSearch: () => 'Recherche',
        Zipcode: () => GeneralTexts.texts.fr.Zipcode(),
        City: () => GeneralTexts.texts.fr.City(),
        AcquiringPosition: () =>
            'Votre position actuelle sera déterminée' + Ellipsis,
        CancelAcquisition: () => 'Annuler',
        GeolocationErrorTitle: () => 'Géolocalisation',
        GeolocationErrorText: () =>
            "Le lieu actuel n'a pas pu être déterminé. Veuillez contrôler le service de localisation de votre dispositif.",
    },

    it: {
        Location: () => GeneralTexts.texts.it.ZipcodeCity(),
        LocationSearch: () => 'Ricerca',
        Zipcode: () => GeneralTexts.texts.it.Zipcode(),
        City: () => GeneralTexts.texts.it.City(),
        AcquiringPosition: () =>
            'Viene determinata la vostra posizione attuale' + Ellipsis,
        CancelAcquisition: () => 'Annulla',
        GeolocationErrorTitle: () => 'Geolocalizzazione',
        GeolocationErrorText: () =>
            'Non è stato possibile determinare la posizione attuale. Vogliate controllare i servizi di localizzazione del vostro apparecchio.',
    },

    en: {
        Location: () => GeneralTexts.texts.en.ZipcodeCity(),
        LocationSearch: () => 'Search',
        Zipcode: () => GeneralTexts.texts.en.Zipcode(),
        City: () => GeneralTexts.texts.en.City(),
        AcquiringPosition: () =>
            'Your current location will be determined' + Ellipsis,
        CancelAcquisition: () => 'Cancel',
        GeolocationErrorTitle: () => 'Geolocation',
        GeolocationErrorText: () =>
            'The current location could not be determined. Please check the location services of your device.',
    },
};

export const zoneDropinTexts: Text.Translations<ZoneDropin.Texts> = {
    de: {
        Title: () => `Zonen / Parkings`,
        TitleSearch: () => 'Suche',
        ZoneIdLabel: () => 'Nr.',
        ZoneNameLabel: () => 'Bezeichnung',
    },

    fr: {
        Title: () => `Zones / Parkings`,
        TitleSearch: () => 'Recherche',
        ZoneIdLabel: () => 'No',
        ZoneNameLabel: () => 'Dénomination',
    },

    it: {
        Title: () => `Zone / Parcheggi`,
        TitleSearch: () => 'Ricerca',
        ZoneIdLabel: () => 'Nr.',
        ZoneNameLabel: () => 'Denominazione',
    },

    en: {
        Title: () => `Zones / Parkings`,
        TitleSearch: () => 'Search',
        ZoneIdLabel: () => 'Nr.',
        ZoneNameLabel: () => 'Name',
    },
};

export const permitTypeDropinTexts: Text.Translations<PermitTypeDropin.Texts> =
    {
        de: {
            Title: () => GeneralTexts.texts.de.PermitType(),
            Name: () => 'Bezeichnung',
        },

        fr: {
            Title: () => GeneralTexts.texts.fr.PermitType(),
            Name: () => 'Dénomination',
        },

        it: {
            Title: () => GeneralTexts.texts.it.PermitType(),
            Name: () => 'Denominazione',
        },

        en: {
            Title: () => GeneralTexts.texts.en.PermitType(),
            Name: () => 'Name',
        },
    };

export const licensePlateDropinTexts: Text.Translations<{
    dropinLabel: Translation;
    numSelectableLicensePlates: (maxlp: string) => string;
    numSelectableBadges: (maxlp: string) => string;
}> = {
    de: {
        dropinLabel: () => GeneralTexts.texts.de.Vehicle(),
        numSelectableLicensePlates: (maxlp: string) =>
            `Max. ${maxlp} Kennzeichen`,
        numSelectableBadges: (maxBadges: string) => `Max. ${maxBadges} Badges`,
    },
    fr: {
        dropinLabel: () => GeneralTexts.texts.fr.Vehicle(),
        numSelectableLicensePlates: (maxlp: string) =>
            `Au maximum  ${maxlp} immatriculations`,
        numSelectableBadges: (maxBadges: string) =>
            `Au maximum ${maxBadges} badge`,
    },
    it: {
        dropinLabel: () => GeneralTexts.texts.it.Vehicle(),
        numSelectableLicensePlates: (maxlp: string) =>
            `Al massimo ${maxlp} targhe`,
        numSelectableBadges: (maxBadges: string) =>
            `Al massimo ${maxBadges} badge`,
    },
    en: {
        dropinLabel: () => GeneralTexts.texts.en.Vehicle(),
        numSelectableLicensePlates: (maxlp: string) =>
            `Max ${maxlp} license plates`,
        numSelectableBadges: (maxBadges: string) => `Max ${maxBadges} badges`,
    },
};
