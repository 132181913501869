import * as superagent from 'superagent';
import { Message } from 'dg-web-shared/lib/Localized';
import { Localized } from '../../common/components/Localized';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import {
    portalSlideIn,
    PortalSlideInProps,
} from '../root/components/PortalSlidein';
import * as LoginsState from './state/LoginsState';
import { FieldItemBlock } from '../root/components/ItemBlock';
import { ActionBlock } from '../root/components/ActionBlock';
import { ModalDeleteQuestionBox } from '../../ui/modals/Confirmable';
import {
    RequestStatus,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { logAction } from '../../utils/ActionLog';
import { useStore } from 'dg-web-shared/lib/Flux';
import { Vehicles } from '../vehicles/VehicleState';
import { useState } from 'react';

export const LoginEditMenuSlideIn = portalSlideIn(LoginEditMenu);

function LoginEditMenu({
    login,
    selectionCallbacks,
    onClose,
    currentLoginId,
}: {
    login: LoginsState.GetLoginData | null;
    currentLoginId: number;
    selectionCallbacks: SelectionCallbacks;
} & PortalSlideInProps) {
    const { store, update } = useStore(() => null);
    const [sendDeleteLoginState, sendDeleteLoginRequest] = useServerWrite({
        req: () => {
            logAction(store, 'delete-login', login);

            return superagent.del(
                `/ui-api/customer-account/logins-v2/${login?.id}`,
            );
        },

        onResponse(response) {
            if (response.status === RequestStatus.SUCCESS) {
                update(s => {
                    new LoginsState.StateSlice(s).reset();
                    Vehicles.forceRefetch(s);
                    setDeleteLogin(false);
                    onClose();
                    return 'login-deleted';
                });
            }
        },
    });
    const [deleteLogin, setDeleteLogin] = useState(false);
    const name = login?.firstName + ' ' + login?.lastName;
    return (
        <>
            {menuItemData(login, selectionCallbacks).map(it => (
                <>
                    <FieldItemBlock
                        label={<Localized {...it.labelMessage} />}
                        content={it.currentValue}
                        onClick={it.callback}
                    />
                </>
            ))}
            {login?.id !== currentLoginId && (
                <>
                    <ActionBlock
                        title={<DeleteLoginTitle />}
                        icon="delete"
                        onClick={() => setDeleteLogin(true)}
                    />
                    {deleteLogin && (
                        <ModalDeleteQuestionBox
                            titleCaption={<DeleteLoginTitle />}
                            confirmCallback={sendDeleteLoginRequest}
                            cancelCallback={() => setDeleteLogin(false)}
                            isPending={
                                sendDeleteLoginState.status ===
                                RequestStatus.PENDING
                            }
                        >
                            <p>
                                <Localized
                                    de={`Wollen Sie wirklich den Benutzer «${name}» löschen?`}
                                    fr={`Voulez-vous vraiment effacer l'utilisateur «${name}»?`}
                                    it={`Vuole veramente eliminare l'utente «${name}»?`}
                                    en={`Do you really want to delete the user «${name}»?`}
                                />
                            </p>
                        </ModalDeleteQuestionBox>
                    )}
                </>
            )}
        </>
    );
}

const DeleteLoginTitle = () => (
    <Localized
        de="Benutzer löschen"
        fr="Supprimer l'utilisateur"
        it="Eliminare utente"
        en="Delete user"
    />
);

function menuItemData(
    login: LoginsState.GetLoginData | null,
    selectionCallbacks: SelectionCallbacks,
): {
    labelMessage: Message;
    currentValue: React.ReactNode;
    callback: () => void;
}[] {
    if (!login) {
        return [];
    }

    return [
        {
            labelMessage: {
                de: 'Anrede',
                fr: 'Titre',
                it: 'Titolo',
                en: 'Title',
            },
            currentValue: login.gender ? (
                login.gender === 'm' ? (
                    <Localized de="Herr" fr="Monsieur" it="Signor" en="Mr" />
                ) : (
                    <Localized de="Frau" fr="Madame" it="Signora" en="Ms" />
                )
            ) : (
                '—'
            ),
            callback: selectionCallbacks.editGender.bind(null, login.id),
        },
        {
            labelMessage: {
                de: 'Vorname / Nachname',
                fr: 'Prénom / Nom',
                it: 'Nome / Cognome',
                en: 'First name / Last name',
            },
            currentValue:
                login.firstName && login.lastName
                    ? [login.firstName, login.lastName].join(' ')
                    : '—',
            callback: selectionCallbacks.editName.bind(null, login.id),
        },
        {
            labelMessage: {
                de: 'E-Mail',
                fr: 'E-mail',
                it: 'E-mail',
                en: 'E-mail',
            },
            currentValue: login.requestedEmail ? (
                <Localized
                    de={`${login.requestedEmail} - nicht bestätigt`}
                    fr={`${login.requestedEmail} - pas confirmé`}
                    it={`${login.requestedEmail} - non confermato`}
                    en={`${login.requestedEmail} - not confirmed`}
                />
            ) : login.confirmed ? (
                login.email
            ) : (
                <Localized
                    de={`${login.email} - nicht bestätigt`}
                    fr={`${login.email} - pas confirmé`}
                    it={`${login.email} - non confermato`}
                    en={`${login.email} - not confirmed`}
                />
            ),
            callback: login.confirmed
                ? login.requestedEmail
                    ? selectionCallbacks.reviewRequestedEmail.bind(
                          null,
                          login.id,
                      )
                    : selectionCallbacks.editEmail.bind(null, login.id)
                : selectionCallbacks.reviewUnconfirmedEmail.bind(
                      null,
                      login.id,
                  ),
        },
        {
            labelMessage: {
                de: 'Rolle',
                fr: 'Rôle',
                it: 'Ruolo',
                en: 'Role',
            },
            currentValue:
                login.roleId === CurrentLoginState.LoginRole.ADMIN ? (
                    <Localized
                        de="Administrator"
                        fr="Administrateur"
                        it="Amministratore"
                        en="Administrator"
                    />
                ) : (
                    <Localized
                        de="Kein Administrator"
                        fr="Pas d‘administrateur"
                        it="Non amministratore"
                        en="Not administrator"
                    />
                ),
            callback: selectionCallbacks.editRole.bind(null, login.id),
        },
        {
            labelMessage: {
                de: 'Passwort',
                fr: 'Mot de passe',
                it: 'Password',
                en: 'Password',
            },
            currentValue: '••••••••',
            callback: selectionCallbacks.editPassword.bind(null, login.id),
        },
        {
            labelMessage: {
                de: 'Status',
                fr: 'Statut',
                it: 'Stato',
                en: 'Status',
            },
            currentValue: login.deactivated ? (
                <Localized
                    de="Inaktiv"
                    fr="Pas actif"
                    it="Non attivo"
                    en="Deactivated"
                />
            ) : (
                <Localized de="Aktiv" fr="Actif" it="Attivo" en="Active" />
            ),
            callback: selectionCallbacks.editStatus.bind(null, login.id),
        },
    ];
}

type SelectionCallbacks = {
    editGender: (loginId: number) => void;
    editName: (loginId: number) => void;
    reviewUnconfirmedEmail: (loginId: number) => void;
    editEmail: (loginId: number) => void;
    reviewRequestedEmail: (loginId: number) => void;
    editRole: (loginId: number) => void;
    editPassword: (loginId: number) => void;
    editStatus: (loginId: number) => void;
};
