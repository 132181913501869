import * as Flux from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../../../common/state/SettingsState';
import * as LayoutState from '../../../layout/state/LayoutState';
import { texts as helpdeskTexts } from '../i18n/HelpdeskTexts';
import { ResponsiveProperties } from '../../../layout/utils/ResponsiveProperties';
import { columnsVariant } from '../../../layout/utils/columnsVariant';
import { css } from '@emotion/css';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export interface HelpdeskTexts {
    title: () => string;
    content: () => JSX.Element;
}

export interface HelpdeskState {
    settings: SettingsState.State;
    layout: LayoutState.State;
}

export class Helpdesk extends Flux.Container<HelpdeskState> {
    static displayName = 'Helpdesk';

    stateSelector(): HelpdeskState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
            settings: new SettingsState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        const texts = helpdeskTexts[this.state.settings.language];
        const responsiveProps = new ResponsiveProperties(this.state);
        return (
            <div
                className={css([
                    {
                        background: Colors.white,
                        padding: '24px',
                        marginTop: '32px',
                        ...paper(4),
                    },
                    columnsVariant(this.state) === 'OneColumn' &&
                        responsiveProps.toString() === 'Mobile' && {
                            borderRadius: 0,
                            ...paper(0),
                        },
                ])}
            >
                <div className={css({ color: Colors.blue, ...Typo.heading2 })}>
                    {texts.title()}
                </div>
                <div
                    className={css({
                        marginTop: '10px',
                        color: Colors.typo1,
                        ...Typo.body,
                    })}
                >
                    {texts.content()}
                </div>
            </div>
        );
    }
}
