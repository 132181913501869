import { IconProps } from 'dg-web-shared/ui/icons/Icon';
import { IconButton } from 'dg-web-shared/tb-ui/buttons/IconButton.tsx';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars';
import { defaultTransition } from 'dg-web-shared/tb-ui/transitions.ts';

interface Props extends IconProps {
    onClick: () => void;
    title: React.ReactNode;
}

export const ActionBlock = (p: Props) => (
    <Clickable
        element="div"
        className={css({
            ...Typo.action,
            color: Colors.blue,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '72px',
            padding: '0 24px 0 24px',
            overflow: 'hidden',
            cursor: 'pointer',
            borderBottom: `1px solid ${Colors.lightgrey}`,
            '&:hover': {
                backgroundColor: Colors.contentHover,
            },
            ...defaultTransition('background', 500),
            textTransform: 'uppercase',
        })}
        onClick={p.onClick}
    >
        <div>{p.title}</div>
        <div className={css({ paddingLeft: '12px' })}>
            <IconButton
                icon={p.icon}
                onClick={(): void => {
                    return;
                }}
            />
        </div>
    </Clickable>
);
