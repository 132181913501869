import * as superagent from 'superagent';
import * as Flux from 'dg-web-shared/lib/Flux';
import {
    getServerVersion,
    parseVersion,
} from 'dg-web-shared/common/utils/UpdateUtils';

// interval to check for updates in s
const updateCheckInterval = 300 * 1000;

// stores unix ts of last update check
let lastUpdateCheck: number;

export function fireUpdateRequest(store: Flux.Store): void {
    const req = getServerVersion();
    req.end(function (
        err: unknown,
        superagentRes: superagent.Response<unknown>,
    ): void {
        // since we do not use the updater method, we must trigger a change emit
        store.emitChange();

        // if request can be completed, check version and update if necessary
        if (!err && superagentRes.ok && superagentRes.text) {
            const serverVersion = parseVersion(superagentRes.text);
            if (!SENTRY_RELEASE?.id || !serverVersion) {
                return;
            }

            if (
                document &&
                document.location &&
                serverVersion !== SENTRY_RELEASE.id
            ) {
                document.location.href = '/?pcsversion=' + serverVersion;
            }
        }
    });
}

export function init(store: Flux.Store): void {
    lastUpdateCheck = Date.now();

    fireUpdateRequest(store);

    (function updateCheck(): void {
        requestAnimationFrame(updateCheck);
        if (Date.now() > lastUpdateCheck + updateCheckInterval) {
            lastUpdateCheck = Date.now();

            fireUpdateRequest(store);
        }
    })();
}
