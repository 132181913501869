import { css } from '@emotion/css';

import { useStore } from 'dg-web-shared/lib/Flux';
import { JsxMessage } from 'dg-web-shared/lib/Localized';
import { Localized } from '../../common/components/Localized';
import { Colors } from 'dg-web-shared/ui/vars';
import * as MetaServerState from '../meta/state/MetaServerState';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import { InlineAlertBox } from '../../ui/modals/Confirmable';
import { ErrorBlock } from '../root/components/ErrorBlock';
import { GenericErrorBody } from '../../ui/modals/HandleHttpStati';
import { MaterialButton } from '../../ui/inputs/MaterialButton';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const BankTransferTopUpSlideIn = portalSlideIn(BankTransferTopUp);

export interface LimitData {
    limitType: LimitType;
    amountToLimit: number;
    key: string;
}

export enum LimitType {
    HARD = 'HARD',
    SOFT = 'SOFT',
}

function BankTransferTopUp() {
    const meta = useStore(s => ({
        metaServer: new MetaServerState.StateSlice(s).state,
    })).storeState.metaServer;
    const [limit] = useServerFetch<LimitData, object>(
        () => ({
            url: `/ui-api/customer-account/deposits/limits`,
        }),
        {},
    );

    const isCompany =
        meta.data.customerAccountType === CustomerAccountType.COMPANY;

    switch (limit.status) {
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.PENDING:
            return <Spinner />;
        case RequestStatus.ERROR:
            return <ErrorBlock text={<GenericErrorBody />} />;
        case RequestStatus.SUCCESS:
            if (limit.data?.limitType === LimitType.HARD) {
                return (
                    <div>
                        <Notice />
                        <QrBillDownloadButton downloadKey={limit.data.key} />
                    </div>
                );
            } else {
                return (
                    <IdentificationRequiredBankTopup isCompany={isCompany} />
                );
            }
    }
}

function Notice() {
    return (
        <div
            className={css({
                padding: '10px 20px',
                backgroundColor: Colors.error_bg,
                color: Colors.error,
                fontSize: '14px',
                borderBottom: `solid 1px ${Colors.rgba(Colors.error, 0.5)}`,
                ...Typo.robotoRegular,
            })}
        >
            <Localized {...messages.notice} />
        </div>
    );
}

function IdentificationRequiredBankTopup({
    isCompany,
}: {
    isCompany: boolean;
}) {
    return (
        <div className={css({ padding: '10px 24px 0 24px' })}>
            <InlineAlertBox
                titleCaption={
                    <Localized
                        de="Identifizierung benötigt"
                        fr="Pièce d'identité requise"
                        it="Identificazione richiesta"
                        en="Identification required"
                    />
                }
            >
                <p>
                    {isCompany ? (
                        <Localized
                            de="Um weiter aufzuladen, müssen Sie die UID der Firma registrieren (Konto → Konto/Adresse → Inhaber)."
                            fr="Pour continuer à recharger, vous devez enregistrer le IDE de votre entreprise (compte → compte/adresse → titulaire)."
                            it="Per poter continuare a ricaricare deve registrare l’IDI della sua impresa (conto → conto/indirizzo → titolare)."
                            en="In order to continue topping up, you must register the UID of your company (account → account/address → owner)."
                        />
                    ) : (
                        <Localized
                            de="Um weiter aufzuladen, müssen Sie Ihre Mobiltelefonnummer registrieren (Konto → Benutzerprofil → Mobiltelefon)."
                            fr="Pour continuer à recharger, vous devez enregistrer votre numéro de téléphone mobile (compte → profil utilisateur → portable)."
                            it="Per poter continuare a ricaricare deve registrare il suo numero di cellulare (conto → profilo utente → cellulare)."
                            en="In order to continue topping up, you must register your mobile phone number (account → user profile → mobile)."
                        />
                    )}
                </p>
                <p>
                    <Localized
                        de="Alternativ können Sie auch eine andere Zahlungsweise wählen."
                        fr="Vous pouvez également choisir un autre mode de paiement."
                        it="In alternativa può scegliere un’altra modalità di pagamento."
                        en="Alternatively, you may choose another payment method."
                    />
                </p>
                <AdditionalInfo isCompany={isCompany} />
            </InlineAlertBox>
        </div>
    );
}

export function AdditionalInfo({ isCompany }: { isCompany: boolean }) {
    const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);
    const AdditionalLimitInfoSlideIn = portalSlideIn(AdditionalLimitInfo);

    function AdditionalLimitInfo() {
        return (
            <div
                className={css({
                    padding: '20px 20px',
                    ...Typo.robotoLight,
                })}
            >
                {isCompany ? (
                    <>
                        <p>
                            <Localized
                                de="Aufgrund regulatorischer Vorschriften (GwV-FINMA, 955.033.0, Art. 11) der eidgenössischen Finanzmarktaufsicht (FINMA) im Zusammenhang mit der Bekämpfung von Geldwäscherei gelten bei Parkingpay für Firmenkunden mit einem kumulierten Vorauszahlungsbetrag von mehr als CHF 100 innerhalb der letzten 12 Monate zusätzliche Anforderungen, welche mit der Registrierung der Unternehmens-Identifikationsnummer (UID) sichergestellt werden."
                                fr="En raison des dispositions réglementaires (OBA-FINMA, 955.033.0, art. 11) de l'Autorité fédérale de surveillance des marchés financiers (FINMA) relatives à la lutte contre le blanchiment d'argent, des exigences supplémentaires s'appliquent aux clients commerciaux de Parkingpay dont le montant de recharge cumulé est supérieur à 100 CHF au cours des 12 derniers mois. Ces exigences sont assurées par l'enregistrement du numéro d’identification des entreprises (IDE)."
                                it="A seguito disposizioni normative (ORD-FINMA, 955.033.0, Art. 11) dell'Autorità federale di vigilanza sui mercati finanziari (FINMA) in relazione alla lotta contro il riciclaggio di denaro, per gli clienti aziendali Parkingpay con un importo cumulativo delle ricariche superiore a CHF 100 negli ultimi 12 mesi si applicano dei requisiti aggiuntivi, che vengono garantiti con la registrazione del numero d’identificazione delle imprese (IDI)."
                                en="As a result of regulatory provisions (GwV-FINMA, 955.033.0, Art. 11) of the Swiss Financial Market Supervisory Authority (FINMA) in connection with the fight against money laundering, additional requirements apply for Parkingpay corporate customers with a cumulative amount of top-ups exceeding CHF 100 in the last 12 months, which are ensured by registration of the business identification number (UID)."
                            />
                        </p>
                        <p>
                            <Localized
                                de="Zudem weisen wir darauf hin, dass ab dem 1. Juli 2022 maximal noch CHF 4‘000 innerhalb der letzten 12 Monate im Rahmen der Zahlungsweise Vorauszahlung erlaubt sind."
                                fr="En outre, à partir du 1er juillet 2022, pour le mode prépayé, le montant total des recharges effectuées au cours des 12 derniers mois ne pourra pas dépasser CHF 4'000."
                                it="Inoltre, a partire dal 1° luglio 2022, per la modalità prepagata, il totale delle ricariche effettuate nei 12 mesi precedenti non potrà superare l’importo di CHF 4’000."
                                en="In addition, as of 1 July 2022, for the prepaid mode, the total amount of top-ups made in the previous 12 months may not exceed CHF 4'000."
                            />
                        </p>
                        <p>
                            <Localized
                                de="Durch die Wahl einer anderen Zahlungsweise können Sie die Registrierung der UID und die erwähnten Betragslimiten vermeiden."
                                fr="En choisissant un autre mode de paiement, vous pouvez éviter l'enregistrement de UID et la limite de montant indiquée ci-dessus."
                                it="Scegliendo un metodo di pagamento alternativo, può evitare la registrazione del UID e il limite d’importo indicato qui sopra."
                                en="By choosing an alternative payment method, you can avoid the registration of the UID and the amount limit indicated above."
                            />
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            <Localized
                                de="Aufgrund regulatorischer Vorschriften (GwV-FINMA, 955.033.0, Art. 11) der eidgenössischen Finanzmarktaufsicht (FINMA) im Zusammenhang mit der Bekämpfung von Geldwäscherei gelten bei Parkingpay für Privatkunde mit einem kumulierten Vorauszahlungsbetrag von mehr als CHF 100 innerhalb der letzten 12 Monate zusätzliche Anforderungen, welche mit der Registrierung der Mobiltelefonnummer und der anschliessenden Verifikation mittels einem smsTAN-Verfahren sichergestellt werden."
                                fr="En raison des dispositions réglementaires (OBA-FINMA, 955.033.0, art. 11) de l'Autorité fédérale de surveillance des marchés financiers (FINMA) relatives à la lutte contre le blanchiment d'argent, des exigences supplémentaires s'appliquent aux clients privés de Parkingpay dont le montant de recharge cumulé est supérieur à 100 CHF au cours des 12 derniers mois. Ces exigences sont assurées par l'enregistrement du numéro de portable et la vérification ultérieure au moyen d'une procédure smsTAN."
                                it="A seguito disposizioni normative (ORD-FINMA, 955.033.0, Art. 11) dell'Autorità federale di vigilanza sui mercati finanziari (FINMA) in relazione alla lotta contro il riciclaggio di denaro, per gli utenti privati Parkingpay con un importo cumulativo delle ricariche superiore a CHF 100 negli ultimi 12 mesi si applicano dei requisiti aggiuntivi, che vengono garantiti con la registrazione del numero di cellulare e la successiva verifica mediante una procedura smsTAN."
                                en="As a result of regulatory provisions (GwV-FINMA, 955.033.0, Art. 11) of the Swiss Financial Market Supervisory Authority (FINMA) in connection with the fight against money laundering, additional requirements apply for Parkingpay private users with a cumulative amount of top-ups exceeding CHF 100 in the last 12 months, which are ensured by registration of the mobile phone number and subsequent verification by means of an smsTAN procedure."
                            />
                        </p>
                        <p>
                            <Localized
                                de="Zudem weisen wir darauf hin, dass ab dem 1. Juli 2022 maximal noch CHF 2‘000 innerhalb der letzten 12 Monate im Rahmen der Zahlungsweise Vorauszahlung erlaubt sind."
                                fr="En outre, à partir du 1er juillet 2022, pour le mode prépayé, le montant total des recharges effectuées au cours des 12 derniers mois ne pourra pas dépasser CHF 2'000."
                                it="Inoltre, a partire dal 1° luglio 2022, per la modalità prepagata, il totale delle ricariche effettuate nei 12 mesi precedenti non potrà superare l’importo di CHF 2’000."
                                en="In addition, as of 1 July 2022, for the prepaid mode, the total amount of top-ups made in the previous 12 months may not exceed CHF 2'000."
                            />
                        </p>
                        <p>
                            <Localized
                                de="Durch die Wahl einer anderen Zahlungsweise können Sie die Registrierung der Mobiltelefonnummer und die erwähnten Betragslimiten vermeiden."
                                fr="En choisissant un autre mode de paiement, vous pouvez éviter l'enregistrement de votre numéro de portable et la limite de montant indiquée ci-dessus."
                                it="Scegliendo un metodo di pagamento alternativo, può evitare la registrazione del numero di cellulare e il limite d’importo indicato qui sopra."
                                en="By choosing an alternative payment method, you can avoid the registration of your mobile phone number and the amount limit indicated above."
                            />
                        </p>
                    </>
                )}
            </div>
        );
    }

    return (
        <>
            <a
                className={css({
                    display: 'flex',
                    cursor: 'pointer',
                    color: Colors.blue,
                    textDecoration: 'underline',
                })}
                onClick={() => setOpenAdditionalInfo(true)}
            >
                <p>
                    <Localized
                        de="Weitere Informationen"
                        fr="Informations complémentaires"
                        it="Informazioni aggiuntive"
                        en="Additional information"
                    />
                </p>
            </a>
            <AdditionalLimitInfoSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                open={openAdditionalInfo}
                title={
                    <Localized
                        {...{
                            de: 'Weitere Informationen',
                            fr: 'Informations complémentaires',
                            it: 'Informazioni aggiuntive',
                            en: 'Additional information',
                        }}
                    />
                }
                onClose={() => setOpenAdditionalInfo(false)}
            />
        </>
    );
}

function QrBillDownloadButton({ downloadKey }: { downloadKey: string }) {
    return (
        <div
            className={css({
                padding: '20px 20px',
            })}
        >
            <a
                href={`/ui-api/customer-account/deposits/qr-deposit-pdf?key=${encodeURIComponent(
                    downloadKey,
                )}`}
                target="_blank"
                rel="noreferrer"
            >
                <MaterialButton
                    primary
                    label={
                        <Localized
                            de="QR-Einzahlungsschein herunterladen"
                            fr="Télécharger le bulletin QR"
                            it="Scarica la polizza QR"
                            en="Download QR slip"
                        />
                    }
                    onClick={() => {}}
                />
            </a>
        </div>
    );
}

const messages: Messages = {
    notice: {
        de: (
            <>
                <p>
                    Die Gutschrift auf Ihrem Parkingpay-Konto erfolgt erst nach
                    Eingang Ihrer Zahlung (Sie erhalten eine Benachrichtigung
                    per E-Mail).
                </p>

                <p>
                    Nach der Belastung auf Ihrem Bank-/Postkonto oder nach der
                    Einzahlung am Postschalter kann es 3 bis 5 Arbeitstage
                    dauern, bis die Zahlung im Parkingpay-Konto gutgeschrieben
                    ist.
                </p>

                <p>
                    Diese Zahlung alleine gibt kein Anrecht um zu Parkieren. Um
                    nicht gebüsst zu werden, müssen Sie entweder eine
                    Bewilligung kaufen oder einen Parkvorgang starten.
                </p>
            </>
        ),
        fr: (
            <>
                <p>
                    Le crédit sur votre compte Parkingpay ne sera disponible
                    qu’à la réception de votre paiement (vous recevrez une
                    notification par e-mail).
                </p>

                <p>
                    Après avoir débité votre compte bancaire/postal ou après
                    avoir effectué un dépôt au guichet de la Poste, cela peut
                    prendre entre 3 et 5 jours ouvrables pour que le paiement
                    soit crédité sur le compte Parkingpay.
                </p>

                <p>
                    Ce paiement seul ne vous donne aucun droit à stationner.
                    Pour éviter une amende vous devez acheter une autorisation
                    ou démarrer une procédure de stationnement.
                </p>
            </>
        ),
        it: (
            <>
                <p>
                    L’accredito sul vostro conto Parkingpay avverrà solo dopo
                    l’entrata del pagamento (riceverà una notifica via e-mail).
                </p>

                <p>
                    Dopo l&#39;addebito sul conto bancario/postale o dopo il
                    versamento allo sportello postale, potrebbero essere
                    necessari da 3 a 5 giorni lavorativi, perché il pagamento
                    venga accreditato sul conto Parkingpay.
                </p>
                <p>
                    Il pagamento da solo non da alcun diritto al parcheggio. Per
                    evitare una contravvenzione è necessario acquistare
                    un’autorizzazione oppure avviare una procedura di
                    parcheggio.
                </p>
            </>
        ),

        en: (
            <>
                <p>
                    Your Parkingpay account will not be credited until your
                    payment has been received (you will be notified by e-mail
                    then).
                </p>

                <p>
                    It may take 3 to 5 working days for the payment to be
                    credited to your Parkingpay account after it has been
                    debited from your bank/post account, or after it has been
                    deposited at the post office counter.
                </p>

                <p>
                    This payment alone gives no right to parking. In order not
                    to be fined, you must either buy a permit, or start a
                    parking transaction.
                </p>
            </>
        ),
    },
};

interface Messages {
    notice: JsxMessage;
}
