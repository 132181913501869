import { portalSlideIn, PortalSlideInProps } from './PortalSlidein';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { ItemBlockGroup } from './ItemBlock';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { defaultTransition } from 'dg-web-shared/tb-ui/transitions.ts';
import { hover } from 'dg-web-shared/tb-ui/hover.ts';

export interface DropdownSlideInOption<T> {
    id: T;
    display: React.ReactNode;
}

export interface DropdownSlideInProps<T> {
    selected: T | null;
    options: DropdownSlideInOption<T>[];
    optionsHeader?: React.ReactNode;
    topOptions?: DropdownSlideInOption<T>[];
    topOptionsHeader?: React.ReactNode;
    onSelected: (id: T) => void;
    additionalContent?: React.ReactNode;
}

export function DropdownSlideIn<T>(
    props: DropdownSlideInProps<T> & PortalSlideInProps,
) {
    const SlideInComponent = portalSlideIn<DropdownSlideInProps<T>>(
        DropdownSlideInContent,
    );
    return <SlideInComponent {...props} />;
}

export function DropdownSlideInContent<T>(p: DropdownSlideInProps<T>) {
    return (
        <>
            {!!p.topOptions && (
                <ItemBlockGroup
                    title={p.topOptionsHeader}
                    hasTopSpace={!p.topOptionsHeader}
                >
                    <ItemsBlock
                        options={p.topOptions}
                        selected={p.selected}
                        onSelected={p.onSelected}
                    />
                </ItemBlockGroup>
            )}
            <ItemBlockGroup
                title={p.optionsHeader}
                hasTopSpace={!p.optionsHeader}
            >
                <ItemsBlock
                    options={p.options}
                    selected={p.selected}
                    onSelected={p.onSelected}
                />
            </ItemBlockGroup>
            {p.additionalContent}
        </>
    );
}

function ItemsBlock<T>(p: {
    options: DropdownSlideInOption<T>[];
    selected: T | null;
    onSelected: (id: T) => void;
}) {
    return (
        <div className={css({ background: Colors.white })}>
            {p.options.map(option => {
                const isSelected = option.id === p.selected;
                const selectedStyle = isSelected
                    ? {
                          color: Colors.white,
                          backgroundColor: Colors.blue,
                          '&:hover': {
                              color: Colors.white,
                              backgroundColor: Colors.blue,
                          },
                      }
                    : {};
                return (
                    <div
                        key={`option-${option.id}`}
                        className={css({
                            ...Typo.body,
                            color: Colors.blue,
                            background: Colors.white,
                            position: 'relative',
                            borderBottom: `1px solid ${Colors.rgba(
                                Colors.black,
                                0.08,
                            )}`,
                            ...defaultTransition('background', 500),
                            ...hover({ background: Colors.formHover }),
                            ...selectedStyle,
                        })}
                    >
                        <Clickable
                            className={css({
                                display: 'flex',
                                height: '72px',
                                alignItems: 'center',
                            })}
                            element="div"
                            onClick={p.onSelected.bind(null, option.id)}
                        >
                            {option.display}
                        </Clickable>
                    </div>
                );
            })}
        </div>
    );
}

interface SlideInDisplayStyleProps {
    firstRowText: React.ReactNode;
    secondRowText?: string;
}

export const SlideInDisplay = (p: SlideInDisplayStyleProps) => {
    return (
        <div className={css({ display: 'flex' })}>
            <div
                className={css({
                    margin: '0 24px',
                    minWidth: '35px',
                })}
            >
                {p.firstRowText}
            </div>
            {p.secondRowText && <div>{p.secondRowText}</div>}
        </div>
    );
};
