import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import { ParkingpayGenericError } from './ParkingpayGenericError';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { SpinnerModal } from '../../ui/spinner/Spinner.tsx';

export function ParkingpayAsyncLoadedSection<D, ED>({
    state,
    renderSuccess,
    renderError,
    showSpinnerOnRefetch,
    portal,
}: {
    state: ServerRequestState<D, ED>;
    renderSuccess: (data: D) => JSX.Element | null;
    renderError?: (error: ED | null) => JSX.Element | null;
    showSpinnerOnRefetch?: boolean;
    portal?: SlideInPortalId;
}): JSX.Element | null {
    switch (state.status) {
        case RequestStatus.NEVER_EXECUTED:
            return <ParkingpayAsyncSpinner portal={portal} />;
        case RequestStatus.PENDING:
            if (!showSpinnerOnRefetch && state.data) {
                return renderSuccess(state.data);
            } else {
                return <ParkingpayAsyncSpinner portal={portal} />;
            }
        case RequestStatus.ERROR:
            return renderError ? (
                renderError(state.data)
            ) : (
                <ParkingpayGenericError />
            );
        case RequestStatus.SUCCESS:
            return renderSuccess(state.data);
    }
}

function ParkingpayAsyncSpinner({ portal }: { portal?: SlideInPortalId }) {
    return portal ? <SpinnerModal visible portal={portal} /> : <Spinner />;
}
