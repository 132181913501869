import { useStore } from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../../../../common/state/SettingsState';
import * as LayoutState from '../../../state/LayoutState';
import {
    LogoSloganVariant,
    LogoSloganVariantOption,
} from '../../../utils/LogoSloganVariant';
import { ThreeColumnsProperties } from '../../../utils/ThreeColumnsProperties';
import { columnsVariant } from '../../../utils/columnsVariant';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import { LayoutVariantOption } from '../../LayoutContainer';
import logo from '../../../../../assets/parkingpay_logo.svg';

function logoSloganDisabled(
    s: {
        layout: LayoutState.State;
    },
    layoutVariant: LayoutVariantOption,
): boolean {
    if (layoutVariant !== LayoutVariantOption.StaticPage) {
        const columnsProps = new ThreeColumnsProperties(s);
        return columnsProps.variant.oneColumn;
    } else {
        return false;
    }
}

function getLogoSlogalVariantSize(option: LogoSloganVariantOption) {
    switch (option) {
        case LogoSloganVariantOption.Large:
            return '340px';
        case LogoSloganVariantOption.Medium:
            return '250px';
        case LogoSloganVariantOption.Small:
            return '220px';
    }
}

export function LogoSlogan({
    layoutVariant,
}: {
    layoutVariant: LayoutVariantOption;
}) {
    const { storeState } = useStore(store => ({
        layout: new LayoutState.StateSlice(store).state,
        settings: new SettingsState.StateSlice(store).state,
    }));

    const navigate = useNavigate();
    const logoSloganVariant: LogoSloganVariant = new LogoSloganVariant(
        storeState,
        layoutVariant,
    );

    if (logoSloganDisabled(storeState, layoutVariant)) {
        return null;
    }

    return (
        <Clickable
            element="div"
            className={css([
                {
                    marginLeft: '80px',
                    '&::before': { content: `' '`, display: 'table' },
                    '&::after': {
                        content: `' '`,
                        display: 'table',
                        clear: 'both',
                    },
                },
                logoSloganVariant.variant === LogoSloganVariantOption.Large && {
                    height: '88px',
                    marginTop: '16px',
                },
                logoSloganVariant.variant ===
                    LogoSloganVariantOption.Medium && {
                    height: '56px',
                    marginTop: '16px',
                },
                logoSloganVariant.variant === LogoSloganVariantOption.Small && {
                    height: '40px',
                    marginTop: '12px',
                },
                columnsVariant(storeState) === 'OneColumn' && {
                    marginBottom: '12px',
                    marginLeft: '24px',
                },
            ])}
            onClick={() => navigate('/')}
        >
            <img
                className={css({
                    display: 'block',
                    float: 'left',
                    width: getLogoSlogalVariantSize(logoSloganVariant.variant),
                })}
                src={logo}
            />
        </Clickable>
    );
}
