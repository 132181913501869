import { useServerSuccessEffect } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    PermitLicensePlateType,
    PermitType,
} from '../../state/permit/PermitTypeModels';
import { ParkingpayAsyncLoadedSection } from '../../../common/components/ParkingpayAsyncLoadedSection';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import { useServerFetch } from '../../../hooks/ServerStateHooks';

function usePermitTypesByZone(selectedZoneId: number) {
    return useServerFetch<PermitType[], { zoneId: number }>(
        ({ zoneId }) => ({
            url: `/ui-api/park-options/permits/${zoneId}`,
        }),
        { zoneId: selectedZoneId },
    );
}

function usePermitTypesByZipCode(selectedZipCode: string) {
    return useServerFetch<PermitType[], { zipCode: string }>(
        ({ zipCode }) => ({
            url: `/ui-api/park-options/city-wide-permits/${zipCode}`,
        }),
        { zipCode: selectedZipCode },
    );
}

function WithPermitTypesByZone({
    render,
    zoneId,
    onSuccess,
}: {
    render: (permitTypes: PermitType[]) => JSX.Element;
    onSuccess: (permitTypes: PermitType[]) => void;
    zoneId: number;
}) {
    const [permitTypes] = usePermitTypesByZone(zoneId);

    useServerSuccessEffect(permitTypes, onSuccess);

    return (
        <ParkingpayAsyncLoadedSection
            state={permitTypes}
            showSpinnerOnRefetch
            renderSuccess={render}
            portal={SlideInPortalId.PARK_CREATE}
        />
    );
}

function WithPermitTypesByZipCode({
    render,
    zipCode,
    onSuccess,
}: {
    render: (permitTypes: PermitType[]) => JSX.Element;
    onSuccess: (permitTypes: PermitType[]) => void;
    zipCode: string;
}) {
    const [permitTypes] = usePermitTypesByZipCode(zipCode);

    useServerSuccessEffect(permitTypes, onSuccess);

    return (
        <ParkingpayAsyncLoadedSection
            state={permitTypes}
            showSpinnerOnRefetch
            renderSuccess={render}
            portal={SlideInPortalId.PARK_CREATE}
        />
    );
}

function parseLpTypes(
    types: PermitLicensePlateType[] | null,
): PermitLicensePlateType[] {
    return types ?? [];
}

export function WithPermitTypes({
    render,
    zoneId,
    zipCode,
    onSuccess,
}: {
    render: (permitTypes: PermitType[]) => JSX.Element;
    onSuccess: (permitTypes: PermitType[]) => void;
    zipCode: string;
    zoneId: number | null;
}) {
    if (zoneId) {
        return (
            <WithPermitTypesByZone
                zoneId={zoneId}
                render={render}
                onSuccess={onSuccess}
            />
        );
    }

    return (
        <WithPermitTypesByZipCode
            render={permitTypes =>
                render(
                    permitTypes.map(t => ({
                        ...t,
                        allowedLicensePlateTypes: parseLpTypes(
                            t.allowedLicensePlateTypes,
                        ),
                    })),
                )
            }
            zipCode={zipCode}
            onSuccess={onSuccess}
        />
    );
}
