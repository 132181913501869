import { useState } from 'react';
import { useStore } from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../../common/state/SettingsState';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { VehicleAdd } from './VehicleAdd';
import { VehicleAddBadge } from './VehicleAddBadge';
import { VehicleDetail } from './VehicleDetail';
import { AddVehicleRow, VehiclesListSlideIn } from './VehiclesList';
import { UpdateVehicle, Vehicles } from './VehicleState';
import { vehicleTexts } from './VehicleTexts';
import { MainMenuItemBlock } from '../root/components/ItemBlock';
import {
    IconButton,
    IconButtonType,
} from 'dg-web-shared/tb-ui/buttons/IconButton.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16';
import { css } from '@emotion/css';
import * as LayoutState from '../../layout/state/LayoutState';
import { ResponsiveProperties } from '../../layout/utils/ResponsiveProperties';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { useVehicleListOpen } from '../../hooks/OpenVehicleList';

interface VehicleMenuLocalState {
    addNewVehicle: boolean;
    vehicleDetails: boolean;
    selectedVehicleId: number | null;
}

export function VehiclesMenu() {
    const [vehicleMenu, setVehicleMenu] = useState<VehicleMenuLocalState>({
        addNewVehicle: false,
        vehicleDetails: false,
        selectedVehicleId: null,
    });
    const [addBadgeOpen, setAddBadgeOpen] = useState(false);

    const { listOpen, setListOpen } = useVehicleListOpen();

    const { storeState, update } = useStore(s => {
        return {
            settings: new SettingsState.StateSlice(s).state,
            vehicles: Vehicles.get(s),
            layout: new LayoutState.StateSlice(s).state,
            login: CurrentLoginState.get(s),
        };
    });

    const vehicles = storeState.vehicles.data;
    const t = vehicleTexts[storeState.settings.language];

    const selectedVehicle =
        vehicles.find(
            vehicle => vehicle.customerCarId === vehicleMenu.selectedVehicleId,
        ) || null;

    return (
        <div>
            {vehicles.length > 0 ? (
                <MainMenuItemBlock
                    onClick={() => setListOpen(true)}
                    title={t.vehicles()}
                    rightTitle={vehicles.length.toString()}
                />
            ) : (
                <AddVehicleRow
                    title={t.addVehicle()}
                    onClick={() => {
                        setVehicleMenu(prevState => ({
                            ...prevState,
                            addNewVehicle: true,
                        }));
                    }}
                />
            )}

            {storeState.login.data && (
                <>
                    <VehiclesListSlideIn
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={listOpen}
                        title={
                            <div
                                className={css({
                                    display: 'flex',
                                    alignItems: 'center',
                                })}
                            >
                                <div>{t.vehicles()}</div>
                                <div
                                    className={css({
                                        marginLeft: 'auto',
                                        marginRight: '10px',
                                        marginTop: '6px',
                                    })}
                                >
                                    <VehicleListDownload
                                        layout={storeState.layout}
                                        login={storeState.login}
                                        settings={storeState.settings}
                                    />
                                </div>
                            </div>
                        }
                        onClose={() => setListOpen(false)}
                        vehicles={vehicles}
                        selectVehicle={vehicleId => {
                            setVehicleMenu(prevState => ({
                                ...prevState,
                                vehicleDetails: true,
                                selectedVehicleId: vehicleId,
                            }));
                        }}
                        addNewVehicle={() => {
                            setVehicleMenu(prevState => ({
                                ...prevState,
                                addNewVehicle: true,
                            }));
                        }}
                        numberOfEnabledLprZones={
                            storeState.login.data.numberOfEnabledLprZones
                        }
                    />

                    <VehicleDetail
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={vehicleMenu.vehicleDetails}
                        title={t.vehicle()}
                        onClose={() => {
                            update(store => {
                                UpdateVehicle.reset(store);
                                return 'reset-vehicle';
                            });
                            setVehicleMenu(prevState => ({
                                ...prevState,
                                vehicleDetails: false,
                                selectedVehicleId: null,
                            }));
                        }}
                        addBadge={() => setAddBadgeOpen(true)}
                        texts={t}
                        vehicle={selectedVehicle}
                        hasLprEnabled={
                            storeState.login.data.numberOfEnabledLprZones > 0
                        }
                    />
                </>
            )}

            <VehicleAddBadge
                portal={SlideInPortalId.USER_ACCOUNT}
                texts={t}
                vehicle={selectedVehicle}
                title={t.addBadge()}
                open={addBadgeOpen}
                onClose={() => setAddBadgeOpen(false)}
                onSuccess={() => null}
            />

            <VehicleAdd
                portal={SlideInPortalId.USER_ACCOUNT}
                open={vehicleMenu.addNewVehicle}
                title={t.addVehicle()}
                onClose={() => {
                    update(store => {
                        UpdateVehicle.reset(store);
                        return 'reset-UpdateVehicle';
                    });
                    setVehicleMenu(prevState => ({
                        ...prevState,
                        addNewVehicle: false,
                    }));
                }}
                onSuccess={(res, data) => {
                    setVehicleMenu(prevState => ({
                        ...prevState,
                        selectedVehicleId: data.addBadgeToVehicle
                            ? res.vehicleId
                            : null,
                        vehicleDetails: false,
                    }));
                    setAddBadgeOpen(data.addBadgeToVehicle);
                }}
                country={selectedVehicle && selectedVehicle.country}
                type={selectedVehicle ? selectedVehicle.type : null}
                licensePlateNr={
                    selectedVehicle && selectedVehicle.licensePlateNr
                }
                loginId={selectedVehicle && selectedVehicle.loginId}
            />
        </div>
    );
}

const isMobile = (p: { layout: LayoutState.State }) =>
    new ResponsiveProperties(p).mobile;

interface VehicleListDownloadProps {
    layout: LayoutState.State;
    login: CurrentLoginState.State;
    settings: SettingsState.State;
}

const VehicleListDownload = (p: VehicleListDownloadProps) =>
    isMobile(p) ||
    p.login.data?.loginRole ===
        CurrentLoginState.LoginRole.RESTRICTED ? null : (
        <IconButton
            small={true}
            icon16px={true}
            link={`ui-api/customer-account/vehicles/export?lang=${p.settings.language}`}
            type={IconButtonType.regular}
            icon={Icons16.download}
            onClick={() => null}
        />
    );
