import * as superagent from 'superagent';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import * as AsyncRequest from '../../../AsyncRequest';
import * as ServerStateSlice from '../../../common/state/ServerStateSlice';
import * as LoginsState from './LoginsState';

export class StateSlice extends ServerStateSlice.LegacyServerStateSlice<
    GetLoginData[],
    void
> {
    key(): string {
        return 'account-logins-LoginsState';
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.shouldFetch) {
            store.legacyUpdater(receiveLogins);
        }
    }

    getEmptyData() {
        return { data: [], errorData: undefined };
    }
}

function receiveLogins(): Flux.Write {
    return AsyncRequest.request(
        superagent.get('/ui-api/customer-account/logins-v2'),
        (store: Flux.Store, res: Response): void => {
            new LoginsState.StateSlice(store).writeRequest(res);
        },
    );
}

export type GetLoginData = {
    id: number;
    confirmed: boolean;
    email: string;
    requestedEmail: string | null;
    roleId: number;
    firstName: string | null;
    lastName: string | null;
    deactivated: boolean;
    gender: 'm' | 'f' | null;
};
