import { css, injectGlobal } from '@emotion/css';

import { useEffect, useState } from 'react';
import * as Flux from 'dg-web-shared/lib/Flux';
import { useStore, useUpdate } from 'dg-web-shared/lib/Flux';
import { BasicButton } from 'dg-web-shared/tb-ui/buttons/BasicButton.tsx';
import * as LayoutActions from '../../layout/actions/LayoutActions';
import { mobileNavigateAccount } from '../../layout/actions/LayoutActions';
import { AuthenticatedHomePage } from '../../pages/home-authenticated/components/AuthenticatedHomePage';
import { LandingPage } from '../../pages/home-unauthenticated/LandingPage';
import { AccountDeletedPage } from '../../pages/static/components/AccountDeletedPage';
import { ActivateAccountPage } from '../../pages/static/components/ActivateAccountPage';
import { ConfirmRequestedEmailPage } from '../../pages/static/components/ConfirmRequestedEmailPage';
import { FAQPage } from '../../pages/static/components/FAQPage';
import { NewsletterUnsubscribePage } from '../../pages/static/components/NewsletterUnsubscribePage';
import { OfflinePage } from '../../pages/static/components/OfflinePage';
import { ReactivateTerminatedAccountPage } from '../../pages/static/components/ReactivateTerminatedAccountPage';
import { ReceiptPage } from '../../pages/static/components/ReceiptPage';
import { ResetPasswordPage } from '../../pages/static/components/ResetPasswordPage';
import { AppStartupHooks } from '../state/AppState';
import { LucernConfirmTenantPage } from '../../pages/static/components/LucernConfirmTenantPage';
import { PermitPayloadContextProvider } from '../../park-create/components/permit/PermitPayloadContext';
import { WinterthurConfirmTenantPage } from '../../pages/static/components/WinterthurConfirmTenantPage';
import { QuickCheckoutTicket } from '../../quickcheckout/ticket/QuickCheckoutTicket';
import { TransactionListProvider } from '../../transactions-list/TransactionsListContext';
import {
    createBrowserRouter,
    Navigate,
    Outlet,
    RouterProvider,
} from 'react-router-dom';
import {
    DatatransCallback,
    TwintAbortCallback,
    TwintPairingCallback,
} from '../../common/components/PaymentCallback';
import { MetaFormsVisibilityStateSlice } from '../../account/meta/MetaForms';
import { TicketPlusGuidePage } from '../../pages/static/components/TicketPlusGuidePage';
import { UnathorizedPage } from './UnathorizedPage';
import {
    AuthStateProvider,
    AuthStatusType,
    useAuthState,
} from '../state/AuthState';
import { InvalidQrCodeScanModal } from '../../park-create/components/InvalidQrCodeScanModal';
import {
    AutomaticPaymentDeactivatedModal,
    ParkCreateTicketPlus,
    TicketAlreadyClaimedModal,
    TicketApprovalLimitReachedModal,
    TicketNotFoundModal,
    TicketPaymentDisabledModal,
    TicketUnknownErrorModal,
} from '../../park-create/ticket/ParkCreateTicketPlus';
import { DeepLinkConverter } from '../../park-create/components/DeepLinkConverter';
import { TicketRoutes } from '../../park-create/ticket/TicketRoutes';
import {
    TicketActivationAliasSuccessModal,
    TicketActivationDirectSuccessModal,
    TicketActivationFailedModal,
    TicketActivationTopupSuccessModal,
} from '../../park-create/ticket/TicketApprovedModals';
import {
    DeepLinkPermittypeConsumer,
    DeepLinkTicketConsumer,
} from './DeepLinkConsumer';
import { LegalPage } from '../../pages/static/components/Legal';
import { QuickCheckoutPermittype } from '../../quickcheckout/permittype/QuickCheckoutPermittype';
import { QuickCheckoutRoot } from '../../quickcheckout/QuickCheckoutRoot';
import { QuickCheckoutPermittypeDetailsSlideIn } from '../../quickcheckout/permittype/QuickCheckoutPermittypeDetailsSlideIn';
import { QuickCheckoutZoneInfoSlideIn } from '../../quickcheckout/ticket/QuickCheckoutZoneInfoSlideIn';
import { QuickCheckoutPermittypeTypeSelectionSlideIn } from '../../quickcheckout/permittype/QuickCheckoutPermittypeTypeSelectionSlideIn';
import { QuickCheckoutPermittypeCountrySelectionSlideIn } from '../../quickcheckout/permittype/QuickCheckoutPermittypeCountrySelectionSlideIn';
import { QuickCheckoutPermitReceipt } from '../../quickcheckout/transaction/QuickCheckoutPermitReceipt';
import {
    PermitPurchaseAbortedModal,
    PermitPurchaseConfirmationModal,
} from '../../quickcheckout/common/QuickCheckoutPaymentModals';
import { EmptyBackground } from '../../ui/spinner/Spinner.tsx';

injectGlobal`
    *,
    *:before,
    *:after {
        box-sizing: border-box;
        user-select: inherit;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-focus-ring-color: rgba(255, 255, 255, 0);
        outline: none;
        @media screen and (min-width: 1224px) {
            user-select: text;
        }

        .input {
            user-select: text;
        }
    }

    html, body {
        margin: 0;
        padding: 0;
        height: 100%;
        position: relative;
        user-select: none;
    }
`;

export const ACCOUNT_DELETION_PATH = '/account-deletion';
export const INVALID_SCAN_PATH = 'invalid-scan';
export const TWINT_QR_CODE_SCAN_PATH = 'twint-qr-code';

export function App({ allState }: { allState: Flux.Store }) {
    return (
        <Flux.StoreContext.Provider value={allState}>
            <AuthStateProvider>
                <ReactRouter />
            </AuthStateProvider>
        </Flux.StoreContext.Provider>
    );
}

function ReactRouter() {
    const { store } = useStore(() => null);

    useEffect(() => {
        const resizeHandler = () => {
            store.legacyUpdater(args => LayoutActions.viewportResize(args!), {
                viewportWidth: window.innerWidth,
                viewportHeight: window.innerHeight,
            });
        };

        resizeHandler();
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return (
        <RouterProvider
            router={createBrowserRouter([
                {
                    element: <AppFunctionality />,
                    children: [
                        {
                            path: '/faq',
                            element: <FAQPage allState={store} />,
                        },
                        {
                            path: '/t/:ticketString',
                            element: <DeepLinkTicketConsumer />,
                        },
                        {
                            path: '/p/:permitUrlIdentification',
                            element: <DeepLinkPermittypeConsumer />,
                        },
                        {
                            path: '/',
                            element: <CoreApp />,
                            children: [
                                {
                                    path: 'ticket/:ticketString',
                                    element: <ParkCreateTicketPlus />,
                                    children: [
                                        {
                                            path: TicketRoutes.automaticPaymentDeactivated,
                                            element: (
                                                <AutomaticPaymentDeactivatedModal />
                                            ),
                                        },
                                        {
                                            path: TicketRoutes.alreadyClaimed,
                                            element: (
                                                <TicketAlreadyClaimedModal />
                                            ),
                                        },
                                        {
                                            path: TicketRoutes.paymentDisabled,
                                            element: (
                                                <TicketPaymentDisabledModal />
                                            ),
                                        },
                                        {
                                            path: TicketRoutes.notFound,
                                            element: <TicketNotFoundModal />,
                                        },
                                        {
                                            path: TicketRoutes.limitReached,
                                            element: (
                                                <TicketApprovalLimitReachedModal />
                                            ),
                                        },
                                        {
                                            path: TicketRoutes.unknownError,
                                            element: (
                                                <TicketUnknownErrorModal />
                                            ),
                                        },
                                        {
                                            path: TicketRoutes.activationFailed,
                                            element: (
                                                <TicketActivationFailedModal />
                                            ),
                                        },
                                        {
                                            path: TicketRoutes.topupActivationSuccessful,
                                            element: (
                                                <TicketActivationTopupSuccessModal />
                                            ),
                                        },
                                        {
                                            path: TicketRoutes.aliasActivationSuccessful,
                                            element: (
                                                <TicketActivationAliasSuccessModal />
                                            ),
                                        },
                                        {
                                            path: TicketRoutes.directActivationSuccessful,
                                            element: (
                                                <TicketActivationDirectSuccessModal />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: 'permittype/:permitUrlIdentification',
                                    element: <DeepLinkConverter />,
                                },
                                {
                                    path: 'z/:zoneUrlIdentification',
                                    element: <DeepLinkConverter />,
                                },
                                {
                                    path: `${TWINT_QR_CODE_SCAN_PATH}/:twintQrCodeString`,
                                    element: <DeepLinkConverter />,
                                },
                                {
                                    path: INVALID_SCAN_PATH,
                                    element: <InvalidQrCodeScanModal />,
                                },
                            ],
                        },
                        {
                            path: '/quickcheckout',
                            element: <QuickCheckoutRoot />,
                            children: [
                                {
                                    path: 'ticket/:ticketId/:paymentAttemptId?',
                                    element: <QuickCheckoutTicket />,
                                    children: [
                                        {
                                            path: 'parking-details',
                                            element: (
                                                <QuickCheckoutZoneInfoSlideIn />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: 'permittype/:permitUrlIdentification',
                                    element: <QuickCheckoutPermittype />,
                                    children: [
                                        {
                                            path: 'details',
                                            element: (
                                                <QuickCheckoutPermittypeDetailsSlideIn />
                                            ),
                                        },
                                        {
                                            path: 'vehicle-country-selection',
                                            element: (
                                                <QuickCheckoutPermittypeCountrySelectionSlideIn />
                                            ),
                                        },
                                        {
                                            path: 'vehicle-type-selection',
                                            element: (
                                                <QuickCheckoutPermittypeTypeSelectionSlideIn />
                                            ),
                                        },
                                        {
                                            path: 'purchase-aborted',
                                            element: (
                                                <PermitPurchaseAbortedModal />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: 'permit/:externalId',
                                    element: <QuickCheckoutPermitReceipt />,
                                    children: [
                                        {
                                            path: 'purchase-confirmation',
                                            element: (
                                                <PermitPurchaseConfirmationModal />
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: '/unauthorized',
                            element: <UnathorizedPage />,
                        },
                        {
                            path: '/offline',
                            element: <OfflinePage />,
                        },
                        {
                            path: '/lconf/:externalId',
                            element: <LucernConfirmTenantPage />,
                        },
                        {
                            path: '/winterthur-resident/:externalId',
                            element: <WinterthurConfirmTenantPage />,
                        },
                        {
                            path: '/account-activated/:token',
                            element: <ActivateAccountPage />,
                        },
                        {
                            path: '/reset-password/:token',
                            element: <ResetPasswordPage />,
                        },
                        {
                            path: '/reactivate-terminated-account/:token',
                            element: <ReactivateTerminatedAccountPage />,
                        },
                        {
                            path: '/account-deleted/saldo',
                            element: <AccountDeletedPage saldo={true} />,
                        },
                        {
                            path: '/account-deleted/nosaldo',
                            element: <AccountDeletedPage saldo={false} />,
                        },
                        {
                            path: '/confirm-requested-email/:token',
                            element: <ConfirmRequestedEmailPage />,
                        },
                        {
                            path: '/receipt/:hash',
                            element: <ReceiptPage />,
                        },
                        {
                            path: '/newsletter/unsubscribe',
                            element: <NewsletterUnsubscribePage />,
                        },
                        {
                            path: '/demo',
                            element: <DemoPage />,
                        },
                        {
                            path: '/datatrans-callback',
                            element: <DatatransCallback />,
                        },
                        {
                            path: '/twint-aborted/:mode/:twintPaymentAttemptId',
                            element: <TwintAbortCallback />,
                        },
                        {
                            path: '/twint-success/:mode/:twintPaymentAttemptId',
                            element: <TwintPairingCallback />,
                        },
                        {
                            path: '/ticket-plus-guide',
                            element: <TicketPlusGuidePage />,
                        },
                        {
                            path: ACCOUNT_DELETION_PATH,
                            element: <NavigateToAccountDeletion />,
                        },
                        {
                            path: '/privacy',
                            element: <LegalPage />,
                        },
                        {
                            path: '*',
                            element: <Navigate to="/" replace />,
                        },
                    ],
                },
            ])}
        />
    );
}

function NavigateToAccountDeletion() {
    const { authStatus } = useAuthState();
    const update = useUpdate();
    useEffect(() => {
        if (authStatus === AuthStatusType.OK) {
            update(store => {
                mobileNavigateAccount(store);
                new MetaFormsVisibilityStateSlice(store).toggle();
                return 'navigateToAccountDeletion';
            });
        }
    }, [authStatus]);

    return <CoreApp />;
}

function AppFunctionality() {
    return (
        <AppStartupHooks>
            <Outlet />
        </AppStartupHooks>
    );
}

function CoreApp(): JSX.Element {
    const { authStatus } = useAuthState();

    switch (authStatus) {
        case AuthStatusType.UNKNOWN:
            return <EmptyBackground />;
        case AuthStatusType.NOT_OK:
            return <LandingPage />;
        case AuthStatusType.OK:
            return (
                <PermitPayloadContextProvider>
                    <TransactionListProvider>
                        <AuthenticatedHomePage />
                    </TransactionListProvider>
                </PermitPayloadContextProvider>
            );
    }
}

const DemoPage = () => {
    const [state, setState] = useState<{
        size: 'mobile' | 'desktop';
    }>({
        size: 'mobile',
    });
    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            })}
        >
            <div
                className={css({
                    width: state.size === 'mobile' ? '380px' : '90%',
                    height: state.size === 'mobile' ? '720px' : '80%',
                    border: '40px solid #ddd',
                    marginTop: '-5%',
                    borderWidth: '55px 7px',
                    borderRadius: '40px',
                    overflow: 'hidden',
                    transition: 'all 0.5s ease',
                    boxShadow:
                        ' 0px 3px 0 #BBB, 0px 4px 0 #BBB, 0px 5px 0 #BBB, 0px 7px 0 #BBB, 0px 10px 20px #666',
                })}
                id="phone_1"
            >
                <iframe
                    className={css({
                        border: 0,
                        width: '100%',
                        height: '100%',
                    })}
                    src={window.location.origin}
                    id="frame_1"
                />
            </div>
            <div
                className={css({
                    display: 'flex',
                    width: '480px',
                    justifyContent: 'space-between',
                })}
            >
                <div className={css({ marginTop: '50px' })}>
                    <BasicButton
                        label="Test"
                        onClick={() =>
                            (window.location.href =
                                'https://customer.test.digitalparking.ch/demo')
                        }
                        active={
                            window.location.href ==
                            'https://customer.test.digitalparking.ch/demo'
                        }
                    />
                    <BasicButton
                        label="Prod"
                        onClick={() =>
                            (window.location.href =
                                'https://parkingpay.ch/demo')
                        }
                        active={
                            window.location.href == 'https://parkingpay.ch/demo'
                        }
                    />
                </div>
                <div className={css({ marginTop: '50px' })}>
                    <BasicButton
                        label="Mobile"
                        onClick={() =>
                            setState(s => ({ ...s, ...{ size: 'mobile' } }))
                        }
                        active={state.size === 'mobile'}
                    />
                    <BasicButton
                        label="Desktop"
                        onClick={() =>
                            setState(s => ({ ...s, ...{ size: 'desktop' } }))
                        }
                        active={state.size === 'desktop'}
                    />
                </div>
            </div>
        </div>
    );
};
