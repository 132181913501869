import { css } from '@emotion/css';

import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { Localized } from '../../../common/components/Localized';
import { Colors } from 'dg-web-shared/ui/vars';
import { portalSlideIn } from '../../root/components/PortalSlidein';
import { logAction } from '../../../utils/ActionLog';
import { useStore } from 'dg-web-shared/lib/Flux';
import { PaymentMethodFormBlock } from '../PaymentMethodFormBlock';
import { SingleSelection } from 'dg-web-shared/tb-ui/inputs/SingleSelection.tsx';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import {
    ChannelRepresentation,
    InvoiceChannelDropdownSlidein,
    InvoiceReceiverDropdownSliden,
} from '../../invoices/InvoicesSettingsMenu';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import * as LoginsState from '../../logins/state/LoginsState';
import * as MetaServerState from '../../meta/state/MetaServerState';
import { CompanyUidValidationError } from '../../meta/CompanyEditForm';
import {
    CountryNotSupportedForPaymentMethod,
    isCountryNotSupportedForPaymentMethod,
} from '../direct-debit/DirectDebitRequestForm';
import { PaymentMethodEnum } from '../../../api/Http';
import { useEffect, useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { InvoiceChannelType } from 'dg-web-shared/dto/Invoice.ts';

export const InvoiceRequestFormSlideIn = portalSlideIn<Props>(
    InvoiceRequestFormWrapper,
);

function InvoiceRequestFormWrapper(props: Props) {
    return <InvoiceRequestForm {...props} />;
}

export const INVOICE_MONTHLY_FEE = '18.50';
export const INVOICE_MONTHLY_FEE_PAPER = '22.50';
export const INVOICE_MAIL_FEE = '4.-';

function InvoiceRequestForm(props: Props) {
    const { storeState, store } = useStore(s => ({
        currentLogin: CurrentLoginState.get(s),
        logins: new LoginsState.StateSlice(s).state,
        metaServer: new MetaServerState.StateSlice(s).state,
    }));
    const [feeAccepted, setFeeAccepted] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [companyUid, setCompanyUid] = useState(
        storeState.metaServer.data.address.companyUid || '',
    );
    const [showCompanyUidError, setShowCompanyUidError] = useState(false);
    useEffect(() => {
        setShowCompanyUidError(props.showCompanyUidError);
    }, [props.showCompanyUidError]);
    const [companyUidEdited, setCompanyUidEdited] = useState(false);
    const [invoiceChannelOpen, setInvoiceChannelOpen] = useState(false);
    const [invoiceChannel, setInvoiceChannel] = useState(
        InvoiceChannelType.EMAIL,
    );
    const [invoiceReceiverOpen, setInvoiceReceiverOpen] = useState(false);
    const [invoiceReceiver, setInvoiceReceiver] = useState(
        storeState.currentLogin.data?.email || null,
    );

    if (
        storeState.logins.data === null ||
        storeState.currentLogin.data === null
    ) {
        return null;
    }

    if (
        isCountryNotSupportedForPaymentMethod(
            storeState.metaServer.data.address.countryId,
        ) ||
        (storeState.metaServer.data.shippingAddress.countryId.trim() != '' &&
            isCountryNotSupportedForPaymentMethod(
                storeState.metaServer.data.shippingAddress.countryId,
            ))
    ) {
        return (
            <CountryNotSupportedForPaymentMethod
                paymentMethod={PaymentMethodEnum.INVOICE}
            />
        );
    }

    return (
        <PaymentMethodFormBlock
            onCancel={props.onCancel}
            onSubmit={() => {
                logAction(store, 'invoice-requested', companyUid);
                props.onSubmit({
                    company1: storeState.metaServer.data.address.company1,
                    companyUid: companyUid,
                    channel: invoiceChannel,
                    receiving_email: invoiceReceiver || null,
                });
            }}
            submitEnabled={
                confirmed && feeAccepted && isValidCompanyUid(companyUid)
            }
            isPending={props.isPending}
        >
            <div
                className={css({
                    ...Typo.robotoRegular,
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: Colors.action_f,
                })}
            >
                <p>
                    <Localized
                        de="Bei dieser Zahlungsweise werden die Parkgebühren während des ganzen Monats auf dem Parkingpay-Konto verrechnet; zu Beginn des Folgemonats wird der fällige Gesamtbetrag in Rechnung gestellt."
                        fr="Avec ce mode de paiement, pendant tout le mois, les taxes de stationnement sont comptabilisés sur le compte Parkingpay; au début du mois suivant le montant total dû est facturé."
                        it="Con questa modalità di pagamento, durante tutto il mese, le tasse di parcheggio vengono conteggiate sul conto Parkingpay; all'inizio del mese seguente viene fatturato l'importo totale dovuto."
                        en="With this payment method, parking fees are charged to your Parkingpay account throughout the month; the total amount due is invoiced at the beginning of the following month."
                    />
                </p>

                <SingleSelection
                    labelText={{
                        de: 'Versandart',
                        en: 'Shipping method',
                        fr: "Mode d'envoi",
                        it: "Modalità d'invio",
                    }}
                    selection={
                        <ChannelRepresentation channel={invoiceChannel} />
                    }
                    onClick={() => setInvoiceChannelOpen(true)}
                    context={InputContext.form}
                />

                {invoiceChannel === InvoiceChannelType.EMAIL && (
                    <SingleSelection
                        labelText={{
                            de: 'PDF Empfänger',
                            fr: 'Destinataire PDF',
                            it: 'Destinatario PDF',
                            en: 'PDF Recipient',
                        }}
                        selection={invoiceReceiver}
                        onClick={() => setInvoiceReceiverOpen(true)}
                        context={InputContext.form}
                    />
                )}

                <FeeInfo channel={invoiceChannel} />

                <LabeledToggle
                    type={ToggleType.checkbox}
                    label={
                        <Localized
                            de="Ich bin mit den Kosten einverstanden"
                            fr="Je suis d'accord avec les coûts"
                            it="Sono d'accordo con i costi"
                            en="I accept the fees"
                        />
                    }
                    selected={feeAccepted}
                    onClick={() => setFeeAccepted(!feeAccepted)}
                />

                <InformationForTheCustomer />

                <LabeledToggle
                    type={ToggleType.checkbox}
                    label={
                        <Localized
                            de="Meine Firma erfüllt diese Bedingungen"
                            fr="Mon entreprise remplit ces conditions"
                            it="La mia azienda soddisfa questi requisiti"
                            en="My company meets these requirements"
                        />
                    }
                    selected={confirmed}
                    onClick={() => {
                        setConfirmed(!confirmed);
                    }}
                />

                <TextField
                    onChange={setCompanyUid}
                    onBlur={() => {
                        setCompanyUid(companyUid.toUpperCase().trim());
                        setCompanyUidEdited(true);
                    }}
                    readOnly={
                        companyUid ===
                        storeState.metaServer.data.address.companyUid
                    }
                    value={companyUid}
                    errorText={
                        companyUidEdited && !isValidCompanyUid(companyUid) ? (
                            <Localized
                                de="Ungültige UID"
                                fr="IDE pas valable"
                                it="IDI non valido"
                                en="Invalid UID"
                            />
                        ) : (
                            ''
                        )
                    }
                    labelText={
                        <Localized
                            de="Unternehmens-Identifikationsnummer (UID)"
                            fr="Numéro d’identification des entreprises (IDE)"
                            it="Numero d’identificazione delle imprese (IDI)"
                            en="Company identification number (UID)"
                        />
                    }
                />

                {showCompanyUidError && (
                    <CompanyUidValidationError
                        onConfirm={() => setShowCompanyUidError(false)}
                    />
                )}

                <InvoiceChannelDropdownSlidein
                    open={invoiceChannelOpen}
                    onClose={() => setInvoiceChannelOpen(false)}
                    selected={invoiceChannel}
                    onSelected={(channel: InvoiceChannelType) => {
                        setInvoiceChannel(channel);
                        if (channel === InvoiceChannelType.PAPER) {
                            setInvoiceReceiver(
                                storeState.currentLogin.data?.email || null,
                            );
                        }
                        setInvoiceChannelOpen(false);
                    }}
                />

                <InvoiceReceiverDropdownSliden
                    open={invoiceReceiverOpen}
                    onClose={() => setInvoiceReceiverOpen(false)}
                    receiver={invoiceReceiver || ''}
                    onSave={(receiver: string) => {
                        setInvoiceReceiver(receiver);
                        setInvoiceReceiverOpen(false);
                    }}
                />
            </div>
        </PaymentMethodFormBlock>
    );
}

const FeeInfo = (p: { channel: InvoiceChannelType }) => {
    const fee =
        p.channel === InvoiceChannelType.EMAIL ? (
            INVOICE_MONTHLY_FEE
        ) : (
            <>
                {INVOICE_MONTHLY_FEE_PAPER}{' '}
                <Localized
                    de={`(inkl. CHF ${INVOICE_MAIL_FEE} Zuschlag Papierform)`}
                    fr={`(y compris supplément de CHF ${INVOICE_MAIL_FEE} pour envoi sur papier)`}
                    it={`(incl. supplemento CHF ${INVOICE_MAIL_FEE} invio cartaceo)`}
                    en={`(incl. surcharge CHF ${INVOICE_MAIL_FEE} paper delivery)`}
                />
            </>
        );
    return (
        <Localized
            de={
                <>
                    <b>
                        {`Eine Administrationsgebühr von CHF `}
                        {fee}
                    </b>
                    {
                        ' wird für jede Rechnung erhoben. In Monaten ohne Transaktionen wird keine Rechnung verschickt.'
                    }
                </>
            }
            fr={
                <>
                    <b>
                        {`Des frais administratifs de CHF `}
                        {fee}
                    </b>
                    {
                        " seront prélevés pour chaque facture. Dans les mois sans transactions, aucune facture n'est envoyée."
                    }
                </>
            }
            it={
                <>
                    {'Per ogni fattura viene prelevata una '}
                    <b>
                        {`tassa amministrativa di CHF `}
                        {fee}
                    </b>
                    {
                        '. Nei mesi senza transazioni non viene inviata alcuna fattura.'
                    }
                </>
            }
            en={
                <>
                    <b>
                        {` An administration fee of CHF  `}
                        {fee}
                    </b>
                    {
                        ' will be charged for each invoice. In months without transactions no invoice is sent.'
                    }
                </>
            }
        />
    );
};

function InformationForTheCustomer() {
    return (
        <>
            <p>
                <Localized
                    de="Die Aktivierung ist auf eine Überprüfung unsererseits bedingt; der Antrag darf nur weitergeleitet werden, wenn die Firma folgenden Bedingungen erfüllt:"
                    fr="L'activation est conditionnée à une vérification de notre part; la demande ne peut être transmise que si l'entreprise remplit les conditions suivantes:"
                    it="L'attivazione è condizionata ad una verifica da parte nostra; la richiesta può essere inoltrata solo se l'azienda soddisfa i seguenti requisiti:"
                    en="Activation is subject to verification by us; the request can only be made if the company meets the following requirements:"
                />
            </p>
            <ul>
                <li>
                    <Localized
                        de="der Sitz ist in der Schweiz oder in Liechtenstein"
                        fr="son siège social est en Suisse ou au Liechtenstein"
                        it="la sede è in Svizzera oppure nel Liechtenstein"
                        en="the head office is in Switzerland or Liechtenstein"
                    />
                </li>
                <li>
                    <Localized
                        de="die Rechtsform der Firma ist entweder AG oder GmbH"
                        fr="la forme juridique de l'entreprise est SA ou S.A.R.L."
                        it="la forma giuridica dell'azienda è SA oppure Sagl"
                        en="the legal form of the company is either plc or Ltd"
                    />
                </li>
                <li>
                    <Localized
                        de="die Firma ist in der Lage, die Rechnung innerhalb von 15 Tagen mit einem QR-Einzahlungsschein mit Referenznummer zu bezahlen"
                        fr="l'entreprise est en mesure de régler la facture, dans un délai de 15 jours, via le bulletin de versement QR avec numéro de référence"
                        it="l'azienda è in grado di saldare le fatture, entro il termine di 15 giorni, tramite polizza di versamento QR con numero di riferimento"
                        en="the company is able to pay invoices within 15 days via QR payment slip with reference number"
                    />
                </li>
                <li>
                    <Localized
                        de="die Kontaktperson muss im HR als Zeichnungsberechtigter eingetragen sein"
                        fr="la personne de contact doit être inscrite au registre du commerce en tant que signataire autorisé"
                        it="la persona di contatto deve essere iscritta a registro di commercio come firmatario autorizzato"
                        en="the contact person must be entered in the commercial register as an authorised signatory"
                    />
                </li>
            </ul>
        </>
    );
}

export interface InvoiceRequestPayload {
    company1?: string;
    companyUid: string;
    channel: InvoiceChannelType;
    receiving_email?: string | null;
}

interface Props {
    onCancel: () => void;
    onSubmit: (values: InvoiceRequestPayload) => void;
    showCompanyUidError: boolean;
    isPending: boolean;
}

export function isValidCompanyUid(value: string) {
    return /^(CHE-(\d{3}\.){2}\d{3})$|^(FL-\d{4}(\.\d{3}){2}-\d)$/.test(value);
}
