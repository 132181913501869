import { css } from '@emotion/css';

import * as Flux from 'dg-web-shared/lib/Flux';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter';
import * as Sort from 'dg-web-shared/lib/Sort';
import * as String from 'dg-web-shared/lib/String';
import { BasicButton } from 'dg-web-shared/tb-ui/buttons/BasicButton.tsx';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24';
import * as Text from '../../../common/i18n/Text';
import { GeolocationState } from '../../../common/state/GeolocationState';
import * as SettingsState from '../../../common/state/SettingsState';
import { Colors } from 'dg-web-shared/ui/vars';
import { SearchField, SearchFieldStyle } from '../../../ui/SearchField';
import {
    SlideInContentBody,
    SlideInContentFixedColumn,
    SlideInContentFlexColumn,
    SlideInContentHeader,
    SlideInContentHeaderSortableItem,
    SlideInContentItem,
    SlideInContentItemText,
    SlideInContentItemTextSmall,
    SlideInSearchFieldContainer,
} from '../../../ui/slidein/SlideIn';

import * as CityDropinState from '../../state/CityDropinState';
import * as ParkOptionListState from '../../state/ParkOptionListState';

import * as DropinTexts from '../../i18n/DropinTexts';
import * as ParkCreateActions from '../../actions/ParkCreateActions';
import * as DropinActions from '../../actions/DropinActions';

import { logAction } from '../../../utils/ActionLog';
import { ParkCreatePortalSlideIn } from '../../ParkCreateSlideIn';
import { CitiesState } from '../ParkOptionList';
import { ArrowPosition, InlineInfoBox } from '../../../ui/modals/Confirmable';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { isRunningInNativeWrapper } from '../../../Native';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import React from 'react';
import { Icon24 } from 'dg-web-shared/ui/icons/Icon';

export interface Texts {
    Location: Text.Translation;
    LocationSearch: Text.Translation;
    Zipcode: Text.Translation;
    City: Text.Translation;
    AcquiringPosition: Text.Translation;
    CancelAcquisition: Text.Translation;
    GeolocationErrorTitle: Text.Translation;
    GeolocationErrorText: Text.Translation;
}

function sortCities(
    cities: CitiesState.City[],
    sortField: CityDropinState.SortField,
    sortAscending: boolean,
) {
    switch (sortField) {
        case CityDropinState.SortField.Zipcode:
            return cities.sort((a: CitiesState.City, b: CitiesState.City) =>
                Sort.arithmeticCompare(a.zipCode, b.zipCode, sortAscending),
            );
        case CityDropinState.SortField.City:
            return cities.sort((a: CitiesState.City, b: CitiesState.City) =>
                Sort.localeCompare(a.names[0], b.names[0], sortAscending),
            );
        case CityDropinState.SortField.Distance:
            return cities.sort((a, b) =>
                Sort.numberCompare(
                    a.distance,
                    b.distance,
                    sortAscending,
                    false,
                ),
            );
        default:
            return cities;
    }
}

function filterCities<T extends CitiesState.City>(
    cities: Readonly<T[]>,
    filterValue: string,
): T[] {
    return cities.filter(
        city =>
            String.normalizedIncludes(city.zipCode, filterValue) ||
            String.normalizedIncludes(
                city.names.map(n => n).join(' '),
                filterValue,
            ),
    );
}

const isDistanceColumnEnabled = (s: GeolocationState.State) =>
    GeolocationState.hasPosition(s);

export interface CityDropinContainerState {
    settings: SettingsState.State;
    cityDropin: CityDropinState.State;
    selection: ParkOptionListState.Selection.State;
    geolocation: GeolocationState.State;
}

interface CityDropinRenderValue {
    items: JSX.Element[];
    selectedItemRef: React.ReactInstance;
}

export const selectCityDropinState = (
    store: Flux.Store,
): CityDropinContainerState => ({
    settings: new SettingsState.StateSlice(store).state,
    cityDropin: new CityDropinState.StateSlice(store).state,
    selection: ParkOptionListState.Selection.get(store),
    geolocation: GeolocationState.get(store),
});

interface CityDropinListProps extends CityDropinContainerState {
    update: Flux.Updater;
    cities: Readonly<CitiesState.City[]>;
    noZones?: boolean;
}

const DistanceHeaderIcon = () => (
    <div className={css({ marginBottom: '-3px' })}>
        <Icon24 icon={Icons24.nearby} />
    </div>
);

export const CitySelectionSlideIn: React.FC<{
    cities: Readonly<CitiesState.City[]>;
}> = ({ cities }) => {
    const { storeState, update } = Flux.useStore(selectCityDropinState);
    const texts = DropinTexts.cityDropinTexts[storeState.settings.language];

    const handleAcquisitionCancel = () => {
        update(cancelAcquisition);
    };
    const handleCloseSlideIn = () => {
        update(DropinActions.CityDropin.close);
    };

    return (
        <ParkCreatePortalSlideIn
            title={texts.Location()}
            open={storeState.cityDropin.open}
            onClose={handleCloseSlideIn}
        >
            {GeolocationState.isAcquiringPosition(storeState.geolocation) &&
            !storeState.cityDropin.positionAcquisitionCanceled ? (
                <div>
                    <div
                        className={css({
                            background: Colors.white,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        })}
                    >
                        <div
                            className={css({
                                padding: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexFlow: 'column',
                            })}
                        >
                            <Spinner />
                            <div
                                className={css({
                                    paddingTop: '20px',
                                    color: Colors.action_w,
                                    textAlign: 'center',
                                    ...Typo.body,
                                })}
                            >
                                {texts.AcquiringPosition()}
                            </div>
                            <div className={css({ paddingTop: '20px' })}>
                                <BasicButton
                                    label={texts.CancelAcquisition()}
                                    onClick={handleAcquisitionCancel}
                                    dark={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <CityDropinList
                    update={update}
                    {...storeState}
                    cities={cities}
                />
            )}
        </ParkCreatePortalSlideIn>
    );
};

export class CityDropinList extends React.Component<CityDropinListProps> {
    renderItems(): CityDropinRenderValue {
        return {
            items: sortCities(
                filterCities(
                    this.props.cities,
                    this.props.cityDropin.filterValue,
                ),
                this.props.cityDropin.sortField,
                this.props.cityDropin.sortAscending,
            ).map((city, i) => {
                const selected =
                    this.props.selection.selectedZipCode === city.zipCode;

                const distance =
                    city.distance === null || (city.distance as number) > 1e5
                        ? '> 100 km'
                        : NumberFormatter.format(
                              this.props.settings.language,
                              (city.distance as number) * 1e-3,
                              '0.0',
                          ) + ' km';

                return (
                    <SlideInContentItem
                        onClick={() => {
                            this.props.update(s => {
                                return logAction(s, 'select-city-from-dropin', {
                                    zipCode: city.zipCode,
                                    searchUsed:
                                        !!this.props.cityDropin.filterValue,
                                    openDuration: this.props.cityDropin
                                        .openEpoch
                                        ? new Date().getTime() -
                                          this.props.cityDropin.openEpoch
                                        : null,
                                    sortField:
                                        CityDropinState.SortField[
                                            this.props.cityDropin.sortField
                                        ],
                                    sortAscending:
                                        this.props.cityDropin.sortAscending,
                                    selectedItem: i,
                                });
                            });
                            this.props.update(store =>
                                ParkCreateActions.setCity(store, city.zipCode),
                            );
                        }}
                        disabled={this.props.noZones === true}
                        selected={selected}
                        key={`city-${city.zipCode}`}
                        ref={selected ? 'selectedItem' : ''}
                    >
                        <SlideInContentFixedColumn
                            width={48}
                            spacingLeft={false}
                        >
                            <SlideInContentItemText>
                                {city.zipCode.slice(0, 4)}
                            </SlideInContentItemText>
                        </SlideInContentFixedColumn>
                        <SlideInContentFlexColumn
                            spacingRight={isDistanceColumnEnabled(
                                this.props.geolocation,
                            )}
                        >
                            <SlideInContentItemText>
                                {city.names.map(n => (
                                    <div key={n}>{n}</div>
                                ))}
                            </SlideInContentItemText>
                        </SlideInContentFlexColumn>
                        {isDistanceColumnEnabled(this.props.geolocation) ? (
                            <SlideInContentFixedColumn
                                width={60}
                                spacingRight={false}
                            >
                                <SlideInContentItemTextSmall textAlign="right">
                                    {distance}
                                </SlideInContentItemTextSmall>
                            </SlideInContentFixedColumn>
                        ) : null}
                    </SlideInContentItem>
                );
            }),
            // eslint-disable-next-line react/no-string-refs
            selectedItemRef: this.refs.selectedItem,
        };
    }

    renderGeolocationError() {
        const texts = DropinTexts.cityDropinTexts[this.props.settings.language];
        const hidePositionError = (store: Flux.Store) => {
            new CityDropinState.StateSlice(
                store,
            ).setHidePositionErrorNotification(true);
            return 'hidePositionError';
        };
        if (
            !isRunningInNativeWrapper() &&
            GeolocationState.isError(this.props.geolocation) &&
            !this.props.cityDropin.hidePositionErrorNotification
        ) {
            return (
                <div
                    className={css({
                        background: Colors.white,
                        padding: '10px 16px',
                    })}
                >
                    <InlineInfoBox
                        titleCaption={texts.GeolocationErrorTitle()}
                        arrowPosition={ArrowPosition.none}
                        onClose={() => this.props.update(hidePositionError)}
                    >
                        <p>{texts.GeolocationErrorText()}</p>
                    </InlineInfoBox>
                </div>
            );
        }
    }

    renderSearchField(): JSX.Element {
        const texts = DropinTexts.cityDropinTexts[this.props.settings.language];
        return (
            <SlideInSearchFieldContainer>
                <SearchField
                    labelText={texts.LocationSearch()}
                    elevatedLabelText={texts.LocationSearch()}
                    value={this.props.cityDropin.filterValue}
                    onChange={value =>
                        this.props.update(store =>
                            DropinActions.CityDropin.setFilterValue(
                                store,
                                value,
                            ),
                        )
                    }
                    onClear={() =>
                        this.props.update(
                            DropinActions.CityDropin.clearFilterValue,
                        )
                    }
                    disabled={!this.props.cityDropin.open}
                    searchFieldStyle={SearchFieldStyle.BLUE_GRAY}
                />
            </SlideInSearchFieldContainer>
        );
    }

    render() {
        const texts = DropinTexts.cityDropinTexts[this.props.settings.language];
        const { items, selectedItemRef } = this.renderItems();
        return (
            <div>
                {this.renderGeolocationError()}
                {this.renderSearchField()}
                <SlideInContentHeader
                    className={css({ background: Colors.white })}
                >
                    <SlideInContentFixedColumn width={48} spacingLeft={false}>
                        <SlideInContentHeaderSortableItem
                            label={texts.Zipcode()}
                            active={
                                this.props.cityDropin.sortField ===
                                CityDropinState.SortField.Zipcode
                            }
                            sortAscending={this.props.cityDropin.sortAscending}
                            onClick={() =>
                                this.props.update(
                                    DropinActions.CityDropin.sortByZipcode,
                                )
                            }
                        />
                    </SlideInContentFixedColumn>
                    <SlideInContentFlexColumn
                        spacingRight={isDistanceColumnEnabled(
                            this.props.geolocation,
                        )}
                    >
                        <SlideInContentHeaderSortableItem
                            label={texts.City()}
                            active={
                                this.props.cityDropin.sortField ===
                                CityDropinState.SortField.City
                            }
                            sortAscending={this.props.cityDropin.sortAscending}
                            onClick={() =>
                                this.props.update(
                                    DropinActions.CityDropin.sortByCity,
                                )
                            }
                        />
                    </SlideInContentFlexColumn>
                    {isDistanceColumnEnabled(this.props.geolocation) ? (
                        <SlideInContentFixedColumn
                            width={60}
                            spacingRight={false}
                        >
                            <SlideInContentHeaderSortableItem
                                label={<DistanceHeaderIcon />}
                                active={
                                    this.props.cityDropin.sortField ===
                                    CityDropinState.SortField.Distance
                                }
                                sortAscending={
                                    this.props.cityDropin.sortAscending
                                }
                                onClick={() =>
                                    this.props.update(
                                        DropinActions.CityDropin.sortByDistance,
                                    )
                                }
                            />
                        </SlideInContentFixedColumn>
                    ) : null}
                </SlideInContentHeader>
                <SlideInContentBody
                    updateScrollPosition={
                        !GeolocationState.hasPosition(this.props.geolocation)
                    }
                    selectedItemRef={selectedItemRef}
                >
                    {items.slice(0, 150)}
                </SlideInContentBody>
            </div>
        );
    }
}

const cancelAcquisition = (store: Flux.Store) => {
    new CityDropinState.StateSlice(store).setPositionAcquisitionCanceled(true);
    return 'cancelAcquisition';
};
