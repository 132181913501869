import { css } from '@emotion/css';

import * as superagent from 'superagent';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    RequestStatus,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { Localized } from '../../common/components/Localized';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { Colors } from 'dg-web-shared/ui/vars';
import { MaterialButton } from '../../ui/inputs/MaterialButton';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import * as LoginsState from './state/LoginsState';
import { ModalErrorBox, ModalSuccessBox } from '../../ui/modals/Confirmable';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { SpinnerModal } from '../../ui/spinner/Spinner.tsx';

export const RequestedEmailReviewSlideIn = portalSlideIn<{
    login: LoginsState.GetLoginData | null;
}>(RequestedEmailReview);

function RequestedEmailReview(props: {
    portal: SlideInPortalId;
    onClose: () => void;
    login: LoginsState.GetLoginData | null;
}) {
    if (!props.login) {
        return <Spinner />;
    }

    return (
        <div
            className={css({
                padding: '24px',
                ...Typo.robotoRegular,
                fontSize: '16px',
                lineHeight: '22px',
                color: Colors.action_f,
            })}
        >
            <TextField
                context={InputContext.form}
                value={props.login.requestedEmail}
                readOnly={true}
                labelText={
                    <Localized
                        de="Neue E-Mail-Adresse"
                        fr="Nouvelle adresse e-mail"
                        it="Nuovo indirizzo e-mail"
                        en="New e-mail address"
                    />
                }
            />

            <div>
                <Localized
                    de="Der Nutzer hat die neue E-Mail-Adresse noch nicht bestätigt; dafür hatten wir ihm ein Bestätigungslink per E-Mail geschickt."
                    fr="L'utilisateur n'a pas encore confirmé la nouvelle adresse e-mail; nous lui avons envoyé un lien de confirmation par e-mail."
                    it="L'utente non ha ancora confermato il nuovo indirizzo e-mail; a questo scopo gli abbiamo inviato per e-mail il link di conferma."
                    en="The user has not yet confirmed the new e-mail address; we sent him a confirmation link via e-mail for this purpose."
                />
            </div>

            <LoginEmail.LocalizedResendLinkForUser
                loginId={props.login.id}
                onSuccess={props.onClose}
                portal={props.portal}
            />

            <div className={css({ textAlign: 'right', width: '100%' })}>
                <LoginEmail.CancelChangeButton
                    httpPostUrl={`/ui-api/customer-account/logins-v2/${props.login.id}/requested-email-cancel`}
                    portal={props.portal}
                    onSuccess={props.onClose}
                />
            </div>
        </div>
    );
}

export namespace LoginEmail {
    export function LocalizedResendLinkForUser(props: {
        loginId: number;
        onSuccess: () => void;
        portal: SlideInPortalId;
    }) {
        const httpPostUrl = `/ui-api/customer-account/logins-v2/${props.loginId}/resend-requested-email-confirmation`;

        const linkProps = {
            httpPostUrl,
            onSuccess: props.onSuccess,
            portal: props.portal,
        };

        return (
            <Localized
                de={
                    <p>
                        Wenn der Benutzer diese E-Mail noch nicht erhalten hat,
                        sollte er seinen Spam-Ordner im E-Mail-Programm
                        überprüfen. Ansonsten können Sie hier{' '}
                        <LoginEmail.ResendLink {...linkProps}>
                            einen neuen Aktivierungslink anfordern.
                        </LoginEmail.ResendLink>
                    </p>
                }
                fr={
                    <p>
                        Si l&#39;utilisateur n&#39;a pas encore reçu cet e-mail,
                        il doit vérifier son dossier spam (courrier indésirable)
                        dans le programme de messagerie. Sinon, vous pouvez{' '}
                        <LoginEmail.ResendLink {...linkProps}>
                            demander un nouveau lien de confirmation ici.
                        </LoginEmail.ResendLink>
                    </p>
                }
                it={
                    <p>
                        Se l&#39;utente non ha ancora ricevuto questo e-mail,
                        dovrebbe verificare la propria cartella spam (posta
                        indesiderata) nel programma di posta elettronica.
                        Altrimenti lei può richiedere qui{' '}
                        <LoginEmail.ResendLink {...linkProps}>
                            un nuovo link di conferma.
                        </LoginEmail.ResendLink>
                    </p>
                }
                en={
                    <p>
                        If the user has not yet received this e-mail, he should
                        check his spam folder (junk mail) in the e-mail program.
                        Otherwise you can{' '}
                        <LoginEmail.ResendLink {...linkProps}>
                            request here a new confirmation link.
                        </LoginEmail.ResendLink>
                    </p>
                }
            />
        );
    }

    export function ResendLink(props: {
        httpPostUrl: string;
        children: React.ReactNode;
        portal: SlideInPortalId;
        onSuccess: () => void;
    }) {
        const [httpPost, doHttpPost, resetHttpPost] = useServerWrite<
            object,
            object
        >({
            req: () => superagent.post(props.httpPostUrl).send({}),
        });

        return (
            <>
                <a
                    className={css({
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    })}
                    onClick={doHttpPost}
                >
                    {props.children}
                </a>

                <SpinnerModal
                    portal={props.portal}
                    visible={httpPost.status === RequestStatus.PENDING}
                />

                {httpPost.status === RequestStatus.SUCCESS && (
                    <ModalSuccessBox
                        confirmCallback={props.onSuccess}
                        titleCaption={
                            <Localized
                                de="E-Mail gesendet"
                                fr="E-mail envoyé"
                                it="E-mail inviato"
                                en="E-mail sent"
                            />
                        }
                    >
                        <p>
                            <Localized
                                de="Eine neue E-Mail-Mitteilung mit dem Bestätigungslink wurde versandt."
                                fr="Un nouveau message e-mail avec le lien de confirmation a été envoyé."
                                it="Un nuovo messaggio e-mail con il link di conferma è stato inviato."
                                en="A new e-mail message with the confirmation link has been sent."
                            />
                        </p>
                    </ModalSuccessBox>
                )}

                {httpPost.status === RequestStatus.ERROR && (
                    <ErrorMessageBox onClose={resetHttpPost} />
                )}
            </>
        );
    }

    export function CancelChangeButton(props: {
        httpPostUrl: string;
        portal: SlideInPortalId;
        onSuccess: () => void;
    }) {
        const { update } = useStore(() => {});

        const [httpPost, doHttpPost, resetHttpPost] = useServerWrite<
            object,
            object
        >({
            req: () => superagent.post(props.httpPostUrl).send({}),

            onResponse: response => {
                if (response.status === RequestStatus.SUCCESS) {
                    update(CurrentLoginState.reset);

                    update(store => {
                        const loginsSlice = new LoginsState.StateSlice(store);
                        loginsSlice.reset.bind(loginsSlice);
                        return 'reset-login-state';
                    });

                    props.onSuccess();
                }
            },
        });

        return (
            <>
                <MaterialButton
                    onClick={doHttpPost}
                    label={
                        <Localized
                            de="Änderung verwerfen"
                            fr="Rejeter la modification"
                            it="Annulla modifica"
                            en="Discard change"
                        />
                    }
                />

                {httpPost.status === RequestStatus.ERROR && (
                    <ErrorMessageBox onClose={resetHttpPost} />
                )}
            </>
        );
    }

    export function ErrorMessageBox(props: { onClose: () => void }) {
        return (
            <ModalErrorBox confirmCallback={props.onClose}>
                <p>
                    <Localized
                        de="Es ist ein unerwarteter Fehler aufgetreten."
                        fr="Une erreur inattendue est survenue."
                        it="Si è verificato un errore imprevisto."
                        en="An unexpected error has occurred."
                    />
                    <br />
                    <Localized
                        de="Bitte versuchen Sie es später noch einmal."
                        fr="Merci de réessayer plus tard."
                        it="Voglia riprovare più tardi."
                        en="Please try again later."
                    />
                </p>
                <p>
                    <Localized
                        de="Wenn das Problem weiterhin auftritt, melden Sie sich bei unserem Helpdesk."
                        fr="Si le problème persiste, merci de l’annoncer à notre Helpdesk."
                        it="Se il problema dovesse persistere, contatti il nostro Helpdesk."
                        en="If the problem persists, please contact our Helpdesk."
                    />
                </p>
            </ModalErrorBox>
        );
    }
}
