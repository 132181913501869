import { localState, selectState, Store } from 'dg-web-shared/lib/Flux';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { Localized } from '../../common/components/Localized';
import {
    portalSlideIn,
    PortalSlideInProps,
} from '../root/components/PortalSlidein';
import { VehicleDetailProps } from './VehicleDetail';
import { VehicleSlideInForm } from './VehicleSlideInForm';
import { UpdateVehicle } from './VehicleState';

export const VehicldeDescriptionEdit = portalSlideIn<VehicleDetailProps>(
    localState<
        VehicleDetailProps & PortalSlideInProps,
        { description: string }
    >(
        p => ({ description: p.vehicle.description || '' }),
        selectState(
            store => ({ selectedVehicle: UpdateVehicle.get(store) }),
            p => (
                <VehicleSlideInForm
                    disabledPrimary={p.state.description.length === 0}
                    onCancel={p.onClose}
                    onSave={() => {
                        p.update((store: Store) =>
                            UpdateVehicle.put(
                                store,
                                {
                                    ...p.vehicle,
                                },
                                {
                                    ...p.vehicle,
                                    description: p.state.description,
                                },
                                () => p.onClose(),
                            ),
                        );
                    }}
                    selectedVehicle={p.selectedVehicle}
                >
                    <TextField
                        labelText={
                            <Localized
                                de="Beschreibung"
                                fr="Description"
                                it="Descrizione"
                                en="Description"
                            />
                        }
                        value={p.state.description}
                        errorText={
                            p.selectedVehicle.status === 'error' &&
                            p.state.description.length === 0 ? (
                                <Localized
                                    de="Die Beschreibung darf nicht leer sein."
                                    fr="La description ne peut pas être vide."
                                    it="La descrizione non può essere vuota."
                                    en="The description cannot be empty."
                                />
                            ) : null
                        }
                        onChange={value => {
                            p.setState({ description: value });
                        }}
                    />
                </VehicleSlideInForm>
            ),
        ),
    ),
);
