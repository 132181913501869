import { css } from '@emotion/css';

import { InputContext, inputContextToColor } from '../InputContext';
import { Colors } from '../../../ui/vars';
import { defaultTransition } from '../../transitions';
import React from 'react';

export enum UnderlineStatus {
    blur = 1,
    focus,
    error,
    none,
}

interface Props {
    key?: React.Key;
    status: UnderlineStatus;
    context: InputContext;
}

function getUnderlineBlurStyle(status: UnderlineStatus, context: InputContext) {
    const color = inputContextToColor(context);
    return css({
        position: 'absolute',
        width: '100%',
        bottom: '4px',
        height: '1px',
        background:
            status === UnderlineStatus.none
                ? Colors.rgba('#000', 0)
                : Colors.rgba(color, 0.6),
    });
}

function getUnderlineFocusStyle(
    status: UnderlineStatus,
    context: InputContext,
) {
    const color = inputContextToColor(context);
    return css([
        {
            position: 'absolute',
            width: '100%',
            bottom: '4px',
            height: '2px',

            ...defaultTransition('transform'),
            transform: 'scaleX(1)',
            background:
                context === InputContext.activeblue
                    ? Colors.rgba(color, 0.6)
                    : Colors.rgba(color, 0.8),
        },
        status === UnderlineStatus.blur && {
            transform: 'scaleX(0)',
        },
        status === UnderlineStatus.none && {
            transform: 'scaleX(0)',
        },
        status === UnderlineStatus.error && [
            {
                background: Colors.rgba(Colors.error, 0.8),
                transform: 'scaleX(1)',
            },
            context === InputContext.inverted && {
                background: Colors.error_b,
            },
        ],
    ]);
}

export class Underline extends React.Component<Props> {
    render() {
        return (
            <div className={css({ height: '8px', position: 'relative' })}>
                <div
                    className={getUnderlineBlurStyle(
                        this.props.status,
                        this.props.context,
                    )}
                />
                <div
                    className={getUnderlineFocusStyle(
                        this.props.status,
                        this.props.context,
                    )}
                />
            </div>
        );
    }
}

export var Factory = React.createFactory<Props>(Underline);
