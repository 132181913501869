import * as Flux from '../../lib/Flux.tsx';
import * as superagent from 'superagent';
import { Maybe } from '../../lib/MaybeV2.ts';
import { Response, StatusCode } from '../../lib/HttpResponse.ts';

export interface State<D> {
    pending: boolean;
    shouldFetch: boolean;
    data: D;
    statusCode: StatusCode;
    attempts: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: Maybe<superagent.Request<any, any>>;
}

export abstract class LegacyServerStateSlice<D> extends Flux.StateSlice<
    State<D>
> {
    abstract getEmptyData(): D;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    abstract parseBody(body: any): D;

    getInitialState(): State<D> {
        return {
            pending: false,
            shouldFetch: true,
            data: this.getEmptyData(),
            statusCode: new StatusCode(null),
            attempts: 0,
            request: null,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    writeRequest(res: Response, ..._args: any[]): void {
        if (res.pending) {
            this.writePending(res.request);
        } else if (res.statusCode.cls.success) {
            this.writeSuccess(res);
        } else {
            const s = res.statusCode;
            this.writeError(res, !s.unauthorized && !s.forbidden);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected writePending(request: Maybe<superagent.Request<any, any>>): void {
        this.set((s: State<D>): State<D> => {
            s.pending = true;
            s.shouldFetch = false;
            s.data = this.getEmptyData();
            s.statusCode = new StatusCode(null);
            s.request = request;
            return s;
        });
    }

    protected writeSuccess(res: Response): void {
        this.set((s: State<D>): State<D> => {
            s.pending = false;
            s.shouldFetch = false;
            s.attempts = 0;
            s.data = res.body ? this.parseBody(res.body) : this.getEmptyData();
            s.statusCode = res.statusCode;
            return s;
        });
    }

    protected writeError(res: Response, retry: boolean): void {
        this.set((s: State<D>): State<D> => {
            s.pending = false;
            s.shouldFetch = retry ? s.attempts <= 3 : false;
            s.attempts += 1;
            s.data = this.getEmptyData();
            s.statusCode = res.statusCode;
            return s;
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function someArePendingOrWillBeFetched(...stati: State<any>[]): boolean {
    for (const s of stati) {
        if (s.pending || s.shouldFetch) {
            return true;
        }
    }
    return false;
}
