import { css } from '@emotion/css';

import { useEffect } from 'react';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    RequestStatus,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { Localized } from '../../../common/components/Localized';
import { Address } from './Address';
import { Helpdesk } from './Helpdesk';
import { LeftColumn, RightColumn, StaticPage } from './StaticPage';
import { InlineErrorBox, InlineInfoBox } from '../../../ui/modals/Confirmable';
import { useParams } from 'react-router';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export function ConfirmRequestedEmailPage() {
    const { store } = useStore(() => null);
    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}>
                <Content />
            </LeftColumn>
            <RightColumn allState={store}>
                <Helpdesk allState={store} />
                <Address allState={store} />
            </RightColumn>
        </StaticPage>
    );
}

function Content() {
    const { token } = useParams();

    const [httpPost, doHttpPost] = useServerWrite<{ token: string }, null>(
        () => ({
            url: '/ui-api/current-login/confirm-email',
        }),
    );

    useEffect(() => {
        if (httpPost.status === RequestStatus.NEVER_EXECUTED && token) {
            doHttpPost({ token });
        }
    }, [token]);

    return (
        <div className={css({ maxWidth: '480px', ...Typo.body })}>
            {((): JSX.Element => {
                switch (httpPost.status) {
                    case RequestStatus.NEVER_EXECUTED:
                    case RequestStatus.PENDING:
                        return <Spinner />;

                    case RequestStatus.ERROR:
                        return (
                            <Error httpStatusCode={httpPost.httpStatusCode} />
                        );

                    case RequestStatus.SUCCESS:
                        return <Success />;
                }
            })()}
        </div>
    );
}

function Error(props: { httpStatusCode: number }) {
    return (
        <InlineErrorBox
            titleCaption={
                <Localized
                    de="Es ist ein Fehler aufgetreten."
                    fr="Une erreur est survenue."
                    it="Si è verificato un errore."
                    en="An error has occurred."
                />
            }
        >
            <p>
                <Localized
                    de="Die Überprüfung des Bestätigungstokens ist fehlgeschlagen."
                    fr="La vérification du jeton de confirmation a échoué."
                    it="La verifica del gettone di conferma è fallita."
                    en="The confirmation token verification failed."
                />
            </p>

            <p>HTTP {props.httpStatusCode}</p>
        </InlineErrorBox>
    );
}

function Success() {
    return (
        <InlineInfoBox
            titleCaption={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
        >
            <p>
                <Localized
                    de="Die neue E-Mail-Adresse wurde bestätigt."
                    fr="La nouvelle adresse e-mail a été confirmée."
                    it="Il nuovo indirizzo e-mail è stato confermato."
                    en="The new e-mail address has been confirmed."
                />
            </p>
        </InlineInfoBox>
    );
}
