import { css } from '@emotion/css';

import { selectState } from 'dg-web-shared/lib/Flux';
import * as PaymentTypeState from '../../../common/state/PaymentTypeState';
import { portalSlideIn } from '../../../account/root/components/PortalSlidein';
import { OnlinePaymentSelection } from '../../../account/top-up-balance/ActionableItem';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import { SelectedPaymentFlow } from '../../../common/payment/Payment';
import { logAction } from '../../../utils/ActionLog';
import {
    SavedCreditCardAliasResponse,
    SavedCreditCardAliasResponseTag,
} from 'dg-web-shared/model/PaymentAlias';
import { PaymentOriginType } from './AddPaymentMethodParkCreateMenu';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const AddPaymentMethodParkCreateMenuSlideIn = portalSlideIn(
    AddPaymentMethodParkCreateMenuStatelessWrapper,
);

function AddPaymentMethodParkCreateMenuStatelessWrapper(props: Props) {
    return <AddPaymentMethodParkCreateMethods {...props} />;
}

const AddPaymentMethodParkCreateMethods = selectState<
    Props,
    {
        paymentType: PaymentTypeState.State;
        loginState: CurrentLoginState.State;
    }
>(
    store => ({
        paymentType: new PaymentTypeState.StateSlice(store).state,
        loginState: CurrentLoginState.get(store),
    }),
    props => {
        function nav(category: SelectedPaymentFlow) {
            props.update(store =>
                logAction(
                    store,
                    `top-up-from-${props.contractType}-navigation`,
                    category,
                ),
            );

            props.setActivePaymentCategory(category);
        }

        const alias =
            props.aliasData.tag === SavedCreditCardAliasResponseTag.FOUND
                ? props.aliasData.creditCardAlias
                : null;

        return (
            <div
                className={css({
                    ...Typo.robotoRegular,
                })}
            >
                <OnlinePaymentSelection
                    currentAlias={alias}
                    onClick={(p: SelectedPaymentFlow) => nav(p)}
                />
            </div>
        );
    },
);

interface Props {
    contractType: PaymentOriginType;
    aliasData: SavedCreditCardAliasResponse;
    setActivePaymentCategory: (c: SelectedPaymentFlow) => void;
    onClose: () => void;
}
