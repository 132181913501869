import { AuthStatusType, useAuthState } from '../state/AuthState';
import { isRunningInNativeWrapper } from '../../Native';
import { Navigate, useParams } from 'react-router-dom';
import { SpinnerAppLoad } from 'dg-web-shared/pc-ui/md/layout/SpinnerAppLoad.tsx';

export function DeepLinkTicketConsumer() {
    const { ticketString } = useParams<{ ticketString: string }>();
    const { authStatus } = useAuthState();

    if (isRunningInNativeWrapper() || authStatus === AuthStatusType.OK) {
        return <Navigate to={`/ticket/${ticketString}`} replace />;
    }

    if (authStatus === AuthStatusType.NOT_OK) {
        return (
            <Navigate to={`/quickcheckout/ticket/${ticketString}`} replace />
        );
    }

    return <SpinnerAppLoad />;
}

export function DeepLinkPermittypeConsumer() {
    const { permitUrlIdentification } = useParams<{
        permitUrlIdentification: string;
    }>();
    const { authStatus } = useAuthState();

    if (isRunningInNativeWrapper() || authStatus === AuthStatusType.OK) {
        return (
            <Navigate to={`/permittype/${permitUrlIdentification}`} replace />
        );
    }

    if (authStatus === AuthStatusType.NOT_OK) {
        return (
            <Navigate
                to={`/quickcheckout/permittype/${permitUrlIdentification}`}
                replace
            />
        );
    }

    return <SpinnerAppLoad />;
}
