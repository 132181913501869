import { FlowStateTicketBooked } from './QuickCheckoutTypes';
import { TicketPlusTicketBooked } from '../../common/components/ticketplus/TicketPlusTicketBooked';
import { Localized } from '../../common/components/Localized';
import styled from '@emotion/styled';
import { Colors } from 'dg-web-shared/ui/vars';
import { useNavigate } from 'react-router-dom';
import { Typo } from 'dg-web-shared/ui/typo.ts';

const ReceiptDownloadLink = styled.a({
    display: 'block',
    textDecoration: 'underline',
    color: Colors.white,
    ...Typo.body,
    marginTop: 20,
});

export function QuickCheckoutFlowTicketBooked({
    flowState,
}: {
    flowState: FlowStateTicketBooked;
}) {
    const { ticket, zoneInfo } = flowState;
    const navigate = useNavigate();
    return (
        <TicketPlusTicketBooked
            {...ticket}
            zone={zoneInfo.name}
            city={zoneInfo.city}
            operator={zoneInfo.operatorName}
            onOpenZoneInfo={() => navigate('parking-details')}
            additionalContent={
                <ReceiptDownloadLink
                    href={`/ui-api/quickcheckout/receipt/pdf/${ticket.ticketId}`}
                    target="_blank"
                    className=""
                >
                    <Localized
                        de="Quittung herunterladen"
                        fr="Télécharger le reçu"
                        it="Scarica la ricevuta"
                        en="Download receipt"
                    />
                </ReceiptDownloadLink>
            }
        />
    );
}
