import * as superagent from 'superagent';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { Store } from 'dg-web-shared/lib/Flux';
import { ResponseParameters } from '../../../common/payment/Payment';
import * as CommonQueryActions from '../../../common/actions/CommonQueryActions';
import {
    navigateToPermitPurchaseConfirmation,
    refreshStateSlicesOnSuccessfulPermitPurchase,
} from '../../../park-create/actions/PermitActions';
import {
    PairingState,
    PendingTwintPairing,
    transactionFromUoFState,
    TwintUoFStateResponse,
} from 'dg-web-shared/common/utils/TwintPairing';
import {
    TwintParkingpayPaymentStates,
    TwintParkingpayPendingState,
    TwintParkingpayUofStates,
    UiPaymentOrigin,
} from 'dg-web-shared/common/utils/TwintPairingParkingpay';
import { useServerFetch } from '../../../hooks/ServerStateHooks';

export const api = `/ui-api/customer-account/twint`;

export function useRequests(
    mode: PendingTwintPairing.TwintOrderMethodType,
    requestCounter: number,
    token: string | null,
    paymentAttemptId: number | null,
): TwintParkingpayPendingState {
    switch (mode) {
        case PendingTwintPairing.TwintOrderMethodType.ORDER:
        case PendingTwintPairing.TwintOrderMethodType.COMBINED:
            return useTwintPaymentState(
                token,
                paymentAttemptId,
                requestCounter,
                mode,
            );
        case PendingTwintPairing.TwintOrderMethodType.UOF:
            return useTwintUoFState(token, paymentAttemptId, requestCounter);
        default:
            throw new Error('Invalid twint pairing mode ' + mode);
    }
}

type TwintUofStateResponseTypes =
    | TwintUofStateResponseBase
    | TwintTicketUofStateResponse;

interface TwintParkingpayUofStateResponse extends TwintUoFStateResponse {
    uiPaymentOrigin: UiPaymentOrigin;
}

interface TwintUofStateResponseBase extends TwintParkingpayUofStateResponse {
    uiPaymentOrigin:
        | UiPaymentOrigin.STORE_ALIAS_FROM_CHECKIN
        | UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT;
}

interface TwintTicketUofStateResponse extends TwintParkingpayUofStateResponse {
    uiPaymentOrigin: UiPaymentOrigin.STORE_ALIAS_FROM_TICKET;
    ticketString: string;
}

function useTwintUoFState(
    token: string | null,
    paymentAttemptId: number | null,
    requestCounter: number,
): TwintParkingpayUofStates {
    const [uofData] = useServerFetch<
        TwintUofStateResponseTypes,
        { token: string | null; paymentAttemptId: number | null; count: number }
    >(
        ({ token, paymentAttemptId }) => ({
            url: `${api}-alias/state?pairingToken=${token}&paymentAttemptId=${paymentAttemptId}`,
        }),
        {
            token: token,
            paymentAttemptId: paymentAttemptId,
            count: requestCounter,
        },
    );

    const data = uofData.data;

    if (uofData.status === RequestStatus.ERROR || !data) {
        return {
            mode: PendingTwintPairing.TwintOrderMethodType.UOF,
            state: PairingState.UNKNOWN,
            requestState: uofData.status,
        };
    } else {
        switch (data.uiPaymentOrigin) {
            case UiPaymentOrigin.STORE_ALIAS_FROM_TICKET: {
                return {
                    mode: PendingTwintPairing.TwintOrderMethodType.UOF,
                    state: transactionFromUoFState(data.state),
                    requestState: uofData.status,
                    uiPaymentOrigin: data.uiPaymentOrigin,
                    ticketString: data.ticketString,
                };
            }

            case UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT:
            case UiPaymentOrigin.STORE_ALIAS_FROM_CHECKIN:
                return {
                    mode: PendingTwintPairing.TwintOrderMethodType.UOF,
                    state: transactionFromUoFState(data.state),
                    requestState: uofData.status,
                    uiPaymentOrigin: data.uiPaymentOrigin,
                };
            default:
                throw new Error(
                    'Invalid store alias payment origin ' +
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (data as any)?.uiPaymentOrigin,
                );
        }
    }
}

enum TwintTransactionState {
    UNKNOWN = 'UNKNOWN',
    CREATED = 'CREATED',
    AUTHORIZED = 'AUTHORIZED',
    SETTLED = 'SETTLED',
    ABORTED = 'ABORTED',
}

type TwintTransacitonStateResponseTypes =
    | TwintTransactionStateResponseBase
    | TwintPermitTransactionStateResponse
    | TwintTicketTransactionStateResponse;

interface TwintTransactionStateResponse {
    state: TwintTransactionState;
    token: number;
    amount: string;
    uiPaymentOrigin: UiPaymentOrigin;
}

interface TwintTransactionStateResponseBase
    extends TwintTransactionStateResponse {
    uiPaymentOrigin:
        | UiPaymentOrigin.TOPUP_FROM_ACCOUNT
        | UiPaymentOrigin.TOPUP_FROM_CHECKIN;
}

interface TwintPermitTransactionStateResponse
    extends TwintTransactionStateResponse {
    uiPaymentOrigin: UiPaymentOrigin.TOPUP_FROM_PERMIT;
    permitId: number;
}

interface TwintTicketTransactionStateResponse
    extends TwintTransactionStateResponse {
    uiPaymentOrigin: UiPaymentOrigin.TOPUP_FROM_TICKET;
    ticketString: string;
}

function useTwintPaymentState(
    token: string | null,
    paymentAttemptId: number | null,
    requestCounter: number,
    mode:
        | PendingTwintPairing.TwintOrderMethodType.ORDER
        | PendingTwintPairing.TwintOrderMethodType.COMBINED,
): TwintParkingpayPaymentStates {
    const [pairingData] = useServerFetch<
        TwintTransacitonStateResponseTypes,
        {
            token: string | null;
            paymentAttemptId: number | null;
            count: number;
            mode: PendingTwintPairing.TwintOrderMethodType;
        }
    >(
        ({ token, paymentAttemptId, mode }) => ({
            url: `${api}/transaction-state?pairingToken=${token}&paymentAttemptId=${paymentAttemptId}&mode=${mode}`,
        }),
        {
            token: token,
            paymentAttemptId: paymentAttemptId,
            count: requestCounter,
            mode: mode,
        },
    );

    const data = pairingData.data;

    if (pairingData.status === RequestStatus.ERROR || !data) {
        return {
            mode: mode,
            state: PairingState.UNKNOWN,
            requestState: pairingData.status,
        };
    } else {
        switch (data.uiPaymentOrigin) {
            case UiPaymentOrigin.TOPUP_FROM_PERMIT:
                return {
                    mode: mode,
                    state: pairingStateFromTransactionState(data.state),
                    requestState: pairingData.status,
                    amount: data.amount,
                    uiPaymentOrigin: data.uiPaymentOrigin,
                    permitId: data.permitId,
                };
            case UiPaymentOrigin.TOPUP_FROM_ACCOUNT:
            case UiPaymentOrigin.TOPUP_FROM_CHECKIN:
                return {
                    mode: mode,
                    state: pairingStateFromTransactionState(data.state),
                    requestState: pairingData.status,
                    amount: data.amount,
                    uiPaymentOrigin: data.uiPaymentOrigin,
                };
            case UiPaymentOrigin.TOPUP_FROM_TICKET:
                return {
                    mode: mode,
                    state: pairingStateFromTransactionState(data.state),
                    requestState: pairingData.status,
                    amount: data.amount,
                    uiPaymentOrigin: data.uiPaymentOrigin,
                    ticketString: data.ticketString,
                };
            default:
                throw new Error(
                    'Invalid topup payment origin ' +
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (data as any)?.uiPaymentOrigin,
                );
        }
    }
}

const pairingStateFromTransactionState = (state: TwintTransactionState) => {
    switch (state) {
        case TwintTransactionState.ABORTED:
            return PairingState.ABORTED;
        case TwintTransactionState.UNKNOWN:
            return PairingState.UNKNOWN;
        case TwintTransactionState.CREATED:
            return PairingState.CREATED;
        case TwintTransactionState.AUTHORIZED:
            return PairingState.CONFIRMED_BY_USER;
        case TwintTransactionState.SETTLED:
            return PairingState.SUCCESS;
    }
};

export function twintAbortRequest(
    pairingToken: string | null,
    paymentAttemptId: number | null,
    mode: PendingTwintPairing.TwintOrderMethodType,
) {
    switch (mode) {
        case PendingTwintPairing.TwintOrderMethodType.COMBINED:
        case PendingTwintPairing.TwintOrderMethodType.ORDER:
            return superagent.post(`${api}/abort-transaction`).send({
                pairingToken: pairingToken,
                paymentAttemptId: paymentAttemptId,
                mode: mode,
            });
        case PendingTwintPairing.TwintOrderMethodType.UOF:
            return superagent.post(`${api}-alias/abort`).send({
                pairingToken: pairingToken,
                paymentAttemptId: paymentAttemptId,
            });
    }
}

export function settleTwint(
    store: Store,
    token: string | null,
    paymentAttemptId: number | null,
    refetchTransactionList: () => void,
    mode: PendingTwintPairing.TwintOrderMethodType,
) {
    superagent
        .post('/ui-api/customer-account/twint/settle-transaction')
        .send({
            pairingToken: token,
            paymentAttemptId: paymentAttemptId,
            mode: mode,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .end((_err: any, res: superagent.Response<ResponseParameters>) => {
            // re-fetch the balance
            store.legacyUpdater(CommonQueryActions.receiveBalance);

            // navigate to new permit
            const permitId = res?.body?.permitId;
            if (permitId) {
                refetchTransactionList();
                refreshStateSlicesOnSuccessfulPermitPurchase(store);
                navigateToPermitPurchaseConfirmation(store, permitId, true);
            }
        });
}
