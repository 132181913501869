import { portalSlideIn } from '../../account/root/components/PortalSlidein';
import { Localized } from '../../common/components/Localized';
import { css } from '@emotion/css';
import { AspectRatio } from 'dg-web-shared/common/components/AspectRatio';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { TicketLayout } from '../../common/components/ticketplus/TicketPlusLayout';
import { Colors } from 'dg-web-shared/ui/vars';
import { useState } from 'react';
import ticketPlusImg from '../../common/assets/ticket-plus-2x.png';

export const TicketScanDevSlideIn = portalSlideIn<{
    onTicketScan: (qrCodeString: string) => void;
}>(TicketQrScanner);

function TicketQrScanner({
    onTicketScan,
}: {
    onTicketScan: (qrCodeString: string) => void;
}) {
    const [ticketString, setTicketString] = useState('');
    return (
        <TicketLayout type="ticketplus">
            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    flexShrink: 1,
                    padding: '28px',
                    overflow: 'auto',
                    fontSize: '16px',
                    '& > *': {
                        marginBottom: '16px',
                        '&:last-child': {
                            marginBottom: 0,
                        },
                    },
                })}
            >
                <div>
                    <Localized
                        de="Bezahlen Sie das Parking Ticket bequem, ohne den Gang zur Kasse."
                        fr="Payez votre ticket de parking facilement, sans passer par la caisse."
                        it="Paghi il ticket di parcheggio comodamente, senza doversi recare alla cassa."
                        en="Pay the parking ticket conveniently, without going to the cash desk."
                    />
                </div>
                <div
                    className={css({
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexShrink: 0,
                    })}
                >
                    <img
                        src={ticketPlusImg}
                        className={css({
                            width: '140px',
                            maxWidth: '80%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        })}
                    />
                </div>
                <div>
                    <Localized
                        de="Scannen Sie den QR-Code ein und folgen Sie den Anweisungen."
                        fr="Scannez le code QR et suivez les instructions."
                        it="Scansioni il codice QR e segua le istruzioni."
                        en="Scan the QR code and follow the instructions."
                    />
                </div>
                <AspectRatio ratio={1}>
                    <form
                        onSubmit={event => {
                            event.preventDefault();
                            onTicketScan(ticketString);
                        }}
                        className={css({
                            background: Colors.white,
                            padding: 16,
                            borderRadius: 3,
                        })}
                    >
                        <TextField
                            labelText={'Ticket-String'}
                            value={ticketString}
                            autoCorrect="off"
                            onChange={ticketString => {
                                setTicketString(ticketString);
                            }}
                        />
                        <button type="submit">Confirm</button>
                    </form>
                </AspectRatio>
                <div>
                    <Localized
                        de="In Parkings, die diese Möglichkeit anbieten, finden Sie das Parkingpay-Logo und einen QR-Code auf dem Ticket."
                        fr="Dans les parkings qui offrent cette possibilité, vous trouverez le logo Parkingpay et un code QR sur le ticket."
                        it="Nei parcheggi che offrono questa possibilità, troverà il logo Parkingpay e un codice QR sul ticket."
                        en="In parking facilities that offer this option, you will find the Parkingpay logo and a QR code on the ticket."
                    />
                </div>
            </div>
        </TicketLayout>
    );
}
