import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';
import { BasicButtonStyle } from 'dg-web-shared/tb-ui/buttons/BasicButton.tsx';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import React from 'react';

export interface ButtonProps {
    onClick: ClickHandler | null;
    label: React.ReactNode;
    disabled?: boolean;
    negative?: boolean;
    primary?: boolean;
    link?: string;
    className?: string;
}

interface State {
    pressed: boolean;
}

export class MaterialButton extends React.Component<ButtonProps, State> {
    state: State = { pressed: false };

    getDepth(): number {
        if (this.props.disabled) {
            return 0;
        }
        if (this.state.pressed) {
            return 3;
        }
        return 2;
    }

    render(): JSX.Element {
        const p = this.props;
        return (
            <Clickable
                element={p.link ? 'a' : 'button'}
                target={p.link && '_blank'}
                href={p.link || undefined}
                className={css([
                    BasicButtonStyle,
                    {
                        ...Typo.navbutton,
                        fontSize: '16px',
                        minWidth: '90px',
                    },
                    p.className,
                ])}
                onClick={p.onClick}
                onClickStart={(): void => this.setState({ pressed: true })}
                onClickEnd={(): void => this.setState({ pressed: false })}
                data-negative={p.negative || false}
                data-dark={p.primary || false}
                data-disabled={p.disabled || false}
                data-elevation={this.getDepth()}
                disabled={p.disabled}
            >
                {p.label}
            </Clickable>
        );
    }
}
