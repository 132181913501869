import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { account, home } from 'dg-web-shared/ui/icons/Icons24';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { Updater, useStore } from 'dg-web-shared/lib/Flux';
import { CitiesState } from '../../park-create/components/ParkOptionList';
import { GeolocationState } from '../../common/state/GeolocationState';
import { screenHeightPercentile } from './LandingPageVars';
import { Localized } from '../../common/components/Localized';
import { HasEverLoggedInState } from './HasEverLoggedInState';
import React from 'react';
import { Icon24 } from 'dg-web-shared/ui/icons/Icon';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import ppLogoLarge from '../../../assets/parkingpay-center-neg.svg';
import logo from '../../../assets/parkingpay_icon.svg';

const TRANSITION_TIME = 350;

interface MobileLandingPageHeaderProps {
    viewportHeight: number;
    scrollTop: number;
    everScrolled: boolean;
    bigLoginPossible: boolean;
    hasEverLoggedIn: boolean;
    isStandAlone?: boolean;
}

const goToRoot = () => {
    window.location.href = '/';
};

const getLoginClickHandler =
    (update: Updater, isStandAlone?: boolean) => () => {
        if (isStandAlone) {
            return goToRoot();
        }
        update(store =>
            HasEverLoggedInState.stateWrite(store, {
                hasEver: true,
            }),
        );
    };

const getHomeClickHandler = (update: Updater, isStandAlone?: boolean) => () => {
    if (isStandAlone) {
        return goToRoot();
    }
    update(store =>
        HasEverLoggedInState.stateWrite(store, {
            hasEver: false,
        }),
    );
};

export function MobileLandingPageHeader({
    viewportHeight,
    scrollTop,
    everScrolled,
    bigLoginPossible,
    hasEverLoggedIn,
    isStandAlone,
}: MobileLandingPageHeaderProps) {
    const scrolled =
        !!isStandAlone || scrollTop > 20 || everScrolled || hasEverLoggedIn;
    const bigLogin = !scrolled && !hasEverLoggedIn && bigLoginPossible;

    const { update } = useStore(s => {
        const location = GeolocationState.get(s);
        return {
            cities: CitiesState.get(s, {
                // used for prefetching
                location: location.currentLocation,
                isAuthed: false,
            }),
        };
    });

    return (
        <div
            className={css({
                height:
                    everScrolled || hasEverLoggedIn || isStandAlone
                        ? 64
                        : `${Math.max(
                              64,
                              screenHeightPercentile(viewportHeight, 1 / 3) -
                                  (scrollTop - 10 || 0) -
                                  35,
                          )}px`,

                flexShrink: 0,
                background: Colors.blue,
                transition: 'height 7ms',
                display: 'flex',
                position: 'relative',
                justifyContent: scrolled ? 'space-between' : 'center',
                alignItems: scrolled ? 'flex-start' : 'center',
                willChange: 'height',
            })}
        >
            <Logo scrolled={scrolled} />
            {!hasEverLoggedIn ? (
                <MobileLoginButton
                    bigLogin={bigLogin}
                    handleClick={getLoginClickHandler(update, isStandAlone)}
                />
            ) : (
                <HomeButton
                    handleClick={getHomeClickHandler(update, isStandAlone)}
                />
            )}
        </div>
    );
}

interface DesktopLandingPageHeaderProps {
    hasEverLoggedIn: boolean;
    update: Updater;
    isStandAlone?: boolean;
}

export function DesktopLandingPageHeader({
    hasEverLoggedIn,
    update,
    isStandAlone,
}: DesktopLandingPageHeaderProps) {
    const buttonStyles = css({
        display: 'flex',
        ...Typo.robotoBold,
        fontSize: '16px',
        alignItems: 'flex-end',
    });
    return (
        <div
            className={css({
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                top: 0,
                left: 0,
                right: 0,
                height: '85px',
                opacity: 0.88,
                padding: '0 64px',
                background: Colors.blue,
                color: Colors.white,
            })}
        >
            <img src={logo} width={'auto'} height={'50px'} />
            {hasEverLoggedIn ? (
                <Clickable
                    element="div"
                    onClick={getHomeClickHandler(update, isStandAlone)}
                    className={buttonStyles}
                >
                    <Icon24 icon={home} />
                    <div className={css({ marginLeft: '4px' })}>
                        <Home />
                    </div>
                </Clickable>
            ) : (
                <Clickable
                    element="div"
                    onClick={getLoginClickHandler(update, isStandAlone)}
                    className={buttonStyles}
                >
                    <Icon24 icon={account} />
                    <div className={css({ marginLeft: '4px' })}>
                        <Login />
                    </div>
                </Clickable>
            )}
        </div>
    );
}

const MobileLoginButton = (p: {
    bigLogin: boolean;
    handleClick: () => void;
}) => {
    return (
        <Clickable
            element="div"
            className={css({
                position: 'absolute',
                top: '8px',
                right: 0,
                color: Colors.white,
                paddingTop: '12px',
                paddingLeft: '6px',
                paddingRight: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            })}
            onClick={p.handleClick}
        >
            <div
                className={css({
                    transform: p.bigLogin ? 'scale(1.5)' : 'none',
                })}
            >
                <Icon24 icon={account} />
            </div>
            {p.bigLogin && (
                <div
                    className={css({
                        ...Typo.robotoBold,
                        fontSize: '14px',
                        letterSpacing: '0.3px',
                        marginTop: '6px',
                        textAlign: 'center',
                    })}
                >
                    <Login />
                </div>
            )}
        </Clickable>
    );
};

const Login = () => {
    return <Localized de="Anmelden" fr="Connexion" it="Accesso" en="Login" />;
};

const Home = () => {
    return <Localized de="Home" fr="Home" it="Home" en="Home" />;
};

const HomeButton = ({ handleClick }: { handleClick: () => void }) => {
    return (
        <Clickable
            element="div"
            onClick={handleClick}
            className={css({
                position: 'absolute',
                top: '8px',
                right: 0,
                color: Colors.white,
                paddingTop: '10px',
                paddingLeft: '6px',
                paddingRight: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            })}
        >
            <Icon24 icon={home} />
        </Clickable>
    );
};

interface LogoProps {
    scrolled: boolean;
}

class Logo extends React.Component<LogoProps, { transitioning: boolean }> {
    _timeout = null;

    constructor(p: LogoProps) {
        super(p);
        this.state = { transitioning: false };
    }

    UNSAFE_componentWillReceiveProps(nextProps: LogoProps) {
        if (this.props.scrolled !== nextProps.scrolled) {
            this.setState({ transitioning: true });
            setTimeout(() => {
                this.setState({ transitioning: false });
            }, TRANSITION_TIME);
        }
    }

    render() {
        return (
            <>
                {this.props.scrolled && (
                    <img
                        src={logo}
                        width={'auto'}
                        height={'40px'}
                        className={css({
                            opacity: this.state.transitioning ? 0 : 1,
                            transition: 'opacity 1000ms',
                            marginLeft: '10px',
                            marginTop: '12px',
                            willChange: 'opacity',
                        })}
                    />
                )}
                {!this.props.scrolled && (
                    <img
                        src={ppLogoLarge}
                        width={'auto'}
                        height={'95%'}
                        className={css({
                            opacity: this.state.transitioning ? 0 : 1,
                            transition: 'opacity 1000ms',
                            willChange: 'opacity',
                        })}
                    />
                )}
            </>
        );
    }
}
