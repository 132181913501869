import { Localized } from '../components/Localized';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const SINGLE_TRANSACTION_COST = 25;

export function AdditionalCosts() {
    return (
        <div
            className={css({
                margin: '16px 0',
                ...Typo.robotoRegular,
            })}
        >
            <div className={css({ ...Typo.robotoBold })}>
                <Localized
                    de={`Zuschlag: ${SINGLE_TRANSACTION_COST} Rp.`}
                    fr={`Supplément: ${SINGLE_TRANSACTION_COST} ct.`}
                    it={`Supplemento: ${SINGLE_TRANSACTION_COST} ct.`}
                    en={`Surcharge: ${SINGLE_TRANSACTION_COST} ct.`}
                />
            </div>
            <div>
                <Localized
                    de="(nur Belastungen kleiner als CHF 20)"
                    fr="(uniquement pour les charges inférieurs à 20 CHF)"
                    it="(solo per addebiti inferiori a CHF 20)"
                    en="(only for charges below CHF 20)"
                />
            </div>
        </div>
    );
}
