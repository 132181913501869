import { useStore } from 'dg-web-shared/lib/Flux';
import { addBadgeTexts } from '../../account-setup/i18n/AddBadgeTexts';
import { Localized } from '../../common/components/Localized';
import * as BadgeTypesState from '../../common/state/BadgeTypesState';
import * as SettingsState from '../../common/state/SettingsState';
import {
    ParkingBadgeImage,
    ParkingBadgeImageSize,
} from '../../pages/home-unauthenticated/components/ParkingBadge';
import {
    portalSlideIn,
    PortalSlideInProps,
} from '../root/components/PortalSlidein';
import { VehicleAddBadge } from './VehicleAddBadge';
import { VehicleDetailProps } from './VehicleDetail';
import { UpdateVehicle } from './VehicleState';
import { VehicleListElement } from './VehiclesList';
import { ActionBlock } from '../root/components/ActionBlock';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import {
    ArrowPosition,
    InlineAlertBox,
    ModalDeleteQuestionBox,
} from '../../ui/modals/Confirmable';
import { stringToFourCharacterBlocks } from 'dg-web-shared/lib/StringConversions';
import { FieldItemBlock } from '../root/components/ItemBlock';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { updateTransactionlistWarnings } from '../../transactions-list/state/TransactionsListState';
import { logAction } from '../../utils/ActionLog';
import { useTransactionListRefetch } from '../../transactions-list/TransactionsListContext';
import { useState } from 'react';

export const VehicleBadgeDetail = portalSlideIn<
    VehicleDetailProps & {
        language: string;
    }
>(VehicleBadgeDetailSlideIn);

function VehicleBadgeDetailSlideIn({
    portal,
    texts,
    vehicle,
    language,
    onClose,
}: PortalSlideInProps &
    VehicleDetailProps & {
        language: string;
    }) {
    const { storeState, update } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        badgeTypes: BadgeTypesState.List.get(s),
        updateVehicle: UpdateVehicle.get(s),
    }));
    const refetchTransactionList = useTransactionListRefetch();
    const [deleteBadge, setDeleteBadge] = useState(false);
    const [replaceBadge, setReplaceBadge] = useState(false);

    return (
        <>
            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '24px 0px 30px',
                    alignItems: 'center',
                    borderBottom: `1px solid ${Colors.lightgrey}`,
                })}
            >
                <ParkingBadgeImage
                    imageSize={ParkingBadgeImageSize.LARGE}
                    noFloat
                />
            </div>
            <VehicleListElement>
                <FieldItemBlock
                    onClick={null}
                    label={addBadgeTexts[language].badgeNrLabel()}
                    content={
                        vehicle.badgeLabelNr
                            ? stringToFourCharacterBlocks(vehicle.badgeLabelNr)
                            : texts.badgeOrdered()
                    }
                />
                <ActionBlock
                    title={
                        <Localized
                            de="Badge ersetzen"
                            fr="Remplacer badge"
                            it="Sostituisci badge"
                            en="Replace badge"
                        />
                    }
                    icon="replace"
                    onClick={() => {
                        update(store => {
                            logAction(store, 'badge-replace', vehicle);
                            return UpdateVehicle.reset(store);
                        });
                        setReplaceBadge(true);
                    }}
                />
                <ActionBlock
                    title={
                        <Localized
                            de="Badge löschen"
                            fr="Supprimer badge"
                            it="Eliminare badge"
                            en="Delete badge"
                        />
                    }
                    icon="delete"
                    onClick={() => {
                        update(store => UpdateVehicle.reset(store));
                        setDeleteBadge(true);
                    }}
                />
                <div style={{ width: '100%' }}>
                    {vehicle.runningBadgeContractOperatorNames.length > 0 && (
                        <BadgeIsInUseWarning
                            operatorNames={
                                vehicle.runningBadgeContractOperatorNames
                            }
                        />
                    )}
                </div>
            </VehicleListElement>
            <VehicleAddBadge
                portal={portal}
                texts={texts}
                vehicle={vehicle}
                emptyBadge={true}
                title={
                    <Localized
                        de="Badge ersetzen"
                        fr="Remplacer badge"
                        it="Sostituisci badge"
                        en="Replace badge"
                    />
                }
                onClose={() => setReplaceBadge(false)}
                onSuccess={() => onClose()}
                open={replaceBadge}
            />
            {deleteBadge && !!vehicle && (
                <ModalDeleteQuestionBox
                    titleCaption={
                        <Localized
                            de="Badge löschen"
                            fr="Supprimer badge"
                            it="Eliminare badge"
                            en="Delete badge"
                        />
                    }
                    confirmCallback={() =>
                        update(store =>
                            UpdateVehicle.deleteBadge(store, vehicle, () => {
                                refetchTransactionList();
                                updateTransactionlistWarnings(update);
                                onClose();
                            }),
                        )
                    }
                    cancelCallback={() => {
                        setDeleteBadge(false);
                    }}
                    isPending={
                        storeState.updateVehicle.status !==
                        RequestStatus.NEVER_EXECUTED
                    }
                >
                    <p>
                        <Localized
                            de={`Wollen Sie wirklich der Badge ${
                                vehicle.badgeLabelNr || ''
                            } löschen?`}
                            fr={`Voulez-vous vraiment effacer le badge ${
                                vehicle.badgeLabelNr || ''
                            }?`}
                            it={`Vuole veramente eliminare il badge ${
                                vehicle.badgeLabelNr || ''
                            }?`}
                            en={`Do you really want to delete the badge ${
                                vehicle.badgeLabelNr || ''
                            }?`}
                        />
                    </p>
                </ModalDeleteQuestionBox>
            )}
        </>
    );
}

function BadgeIsInUseWarning(props: { operatorNames: string[] }) {
    const namesText = props.operatorNames.join(', ');

    return (
        <InlineAlertBox
            titleCaption={
                <Localized
                    de="Betreiber kontaktieren"
                    fr="Contacter l'exploitant"
                    it="Contattare il gestore"
                    en="Contact the operator"
                />
            }
            arrowPosition={ArrowPosition.left}
        >
            <p>
                <Localized
                    de={
                        <div>
                            Dieses Badge ist bei folgenden Betreibern
                            registriert (z.B. für eine Bewilligung oder einen
                            reduzierten Tarif): <b>{namesText}</b>.
                            <br />
                            <br />
                            Die Änderung des Badges hier in der App wird{' '}
                            <b>
                                NICHT automatisch an den Betreiber übermittelt
                            </b>
                            .
                            <br />
                            <br />
                            Wir bitten Sie daher, sich mit den oben genannten
                            Betreibern in Verbindung zu setzen, um die Änderung
                            in Auftrag zu geben.
                        </div>
                    }
                    fr={
                        <div>
                            Ce badge est enregistré (par exemple pour une
                            autorisation ou pour un tarif réduit) auprès des
                            exploitants suivants: <b>{namesText}</b>.
                            <br />
                            <br />
                            La modification du badge ici dans l&apos;app{' '}
                            <b>
                                n&apos;est PAS automatiquement transmise aux
                                exploitants
                            </b>
                            .
                            <br />
                            <br /> Nous vous prions donc de contacter les
                            exploitants mentionnés ci-dessus, en leur indiquant
                            le changement en question.
                        </div>
                    }
                    it={
                        <div>
                            Questo badge è registrato (p.es. per
                            un&apos;autorizzazione o per una tariffa agevolata)
                            presso i seguenti gestori: <b>{namesText}</b>.
                            <br />
                            <br />
                            La modifica del badge qui nell&apos;app{' '}
                            <b>
                                NON viene trasmessa automaticamente ai gestori
                            </b>
                            .
                            <br />
                            <br />
                            La preghiamo quindi di contattare i gestori
                            sopraindicati, segnalando la modifica in questione.
                        </div>
                    }
                    en={
                        <div>
                            This badge is registered (e.g. for a permit or for a
                            reduced tariff) by the following operators:{' '}
                            <b>{namesText}</b>.
                            <br />
                            <br />
                            The change of badge here in the app is{' '}
                            <b>
                                NOT automatically transmitted to the operators
                            </b>
                            .
                            <br />
                            <br />
                            Please contact the above mentioned operators and
                            notify them of the change.
                        </div>
                    }
                />
            </p>
        </InlineAlertBox>
    );
}
