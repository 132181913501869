import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars';
import { TICKET_SCAN_BAR_HEIGHT_PX } from '../../../park-create/ticket/ParkCreateTicketPlus';

export function FooterMobile({ children }: { children: React.ReactNode }) {
    return (
        <div
            className={css({
                display: 'flex',
                height: '48px',
                background: Colors.darkblue,
            })}
        >
            {children}
        </div>
    );
}

export function FooterMobileContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div
            className={css({
                position: 'absolute',
                bottom: 0,
                height: '48px',
                width: '100%',
            })}
        >
            {children}
        </div>
    );
}

export function FooterMobileNavigationContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div
            className={css({
                display: 'flex',
                paddingLeft: '13px',
            })}
        >
            {children}
        </div>
    );
}

export function FooterMobileLanguageSwitchContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div
            className={css({
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
                marginRight: '14px',
                paddingTop: '8px',
            })}
        >
            {children}
        </div>
    );
}

export function FooterMobileNavigationClickableItem({
    title,
    onClick,
}: {
    title: React.ReactNode;
    onClick?: ClickHandler;
}) {
    return (
        <Clickable
            element="div"
            className={css({
                ...Typo.caption,
                color: Colors.action_b,
                margin: '7px 7px 9px 0',
                padding: '0 11px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textDecoration: 'none',
            })}
            onClick={onClick && onClick}
        >
            {title}
        </Clickable>
    );
}

export function FooterScanButtonContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div
            className={css({
                ...Typo.robotoRegular,
                background: Colors.darkblue,
                position: 'absolute',
                bottom: 0,
                height: `${TICKET_SCAN_BAR_HEIGHT_PX}px`,
                width: '100%',
                border: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 16,
                fontWeight: 500,
            })}
        >
            {children}
        </div>
    );
}
