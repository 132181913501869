import { useStore } from 'dg-web-shared/lib/Flux';
import { isDefinedAndDiffersFrom } from 'dg-web-shared/lib/MaybeV2';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import * as Http from '../../api/Http';
import { Localized } from '../../common/components/Localized';
import * as SettingsState from '../../common/state/SettingsState';
import * as Fields from '../../utils/Fields';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import * as MetaServerState from './state/MetaServerState';
import { AccountMeta } from './state/MetaServerState';
import * as MetaState from './state/MetaState';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import { ModalErrorBox } from '../../ui/modals/Confirmable';
import { isValidCompanyUid } from '../payment-method/invoice/InvoiceRequestForm';
import {
    RequestMethod,
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { logAction } from '../../utils/ActionLog';
import { ApiError } from 'dg-web-shared/dto/ApiError.ts';
import { useState } from 'react';
import { AddressCountry } from 'dg-web-shared/dto/AddressCountry.ts';
import { Field } from 'dg-web-shared/model/Iban.ts';

export const CompanyEditFormSlideIn = portalSlideIn(CompanyEditForm);

function CompanyEditForm(props: {
    addressCountries: AddressCountry[];
    accountMeta: AccountMeta;
    onClose: () => void;
}) {
    const { storeState, store } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        meta: MetaState.getMetaState(s),
    }));

    const address = props.accountMeta.address;

    const [form, setForm] = useState<FormState>({
        company1: address.company1 || null,
        company2: address.company2 || null,
        companyUid: address.companyUid || null,
    });
    const [companyUidEdited, setCompanyUidEdited] = useState(false);

    const isSaveable =
        !storeState.meta.response.pending &&
        formValuesChanged(props.accountMeta.address, form) &&
        formIsValid(form);

    const [updateCompanyMetaState, fetchUpdateCompanyMeta] = useServerWrite<
        FormState,
        object,
        { message?: ApiError }
    >(() => ({
        url: `/ui-api/customer-account/meta/update-company`,
        method: RequestMethod.PUT,
    }));
    const [metaUpdateError, setMetaUpdateError] = useState(false);
    const isPending = updateCompanyMetaState.status === RequestStatus.PENDING;
    useServerSuccessEffect(updateCompanyMetaState, () => {
        logAction(store, 'update-account-meta', {
            ...props.accountMeta,
            address: {
                ...props.accountMeta.address,
                company1: form.company1 || undefined,
                company2: form.company2 || undefined,
            },
        });
        new MetaServerState.StateSlice(store).reset();
        onClose();
    });
    useServerErrorEffect(updateCompanyMetaState, (_, res) => {
        if (res?.message === 'COMPANY_UID_INVALID') {
            setMetaUpdateError(true);
        }
    });

    function onClose() {
        new MetaState.LocalStateSlice(store).reset();
        new MetaState.ResponseStateSlice(store).reset();
        props.onClose();
    }

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: onClose,
            }}
            primaryButton={{
                label: isPending ? (
                    <Localized
                        de="Speichern..."
                        fr="Enregistrer..."
                        it="Salva..."
                        en="Saving..."
                    />
                ) : (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                disabled: !isSaveable || isPending,
                onClick: () => {
                    fetchUpdateCompanyMeta({
                        company1: form.company1,
                        company2: form.company2,
                        companyUid: form.companyUid,
                    });
                },
            }}
        >
            <TextField
                context={InputContext.form}
                value={form.company1}
                tabIndex={0}
                name="company"
                autocomplete="organization"
                labelText={
                    <Localized
                        de="Firma"
                        fr="Entreprise"
                        it="Società"
                        en="Company"
                    />
                }
                errorText={mandatoryErrorText(
                    new Fields.GenericField(form.company1 || ''),
                )}
                onChange={(company1: string): void => {
                    setForm({ ...form, company1 });
                    setMetaUpdateError(false);
                }}
            />
            <TextField
                context={InputContext.form}
                value={form.company2}
                tabIndex={0}
                labelText={
                    <Localized
                        de="Zusatzzeile Firma"
                        fr="Ligne suppl. entreprise"
                        it="Riga suppl. società"
                        en="Add. row company"
                    />
                }
                onChange={(company2: string): void => {
                    setForm({ ...form, company2 });
                }}
            />
            <TextField
                context={InputContext.form}
                value={form.companyUid}
                tabIndex={0}
                labelText={
                    <Localized
                        de="Unternehmens-Identifikationsnummer (UID)"
                        fr="Numéro d’identification des entreprises (IDE)"
                        it="Numero d’identificazione delle imprese (IDI)"
                        en="Company identification number (UID)"
                    />
                }
                onChange={(companyUid: string): void => {
                    setForm({ ...form, companyUid });
                    setMetaUpdateError(false);
                }}
                errorText={
                    companyUidEdited &&
                    form.companyUid &&
                    !isValidCompanyUid(form.companyUid) ? (
                        <Localized
                            de="Ungültige UID"
                            fr="IDE pas valable"
                            it="IDI non valido"
                            en="Invalid UID"
                        />
                    ) : (
                        ''
                    )
                }
                onBlur={() => {
                    setCompanyUidEdited(true);
                    setForm({
                        ...form,
                        companyUid: form.companyUid
                            ? form.companyUid?.toUpperCase().trim()
                            : '',
                    });
                }}
            />
            {metaUpdateError && (
                <CompanyUidValidationError
                    onConfirm={() => setMetaUpdateError(false)}
                />
            )}
        </SlideInForm>
    );
}

export function CompanyUidValidationError({
    onConfirm,
}: {
    onConfirm: () => void;
}) {
    return (
        <ModalErrorBox
            confirmCallback={onConfirm}
            titleCaption={
                <Localized
                    de="Validierung fehlgeschlagen"
                    fr="Validation échouée"
                    it="Convalida fallita"
                    en="Validation failed"
                />
            }
        >
            <p>
                <Localized
                    de="Die Validierung vom Firmennamen und der UID ist fehlgeschlagen."
                    fr="La validation du nom de l'entreprise et de l'IDE a échouée."
                    it="La convalida del nome dell'azienda e dell'IDI non è riuscita."
                    en="The validation of the company name and UID failed."
                />
            </p>
            <p>
                <Localized
                    de="Stellen Sie sicher, dass der Firmenname korrekt ist und mit der angegebenen UID übereinstimmt."
                    fr="Assurez-vous que le nom de l'entreprise est correct et correspond à l'IDE fourni."
                    it="Assicurati che il nome dell'azienda sia corretto e corrisponda all'IDI fornito."
                    en="Make sure that the company name is correct and matches the UID provided."
                />
            </p>
        </ModalErrorBox>
    );
}

function formIsValid(form: FormState): boolean {
    const values = makeFormValues(form);

    if (form.companyUid && !isValidCompanyUid(form.companyUid)) {
        return false;
    }
    return Fields.noStatesAndNotEmpty(values.company1);
}

function makeFormValues(s: FormState): FormValues {
    return {
        company1: new Fields.GenericField(s.company1 || ''),
        company2: new Fields.GenericField(s.company2 || ''),
        companyUid: new Fields.GenericField(s.companyUid || ''),
    };
}

function formValuesChanged(cloud: Http.Address, local: FormState): boolean {
    return [
        [local.company1, cloud.company1],
        [local.company2, cloud.company2],
        [local.companyUid, cloud.companyUid],
    ]
        .map(([a, b]) => isDefinedAndDiffersFrom(a, b))
        .reduce((a, b) => a || b);
}

function mandatoryErrorText(field: Field<void>): React.ReactElement | null {
    if (field.isEmpty) {
        return <Localized {...GeneralTexts.mandatoryField} />;
    }

    return null;
}

interface FormValues {
    company1: Fields.GenericField;
    company2: Fields.GenericField;
    companyUid: Fields.GenericField;
}

interface FormState {
    company1: string | null;
    company2: string | null;
    companyUid: string | null;
}
