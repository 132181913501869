import { Store, useStore, Write } from 'dg-web-shared/lib/Flux';
import * as HttpResponse from 'dg-web-shared/lib/HttpResponse';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import * as Http from '../../api/Http';
import * as AsyncRequest from '../../AsyncRequest';
import { Localized } from '../../common/components/Localized';
import * as WriteStateSlice from '../../common/state/WriteStateSlice';
import { ResponseCode } from '../../common/state/WriteStateSlice';
import { ErrorBlock } from '../root/components/ErrorBlock';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import * as LoginsState from './state/LoginsState';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import {
    PasswordStrengthIndicator,
    passwordToShort,
} from '../current-login/PasswordEditForm';
import { GenericErrorBody } from '../../ui/modals/HandleHttpStati';
import { css } from '@emotion/css';
import { useState } from 'react';

export const PasswordEditFormSlideIn = portalSlideIn(PasswordEditForm);

function PasswordEditForm(props: {
    onClose: () => void;
    login: LoginsState.GetLoginData | null;
}) {
    const [currentLoginPassword, setCurrentLoginPassword] = useState('');
    const [otherLoginPassword, setOtherLoginPassword] = useState('');
    const [showPasswordToShortMessage, setShowPasswordToShortMessage] =
        useState(false);
    const [
        showUseCurrentLoginPasswordMessage,
        setShowUseCurrentLoginPasswordMessage,
    ] = useState(false);
    const { storeState } = useStore(s => {
        const responseSlice = new ResponseStateSlice(
            s,
            props.login ? props.login.id : -1,
        );
        return {
            currentLogin: CurrentLoginState.get(s),
            response: responseSlice.state,
            resetResponse: responseSlice.reset.bind(responseSlice),
            legacyUpdater: s.legacyUpdater,
        };
    });

    function onClose() {
        storeState.resetResponse();
        props.onClose();
    }

    const newPasswordToShort = passwordToShort(otherLoginPassword);

    const isSaveable =
        !!currentLoginPassword && !!otherLoginPassword && !newPasswordToShort;

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: onClose,
            }}
            primaryButton={{
                label: storeState.response.pending ? (
                    <Localized
                        de="Speichern..."
                        fr="Enregistrer..."
                        it="Salva..."
                        en="Saving..."
                    />
                ) : (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                disabled: !isSaveable || storeState.response.pending,
                onClick: () => {
                    if (!props.login) {
                        return;
                    }

                    storeState.legacyUpdater(args => httpPutPassword(args!), {
                        id: props.login.id,
                        currentLoginPassword,
                        newPassword: otherLoginPassword,
                        onSuccess: props.onClose,
                        onError: () =>
                            setShowUseCurrentLoginPasswordMessage(true),
                    });
                },
            }}
        >
            <div
                className={css({
                    lineHeight: '22px',
                })}
            >
                <p>
                    <Localized
                        de={
                            <>
                                Um das Passwort des ausgewählten Benutzers zu
                                ändern, müssen Sie zunächst Ihr Passwort hier
                                unten eingeben (
                                <b>{storeState.currentLogin.data?.email}</b>).
                            </>
                        }
                        fr={
                            <>
                                Afin de modifier le mot de passe de
                                l&#39;utilisateur sélectionné, vous devez
                                d&#39;abord saisir votre mot de passe ci-dessous
                                (<b>{storeState.currentLogin.data?.email}</b>).
                            </>
                        }
                        it={
                            <>
                                Per poter modificare la password dell&#39;utente
                                selezionato, deve prima inserire la sua password
                                qui di seguito (
                                <b>{storeState.currentLogin.data?.email}</b>).
                            </>
                        }
                        en={
                            <>
                                In order to change the password of the selected
                                user, you must first enter your password below (
                                <b>{storeState.currentLogin.data?.email}</b>).
                            </>
                        }
                    />
                </p>
                <p>
                    <TextField
                        labelText={
                            <Localized
                                de="Ihr Passwort"
                                fr="Votre mot de passe"
                                it="La sua password"
                                en="Your password"
                            />
                        }
                        value={currentLoginPassword}
                        inputType="password"
                        context={InputContext.form}
                        onChange={setCurrentLoginPassword}
                        onFocus={() =>
                            setShowUseCurrentLoginPasswordMessage(false)
                        }
                        errorText={
                            showUseCurrentLoginPasswordMessage ? (
                                <Localized
                                    de="Das Passwort stimmt nicht mit dem des eingeloggten Benutzers überein"
                                    fr="Le mot de passe ne correspond pas è celui de l'utilisateur connecté"
                                    it="La password non corrisponde a quella dell'utente attuale"
                                    en="The password does not match that of the logged in user"
                                />
                            ) : (
                                ''
                            )
                        }
                    />
                </p>

                <p>
                    <Localized
                        de={
                            <>
                                Das neue Passwort für den Benutzer{' '}
                                <b>{props.login?.email}</b> muss hier unten
                                eingegeben werden.
                            </>
                        }
                        fr={
                            <>
                                Le nouveau mot de passe de l&#39;utilisateur{' '}
                                <b>{props.login?.email}</b> doit être saisi
                                ci-dessous.
                            </>
                        }
                        it={
                            <>
                                La nuova password per l&#39;utente{' '}
                                <b>{props.login?.email}</b> va inserita qui di
                                seguito.
                            </>
                        }
                        en={
                            <>
                                The new password for the user{' '}
                                <b>{props.login?.email}</b> must be entered
                                below.
                            </>
                        }
                    />
                </p>
                <p>
                    <TextField
                        labelText={
                            <Localized
                                de="Neues Passwort"
                                fr="Nouveaux mot de passe"
                                it="Nuova password"
                                en="New password"
                            />
                        }
                        value={otherLoginPassword}
                        inputType="password"
                        context={InputContext.form}
                        onChange={setOtherLoginPassword}
                        onFocus={() => setShowPasswordToShortMessage(false)}
                        onBlur={() => setShowPasswordToShortMessage(true)}
                        errorText={
                            showPasswordToShortMessage &&
                            newPasswordToShort &&
                            otherLoginPassword
                                ? newPasswordToShort
                                : ''
                        }
                    />
                </p>

                <PasswordStrengthIndicator password={otherLoginPassword} />

                {storeState.response.statusCode.cls.error &&
                    storeState.response.responseCode !==
                        ResponseCode.OLD_PW_INVALID && (
                        <ErrorBlock text={<GenericErrorBody />} />
                    )}
            </div>
        </SlideInForm>
    );
}

class ResponseStateSlice extends WriteStateSlice.StateSlice {
    id: number;

    constructor(store: Store, id: number) {
        super(store);
        this.id = id;
    }

    key(): string {
        return 'account-login-PasswordStateResponse-' + this.id;
    }
}

function httpPutPassword(args: {
    id: number;
    currentLoginPassword: string;
    newPassword: string;
    onSuccess: () => void;
    onError: () => void;
}): Write {
    return AsyncRequest.request(
        Http.putLoginPassword(
            args.id,
            args.currentLoginPassword,
            args.newPassword,
        ),
        (store: Store, res: HttpResponse.Response) => {
            new ResponseStateSlice(store, args.id).writeResponse(res);

            if (!res.pending) {
                new LoginsState.StateSlice(store).reset();

                if (res.statusCode.cls.success) {
                    args.onSuccess();
                }
            }
            if (
                res.statusCode.cls.error &&
                res.body.code === ResponseCode.OLD_PW_INVALID
            ) {
                args.onError();
            }
        },
    );
}
