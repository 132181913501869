import { DateTime } from 'luxon';

import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter';
import { LuxonDateStringFormats } from 'dg-web-shared/lib/LuxonDateStringFormats';
import { Localized } from '../Localized';
import {
    InfoFieldWithIcon,
    TicketContent,
    TicketInfoHeader,
} from './TicketPlusLayout';
import { css } from '@emotion/css';
import { PriceDetailsInfoBox } from '../../../transactions-list/components/TransactionsList';
import { useStore } from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../../state/SettingsState';
import * as Date from 'dg-web-shared/lib/Date';
import { Formatter } from 'dg-web-shared/lib/Date';
import { useState } from 'react';
import { TransactionCost } from 'dg-web-shared/model/TransactionList';
import infoButtonIconBits from '../../../../assets/info_button.svg';

export function TicketPlusTicketBooked({
    ticketApiString,
    amount,
    validFrom,
    validTo,
    onOpenZoneInfo,
    zone,
    city,
    operator,
    cost,
    additionalContent,
}: {
    ticketApiString: string;
    amount: number;
    validFrom: string;
    validTo: string;
    onOpenZoneInfo?: () => void;
    zone: string;
    city: string;
    operator: string;
    cost?: TransactionCost;
    additionalContent?: React.ReactNode;
}) {
    const { storeState } = useStore(s => ({
        language: new SettingsState.StateSlice(s).state.language,
    }));
    const [showDetails, setShowDetails] = useState(false);

    return (
        <TicketContent>
            <TicketInfoHeader>
                <Localized
                    de="Parking"
                    fr="Parking"
                    it="Parcheggio"
                    en="Parking"
                />
            </TicketInfoHeader>
            {onOpenZoneInfo !== undefined ? (
                <InfoFieldWithIcon onClick={onOpenZoneInfo}>
                    {zone}
                </InfoFieldWithIcon>
            ) : (
                zone
            )}
            <TicketInfoHeader>
                <Localized de="Ort" fr="Lieu" it="Località" en="City" />
            </TicketInfoHeader>
            {city}
            <TicketInfoHeader>
                <Localized
                    de="Ticket-ID"
                    fr="ID ticket"
                    it="ID ticket"
                    en="Ticket ID"
                />
            </TicketInfoHeader>
            {ticketApiString}
            <TicketInfoHeader>
                <Localized de="Beginn" fr="Début" it="Inizio" en="Start" />
            </TicketInfoHeader>
            {formatDateTime(validFrom)}
            <TicketInfoHeader>
                <Localized de="Ende" fr="Fin" it="Fine" en="End" />
            </TicketInfoHeader>
            {formatDateTime(validTo)}
            <TicketInfoHeader>
                <Localized de="Dauer" fr="Durée" it="Durata" en="Duration" />
            </TicketInfoHeader>
            {Formatter.getDuration(
                DateTime.fromISO(validFrom),
                DateTime.fromISO(validTo),
                Date.Formatter.durationTexts[storeState.language],
            )}
            <TicketInfoHeader>
                <Localized de="Preis" fr="Prix" it="Prezzo" en="Price" />
            </TicketInfoHeader>
            <div className={css({ display: 'flex', lineHeight: '24px' })}>
                {NumberFormatter.numberToPrice(amount)}
                {cost && (
                    <a
                        className={css({
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            paddingLeft: '16px',
                            cursor: 'pointer',
                        })}
                        onClick={() => {
                            setShowDetails(true);
                        }}
                    >
                        <img src={infoButtonIconBits} />
                    </a>
                )}
                {showDetails && cost && (
                    <PriceDetailsInfoBox
                        cost={cost}
                        onClose={() => setShowDetails(false)}
                    />
                )}
            </div>
            <TicketInfoHeader>
                <Localized
                    de="Betreiber"
                    fr="Exploitant"
                    it="Gestore"
                    en="Operator"
                />
            </TicketInfoHeader>
            {operator}
            {additionalContent ?? null}
        </TicketContent>
    );
}

const formatDateTime = (dateTime: string) =>
    DateTime.fromISO(dateTime).toFormat(
        LuxonDateStringFormats.DATE_HOUR_MINUTE,
    );
