import * as Fields from '../../utils/Fields';
import * as Text from './Text';
import {
    generalTexts,
    LicensePlateErrorTexts,
} from 'dg-web-shared/common/components/translations/LicensePlateErrorTexts.ts';

export const emailTexts: Text.Translations<Fields.EmailTexts> = {
    de: {
        Invalid: (): string => 'Diese Email-Adresse ist ungültig.',
    },
    fr: {
        Invalid: (): string => "L'adresse e-mail n'est pas valide.",
    },
    it: {
        Invalid: (): string => "L'indirizzo e-mail non é valido.",
    },
    en: {
        Invalid: (): string => 'The e-mail address is not valid.',
    },
};

export const licensePlateTexts: Text.Translations<LicensePlateErrorTexts> = {
    de: {
        NoValidCanton: (): string => 'Keine gültige Kantonsbezeichnung',
        NoWhiteSpace: (): string => 'Keine Leerzeichen erlaubt',
        MinThreeLetters: (): string => 'Mindestens 3 Zeichen eingeben',
        AllLettersUppercase: (): string =>
            generalTexts.de.AllLettersUppercase(),
        LettersAfterCanton: (): string =>
            'Nur Zahlen nach Kantonskürzel erlaubt',
        LettersAfterCountryFL: (): string =>
            ' Nur Zahlen nach FL-Kürzel erlaubt',
        HasMoreThanSixNumbers: (): string => 'Maximal 6 Zahlen erlaubt',
        HasMoreThanFiveNumbers: (): string => 'Maximal 5 Zahlen erlaubt',
        CountryMustBeFL: (): string =>
            'Das Kennzeichen muss immer mit FL beginnen.',
    },
    fr: {
        NoValidCanton: (): string => "Le code canton n'est pas valable",
        NoWhiteSpace: (): string => 'Espaces pas autorisées',
        MinThreeLetters: (): string => 'Saisir au moins 3 caractères',
        AllLettersUppercase: (): string =>
            generalTexts.fr.AllLettersUppercase(),
        LettersAfterCanton: (): string =>
            'Seulement des chiffres autorisés après le code canton',
        LettersAfterCountryFL: (): string =>
            'Seulement des chiffres autorisés après le code FL',
        HasMoreThanSixNumbers: (): string => 'Maximum 6 chiffres autorisés',
        HasMoreThanFiveNumbers: (): string => 'Maximum 5 chiffres autorisés',
        CountryMustBeFL: (): string =>
            "L'immatriculation doit toujours commencer avec FL.",
    },
    it: {
        NoValidCanton: (): string => 'Il codice del cantone non è valido',
        NoWhiteSpace: (): string => 'Non sono consentiti spazi',
        MinThreeLetters: (): string => 'Inserire almeno 3 caratteri',
        AllLettersUppercase: (): string =>
            generalTexts.it.AllLettersUppercase(),
        LettersAfterCanton: (): string =>
            'Sono consentite solo cifre dopo il codice del cantone',
        LettersAfterCountryFL: (): string =>
            'Sono consentite solo cifre dopo il codice FL',
        HasMoreThanSixNumbers: (): string =>
            'Sono consentite al massimo 6 cifre',
        HasMoreThanFiveNumbers: (): string =>
            'Sono consentite al massimo 5 cifre',
        CountryMustBeFL: (): string =>
            'La targa deve sempre cominciare con FL.',
    },
    en: {
        NoValidCanton: (): string => 'Canton code is invalid',
        NoWhiteSpace: (): string => 'No space allowed',
        MinThreeLetters: (): string => 'Please enter at least 3 characters',
        AllLettersUppercase: (): string =>
            generalTexts.en.AllLettersUppercase(),
        LettersAfterCanton: (): string =>
            'Only digits after the canton code are allowed',
        LettersAfterCountryFL: (): string =>
            'Only digits after the FL code are allowed',
        HasMoreThanSixNumbers: (): string =>
            'A maximum of 6 digits are allowed',
        HasMoreThanFiveNumbers: (): string =>
            'A maximum of 5 digits are allowed',
        CountryMustBeFL: (): string =>
            'The license plate must always start with FL.',
    },
};

export const ibanTexts: Text.Translations<Fields.IbanTexts> = {
    de: {
        NoValidCountryCode: (): string => 'Kein gültiger Ländercode',
        ChecksumFail: (): string => 'Keine gültige IBAN',
        TooShort: (): string => 'Die IBAN ist zu kurz',
        TooLong: (): string => 'Die IBAN ist zu lang',
        AllLettersUppercase: (): string =>
            generalTexts.de.AllLettersUppercase(),
    },
    fr: {
        NoValidCountryCode: (): string => 'Code du pays pas valable',
        ChecksumFail: (): string => 'IBAN pas valable',
        TooShort: (): string => 'IBAN trop court',
        TooLong: (): string => 'IBAN trop long',
        AllLettersUppercase: (): string =>
            generalTexts.fr.AllLettersUppercase(),
    },
    it: {
        NoValidCountryCode: (): string => 'Codice nazione non valido',
        ChecksumFail: (): string => 'IBAN non valido',
        TooShort: (): string => 'IBAN troppo corto',
        TooLong: (): string => 'IBAN troppo lungo',
        AllLettersUppercase: (): string =>
            generalTexts.it.AllLettersUppercase(),
    },
    en: {
        NoValidCountryCode: (): string => 'Country code is invalid',
        ChecksumFail: (): string => 'IBAN is invalid',
        TooShort: (): string => 'IBAN is too short',
        TooLong: (): string => 'IBAN is too long',
        AllLettersUppercase: (): string =>
            generalTexts.en.AllLettersUppercase(),
    },
};

export const bicTexts: Text.Translations<Fields.BicTexts> = {
    de: {
        TooShort: (): string => 'Der BIC ist zu kurz',
        TooLong: (): string => 'Der BIC ist zu lang',
        AllLettersUppercase: (): string =>
            generalTexts.de.AllLettersUppercase(),
        DoesNotMatchIban: (): string =>
            'Der BIC entspricht nicht der angegebenen IBAN',
        Invalid: () => 'Der BIC ist ungültig.',
    },
    fr: {
        TooShort: (): string => 'BIC trop court',
        TooLong: (): string => 'BIC trop long',
        AllLettersUppercase: (): string =>
            generalTexts.fr.AllLettersUppercase(),
        DoesNotMatchIban: (): string => 'BIC pas valable pour ce IBAN',
        Invalid: () => "Le BIC n'est pas valid",
    },
    it: {
        TooShort: (): string => 'BIC troppo corto',
        TooLong: (): string => 'BIC troppo lungo',
        AllLettersUppercase: (): string =>
            generalTexts.it.AllLettersUppercase(),
        DoesNotMatchIban: (): string => 'BIC non valido per questo IBAN',
        Invalid: () => 'Il BIC non è valido',
    },
    en: {
        TooShort: (): string => 'BIC is too short',
        TooLong: (): string => 'BIC is too long',
        AllLettersUppercase: (): string =>
            generalTexts.en.AllLettersUppercase(),
        DoesNotMatchIban: (): string => 'BIC is not valid for this IBAN',
        Invalid: () => 'BIC is invalid',
    },
};
