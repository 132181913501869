import * as CommonQueryActions from '../actions/CommonQueryActions';
import * as Flux from 'dg-web-shared/lib/Flux';
import * as ServerStateSlice from 'dg-web-shared/common/state/ServerStateSliceV2.ts';
import { Language as Language1 } from 'dg-web-shared/dto/Language.ts';

export type Language = Language1;

export type State = ServerStateSlice.State<Language[]>;

export class StateSlice extends ServerStateSlice.LegacyServerStateSlice<
    Language[]
> {
    key(): string {
        return 'common-LanguageState';
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.shouldFetch) {
            store.legacyUpdater(CommonQueryActions.receiveLanguages);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseBody(body: any): Language[] {
        return body || [];
    }

    getEmptyData(): Language[] {
        return [];
    }
}
