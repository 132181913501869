import * as String from '../lib/String.ts';
import {
    stripNonNumericAndNoLetterCharacters,
    stripNonNumericAndNoLetterCharactersAllowSpace,
} from '../lib/StringConversions';
import countries from 'i18n-iso-countries';

function forceString(str: string): string {
    return str ? str : '';
}

function validateCountryCode(countryCode: string): boolean {
    const a2codes = countries.getAlpha2Codes();
    return Boolean(a2codes[countryCode]);
}

/**
 * Prepare an IBAN for mod 97 computation by moving the first 4 chars to the end and transforming the letters to
 * numbers (A = 10, B = 11, ..., Z = 35), as specified in ISO13616.
 *
 * @param {string} iban the IBAN
 * @returns {string} the prepared IBAN
 */
function iso13616Prepare(iban: string): string {
    iban = iban.toUpperCase();
    iban = iban.substr(4) + iban.substr(0, 4);

    const A = 'A'.charCodeAt(0),
        Z = 'Z'.charCodeAt(0);

    return iban
        .split('')
        .map((n: string) => {
            const code = n.charCodeAt(0);
            if (code >= A && code <= Z) {
                // A = 10, B = 11, ... Z = 35
                return code - A + 10;
            } else {
                return n;
            }
        })
        .join('');
}

/**
 * Calculates the MOD 97 10 of the passed IBAN as specified in ISO7064.
 */
function iso7064Mod97_10(iban: string): number {
    let remainder = iban;
    let block = '';

    while (remainder.length > 2) {
        block = remainder.slice(0, 9);
        remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
    }

    return parseInt(remainder, 10) % 97;
}

const IBAN_MAX_LENGTH = 34;
const IBAN_LENGTH_CH_LI = 21;
const POST_FINANCE_CLEARING_NUMBER = 9000;

export enum IbanState {
    NO_VALID_COUNTRY_CODE,
    CHECKSUM_FAIL,
    TOO_SHORT,
    TOO_LONG,
    NOT_UPPERCASE,
}

export enum AccountType {
    BANK = 0,
    POST = 1,
}

export interface Field<S> {
    value: string;
    isEmpty: boolean;
    states: S[];
}

export class Iban implements Field<IbanState> {
    constructor(value: string) {
        value = forceString(value);
        this._value = stripNonNumericAndNoLetterCharactersAllowSpace(value);
        this._valueNoSpace = stripNonNumericAndNoLetterCharacters(value);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    private _valueNoSpace: string;

    get valueNoSpace(): string {
        return this._valueNoSpace;
    }

    get isCHLI(): boolean {
        if (this._value.length < 2) {
            return true; // as long as we do not have the full country code, assume its CH/LI -> removes BIC field
        }
        const countryCode = this._value.substring(0, 2);
        return countryCode === 'CH' || countryCode === 'LI';
    }

    get type(): number | null {
        if (!this.isCHLI) {
            return null;
        }
        if (this.valueNoSpace.length < 9) {
            return null;
        }

        if (this.clearingNumber === POST_FINANCE_CLEARING_NUMBER) {
            return AccountType.POST;
        } else {
            return AccountType.BANK;
        }
    }

    get clearingNumber(): number {
        return parseInt(this.valueNoSpace.substring(4, 9), 10);
    }

    get country(): string {
        return this.value.substring(0, 2) || '';
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): IbanState[] {
        const states: IbanState[] = [];
        if (this.isEmpty) {
            return states;
        }

        const length = this.valueNoSpace.length;
        const value = this.valueNoSpace;

        const countryCode = value.substring(0, 2);

        if (length >= 2) {
            if (!validateCountryCode(countryCode)) {
                states.push(IbanState.NO_VALID_COUNTRY_CODE);
            }
        }

        if (!String.Validators.isUpperCase(value)) {
            states.push(IbanState.NOT_UPPERCASE);
        }

        if (this.isCHLI) {
            if (length < IBAN_LENGTH_CH_LI) {
                states.push(IbanState.TOO_SHORT);
            } else if (length === IBAN_LENGTH_CH_LI) {
                if (length === IBAN_LENGTH_CH_LI) {
                    // 21 = 2 digits country code + 2 digits checksum + 17 digits BBAN
                    if (iso7064Mod97_10(iso13616Prepare(value)) !== 1) {
                        states.push(IbanState.CHECKSUM_FAIL);
                    }
                }
            } else {
                states.push(IbanState.TOO_LONG);
            }
        } else {
            if (value.length > 4 && value.length < IBAN_MAX_LENGTH) {
                if (iso7064Mod97_10(iso13616Prepare(value)) !== 1) {
                    states.push(IbanState.CHECKSUM_FAIL);
                }
            } else if (value.length > IBAN_MAX_LENGTH) {
                states.push(IbanState.TOO_LONG);
            }
        }

        return states;
    }
}
