import { useState } from 'react';
import { PermitPostData } from '../../../api/PermitHttp';
import { Localized } from '../../../common/components/Localized';
import {
    derivePaymentCategory,
    derivePaymentRequestMode,
    isEpaymentPaymentFlow,
    PaymentCategory,
    paymentCategoryFromSelectedPaymentFlow,
    PaymentRequestMode,
    SelectedPaymentFlow,
} from '../../../common/payment/Payment';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import { PermitPaymentSlideIn } from '../../../account/top-up-balance/e-payment-top-up/PermitPayment';
import { SavedCreditCardState } from '../../../account/shared/SavedCreditCardAliasState';
import { AddPaymentMethodParkCreateMenuSlideIn } from './AddPaymentMethodParkCreateMenuSlideIn';
import { DirectOrTopupSwitch } from './DirectOrTopupSwitch';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { StoreAliasRequest } from '../../../common/payment/StoreAliasRequest';
import { usePaymentTypeState } from '../../../common/state/PaymentTypeState';
import { PaymentMethodEnum } from '../../../api/Http';
import { HandleHttpStati } from '../../../ui/modals/HandleHttpStati';
import { logAction } from '../../../utils/ActionLog';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import { SavedCreditCardAliasResponseTag } from 'dg-web-shared/model/PaymentAlias';
import { UiPaymentOrigin } from 'dg-web-shared/common/utils/TwintPairingParkingpay';
import { useParkingpayLanguage } from '../../../utils/UseParkingpayLanguage';
import { SpinnerModal } from '../../../ui/spinner/Spinner.tsx';

export function createStoreAliasPayload(
    payload: StoreAliasPayloadBase,
    paymentOriginInfo: PaymentOriginInfoType,
): StoreAliasRequest.Payload {
    switch (paymentOriginInfo.type) {
        case PaymentOriginType.CHECKIN_BY_TICKET:
            return {
                ...payload,
                uiPaymentOrigin: UiPaymentOrigin.STORE_ALIAS_FROM_TICKET,
                ticketId: paymentOriginInfo.ticketId,
            };
        case PaymentOriginType.ACCOUNT:
            return {
                ...payload,
                uiPaymentOrigin: UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT,
            };
        case PaymentOriginType.CHECKIN_BY_PLATE:
            return {
                ...payload,
                uiPaymentOrigin: UiPaymentOrigin.STORE_ALIAS_FROM_CHECKIN,
            };
        case PaymentOriginType.PERMIT:
            throw new Error('Store alias from permit topup not possible.');
    }
}

export interface StoreAliasPayloadBase {
    paymentCategory: PaymentCategory;
    useAlias: boolean;
    language: string;
}

export function AddPaymentMethodParkCreateMenu({
    open,
    onClose,
    paymentOriginInfo,
}: AddPaymentMethodProps) {
    const update = useUpdate();
    const language = useParkingpayLanguage();
    const [ccAliasGetRequest] = SavedCreditCardState.use();
    const [selected, setSelected] = useState(SelectedPaymentFlow.METHODS);
    const [paymentType] = usePaymentTypeState();
    const [storeAlias, sendStoreAlias, resetStoreAlias] =
        StoreAliasRequest.use();

    if (paymentType.pending) {
        return (
            <SpinnerModal
                visible={true}
                portal={SlideInPortalId.FULL_SCREEN_MODAL}
            />
        );
    }

    const isDirectWithInvalidAlias =
        paymentType.data.activePaymentMethod.activePaymentType ===
            PaymentMethodEnum.DIRECT &&
        (paymentOriginInfo.type === PaymentOriginType.CHECKIN_BY_PLATE ||
            paymentOriginInfo.type === PaymentOriginType.CHECKIN_BY_TICKET);

    function nav(selectedPaymentFlow: SelectedPaymentFlow) {
        // careful. theoretically even if selectedPaymentFlow is Methods, this can be called, for example
        // if a slide-in is not closed
        if (
            isDirectWithInvalidAlias &&
            selectedPaymentFlow !== SelectedPaymentFlow.METHODS
        ) {
            const payload: StoreAliasPayloadBase = {
                paymentCategory:
                    paymentCategoryFromSelectedPaymentFlow(selectedPaymentFlow),
                useAlias: true,
                language: language,
            };
            sendStoreAlias(createStoreAliasPayload(payload, paymentOriginInfo));
            onClose();
        } else {
            if (
                selectedPaymentFlow !== SelectedPaymentFlow.METHODS &&
                paymentOriginInfo.type === PaymentOriginType.CHECKIN_BY_PLATE
            ) {
                update(s =>
                    logAction(
                        s,
                        'show-direct-or-topup-switch',
                        selectedPaymentFlow,
                    ),
                );
            }
            setSelected(selectedPaymentFlow);
        }
    }

    const aliasData =
        ccAliasGetRequest.status === RequestStatus.SUCCESS
            ? ccAliasGetRequest.data
            : null;

    if (!aliasData) {
        return null;
    }
    const paymentRequestMode = derivePaymentRequestMode(selected, aliasData);
    const useExistingAlias =
        paymentRequestMode === PaymentRequestMode.DIRECT_TO_ALIAS;

    const paymentCategory = derivePaymentCategory(
        paymentRequestMode,
        aliasData.tag === SavedCreditCardAliasResponseTag.FOUND
            ? aliasData.creditCardAlias
            : null,
        selected,
    );

    const close = () => {
        onClose();
        nav(SelectedPaymentFlow.METHODS);
    };
    return (
        <>
            <HandleHttpStati
                serverHooks={[[storeAlias, resetStoreAlias]]}
                ignore400={false}
                showSpinner={true}
            />
            <AddPaymentMethodParkCreateMenuSlideIn
                contractType={paymentOriginInfo.type}
                portal={SlideInPortalId.PARK_CREATE}
                open={open}
                onClose={onClose}
                title={
                    <Localized
                        de="Zahlungsmittel"
                        fr="Moyen de payement"
                        it="Mezzo di pagamento"
                        en="Mean of payment"
                    />
                }
                aliasData={aliasData}
                setActivePaymentCategory={(category: SelectedPaymentFlow) => {
                    nav(category);
                }}
            />

            {paymentOriginInfo.type === PaymentOriginType.PERMIT ? (
                <PermitPaymentSlideIn
                    portal={SlideInPortalId.PARK_CREATE}
                    open={isEpaymentPaymentFlow(selected)}
                    title={
                        <Localized
                            de="Online-Zahlung"
                            fr="Paiement en ligne"
                            it="Pagamento online"
                            en="E-payment"
                        />
                    }
                    onClose={() => nav(SelectedPaymentFlow.METHODS)}
                    close={close}
                    paymentRequestMode={paymentRequestMode}
                    paymentCategory={paymentCategory}
                    permitPriceChf={paymentOriginInfo.permitPriceChf}
                    currentBalanceChf={paymentOriginInfo.currentBalanceChf}
                    permitPostData={paymentOriginInfo.permitPostData}
                    useExistingAlias={useExistingAlias}
                />
            ) : (
                <DirectOrTopupSwitch
                    open={isEpaymentPaymentFlow(selected)}
                    onClose={close}
                    aliasData={aliasData}
                    paymentRequestMode={paymentRequestMode}
                    paymentCategory={paymentCategory}
                    paymentOriginInfo={paymentOriginInfo}
                    portal={SlideInPortalId.PARK_CREATE}
                />
            )}
        </>
    );
}

interface AddPaymentMethodProps {
    onClose: () => void;
    open: boolean;
    paymentOriginInfo: PaymentOriginInfoType;
}

export enum PaymentOriginType {
    CHECKIN_BY_PLATE = 'CHECKIN_BY_PLATE',
    CHECKIN_BY_TICKET = 'CHECKIN_BY_TICKET',
    PERMIT = 'PERMIT',
    ACCOUNT = 'ACCOUNT',
}

export type PaymentOriginInfoType =
    | PlateCheckinPaymentInfo
    | TicketCheckinPaymentInfo
    | PermitPaymentInfo
    | AccountPaymentInfo;

interface PaymentOriginInfo {
    type: PaymentOriginType;
}

interface AccountPaymentInfo extends PaymentOriginInfo {
    type: PaymentOriginType.ACCOUNT;
}

interface PlateCheckinPaymentInfo extends PaymentOriginInfo {
    type: PaymentOriginType.CHECKIN_BY_PLATE;
}

interface TicketCheckinPaymentInfo extends PaymentOriginInfo {
    type: PaymentOriginType.CHECKIN_BY_TICKET;
    ticketId: string;
}

interface PermitPaymentInfo extends PaymentOriginInfo {
    type: PaymentOriginType.PERMIT;
    permitPriceChf: number;
    currentBalanceChf: number;
    permitPostData: PermitPostData;
}
