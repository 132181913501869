import * as Flux from 'dg-web-shared/lib/Flux';
import { ReminderEditSlideIn } from '../../current-login/components/ReminderEditSlideIn';
import * as SlideInState from '../state/SlideInState';
import { SlideInType } from '../state/SlideInState';
import { Localized } from '../../../common/components/Localized';
import { SlideInPortalId } from './PortalSlidein';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { defaultTransition } from 'dg-web-shared/tb-ui/transitions.ts';

interface State {
    overlay: SlideInState.State;
}

function isExpanded(state: State): boolean {
    return state.overlay.type !== SlideInState.SlideInType.NONE;
}

export class SlideInContainer extends Flux.Container<State> {
    stateSelector(): State {
        return {
            overlay: new SlideInState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        return (
            <div
                className={css({
                    position: 'absolute',
                    background: Colors.white,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    ...defaultTransition('transform'),
                    transform: isExpanded(this.state)
                        ? undefined
                        : 'translateX(100%)',
                })}
            >
                <ReminderEditSlideIn
                    portal={SlideInPortalId.USER_ACCOUNT}
                    title={
                        <Localized
                            de="Benachrichtigungen"
                            fr="Notifications"
                            it="Notifiche"
                            en="Notifications"
                        />
                    }
                    open={this.state.overlay.type === SlideInType.REMINDER_EDIT}
                    onClose={() =>
                        this.props.allState.update(store => {
                            new SlideInState.StateSlice(store).setType(
                                SlideInState.SlideInType.NONE,
                            );
                            return 'close-reminder-edit';
                        })
                    }
                />
                ;
            </div>
        );
    }
}
