import { useState } from 'react';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { EditForm } from './EditForm';
import { writeEditLoginData } from './components/NewsletterEdit';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ErrorBlock } from '../root/components/ErrorBlock';
import { GenericErrorBody } from '../../ui/modals/HandleHttpStati';
import { Localized } from '../../common/components/Localized';

export const NameEditFormSlideIn = portalSlideIn(NameEditForm);

function NameEditForm(props: {
    title: React.ReactNode;
    open: boolean;
    currentLogin: CurrentLoginState.CurrentLogin;
    onClose: () => void;
}) {
    const [editState, setEditState] = useState({
        firstName: props.currentLogin.firstName,
        lastName: props.currentLogin.lastName,
    });

    const editData = {
        ...props.currentLogin,
        ...editState,
    };

    const [httpPut, doHttpPut] = writeEditLoginData(
        props.onClose,
        false,
        'name-edit',
    );

    return (
        <EditForm
            isSaveable={
                !!editState.firstName &&
                !!editState.lastName &&
                (editState.firstName !== props.currentLogin.firstName ||
                    editState.lastName !== props.currentLogin.lastName)
            }
            onClose={props.onClose}
            onSave={() => {
                doHttpPut(editData);
            }}
            pending={httpPut.status === RequestStatus.PENDING}
        >
            <TextField
                context={InputContext.form}
                value={editState.firstName}
                labelText={
                    <Localized
                        de="Vorname"
                        fr="Prénom"
                        it="Nome"
                        en="Firstname"
                    />
                }
                errorText={
                    editState.firstName ? (
                        ''
                    ) : (
                        <Localized
                            de="Pflichtfeld"
                            fr="Ce champ est obligatoire"
                            it="Campo obbligatorio"
                            en="Mandatory field"
                        />
                    )
                }
                onChange={(v: string) => {
                    setEditState({ ...editState, firstName: v });
                }}
            />
            <TextField
                context={InputContext.form}
                value={editState.lastName}
                labelText={
                    <Localized
                        de="Nachname"
                        fr="Nom"
                        it="Cognome"
                        en="Lastname"
                    />
                }
                errorText={
                    editState.lastName ? (
                        ''
                    ) : (
                        <Localized
                            de="Pflichtfeld"
                            fr="Ce champ est obligatoire"
                            it="Campo obbligatorio"
                            en="Mandatory field"
                        />
                    )
                }
                onChange={(v: string) => {
                    setEditState({ ...editState, lastName: v });
                }}
            />

            {httpPut.status === RequestStatus.ERROR && (
                <ErrorBlock text={<GenericErrorBody />} />
            )}
        </EditForm>
    );
}
