import { Spinner } from '../../../ui/Spinner';
import React from 'react';

interface Props {
    showSpinner: boolean;
    children?: React.ReactNode;
}

export class SpinnerCanvas extends React.Component<Props> {
    render() {
        if (this.props.showSpinner) {
            return <Spinner />;
        } else {
            return <div>{this.props.children}</div>;
        }
    }
}
