import { css } from '@emotion/css';

import { useStore } from 'dg-web-shared/lib/Flux';
import { Localized } from '../../common/components/Localized';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import * as SettingsState from '../../common/state/SettingsState';
import { ResponseCode } from '../../common/state/WriteStateSlice';
import { ErrorBlock } from '../root/components/ErrorBlock';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import { EditFormState } from './EditFormState';
import * as LoginsState from './state/LoginsState';
import { useInvoiceSettings } from '../invoices/InvoicesSettingsMenu';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { GenericErrorBody } from '../../ui/modals/HandleHttpStati';
import { useEffect } from 'react';

export const EditFormSlideIn = portalSlideIn(EditForm);

function EditForm(props: {
    login: LoginsState.GetLoginData | null;
    isSaveable: boolean;
    onClose: () => void;
    children: React.ReactNode;
}) {
    const { storeState, update, store } = useStore(s => ({
        edit: new EditFormState.StateSlice(s).state,
        response: new EditFormState.ResponseStateSlice(s).state,
    }));
    const [invoiceSettings] = useInvoiceSettings();

    useEffect(() => {
        update(store => {
            const edit = new EditFormState.StateSlice(store);
            edit.reset.bind(edit);
            return 'Form-State-Slice-reset';
        });

        update(store => {
            const response = new EditFormState.ResponseStateSlice(store);
            response.reset.bind(response);
            return 'Form-Response-Slice-reset';
        });
    }, []);

    if (invoiceSettings.status === RequestStatus.ERROR) {
        return <GenericErrorBody />;
    }

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: props.onClose,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                disabled:
                    !props.isSaveable ||
                    storeState.response.pending ||
                    invoiceSettings.status === RequestStatus.PENDING,
                onClick: () => {
                    if (!props.login) {
                        return;
                    }

                    store.legacyUpdater(
                        store => EditFormState.httpPutLogin(store!),
                        {
                            edit: storeState.edit,
                            server: props.login,
                            onSuccess: props.onClose,
                        },
                    );
                },
            }}
        >
            {props.children}
            {storeState.response.statusCode.cls.error && (
                <div className={css({ marginBottom: '16px' })}>
                    <RemoteRequestError />
                </div>
            )}
        </SlideInForm>
    );
}

function RemoteRequestError() {
    const { storeState } = useStore(s => ({
        response: new EditFormState.ResponseStateSlice(s).state,
    }));

    if (
        storeState.response.responseCode === ResponseCode.EMAIL_NOT_UNIQUE_EDIT
    ) {
        return <EmailAddressOccupiedError />;
    }

    return <GenericError />;
}

export function GenericError() {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
    }));

    return (
        <ErrorBlock
            text={GeneralTexts.texts[
                storeState.settings.language
            ].ErrorTryAgainOrHelpdesk()}
        />
    );
}

function EmailAddressOccupiedError() {
    return (
        <ErrorBlock
            text={
                <Localized
                    de="Diese E-Mail-Adresse ist bereits in einem Konto registriert."
                    fr="Cette adresse e-mail est déjà enregistrée dans un compte."
                    it="Questo indirizzo e-mail è già registrato in un conto."
                    en="This e-mail address is already registered in an account."
                />
            }
        />
    );
}
