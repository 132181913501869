import { Store } from 'dg-web-shared/lib/Flux';
import { LeftColumn, StaticPage } from './StaticPage';
import { css } from '@emotion/css';
import {
    RequestStatus,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { Localized } from '../../../common/components/Localized';
import { ButtonRow } from 'dg-web-shared/tb-ui/buttons/ButtonRow.tsx';
import { BasicButton } from 'dg-web-shared/tb-ui/buttons/BasicButton.tsx';
import { useParams } from 'react-router';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import robotoRegular = Typo.robotoRegular;

export interface AddTenantFormProps {
    info: FlowInformation;
    refetch: () => void;
}

type AddTenantFormComponent = React.FC<AddTenantFormProps>;

export function ConfirmPage({
    allState,
    FormComponent,
}: {
    allState: Store;
    FormComponent: AddTenantFormComponent;
}) {
    return (
        <StaticPage allState={allState} hideBackground>
            <LeftColumn allState={allState}>
                <div
                    className={css({
                        ...robotoRegular,
                        marginTop: '-3em',
                    })}
                >
                    <ConfirmTenantContent FormComponent={FormComponent} />
                </div>
            </LeftColumn>
        </StaticPage>
    );
}

type WhitelistCustomer = {
    firstName: string;
    lastName: string;
    address: string;
    zipCode: string;
    city: string;
    additionalInformation: string;
};

type FlowInformation = {
    externalId: string;
    completed: boolean;
    customer: WhitelistCustomer;
};

function ConfirmTenantContent({
    FormComponent,
}: {
    FormComponent: AddTenantFormComponent;
}) {
    const externalId = useParams()['externalId'] ?? '';

    const [flowInformation, refetchFlowInformation] = useServerFetch<
        FlowInformation,
        { externalId: string }
    >(context => ({ url: `/ui-api/whitelist-flow/${context.externalId}` }), {
        externalId: externalId,
    });

    switch (flowInformation.status) {
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.PENDING:
            return <Spinner />;
        case RequestStatus.ERROR:
            return (
                <div>
                    Das Formular Nachweispflicht konnte nicht geladen werden.
                </div>
            );
        case RequestStatus.SUCCESS:
            if (!flowInformation.data.completed) {
                return (
                    <FormComponent
                        info={flowInformation.data}
                        refetch={refetchFlowInformation}
                    />
                );
            } else {
                return (
                    <div>Formular Nachweispflicht erfolgreich übermittelt.</div>
                );
            }
    }
}

type CompanyInfo = {
    name: string;
    responsibleFirstName: string;
    responsibleLastName: string;
    address: string;
    zip: string;
    city: string;
    phoneNumber: string;
    email: string;
};

export function CustomerInfo({ customer }: { customer: WhitelistCustomer }) {
    return (
        <>
            <h2>Angaben der Mieterin/Mieter</h2>
            <div>
                {customer.firstName} {customer.lastName}
            </div>
            <div>{customer.address}</div>
            <div>
                {customer.zipCode} {customer.city}
            </div>
            <div>{customer.additionalInformation}</div>
        </>
    );
}

export function FormLogic({ info, refetch }: AddTenantFormProps) {
    const [companyInfo, setCompanyInfo] = useState({
        name: '',
        responsibleFirstName: '',
        responsibleLastName: '',
        address: '',
        zip: '',
        city: '',
        phoneNumber: '',
        email: '',
    });
    const [submitCompanyInfoState, submitCompanyInfoRequest] = useServerWrite<
        CompanyInfo,
        object
    >(() => ({
        url: `/ui-api/whitelist-flow/${info.externalId}`,
    }));

    useServerSuccessEffect(submitCompanyInfoState, refetch);

    const formIsValid =
        companyInfo.address !== '' &&
        companyInfo.city !== '' &&
        companyInfo.zip !== '' &&
        companyInfo.name !== '' &&
        companyInfo.email !== '' &&
        companyInfo.phoneNumber !== '' &&
        companyInfo.responsibleFirstName !== '' &&
        companyInfo.responsibleLastName !== '';

    return (
        <>
            <TextField
                context={InputContext.regular}
                value={companyInfo.name}
                tabIndex={1}
                autoFocus={true}
                labelText={
                    <Localized de="Firma" fr="Firma" it="Firma" en="Firma" />
                }
                onChange={e =>
                    setCompanyInfo({
                        ...companyInfo,
                        name: e,
                    })
                }
            />
            <TextField
                context={InputContext.regular}
                value={companyInfo.responsibleLastName}
                tabIndex={2}
                labelText={
                    <Localized
                        de="Name der zuständigen Person"
                        fr="Name der zuständigen Person"
                        it="Name der zuständigen Person"
                        en="Name der zuständigen Person"
                    />
                }
                onChange={e =>
                    setCompanyInfo({
                        ...companyInfo,
                        responsibleLastName: e,
                    })
                }
            />
            <TextField
                context={InputContext.regular}
                value={companyInfo.responsibleFirstName}
                tabIndex={3}
                labelText={
                    <Localized
                        de="Vorname der zuständigen Person"
                        fr="Vorname der zuständigen Person"
                        it="Vorname der zuständigen Person"
                        en="Vorname der zuständigen Person"
                    />
                }
                onChange={e =>
                    setCompanyInfo({
                        ...companyInfo,
                        responsibleFirstName: e,
                    })
                }
            />
            <TextField
                context={InputContext.regular}
                value={companyInfo.address}
                tabIndex={4}
                labelText={
                    <Localized
                        de="Adresse"
                        fr="Adresse"
                        it="Adresse"
                        en="Adresse"
                    />
                }
                onChange={e =>
                    setCompanyInfo({
                        ...companyInfo,
                        address: e,
                    })
                }
            />
            <TextField
                context={InputContext.regular}
                value={companyInfo.zip}
                tabIndex={5}
                labelText={<Localized de="PLZ" fr="PLZ" it="PLZ" en="PLZ" />}
                onChange={e =>
                    setCompanyInfo({
                        ...companyInfo,
                        zip: e,
                    })
                }
            />
            <TextField
                context={InputContext.regular}
                value={companyInfo.city}
                tabIndex={6}
                labelText={<Localized de="Ort" fr="Ort" it="Ort" en="Ort" />}
                onChange={e =>
                    setCompanyInfo({
                        ...companyInfo,
                        city: e,
                    })
                }
            />
            <TextField
                context={InputContext.regular}
                value={companyInfo.phoneNumber}
                inputType={'tel'}
                tabIndex={7}
                labelText={
                    <Localized
                        de="Telefon (für Rückfragen)"
                        fr="Telefon (für Rückfragen)"
                        it="Telefon (für Rückfragen)"
                        en="Telefon (für Rückfragen)"
                    />
                }
                onChange={e =>
                    setCompanyInfo({
                        ...companyInfo,
                        phoneNumber: e,
                    })
                }
            />
            <TextField
                context={InputContext.regular}
                value={companyInfo.email}
                inputType={'email'}
                tabIndex={8}
                labelText={
                    <Localized
                        de="E-Mail"
                        fr="E-Mail"
                        it="E-Mail"
                        en="E-Mail"
                    />
                }
                onChange={e =>
                    setCompanyInfo({
                        ...companyInfo,
                        email: e,
                    })
                }
            />
            <ButtonRow>
                <BasicButton
                    label={
                        <Localized
                            de="Speichern"
                            fr="Sauvegarder"
                            it="Salva"
                            en="Save"
                        />
                    }
                    negative={true}
                    disabled={
                        !formIsValid ||
                        submitCompanyInfoState.status === RequestStatus.PENDING
                    }
                    onClick={() => submitCompanyInfoRequest(companyInfo)}
                />
            </ButtonRow>
        </>
    );
}
