import { AccountSetupSummaryTexts } from '../components/AccountSetupSummary';
import { Translations } from '../../common/i18n/Text.ts';

/* tslint:disable:max-line-length */
export const summaryTexts: Translations<AccountSetupSummaryTexts> = {
    de: {
        summaryTitle: () => 'Zusammenfassung Ihrer Einstellungen',
        emailBlockLabel: () => 'E-Mail',
        addressBlockLabel: () => 'Adresse',
        vehicleBlockLaber: () => 'Fahrzeug',
        badgeBlockLabel: () => 'Badge',
        badgeOrdered: () => 'Badge wird zugestellt',
    },
    fr: {
        summaryTitle: () => 'Résumé de vos paramètres',
        emailBlockLabel: () => 'E-mail',
        addressBlockLabel: () => 'Adresse',
        vehicleBlockLaber: () => 'Véhicule',
        badgeBlockLabel: () => 'Badge',
        badgeOrdered: () => 'Le badge sera envoyé',
    },
    it: {
        summaryTitle: () => 'Riepilogo delle impostazioni',
        emailBlockLabel: () => 'E-mail',
        addressBlockLabel: () => 'Indirizzo',
        vehicleBlockLaber: () => 'Veicolo',
        badgeBlockLabel: () => 'Badge',
        badgeOrdered: () => 'Il badge verrà spedito',
    },
    en: {
        summaryTitle: () => 'Summary of settings',
        emailBlockLabel: () => 'E-mail',
        addressBlockLabel: () => 'Address',
        vehicleBlockLaber: () => 'Vehicle',
        badgeBlockLabel: () => 'Badge',
        badgeOrdered: () => 'The badge will be shipped',
    },
};
