import { css } from '@emotion/css';
import { Colors } from '../../ui/vars';
import { Clickable, ClickHandler } from '../../ui/Clickable';
import React from 'react';
import { Typo } from '../../ui/typo.ts';

/*
   A generic Button div that implements a ClickableDiv with a "pressed"
   state and correct handling of DOM props (role, type, elevation) plus
   the touchRippleColor
 */

interface BasicButtonProps {
    onClick: ClickHandler | null;
    label: React.ReactNode;
    disabled?: boolean;
    negative?: boolean;
    dark?: boolean;
    link?: string | null;
    active?: boolean;
    arrow?: 'right' | null;
}

export class BasicButton extends React.Component<
    BasicButtonProps,
    {
        pressed: boolean;
    }
> {
    constructor(props: BasicButtonProps) {
        super(props);
        this.state = { pressed: false };
    }

    getDepth(): number {
        if (this.props.disabled) {
            return 0;
        }
        if (this.state.pressed) {
            return 3;
        }
        return 2;
    }

    render() {
        return (
            <Clickable
                element={this.props.link ? 'a' : 'button'}
                target={this.props.link ? '_blank' : undefined}
                href={this.props.link || undefined}
                className={
                    BasicButtonStyle +
                    (this.props.link ? ` ${BasicButtonLinkStyle}` : '')
                }
                onClick={this.props.onClick}
                onClickStart={(): void => this.setState({ pressed: true })}
                onClickEnd={(): void => this.setState({ pressed: false })}
                data-negative={this.props.negative || false}
                data-dark={this.props.dark || false}
                data-disabled={this.props.disabled || false}
                data-active={this.props.active || false}
                data-elevation={this.getDepth()}
                data-arrow={this.props.arrow}
                disabled={this.props.disabled}
            >
                {this.props.label}
            </Clickable>
        );
    }
}

export const BasicButtonStyle = css`
    background: none;
    border: 0;
    color: inherit;
    line-height: normal;
    overflow: visible;
    outline: none;
    padding: 0;
    height: 40px;
    padding: 0 15px;
    border-radius: 2px;
    position: relative;
    font-family: ${Typo.robotoMedium.fontFamily};
    font-weight: ${Typo.robotoMedium.fontWeight};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;

    &[data-elevation='0'] {
        box-shadow: none;
    }

    &[data-elevation='2'] {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    }

    &[data-negative='false'] {
        background: ${Colors.white};
        color: ${Colors.blue};
    }

    &[data-negative='true'] {
        background: ${Colors.blue};
        color: ${Colors.white};
    }

    &[data-dark='true'] {
        background: ${Colors.darkblue};
        color: ${Colors.white};
    }

    &[data-disabled='true'] {
        opacity: 0.4;
    }

    &[data-active='true'] {
        background: ${Colors.blue};
        color: ${Colors.white};
    }

    &[data-arrow='right']::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 20px solid rgba(255, 255, 255, 0);
        border-bottom: 20px solid rgba(255, 255, 255, 0);
        border-left: 16px solid ${Colors.white};
        top: 0;
        right: -15px;
    }
`;

const BasicButtonLinkStyle = css`
    text-decoration: none;
    display: inline-block;
    padding-top: 11px;
`;
