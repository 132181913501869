import { Legal } from 'dg-web-shared/lib/legal/legal_module_imports.ts';
import { useStore } from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../common/state/SettingsState';

export function TosLink({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    const settings = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
    })).storeState.settings;
    return (
        <a
            target="_blank"
            href={Legal.tosFiles[settings.language]}
            className={className}
            rel="noreferrer"
        >
            {children}
        </a>
    );
}
