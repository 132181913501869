import { useStore } from 'dg-web-shared/lib/Flux';
import { Localized } from '../../common/components/Localized';
import { mobileCloseTransactions } from '../../layout/actions/LayoutActions';
import { SlideIn } from '../../ui/slidein/SlideIn';
import * as TransactionsListState from '../state/TransactionsListState';
import { PermitDetailFetching } from './Permit';
import { ModalSuccessBox } from '../../ui/modals/Confirmable';
import { useState } from 'react';

export function PermitPurchaseConfirmationSlideIn() {
    const [closeModal, setCloseModal] = useState(false);
    const { storeState, update } = useStore(s => {
        const transactionsListDetail = TransactionsListState.Detail.get(s);
        return {
            transactionsListDetail,
            permitDetail: transactionsListDetail.purchasedPermitId
                ? TransactionsListState.PermitDetail.get(s, {
                      id: transactionsListDetail.purchasedPermitId,
                  })
                : null,
        };
    });

    return (
        <SlideIn
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
            open={!!storeState.transactionsListDetail.purchasedPermitId}
            onClose={() => {
                update(store =>
                    TransactionsListState.Detail.stateWrite(store, {
                        purchasedPermitId: null,
                        permitPurchaseIncludedBalanceTopUp: false,
                    }),
                );

                update(mobileCloseTransactions);
                setCloseModal(false);
            }}
        >
            {!!storeState.transactionsListDetail.purchasedPermitId &&
                !closeModal && (
                    <ModalSuccessBox
                        titleCaption={
                            <Localized
                                de="Zahlung abgeschlossen"
                                fr="Paiement effectué"
                                it="Pagamento eseguito"
                                en="Payment executed"
                            />
                        }
                        confirmCallback={() => {
                            setCloseModal(true);
                        }}
                    >
                        {storeState.transactionsListDetail
                            .permitPurchaseIncludedBalanceTopUp ? (
                            <p>
                                <Localized
                                    de="Die Zahlung wurde ausgeführt und die Bewilligung wurde erfolgreich gekauft."
                                    fr="Le paiement a été exécuté et l'autorisation a été achetée avec succès."
                                    it="Il pagamento è stato eseguito e l'autorizzazione è stata acquistata con successo."
                                    en="The payment has been executed and the permit was successfully purchased."
                                />
                            </p>
                        ) : (
                            <p>
                                <Localized
                                    de="Die Bewilligung wurde erfolgreich gekauft."
                                    fr="L'autorisation a été achetée avec succès."
                                    it="L'autorizzazione è stata acquistata con successo."
                                    en="The permit was successfully purchased."
                                />
                            </p>
                        )}
                    </ModalSuccessBox>
                )}

            {!!storeState.permitDetail && (
                <PermitDetailFetching
                    permitDetail={storeState.permitDetail}
                    renewalIsOkInCurrentContext={false}
                />
            )}
        </SlideIn>
    );
}
