import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import {
    getLicensePlateStates,
    licensePlateErrorText as genericlicensePlateErrorText,
    LicensePlateState,
} from 'dg-web-shared/lib/LicensePlateValidation';
import {
    getRfidCardStates,
    RfidCardState,
} from 'dg-web-shared/lib/RfidCardValidation';
import * as String from 'dg-web-shared/lib/String';
import { Language } from 'dg-web-shared/lib/Text';
import { ibanTexts } from '../common/i18n/FieldTexts';
import { LicensePlateErrorTexts } from 'dg-web-shared/common/components/translations/LicensePlateErrorTexts.ts';
import { Field, Iban, IbanState } from 'dg-web-shared/model/Iban.ts';
import { Translation } from '../common/i18n/Text';
import * as StringConversions from 'dg-web-shared/lib/StringConversions';
import * as emailValidator from 'email-validator';

export enum EmailState {
    INVALID,
}

function forceString(str: string): string {
    return str ? str : '';
}

export function hasState<S>(field: Field<S>, state: S): boolean {
    return field.states.indexOf(state) !== -1;
}

export class Email implements Field<EmailState> {
    constructor(value: string) {
        this._value = forceString(value);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): EmailState[] {
        if (!this.isEmpty && !emailValidator.validate(this.value)) {
            return [EmailState.INVALID];
        }
        return [];
    }
}

export interface EmailTexts {
    Invalid: Translation;
}

export function emailErrorText(field: Email, text: EmailTexts): string {
    if (hasState(field, EmailState.INVALID)) {
        return text.Invalid();
    }
    return '';
}

export const licensePlateErrorText = (
    field: LicensePlate,
    texts: LicensePlateErrorTexts,
): string =>
    genericlicensePlateErrorText(
        { licensePlateNr: field.value, licensePlateCountryId: field.country },
        texts,
    );

export const stripNotAllowedLpCharachters = (
    value: string,
    countryId: Maybe<string>,
): string => {
    switch (countryId) {
        case 'CH':
        case 'FL':
            return StringConversions.stripNonNumericAndNoLetterCharacters(
                forceString(value),
            );
        default:
            return StringConversions.stripNonNumericAndNoLetterCharactersAllowUmlauts(
                forceString(value),
            );
    }
};

export class LicensePlate implements Field<LicensePlateState> {
    private _countryId: string;

    constructor(value: string | null | undefined, countryId: string) {
        this._countryId = countryId;
        this._value = stripNotAllowedLpCharachters(value || '', countryId);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get country(): string {
        return this._countryId;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): LicensePlateState[] {
        return getLicensePlateStates({
            licensePlateNr: this._value,
            licensePlateCountryId: this._countryId,
        });
    }
}

export class Password implements Field<void> {
    constructor(value: string) {
        this._value = forceString(value);
    }

    private _value: string;

    get value(): string {
        return forceString(this._value);
    }

    get isEmpty(): boolean {
        return this._value === '';
    }

    get states(): void[] {
        return [];
    }
}

export class StandardBadgeNumber implements Field<RfidCardState> {
    constructor(value: string) {
        value = forceString(value);
        this._value =
            StringConversions.stripNonNumericCharactersAllowSpace(value);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): RfidCardState[] {
        if (this.value === '') {
            return [];
        } else {
            return getRfidCardStates(this.value);
        }
    }
}

export enum MobilePhoneState {
    MUST_CONTAIN_PREFIX,
    INVALID,
    TOO_SHORT,
    TOO_LONG,
}

export class MobilePhone implements Field<MobilePhoneState> {
    private prefix = '+';

    constructor(value: string) {
        this._value =
            StringConversions.stripNonNumericCharactersAllowSpaceAndPlus(
                forceString(value),
            );
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): MobilePhoneState[] {
        const states: MobilePhoneState[] = [];
        if (this.isEmpty) {
            return states;
        }
        const valueClean = StringConversions.stripNonNumericCharactersAllowPlus(
            this.value,
        );

        if (valueClean[0] !== this.prefix[0]) {
            states.push(MobilePhoneState.MUST_CONTAIN_PREFIX);
            return states;
        }

        const inputOnly = valueClean.substr(1, valueClean.length - 1);

        if (inputOnly.length < 10) {
            states.push(MobilePhoneState.TOO_SHORT);
        }

        if (inputOnly.length > 13) {
            states.push(MobilePhoneState.TOO_LONG);
        }

        if (!/^\+[1-9][0-9]{9,12}$/.test(valueClean)) {
            states.push(MobilePhoneState.INVALID);
        }

        return states;
    }
}

export class GenericField implements Field<void> {
    constructor(value: string) {
        this._value = forceString(value);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): void[] {
        return [];
    }
}

export class NumberField implements Field<void> {
    constructor(value: string) {
        this._value = StringConversions.stripNonNumericCharacters(
            forceString(value),
        );
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): void[] {
        return [];
    }
}

export interface IbanTexts {
    NoValidCountryCode: Translation;
    ChecksumFail: Translation;
    TooShort: Translation;
    TooLong: Translation;
    AllLettersUppercase: Translation;
}

export function ibanErrorText(badge: Iban, language: Language): string {
    const t = ibanTexts[language];

    const map: [IbanState, string][] = [
        [IbanState.NOT_UPPERCASE, t.AllLettersUppercase()],
        [IbanState.TOO_SHORT, t.TooShort()],
        [IbanState.TOO_LONG, t.TooLong()],
        [IbanState.NO_VALID_COUNTRY_CODE, t.NoValidCountryCode()],
        [IbanState.CHECKSUM_FAIL, t.ChecksumFail()],
    ];

    const match = map.find(([err, _]) => hasState(badge, err));
    return match ? match[1] : '';
}

export enum BicState {
    NOT_UPPERCASE,
    TOO_SHORT,
    TOO_LONG,
    DOES_NOT_MATCH_IBAN,
    INVALID,
}

export interface BicTexts {
    TooShort: Translation;
    TooLong: Translation;
    AllLettersUppercase: Translation;
    DoesNotMatchIban: Translation;
    Invalid: Translation;
}

export function bicErrorText(badge: Bic, text: BicTexts): string {
    if (hasState(badge, BicState.NOT_UPPERCASE)) {
        return text.AllLettersUppercase();
    }
    if (hasState(badge, BicState.TOO_SHORT)) {
        return text.TooShort();
    }
    if (hasState(badge, BicState.TOO_LONG)) {
        return text.TooLong();
    }
    if (hasState(badge, BicState.DOES_NOT_MATCH_IBAN)) {
        return text.DoesNotMatchIban();
    }
    if (hasState(badge, BicState.INVALID)) {
        return text.Invalid();
    }
    return '';
}

export class Bic implements Field<BicState> {
    private _validationCountry: string;

    // the validationCountry is the country given in the first two digits of the IBAN
    constructor(value: string, validationCountry: string) {
        value = forceString(value);
        this._value =
            StringConversions.stripNonNumericAndNoLetterCharacters(value);
        this._validationCountry = validationCountry;
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): BicState[] {
        const states: BicState[] = [];
        if (this.isEmpty) {
            return states;
        }

        if (this.value.length < 8) {
            states.push(BicState.TOO_SHORT);
        }

        if (this.value.length > 11) {
            states.push(BicState.TOO_LONG);
        }

        if (!String.Validators.isUpperCase(this.value)) {
            states.push(BicState.NOT_UPPERCASE);
        }

        if (this.value.substring(4, 6) !== this._validationCountry) {
            states.push(BicState.DOES_NOT_MATCH_IBAN);
        }

        if (
            !this.value.match(
                /[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/,
            )
        ) {
            states.push(BicState.INVALID);
        }
        return states;
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function noStates(...fields: Field<any>[]): boolean {
    for (const f of fields) {
        if (f.states.length > 0) {
            return false;
        }
    }
    return true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function noStatesAndNotEmpty(...fields: Field<any>[]): boolean {
    for (const f of fields) {
        if (f.states.length > 0 || f.isEmpty) {
            return false;
        }
    }
    return true;
}
