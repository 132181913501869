import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { LandingPageAnchor } from './LandingPageFooter';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { Localized } from '../../common/components/Localized';
import { ContentMode, SignUpSlideinState } from './state/SignUpSlideinState';
import { Updater } from 'dg-web-shared/lib/Flux';
import { logAction } from '../../utils/ActionLog';
import { DesktopBreakpointWidth } from './LandingPageVars';
import { isAndroid } from 'dg-web-shared/common/utils/BrowserOrigin';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import relief from './assets/swiss-relief.png';
import moodImage from './assets/parking-emotion.jpg';

export const BlueBackdrop = (p: {
    children?: React.ReactNode;
    image: 'mood' | 'relief';
    spaceBetween?: boolean;
}) => {
    return (
        <div
            className={css({
                position: 'relative',
                overflow: 'hidden',
                padding: '40px 20px',
                [`@media(min-width: ${DesktopBreakpointWidth}px)`]: {
                    padding: '50px 20px',
                },
                ':before': {
                    content: '" "',
                    backgroundImage: `url(${
                        p.image === 'mood' ? moodImage : relief
                    })`,
                    backgroundPosition:
                        p.image === 'mood' ? '100% 100%' : 'center',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    opacity: 0.09,
                    zIndex: -1,
                },
                ':after': {
                    content: '" "',
                    background: Colors.blue,
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    zIndex: -2,
                },
            })}
        >
            <div
                className={css({
                    maxWidth: '500px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                })}
            >
                {p.children}
            </div>
        </div>
    );
};

export const BlueBackdropHeader = (p: {
    children?: React.ReactNode;
    anchor?: LandingPageAnchor;
}) => {
    return (
        <h1
            id={p.anchor}
            className={css({
                fontSize: '33px',
                margin: '0 0 30px 0',
                ...Typo.robotoUltraBold,
                color: Colors.white,
            })}
        >
            {p.children}
        </h1>
    );
};

export const LoadAppOrSignUp = (p: {
    update: Updater;
    showLoadApp: boolean;
    type: ButtonType;
}) => {
    const android = isAndroid();
    return (
        <>
            {p.showLoadApp ? (
                <Button
                    type={p.type}
                    onClick={() =>
                        p.update(s =>
                            logAction(s, 'landing-page-click-load-app'),
                        )
                    }
                    link={
                        android
                            ? 'https://play.google.com/store/apps/details?id=ch.parkingcard.customer'
                            : 'https://itunes.apple.com/ch/app/parkingpay/id1104202443'
                    }
                >
                    <Localized
                        de="App laden"
                        fr="Télécharger l'appli"
                        it="Scarica l'app"
                        en="Download App"
                    />
                </Button>
            ) : (
                <Button
                    type={p.type}
                    onClick={() => {
                        p.update(store =>
                            SignUpSlideinState.stateWrite(store, {
                                loginSlideinOpen: true,
                                contentMode: ContentMode.SIGNUP,
                            }),
                        );
                        p.update(s =>
                            logAction(s, 'landing-page-click-sign-up'),
                        );
                    }}
                >
                    <Localized
                        de="Registrieren"
                        fr="S'enregistrer"
                        it="Registrarsi"
                        en="Sign up"
                    />
                </Button>
            )}
        </>
    );
};

export enum ButtonType {
    white = 'white',
    blue = 'blue',
}

const Button = (p: {
    children?: React.ReactNode;
    type: ButtonType;
    onClick?: () => void;
    link?: string;
}) => {
    return (
        <Clickable
            element={p.link ? 'a' : 'div'}
            href={p.link}
            target={p.link ? '_blank' : undefined}
            onClick={p.onClick}
            className={css({
                height: '64px',
                margin: '10px 0',
                background:
                    p.type === ButtonType.white ? Colors.white : Colors.blue,
                color: p.type === ButtonType.white ? Colors.blue : Colors.white,
                fontSize: '16px',
                ...Typo.robotoBold,
                letterSpacing: '0.8',
                textAlign: 'center',
                lineHeight: '64px',
                borderRadius: '2px',
                display: 'block',
                textDecoration: 'none',
                outline: 'none',
            })}
        >
            {p.children}
        </Clickable>
    );
};
