import { AccountSetupAddLicensePlateTexts } from '../components/AccountSetupAddLicensePlate';
import { Translations } from '../../common/i18n/Text.ts';

/* tslint:disable:max-line-length */
export const addLicensePlateTexts: Translations<AccountSetupAddLicensePlateTexts> =
    {
        de: {
            addLicensePlateTitle: () => 'Erfassen Sie Ihr Kennzeichen',
            licensePlateNrLabel: () => 'Kennzeichen',
            licensePlateCountryLabel: () => 'Land',
            licensePlateTypeLabel: () => 'Schildart',
            licensePlateTypeDescription: () => 'Fahrzeugbezeichnung',
            licensePlateTypeMore: () =>
                'Nach der Registrierung können weitere Fahrzeuge direkt im Konto erfasst und verwaltet werden.',
        },
        fr: {
            addLicensePlateTitle: () => "Saisir l'immatricolation",
            licensePlateNrLabel: () => 'Immatriculation',
            licensePlateCountryLabel: () => 'Pays',
            licensePlateTypeLabel: () => 'Type de plaque',
            licensePlateTypeDescription: () => 'Désignation véhicule',
            licensePlateTypeMore: () =>
                "Après l'enregistrement vous pouvez ajouter et gérer d'autres véhicules directement dans le compte.",
        },
        it: {
            addLicensePlateTitle: () => 'Inserire la targa',
            licensePlateNrLabel: () => 'Targa',
            licensePlateCountryLabel: () => 'Nazione',
            licensePlateTypeLabel: () => 'Tipo di targa',
            licensePlateTypeDescription: () => 'Denominazione veicolo',
            licensePlateTypeMore: () =>
                'Dopo la registrazione è possibile aggiungere e gestire ulteriori veicoli direttamente nel conto.',
        },
        en: {
            addLicensePlateTitle: () => 'Enter your license plate',
            licensePlateNrLabel: () => 'License plate',
            licensePlateCountryLabel: () => 'Country',
            licensePlateTypeLabel: () => 'Category',
            licensePlateTypeDescription: () => 'Vehicle description',
            licensePlateTypeMore: () =>
                'After the registration you can add and manage additional vehicles directly into your account.',
        },
    };
