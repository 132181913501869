import { Conditional, HasChildren } from 'dg-web-shared/lib/ReactHelpers';
import { getOrNull, isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';
import { BasicButton } from 'dg-web-shared/tb-ui/buttons/BasicButton.tsx';
import { Translation } from 'dg-web-shared/lib/Text';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import { ReactNode } from 'react';
import { Icon16 } from 'dg-web-shared/ui/icons/Icon';

export interface AccountSetupBlockTexts {
    yesLabel: Translation;
    noLabel: Translation;
}

interface AccountSetupBlockProps extends HasChildren {
    title: string;
    nextLabel?: React.ReactNode;
    nextDisabled?: boolean;
    previousLabel?: string;
    noArrow?: Maybe<boolean>;
    onNext?: Maybe<ClickHandler>;
    onPrevious?: Maybe<ClickHandler>;
}

export const AccountSetupBlock = (p: AccountSetupBlockProps) => (
    <div
        className={css({
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        })}
    >
        <div
            className={css({
                position: 'absolute',
                top: 0,
                height: '64px',
                width: '100%',
                background: Colors.blue,
                display: 'flex',
                alignItems: 'center',
            })}
        >
            <div
                className={css({
                    color: Colors.white,
                    marginLeft: '24px',
                    fontSize: '20px',
                    lineHeight: '24px',
                    fontFamily: 'Roboto',
                    fontWeight: 700,
                })}
            >
                {p.title}
            </div>
            <Conditional c={isDefined(p.onNext)}>
                <div
                    className={css({
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '30px',
                    })}
                >
                    <BasicButton
                        arrow={p.noArrow ? null : 'right'}
                        label={p.nextLabel || ''}
                        onClick={getOrNull(p.onNext) || null}
                        disabled={p.nextDisabled}
                    />
                </div>
            </Conditional>
        </div>
        <div
            className={css({
                position: 'absolute',
                top: '64px',
                width: '100%',
                bottom: 0,
                overflowX: 'hidden',
                overflowY: 'auto',
                background: Colors.content,
            })}
        >
            <div
                className={css({
                    padding: '16px 25px',
                    background: Colors.white,
                    ...paper(2),
                })}
            >
                {p.children}
            </div>
            <Conditional c={isDefined(p.onPrevious)}>
                <Clickable
                    element="div"
                    onClick={p.onPrevious}
                    className={css({
                        color: Colors.action_w,
                        display: 'flex',
                        padding: '22px 20px',
                    })}
                >
                    <Icon16 icon={Icons16.chevronLeft} />
                    <div
                        className={css({
                            marginLeft: '8px',
                            ...Typo.captionC1,
                        })}
                    >
                        {p.previousLabel}
                    </div>
                </Clickable>
            </Conditional>
        </div>
    </div>
);

export function Para({ children }: { children: ReactNode }) {
    return (
        <div
            className={css({
                color: Colors.blue,
                ...Typo.body,
            })}
        >
            {children}
        </div>
    );
}

export function SummaryTitle({ children }: { children: ReactNode }) {
    return (
        <div
            className={css({
                color: Colors.blue,
                marginTop: '25px',
                ...Typo.body,
            })}
        >
            {children}
        </div>
    );
}
