import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Localized } from '../Localized';
import { ParkingcardTypo } from '../../../style/parkingcardTypo.ts';
import {
    InfoFieldWithIcon,
    TicketContent,
    TicketContentText,
    TicketInfoHeader,
    TicketTitle,
} from './TicketPlusLayout';
import { TicketProcessIcon } from 'dg-web-shared/ui/icons/IconsScalable';
import { TicketState } from './TicketPlusTypes';
import { TicketPlusTicketEntry } from './TicketPlusTicketEntry';

export const TicketPlusTicketApproved = ({
    ticketState,
    ticketApiString,
    ticketCreatedAt,
    onOpenZoneInfo,
    zoneName,
}: {
    ticketState: TicketState;
    ticketApiString: string;
    ticketCreatedAt: string;
    onOpenZoneInfo: () => void;
    zoneName: string;
}) => (
    <TicketContent>
        <TicketInfoHeader>
            <Localized de="Parking" fr="Parking" it="Parcheggio" en="Parking" />
        </TicketInfoHeader>
        <InfoFieldWithIcon onClick={onOpenZoneInfo}>
            {zoneName}
        </InfoFieldWithIcon>
        <TicketInfoHeader>
            <Localized
                de="Ticket-ID"
                fr="ID ticket"
                it="ID ticket"
                en="Ticket ID"
            />
        </TicketInfoHeader>
        {ticketApiString}
        <TicketPlusTicketEntry createdAt={ticketCreatedAt} />
        <TicketInfoHeader>
            <Localized de="Status" fr="Etat" it="Stato" en="Status" />
        </TicketInfoHeader>
        <TranslationForTicketState ticketState={ticketState} />
        <TicketContentText>
            <CenteredBarrierDisplay>
                <Localized
                    de="Bei der Ausfahrt, stecken Sie das Ticket wie gewohnt ein."
                    fr="En sortant, insérez votre ticket comme d'habitude."
                    it="All'uscita, inserisca il ticket come di consueto."
                    en="At the exit, enter the ticket as usual."
                />
                <br />
                <Localized
                    de="Die Parkgebühr wird berechnet und automatisch belastet."
                    fr="La taxe de stationnement sera calculée et automatiquement débitée."
                    it="La tassa di parcheggio verrà calcolata e addebitata automaticamente."
                    en="The parking fee will be calculated and automatically debited."
                />
            </CenteredBarrierDisplay>
        </TicketContentText>
        <TicketTitle>
            <Localized
                de="Den Weg zur Kasse sparen!"
                fr="Éviter de passer à la caisse!"
                it="Eviti di passare alla cassa!"
                en="Save the way to the cash desk!"
            />
        </TicketTitle>
    </TicketContent>
);

function TranslationForTicketState({
    ticketState,
}: {
    ticketState: TicketState;
}) {
    switch (ticketState) {
        case TicketState.ALIAS_PENDING:
            return (
                <Localized
                    de="Zahlungsmittel fehlt"
                    fr="Moyen de paiement manquant"
                    it="Mezzo di pagamento mancante"
                    en="Missing payment mean"
                />
            );
        default:
            return (
                <Localized
                    de="Automatische Zahlung aktiv"
                    fr="Paiement automatique actif"
                    it="Pagamento automatico attivo"
                    en="Automatic payment active"
                />
            );
    }
}

const RevokeApprovalWrapper = styled.div({
    textAlign: 'center',
    textDecoration: 'underline',
    padding: 28,
    opacity: 0.8,
    cursor: 'pointer',
    ...ParkingcardTypo.caption,
});

export const TicketPlusRevokeApprovalLink = ({
    onClick,
}: {
    onClick: () => void;
}) => (
    <RevokeApprovalWrapper onClick={onClick}>
        <Localized
            de="Klicken Sie hier, um die automatische Zahlung zu deaktivieren"
            fr="Cliquer ici pour désactiver le paiement automatique"
            it="Cliccare qui per disattivare il pagamento automatico"
            en="Click here to disable the automatic payment"
        />
    </RevokeApprovalWrapper>
);

const CenteredBarrierDisplay = ({
    children,
}: {
    children: React.ReactNode;
}) => (
    <div
        className={css({
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 0',
            alignItems: 'center',
            textAlign: 'center',
        })}
    >
        <div
            className={css({
                width: 75,
                height: 75,
                position: 'relative',
                flexShrink: 0,
            })}
        >
            <TicketProcessIcon icon={'barrier'} size={'small'} />
        </div>
        <TicketContentText>{children}</TicketContentText>
    </div>
);
