/* tslint:disable:no-unused-variable */

/* tslint:enable:no-unused-variable */
import * as Flux from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../../../common/state/SettingsState';
import * as LayoutState from '../../../layout/state/LayoutState';
import { texts as addressTexts } from '../i18n/AddressTexts';
import { ResponsiveProperties } from '../../../layout/utils/ResponsiveProperties';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import { ThreeColumnsProperties } from '../../../layout/utils/ThreeColumnsProperties';
import logo from '../assets/pplogo.svg';

export interface AddressTexts {
    content: () => JSX.Element;
}

export interface AddressState {
    settings: SettingsState.State;
    layout: LayoutState.State;
}

export class Address extends Flux.Container<AddressState> {
    static displayName = 'Address';

    stateSelector(): AddressState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
            settings: new SettingsState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        const texts = addressTexts[this.state.settings.language];
        const responsiveProps = new ResponsiveProperties(this.state);
        const columnProps = new ThreeColumnsProperties(this.state);
        const isMobileColumn = responsiveProps.mobile && columnProps.hasColumn1;
        return (
            <div
                className={css({
                    ...paper(isMobileColumn ? 0 : 4),
                    background: Colors.blue,
                    borderRadius: isMobileColumn ? 0 : '2px',
                    padding: '24px',
                    marginTop: '32px',
                })}
            >
                <img
                    className={css({
                        width: '220px',
                        marginBottom: '24px',
                    })}
                    src={logo}
                />
                <div
                    className={css({
                        ...Typo.body,
                        color: Colors.rgba(Colors.action_b, 0.8),
                    })}
                >
                    {texts.content()}
                </div>
            </div>
        );
    }
}
