export enum InvoiceChannelType {
    EMAIL = 'EMAIL',
    PAPER = 'PAPER',
}

export enum InvoiceType {
    INVOICE = 'INVOICE',
    REMINDER = 'REMINDER',
    WARNING = 'WARNING',
    WARNING_DEBT_COLLECTION = 'WARNING_DEBT_COLLECTION',
}
