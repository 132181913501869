import { InputContext, inputContextToColor } from '../inputs/InputContext';

import { css } from '@emotion/css';
import { JsxMessage, Message } from '../../lib/Localized';
import { Localized } from '../../common/hooks/LanguageProvider.tsx';
import { Label } from '../inputs/internal/Label';
import React from 'react';
import { Typo } from '../../ui/typo.ts';

interface Props {
    label: string | JsxMessage | Message;
    context?: InputContext;
    selectable?: boolean;
    rightText?: string;
    children?: React.ReactNode;
    key?: React.Key;
    rightAlign?: boolean;
    compactHeight?: number;
    superCompactHeight?: number;
}

export class LabeledText extends React.Component<Props> {
    render() {
        const color = inputContextToColor(
            this.props.context || InputContext.regular,
        );

        return (
            <div
                className={css({
                    color: color,
                    ...Typo.captionC4,
                    marginTop: '8px',
                    marginBottom: '24px',
                    [`@media(max-height: ${this.props.compactHeight || 0}px)`]:
                        {
                            marginTop: 0,
                            marginBottom: '12px',
                        },
                    [`@media(max-height: ${
                        this.props.superCompactHeight || 0
                    }px)`]: {
                        marginBottom: '8px',
                        marginTop: 0,
                    },
                    textAlign: this.props.rightAlign ? 'right' : 'left',
                })}
            >
                <Label
                    context={this.props.context || InputContext.regular}
                    elevated={true}
                    hideHeight={this.props.superCompactHeight}
                    rightText={this.props.rightText}
                    text={
                        typeof this.props.label === 'string' ? (
                            this.props.label
                        ) : (
                            <Localized {...this.props.label} />
                        )
                    }
                />
                <div
                    className={css({
                        ...Typo.body,
                        color: color,
                        userSelect: this.props.selectable ? 'text' : 'auto',
                    })}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export const Factory = React.createFactory<Props>(LabeledText);
