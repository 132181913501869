import * as Account from '../components/Account';
import { Translations } from '../../../common/i18n/Text.ts';

export const texts: Translations<Account.Texts> = {
    de: {
        Account: () => 'Konto',
        Help: () => 'Hilfe',
    },
    fr: {
        Account: () => 'Compte',
        Help: () => 'Aide',
    },
    it: {
        Account: () => 'Conto',
        Help: () => 'Aiuto',
    },
    en: {
        Account: () => 'Account',
        Help: () => 'Help',
    },
};
