import {
    portalSlideIn,
    SlideInPortalId,
} from '../../../account/root/components/PortalSlidein';

import { useState } from 'react';
import { TopUpPaymentSlideIn } from '../../../account/top-up-balance/e-payment-top-up/TopUpPayment';
import { Localized } from '../../../common/components/Localized';
import * as PaymentTypeState from '../../../common/state/PaymentTypeState';
import {
    PaymentCategory,
    PaymentRequestMode,
} from '../../../common/payment/Payment';
import { TimeSelectionWheelTick } from '../zone-transaction/ZoneEnforcedCheckin';
import { useStore, useUpdate } from 'dg-web-shared/lib/Flux';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import * as AccountBalanceState from '../../../common/state/AccountBalanceState';
import { MaterialButton } from '../../../ui/inputs/MaterialButton';
import { StoreAliasRequest } from '../../../common/payment/StoreAliasRequest';
import { HandleHttpStati } from '../../../ui/modals/HandleHttpStati';
import { AdditionalCosts } from '../../../common/payment/FormTextSnippets';
import { css } from '@emotion/css';
import { SlideInFormContainer } from '../../../account/root/components/SlideInForm';
import { ParkingcardTypo } from '../../../style/parkingcardTypo.ts';
import {
    aliasIsValid,
    paymentCategoryFromAliasPaymentMethod,
} from '../../../account/top-up-balance/EPaymentMode';
import {
    ConsumeSaldoFirstText,
    usePaymentTypeChange,
} from '../../../account/payment-method/DirectMethod';
import { numberToPrice } from '../../../utils/NumberFormatter';
import { logAction } from '../../../utils/ActionLog';
import {
    PaymentCallbackState,
    PaymentStatus,
} from '../../../common/components/PaymentCallback';
import {
    SavedCreditCardAliasResponse,
    SavedCreditCardAliasResponseTag,
} from 'dg-web-shared/model/PaymentAlias';
import {
    createStoreAliasPayload,
    PaymentOriginInfoType,
    PaymentOriginType,
    StoreAliasPayloadBase,
} from './AddPaymentMethodParkCreateMenu';
import { useServerSuccessEffect } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { PaymentMethodEnum } from '../../../api/Http';
import { UiPaymentOrigin } from 'dg-web-shared/common/utils/TwintPairingParkingpay';
import {
    DelimiterVariant,
    OrDelimiter,
} from '../../../common/components/OrDelimiter';
import { useParkingpayLanguage } from '../../../utils/UseParkingpayLanguage';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export function DirectOrTopupSwitch({
    paymentRequestMode,
    paymentCategory,
    open,
    onClose,
    aliasData,
    paymentOriginInfo,
    portal,
}: {
    paymentRequestMode: PaymentRequestMode;
    paymentCategory: PaymentCategory;
    open: boolean;
    onClose: () => void;
    aliasData: SavedCreditCardAliasResponse;
    paymentOriginInfo: PaymentOriginInfoType;
    portal: SlideInPortalId;
}) {
    const update = useUpdate();
    const [currentLogin] = CurrentLoginState.use();
    const [wantsTopUp, setWantsTopUp] = useState(false);
    if (currentLogin.data == null) {
        return null;
    }
    return (
        <>
            <DirectOrTopupSelection
                aliasData={aliasData}
                portal={portal}
                open={open}
                onClose={onClose}
                title={
                    <Localized
                        {...{
                            de: 'Zahlungsweise',
                            fr: 'Mode de paiement',
                            it: 'Modalità di pagamento',
                            en: 'Payment mode',
                        }}
                    />
                }
                setWantsToTopUp={() => {
                    setWantsTopUp(true);
                    update(s => logAction(s, 'select-top-up-from-checkin'));
                }}
                paymentCategory={paymentCategory}
                paymentOriginInfo={paymentOriginInfo}
            />
            <TopUpPaymentSlideIn
                portal={portal}
                open={open && wantsTopUp}
                onClose={() => {
                    setWantsTopUp(false);
                    onClose();
                }}
                onDirectEPaymentSuccess={() => {
                    setWantsTopUp(false);
                    onClose();
                    update(TimeSelectionWheelTick.tick);
                }}
                title={
                    <Localized
                        {...{
                            de: 'Konto laden',
                            fr: 'Alimenter le compte',
                            it: 'Ricaricare il conto',
                            en: 'Top up account',
                        }}
                    />
                }
                paymentRequestMode={paymentRequestMode}
                paymentCategory={paymentCategory}
                paymentOriginInfo={paymentOriginInfo}
            />
        </>
    );
}

const DirectOrTopupSelection = portalSlideIn(function DirectOrTopupSelection({
    aliasData,
    setWantsToTopUp,
    paymentCategory,
    onClose,
    paymentOriginInfo,
}: {
    aliasData: SavedCreditCardAliasResponse;
    setWantsToTopUp: () => void;
    paymentCategory: PaymentCategory;
    onClose: () => void;
    paymentOriginInfo: PaymentOriginInfoType;
}) {
    const update = useUpdate();
    const language = useParkingpayLanguage();
    const [storeAlias, sendStoreAlias, resetAlias] = StoreAliasRequest.use();

    const { storeState } = useStore(store => ({
        accountBalance: new AccountBalanceState.StateSlice(store).state,
        allState: store,
    }));

    const [paymentToDirect, setPaymentTypeToDirect, resetPaymentTypeToDirect] =
        usePaymentTypeChange();
    useServerSuccessEffect(paymentToDirect, () => {
        update(s => {
            new PaymentTypeState.StateSlice(s).reset();
            new AccountBalanceState.StateSlice(s).reset();
            logAction(s, 'set-payment-method', 'DIRECT');
            return 'log-and-reset-payment-type-and-account-balance-state';
        });
        update(store =>
            PaymentCallbackState.stateWrite(store, {
                permitId: null,
                pmethod: '',
                status: PaymentStatus.SUCCESS,
                uiPaymentOrigin:
                    paymentOriginInfo.type ===
                    PaymentOriginType.CHECKIN_BY_TICKET
                        ? UiPaymentOrigin.ACTIVATE_ALIAS_FROM_TICKET
                        : UiPaymentOrigin.ACTIVATE_ALIAS_FROM_TOPUP,
            }),
        );
        onClose();
    });

    const saldoIsPositive = storeState.accountBalance.data.balance > 0;

    const balanceText = storeState.accountBalance.data
        ? numberToPrice(storeState.accountBalance.data.balance)
        : '-';

    const aliasSelected =
        aliasData.tag === SavedCreditCardAliasResponseTag.FOUND &&
        paymentCategoryFromAliasPaymentMethod(
            aliasData.creditCardAlias.paymentMethod,
        ) === paymentCategory;

    const validAliasSelected =
        aliasData.tag === SavedCreditCardAliasResponseTag.FOUND &&
        aliasIsValid(aliasData.creditCardAlias) &&
        paymentCategoryFromAliasPaymentMethod(
            aliasData.creditCardAlias.paymentMethod,
        ) === paymentCategory;

    return (
        <SlideInFormContainer>
            <HandleHttpStati
                serverHooks={[
                    [storeAlias, resetAlias],
                    [paymentToDirect, resetPaymentTypeToDirect],
                ]}
                showSpinner={true}
                ignore400={false}
            />
            <>
                <div className={css({ lineHeight: '22px' })}>
                    <p
                        className={css({
                            ...ParkingcardTypo.heading4,
                            textTransform: 'uppercase',
                        })}
                    >
                        {IndividualPayment}
                    </p>
                    <p>
                        <Localized
                            de="Die Parkgebühren werden dem Zahlungsmittel einzeln belastet."
                            fr="Les frais de stationnement seront imputés individuellement aux moyens de paiement."
                            it="Le tasse di parcheggio verranno addebitate singolarmente sul mezzo di pagamento."
                            en="Parking fees are charged individually to the means of payment."
                        />
                    </p>
                    {saldoIsPositive && (
                        <ConsumeSaldoFirstText balanceText={balanceText} />
                    )}
                    <AdditionalCosts />
                    <div
                        className={css({
                            textAlign: 'right',
                            paddingTop: '16px',
                        })}
                    >
                        <MaterialButton
                            className={css({ minWidth: '50% !important' })}
                            primary={true}
                            label={
                                aliasSelected ? (
                                    <Localized
                                        {...{
                                            de: 'Aktivieren',
                                            fr: 'Activer',
                                            it: 'Attiva',
                                            en: 'Activate',
                                        }}
                                    />
                                ) : (
                                    <Localized
                                        {...(paymentCategory !==
                                        PaymentCategory.TWINT
                                            ? {
                                                  de: 'Karte hinzufügen',
                                                  fr: 'Ajouter carte',
                                                  it: 'Aggiungi carta',
                                                  en: 'Add card',
                                              }
                                            : {
                                                  de: 'TWINT hinzufügen',
                                                  fr: 'Ajouter TWINT',
                                                  it: 'Aggiungi TWINT',
                                                  en: 'Add TWINT',
                                              })}
                                    />
                                )
                            }
                            onClick={() => {
                                update(s =>
                                    logAction(s, 'select-direct-from-checkin'),
                                );
                                if (validAliasSelected) {
                                    setPaymentTypeToDirect({
                                        activePaymentType:
                                            PaymentMethodEnum.DIRECT,
                                        ticketId:
                                            paymentOriginInfo.type ===
                                            PaymentOriginType.CHECKIN_BY_TICKET
                                                ? paymentOriginInfo.ticketId
                                                : null,
                                    });
                                    update(TimeSelectionWheelTick.tick);
                                } else {
                                    const payload: StoreAliasPayloadBase = {
                                        paymentCategory,
                                        useAlias: aliasSelected,
                                        language: language,
                                    };
                                    sendStoreAlias(
                                        createStoreAliasPayload(
                                            payload,
                                            paymentOriginInfo,
                                        ),
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
                <OrDelimiter variant={DelimiterVariant.BLUE} />
            </>
            <div className={css({ lineHeight: '22px' })}>
                <p
                    className={css({
                        ...ParkingcardTypo.heading4,
                        textTransform: 'uppercase',
                    })}
                >
                    <Localized
                        de="VORAUSZAHLUNG"
                        fr="PRÉPAIEMENT"
                        it="PREPAGATA"
                        en="PREPAID"
                    />
                </p>
                <p>
                    <Localized
                        de="Sie müssen zuerst Ihr Parkingpay-Konto mit mindestens 20 CHF laden. Die Parkgebühren werden auf das Parkingpay-Konto belastet."
                        fr="Vous devez d'abord charger votre compte Parkingpay avec au moins CHF 20. Les transactions relatives aux frais de stationnement seront imputées sur le compte Parkingpay."
                        it="Deve prima caricare il suo conto Parkingpay con almeno CHF 20. Le tasse di parcheggio verranno addebitate sul quest'ultimo."
                        en="You must first top up your Parkingpay account with at least CHF 20. Parking fee will be debited to your Parkingpay account."
                    />
                </p>
                <p className={css({ ...Typo.robotoBold })}>
                    <Localized
                        de="Kein Zuschlag"
                        fr="Aucun supplément"
                        it="Nessun supplemento"
                        en="No surcharge"
                    />
                </p>
                <div
                    className={css({ textAlign: 'right', paddingTop: '16px' })}
                >
                    <MaterialButton
                        className={css({ minWidth: '50% !important' })}
                        label={
                            <Localized
                                de="Konto laden"
                                fr="Alimenter le compte"
                                it="Ricarica conto"
                                en="Top up account"
                            />
                        }
                        onClick={setWantsToTopUp}
                    />
                </div>
            </div>
        </SlideInFormContainer>
    );
});

const IndividualPayment = (
    <Localized
        de="Einzelzahlung"
        fr="Paiement individuel"
        it="Pagamento singolo"
        en="Individual payment"
    />
);
