import { HelpdeskTexts } from '../components/Helpdesk';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Translations } from '../../../common/i18n/Text.ts';

const helpdeskLink = css({
    marginTop: '10px',
    color: Colors.typo1,
    textDecoration: 'none',
    ...Typo.body,
});
export const texts: Translations<HelpdeskTexts> = {
    de: {
        title: (): string => 'Helpdesk',
        content: (): JSX.Element => {
            return (
                <div>
                    0848 330 555
                    <br />
                    <a
                        className={helpdeskLink}
                        href="mailto:info@parkingpay.ch"
                        tabIndex={-1}
                    >
                        info@parkingpay.ch
                    </a>
                </div>
            );
        },
    },
    fr: {
        title: (): string => 'Helpdesk',
        content: (): JSX.Element => {
            return (
                <div>
                    0848 330 555
                    <br />
                    <a
                        className={helpdeskLink}
                        href="mailto:info@parkingpay.ch"
                        tabIndex={-1}
                    >
                        info@parkingpay.ch
                    </a>
                </div>
            );
        },
    },
    it: {
        title: (): string => 'Helpdesk',
        content: (): JSX.Element => {
            return (
                <div>
                    0848 330 555
                    <br />
                    <a
                        className={helpdeskLink}
                        href="mailto:info@parkingpay.ch"
                        tabIndex={-1}
                    >
                        info@parkingpay.ch
                    </a>
                </div>
            );
        },
    },
    en: {
        title: (): string => 'Helpdesk',
        content: (): JSX.Element => {
            return (
                <div>
                    0848 330 555
                    <br />
                    <a
                        className={helpdeskLink}
                        href="mailto:info@parkingpay.ch"
                        tabIndex={-1}
                    >
                        info@parkingpay.ch
                    </a>
                </div>
            );
        },
    },
};
