import { css } from '@emotion/css';

import { localState, selectState } from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../../common/state/SettingsState';
import { Colors } from 'dg-web-shared/ui/vars';
import { ActionBlock } from '../root/components/ActionBlock';
import {
    portalSlideIn,
    PortalSlideInProps,
} from '../root/components/PortalSlidein';
import { AddBadgeForm, badgeTexts } from './AddBadgeForm';
import { UpdateVehicle, Vehicle } from './VehicleState';
import { VehicleTexts } from './VehicleTexts';
import { ModalQuestionBox } from '../../ui/modals/Confirmable';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import {
    ParkingBadgeImage,
    ParkingBadgeImageSize,
} from '../../pages/home-unauthenticated/components/ParkingBadge';
import { AddressBlock } from '../../park-create/components/license-plate/InfoBoxes';
import * as MetaServerState from '../meta/state/MetaServerState';
import { updateTransactionlistWarnings } from '../../transactions-list/state/TransactionsListState';
import { Localized } from '../../common/components/Localized';
import { useTransactionListRefetch } from '../../transactions-list/TransactionsListContext';
import { SpinnerModal } from '../../ui/spinner/Spinner.tsx';

interface VehicleAddBadgeProps extends onBadgeSuccess {
    texts: VehicleTexts;
    vehicle: Vehicle | null;
    emptyBadge?: boolean;
}

export interface onBadgeSuccess {
    onSuccess: (badgeId: number | null) => void;
}

export const VehicleAddBadge = portalSlideIn<VehicleAddBadgeProps>(
    localState<
        VehicleAddBadgeProps & PortalSlideInProps,
        {
            orderBadge: boolean;
            useExisting: boolean;
        }
    >(
        {
            orderBadge: false,
            useExisting: false,
        },
        selectState(
            store => {
                const settings = new SettingsState.StateSlice(store).state;
                return {
                    settings: settings,
                    selectedVehicle: UpdateVehicle.get(store),
                    updateVehicle: UpdateVehicle.get(store),
                    meta: new MetaServerState.StateSlice(store).state,
                };
            },
            p => {
                const vehicle = p.vehicle;
                const refetchTransactionList = useTransactionListRefetch();

                if (vehicle == null) {
                    return <SpinnerModal visible={true} portal={p.portal} />;
                }

                const onSuccess = (badgeId: number | null) => {
                    p.onClose();
                    p.onSuccess(badgeId);
                };

                return (
                    <div className={css({ background: Colors.white })}>
                        <ActionBlock
                            onClick={() => {
                                p.update(store => UpdateVehicle.reset(store));
                                p.setState({
                                    orderBadge: true,
                                });
                            }}
                            title={badgeTexts[
                                p.settings.language
                            ].orderBadgeLabel()}
                            icon="add"
                        />
                        {p.state.orderBadge && (
                            <ModalQuestionBox
                                titleCaption={badgeTexts[
                                    p.settings.language
                                ].orderBadgeLabel()}
                                confirmCallback={() => {
                                    const newVehicle: Vehicle = {
                                        ...vehicle,
                                        badgeId: null,
                                        badgeLabelNr: null,
                                    };
                                    return p.update(store => {
                                        return UpdateVehicle.postBadge(
                                            store,
                                            newVehicle,
                                            badgeId => {
                                                refetchTransactionList();
                                                updateTransactionlistWarnings(
                                                    p.update,
                                                );
                                                p.onClose();
                                                p.onSuccess(badgeId);
                                            },
                                        );
                                    });
                                }}
                                cancelCallback={() => {
                                    p.setState({ orderBadge: false });
                                }}
                                isPending={
                                    p.updateVehicle.status !==
                                    RequestStatus.NEVER_EXECUTED
                                }
                            >
                                <p>
                                    <div
                                        className={css({
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        })}
                                    >
                                        <ParkingBadgeImage
                                            imageSize={
                                                ParkingBadgeImageSize.LARGE
                                            }
                                        />
                                    </div>
                                </p>
                                <p>
                                    <Localized
                                        de="Wollen Sie wirklich einen neuen Badge bestellen?"
                                        fr="Voulez-vous vraiment commander un nouveau badge?"
                                        it="Vuole veramente ordinare un nuovo badge?"
                                        en="Do you really want to order a new badge?"
                                    />
                                </p>
                                <p>
                                    <Localized
                                        de="Der Badge wird innert 3 Arbeitstagen kostenlos an folgende Adresse per Post zugestellt:"
                                        fr="Le badge sera envoyé gratuitement par la poste dans les 3 jours ouvrables à l'adresse suivante:"
                                        it="Il nuovo badge vi verrà inviato gratuitamente per posta, nei prossimi 3 giorni lavorativi, al seguente indirizzo:"
                                        en="The new badge will be sent to you free of charge by post within the next 3 working days to the following address:"
                                    />
                                </p>
                                <p>
                                    <AddressBlock
                                        address={p.meta.data.address}
                                        shippingAddress={
                                            p.meta.data.shippingAddress
                                        }
                                        isBillingAddress={false}
                                    />
                                </p>
                            </ModalQuestionBox>
                        )}
                        <ActionBlock
                            onClick={() => {
                                p.setState({
                                    useExisting: true,
                                });
                            }}
                            title={badgeTexts[
                                p.settings.language
                            ].useExistingBadgeLabel()}
                            icon="chevronRight"
                        />
                        <AddBadgeForm
                            portal={p.portal}
                            open={p.state.useExisting}
                            texts={p.texts}
                            title={badgeTexts[
                                p.settings.language
                            ].useExistingBadgeLabel()}
                            vehicle={vehicle}
                            onClose={() => {
                                p.setState({ useExisting: false });
                            }}
                            onSuccess={onSuccess}
                        />
                    </div>
                );
            },
        ),
    ),
);
