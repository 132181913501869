import * as WriteStateSlice from '../../../common/state/WriteStateSlice';
import * as Flux from 'dg-web-shared/lib/Flux';

export enum Status {
    PURCHASE, // the regular state, allows to decide in the container what to show
    PURCHASE_SUCCESS,
    ALREADY_LOGGED_IN,
    NO_PURCHASE_NECESSARY,
    GENERIC_ERROR,
    FORCE_PURCHASE, // the user has clicked away the info button
}

export function getState(store: Flux.Store): State {
    return {
        forcePurchase: new InfoBlockStateSlice(store).state.forcePurchase,
        response: new ResponseStateSlice(store).state,
    };
}

export type ResponseState = WriteStateSlice.State;

export class ResponseStateSlice extends WriteStateSlice.StateSlice {
    key(): string {
        return 'park-create-ResponseGatePermitPurchaseState';
    }
}

export interface State extends InfoBlockState {
    response: ResponseState;
}

interface InfoBlockState {
    forcePurchase: boolean;
}

export class InfoBlockStateSlice extends Flux.StateSlice<InfoBlockState> {
    key(): string {
        return 'park-create-InfoBlockGatePermitPurchaseState';
    }

    sideEffects(): void {
        return;
    }

    getInitialState(): InfoBlockState {
        return { forcePurchase: false };
    }
}
