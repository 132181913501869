import { QuickCheckoutLabel } from '../common/QuickCheckoutUiComponents';
import { QuickCheckoutContent } from '../common/QuickCheckoutLayout';
import { Outlet, useParams } from 'react-router-dom';
import {
    PermitReceiptErrorResponse,
    PermitReceiptErrorState,
    PermitReceiptRequestContext,
    PermitReceiptSuccessResponse,
} from './QuickCheckoutPermitReceiptTypes';
import { QuickCheckoutAsyncLoadedSection } from '../common/QuickCheckoutAsyncLoadedSection';
import { PermittypeNotFound } from '../permittype/QuickCheckoutPermittypeErrorStates';
import styled from '@emotion/styled';
import { Colors } from 'dg-web-shared/ui/vars';
import { DateTime } from 'luxon';
import {
    IndentedList,
    processZones,
} from 'dg-web-shared/common/components/IndentedList';
import { css } from '@emotion/css';
import * as SettingsState from '../../common/state/SettingsState';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useStore } from 'dg-web-shared/lib/Flux';
import { languageFromString } from 'dg-web-shared/lib/Localized';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter';
import { Localized } from '../../common/components/Localized.tsx';

export function QuickCheckoutPermitReceipt() {
    const { externalId } = useParams();
    const [receiptState] = useServerFetch<
        PermitReceiptSuccessResponse,
        PermitReceiptRequestContext,
        PermitReceiptErrorResponse
    >(
        () => ({
            url: `/ui-api/quickcheckout/permit/${externalId}`,
        }),
        externalId ? { externalId } : null,
    );

    return (
        <QuickCheckoutAsyncLoadedSection
            state={receiptState}
            renderSuccess={receiptData => (
                <PermitReceiptInfo receiptData={receiptData} />
            )}
            renderError={errorData => {
                switch (errorData?.state) {
                    case PermitReceiptErrorState.NOT_FOUND:
                    case PermitReceiptErrorState.NOT_ALLOWED:
                    case undefined:
                        return <PermittypeNotFound />;
                }
            }}
        />
    );
}

function PermitReceiptInfo({
    receiptData,
}: {
    receiptData: PermitReceiptSuccessResponse;
}) {
    const { storeState } = useStore(store => ({
        language: languageFromString(
            new SettingsState.StateSlice(store).state.language,
        ),
    }));
    const entries = processZones(receiptData.zones);
    const startTime = DateTime.fromISO(receiptData.startTime);
    const endTime = DateTime.fromISO(receiptData.endTime);
    const operatorEditPolicyText =
        receiptData.operatorEditPolicyText[storeState.language];
    return (
        <>
            <QuickCheckoutContent padding="x-only">
                <QuickCheckoutLabel>
                    <Localized
                        de="Bezeichnung"
                        fr="Désignation"
                        it="Descrizione"
                        en="Description"
                    />
                </QuickCheckoutLabel>
                {receiptData.name}

                {receiptData.additionalInfo && (
                    <>
                        <QuickCheckoutLabel>
                            <Localized
                                de="Zusätzliche Informationen"
                                fr="Informations complémentaires"
                                it="Informazioni supplementari"
                                en="Additional information"
                            />
                        </QuickCheckoutLabel>
                        {receiptData.additionalInfo}
                    </>
                )}
                <QuickCheckoutLabel>
                    <Localized
                        de="Kennzeichen"
                        fr="Plaque"
                        it="Numero di targa"
                        en="License plate"
                    />
                </QuickCheckoutLabel>
                {receiptData.licensePlateNr}
                <QuickCheckoutLabel>
                    <Localized
                        de="Gültigkeit"
                        fr="Validité"
                        it="Validità"
                        en="Validity"
                    />
                </QuickCheckoutLabel>
                <FromTo start={startTime} end={endTime} />
                {entries && (
                    <>
                        <QuickCheckoutLabel>
                            <Localized
                                de="Zonen"
                                fr="Zones"
                                it="Zone"
                                en="Zones"
                            />
                        </QuickCheckoutLabel>
                        <IndentedList entries={entries} />
                    </>
                )}
                <QuickCheckoutLabel>
                    <Localized
                        de="Betreiber"
                        fr="Exploitant"
                        it="Gestore"
                        en="Operator"
                    />
                </QuickCheckoutLabel>
                {receiptData.permitIsChangeable &&
                receiptData.operatorContactDataMarkdown ? (
                    <div
                        className={css({
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-all',
                            userSelect: 'text',
                        })}
                    >
                        {receiptData.operatorName}
                        <br />
                        {receiptData.operatorContactDataMarkdown}
                    </div>
                ) : (
                    receiptData.operatorName
                )}
                {receiptData.receiptSentToEmail && (
                    <>
                        <QuickCheckoutLabel>
                            <Localized
                                de="E-Mail (Quittungversandt)"
                                fr="E-mail (envoi du reçu)"
                                it="E-mail (invio della ricevuta)"
                                en="E-mail (receipt delivery)"
                            />
                        </QuickCheckoutLabel>
                        {receiptData.receiptSentToEmail}
                    </>
                )}
                <QuickCheckoutLabel>
                    <Localized de="Preis" fr="Prix" it="Prezzo" en="Price" />
                </QuickCheckoutLabel>
                <Price>
                    {currencyCentsToLocalPrice('de', receiptData.amount * 100)}
                </Price>
                <p>{operatorEditPolicyText}</p>

                <div
                    className={css({
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '16px',
                    })}
                >
                    <a
                        href={`/ui-api/quickcheckout/permit/pdf/${receiptData.externalId}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <PdfButton>
                            <Localized
                                de="Quittung herunterladen"
                                fr="Télécharger le reçu"
                                it="Scarica la ricevuta"
                                en="Download receipt"
                            />
                        </PdfButton>
                    </a>
                </div>
            </QuickCheckoutContent>
            <Outlet />
        </>
    );
}

function FromTo({ start, end }: { start: DateTime; end: DateTime }) {
    if (start.hasSame(end, 'day')) {
        return (
            <div>
                <FromToPart date={start} formatter={DateTime.DATETIME_SHORT} />{' '}
                <FromToPart
                    text={<Localized de="bis" fr="au" it="al" en="to" />}
                    date={end}
                    formatter={DateTime.TIME_24_SIMPLE}
                />{' '}
                <Localized de="Uhr" fr="" it="" en="" />
            </div>
        );
    }
    return (
        <div>
            <div>
                <FromToPart
                    text={<Localized de="von" fr="du" it="dal" en="from" />}
                    date={start}
                    formatter={DateTime.DATETIME_SHORT}
                />
            </div>
            <div>
                <FromToPart
                    text={<Localized de="bis" fr="au" it="al" en="to" />}
                    date={end}
                    formatter={DateTime.DATETIME_SHORT}
                />
            </div>
        </div>
    );
}

function FromToPart({
    date,
    text,
    formatter,
}: {
    date: DateTime;
    text?: JSX.Element;
    formatter: Intl.DateTimeFormatOptions;
}) {
    return (
        <span>
            {text} {date.setLocale('de').toLocaleString(formatter)}
        </span>
    );
}

const Price = styled.div({
    color: Colors.white,
    fontSize: '22px',
    fontWeight: 500,
    letterSpacing: 0,
});

const PdfButton = styled.div({
    color: Colors.black,
    background: Colors.white,
    borderRadius: '6px',
    padding: '10px 12px',
    cursor: 'pointer',
    display: 'inline-block',
    textAlign: 'center',
    textDecoration: 'none',
});
