import { InputContext, inputContextToColor } from './InputContext';
import { css } from '@emotion/css';
import { Colors } from '../../ui/vars';
import { Underline, UnderlineStatus } from './internal/Underline';
import { Label } from './internal/Label';
import { Localized } from '../../common/hooks/LanguageProvider.tsx';
import { Clickable } from '../../ui/Clickable';
import { InputError } from './internal/InputError';
import { Typo } from '../../ui/typo.ts';

const itemStyle = (isSelected: boolean, styleContext: InputContext) => {
    return css({
        ...Typo.body,
        ...(isSelected
            ? {
                  color: `${Colors.rgba(inputContextToColor(styleContext), 1)}`,
                  borderBottom: `solid 1px ${Colors.rgba(
                      inputContextToColor(styleContext),
                      1,
                  )}`,
                  display: 'inline-block',
                  fontWeight: 400,
              }
            : {
                  color: `${Colors.rgba(
                      inputContextToColor(styleContext),
                      0.6,
                  )}`,
                  borderBottom: `solid 1px ${Colors.rgba(
                      inputContextToColor(styleContext),
                      0,
                  )}`,
              }),
    });
};

interface GenderSelectProps {
    gender: 'm' | 'f' | null | undefined;
    underlineStatus: UnderlineStatus;
    errorText: string;
    styleContext: InputContext;
    writeGender: (gender: 'm' | 'f') => void;
}

export const GenderSelect = (p: GenderSelectProps) => {
    return (
        <div
            className={css({
                marginTop: '8px',
            })}
        >
            <Label
                context={p.styleContext}
                elevated={true}
                text={
                    <Localized de="Anrede" fr="Titre" it="Titolo" en="Gender" />
                }
            />
            <div
                className={css({
                    display: 'flex',
                })}
            >
                <div
                    className={css({
                        flex: 1,
                    })}
                >
                    <Clickable
                        element="div"
                        className={itemStyle(p.gender === 'f', p.styleContext)}
                        onClick={() => p.writeGender('f')}
                    >
                        <Localized de="Frau" fr="Madame" it="Signora" en="Ms" />
                    </Clickable>
                </div>
                <div
                    className={css({
                        flex: 1,
                    })}
                >
                    <Clickable
                        element="div"
                        className={itemStyle(p.gender === 'm', p.styleContext)}
                        onClick={() => p.writeGender('m')}
                    >
                        <Localized
                            de="Herr"
                            fr="Monsieur"
                            it="Signor"
                            en="Mr"
                        />
                    </Clickable>
                </div>
            </div>
            <Underline status={p.underlineStatus} context={p.styleContext} />
            <InputError context={p.styleContext} text={p.errorText} />
        </div>
    );
};
