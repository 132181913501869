import * as SettingsState from '../../common/state/SettingsState';
import { Updater, useStore } from 'dg-web-shared/lib/Flux';
import * as AccountSetupState from '../state/AccountSetupState';
import * as AppActions from '../../app/actions/AppActions';
import { navigationTextsFor } from '../i18n/NavigationTexts';
import { addressTexts } from '../i18n/AddressTexts';
import { AccountSetupBlock } from './AccountSetupBlock';
import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { BulletpointText, Infobox, InfoboxText } from './Infobox';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import { logAction } from '../../utils/ActionLog';

export function AccountSetupType() {
    const { storeState, update } = useStore(s => {
        return {
            settings: new SettingsState.StateSlice(s).state,
            accountSetupConfiguration: AccountSetupState.Configuration.get(s),
        };
    });
    const t = addressTexts[storeState.settings.language];
    const type = storeState.accountSetupConfiguration.accountType;

    return (
        <AccountSetupBlock
            title={navigationTextsFor(storeState).headerLabel()}
            nextLabel={navigationTextsFor(storeState).next()}
            onPrevious={() => update(AppActions.logout)}
            previousLabel={t.logout()}
            onNext={() => {
                update(store =>
                    AccountSetupState.Navigation.stateWrite(store, {
                        currentStep: 'Address',
                    }),
                );
                update(store =>
                    logAction(
                        store,
                        'setup-type-done',
                        storeState.accountSetupConfiguration,
                    ),
                );
            }}
        >
            <Infobox>
                <InfoboxText>{t.welcome1()}</InfoboxText>
                <BulletpointText>{t.welcome2()}</BulletpointText>
            </Infobox>
            <LabeledToggle
                type={ToggleType.radio}
                label={t.registerPrivateAccountLabel()}
                selected={type === CustomerAccountType.PRIVATE}
                onClick={() => {
                    update(store =>
                        AccountSetupState.Configuration.stateWrite(store, {
                            accountType: CustomerAccountType.PRIVATE,
                        }),
                    );
                    resetCompanyValues(update);
                }}
            />
            <LabeledToggle
                type={ToggleType.radio}
                label={t.registerCompanyAccountLabel()}
                selected={type === CustomerAccountType.COMPANY}
                onClick={() =>
                    update(store =>
                        AccountSetupState.Configuration.stateWrite(store, {
                            accountType: CustomerAccountType.COMPANY,
                        }),
                    )
                }
            />
            {type === CustomerAccountType.COMPANY && (
                <Infobox>
                    <InfoboxText>
                        {t.registerCompanyAccountWarning()}
                    </InfoboxText>
                </Infobox>
            )}
        </AccountSetupBlock>
    );
}

function resetCompanyValues(update: Updater) {
    update(store =>
        AccountSetupState.Address.stateWrite(store, {
            company1: null,
            company2: null,
            companyUid: null,
        }),
    );
}
