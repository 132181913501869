import * as Flux from 'dg-web-shared/lib/Flux';
import * as Highlight from 'dg-web-shared/lib/Highlight';
import { getOrElse, isDefined, thenElse } from 'dg-web-shared/lib/MaybeV2';
import * as Sort from 'dg-web-shared/lib/Sort';
import * as String from 'dg-web-shared/lib/String';
import * as Icons48 from 'dg-web-shared/ui/icons/Icons48';
import { Localized } from '../../../common/components/Localized';
import * as Text from '../../../common/i18n/Text';
import * as SettingsState from '../../../common/state/SettingsState';
import { SearchField, SearchFieldStyle } from '../../../ui/SearchField';
import {
    SlideInContentBody,
    SlideInContentFixedColumn,
    SlideInContentFlexColumn,
    SlideInContentHeader,
    SlideInContentHeaderSortableItem,
    SlideInContentItem,
    SlideInContentItemText,
    SlideInSearchFieldContainer,
} from '../../../ui/slidein/SlideIn';
import { logAction } from '../../../utils/ActionLog';
import * as DropinActions from '../../actions/DropinActions';
import * as ParkCreateActions from '../../actions/ParkCreateActions';
import * as DropinTexts from '../../i18n/DropinTexts';
import { ParkCreatePortalSlideIn } from '../../ParkCreateSlideIn';
import * as ParkOptionListState from '../../state/ParkOptionListState';
import * as ZoneDropinState from '../../state/ZoneDropinState';
import { CitiesState, ZonesOfCityState } from '../ParkOptionList';
import { ArrowPosition, InlineInfoBox } from '../../../ui/modals/Confirmable';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import React from 'react';
import { IconBig } from 'dg-web-shared/tb-ui/icons/IconBig.tsx';

export interface Texts {
    Title: (city: string) => string;
    TitleSearch: Text.Translation;
    ZoneIdLabel: Text.Translation;
    ZoneNameLabel: Text.Translation;
}

function sortZones(
    zones: ZonesOfCityState.Zone[],
    sortField: ZoneDropinState.SortField,
    sortAscending: boolean,
): ZonesOfCityState.Zone[] {
    const zoneIdComparator = (
        a: ZonesOfCityState.Zone,
        b: ZonesOfCityState.Zone,
    ) =>
        Sort.arithmeticCompare(
            a.extZoneCode || 100000,
            b.extZoneCode || 100000,
            sortAscending,
        );

    const distanceComparator = (
        a: ZonesOfCityState.Zone,
        b: ZonesOfCityState.Zone,
    ) => {
        const r = Sort.numberCompare(
            Math.min(a.distance || 0, 10000),
            Math.min(b.distance || 0, 10000),
            sortAscending,
            false,
        );
        return r !== 0 ? r : zoneIdComparator(a, b);
    };

    switch (sortField) {
        case ZoneDropinState.SortField.ZoneName:
            return zones.sort(
                (a: ZonesOfCityState.Zone, b: ZonesOfCityState.Zone) =>
                    Sort.localeCompare(a.name, b.name, sortAscending),
            );
        case ZoneDropinState.SortField.Distance:
            return zones.sort(distanceComparator);
        default:
            return zones.sort(zoneIdComparator);
    }
}

function filterZones(
    zones: Readonly<ZonesOfCityState.Zone[]>,
    filterValue: string,
): ZonesOfCityState.Zone[] {
    return zones.filter((zone: ZonesOfCityState.Zone) => {
        return (
            (isDefined(zone.extZoneCode) &&
                String.normalizedIncludes(
                    getOrElse<number>(zone.extZoneCode, 0).toString(),
                    filterValue,
                )) ||
            String.normalizedIncludes(zone.name, filterValue)
        );
    });
}

interface ZoneDropinState {
    settings: SettingsState.State;
    zoneDropin: ZoneDropinState.State;
    selection: ParkOptionListState.Selection.State;
}

interface ZoneDropinRenderValue {
    items: JSX.Element[];
    selectedItemRef: React.ReactInstance;
    cropped: boolean;
}

const OptionIcon = (p: {
    small: boolean;
    icon: JSX.Element;
    selected: boolean;
}) => {
    return (
        <div
            className={css([
                {
                    position: 'relative',
                    color: Colors.blue,
                    top: '13px',
                    marginTop: '-26px',
                },
                p.small && {
                    marginLeft: '11px',
                },
                p.selected && {
                    color: Colors.white,
                },
            ])}
        >
            <IconBig icon={p.icon} />
        </div>
    );
};

interface ZoneSelectionSlideInProps {
    zones: Readonly<ZonesOfCityState.Zone[]>;
    selectedCity: CitiesState.City;
}

export const ZoneSelectionSlideIn = Flux.selectState<
    ZoneSelectionSlideInProps,
    ZoneDropinState
>(
    s => ({
        settings: new SettingsState.StateSlice(s).state,
        zoneDropin: new ZoneDropinState.StateSlice(s).state,
        selection: ParkOptionListState.Selection.get(s),
    }),
    p => {
        return <ZoneSelectionSlideInContent {...p} />;
    },
);

const ZONE_LIST_CUTOFF_THRESHOLD = 150;

class ZoneSelectionSlideInContent extends React.Component<
    ZoneDropinState & { update: Flux.Updater } & ZoneSelectionSlideInProps
> {
    renderItems(): ZoneDropinRenderValue {
        const selectableParkOptions = this.props.zones;
        return {
            items: sortZones(
                filterZones(
                    selectableParkOptions,
                    this.props.zoneDropin.filterValue,
                ),
                this.props.zoneDropin.sortField,
                this.props.zoneDropin.sortAscending,
            )
                .slice(0, ZONE_LIST_CUTOFF_THRESHOLD)
                .map((zone: ZonesOfCityState.Zone) => {
                    const selected = thenElse(
                        this.props.selection.selectedParkOptionId,
                        v => v === zone.id,
                        false,
                    );

                    return (
                        <SlideInContentItem
                            onClick={() => {
                                this.props.update(s =>
                                    logAction(s, 'select-zone-from-dropin', {
                                        zipCode:
                                            selectableParkOptions[0].zipCode,
                                        zoneId: zone.id,
                                        itemCount: selectableParkOptions.length,
                                        searchUsed:
                                            !!this.props.zoneDropin.filterValue,
                                        openDuration: this.props.zoneDropin
                                            .openEpoch
                                            ? new Date().getTime() -
                                              this.props.zoneDropin.openEpoch
                                            : null,
                                    }),
                                );
                                this.props.update(store =>
                                    ParkCreateActions.setParkOption(
                                        store,
                                        zone.id,
                                    ),
                                );
                            }}
                            selected={selected}
                            key={zone.id}
                            ref={selected ? 'selectedItem' : ''}
                        >
                            <SlideInContentFixedColumn width={48}>
                                <SlideInContentItemText>
                                    {Highlight.highlightPartialMatch({
                                        text: isDefined(zone.extZoneCode)
                                            ? getOrElse<number>(
                                                  zone.extZoneCode,
                                                  0,
                                              ).toString()
                                            : '',
                                        className: css({ ...Typo.bodyBold }),
                                        filterValue:
                                            this.props.zoneDropin.filterValue,
                                        filterActive:
                                            ZoneDropinState.filterActive(
                                                this.props.zoneDropin,
                                            ),
                                    })}
                                </SlideInContentItemText>
                            </SlideInContentFixedColumn>
                            <SlideInContentFlexColumn>
                                <SlideInContentItemText>
                                    {Highlight.highlightPartialMatch({
                                        text: zone.name.replace(
                                            /\//g,
                                            '/\u200b',
                                        ),
                                        className: css({ ...Typo.bodyBold }),
                                        filterValue:
                                            this.props.zoneDropin.filterValue,
                                        filterActive:
                                            ZoneDropinState.filterActive(
                                                this.props.zoneDropin,
                                            ),
                                    })}
                                </SlideInContentItemText>
                            </SlideInContentFlexColumn>

                            <SlideInContentFixedColumn width={48}>
                                <ZoneTypeIcon
                                    variants={zone.variants}
                                    selected={selected}
                                />
                            </SlideInContentFixedColumn>
                        </SlideInContentItem>
                    );
                }),
            // eslint-disable-next-line react/no-string-refs
            selectedItemRef: this.refs.selectedItem,
            cropped: selectableParkOptions.length > ZONE_LIST_CUTOFF_THRESHOLD,
        };
    }

    render() {
        const texts = DropinTexts.zoneDropinTexts[this.props.settings.language];
        const { items, selectedItemRef, cropped } = this.renderItems();

        return (
            <ParkCreatePortalSlideIn
                open={this.props.zoneDropin.open}
                title={texts.Title(
                    this.props.selectedCity &&
                        this.props.selectedCity.names.length === 1
                        ? this.props.selectedCity.names[0]
                        : '',
                )}
                onClose={() =>
                    this.props.update(DropinActions.ZoneDropin.close)
                }
            >
                <SlideInSearchFieldContainer>
                    <SearchField
                        labelText={texts.TitleSearch()}
                        elevatedLabelText={texts.TitleSearch()}
                        value={this.props.zoneDropin.filterValue}
                        onChange={(value: string) =>
                            this.props.update(store =>
                                DropinActions.ZoneDropin.setFilterValue(
                                    store,
                                    value,
                                ),
                            )
                        }
                        onClear={() =>
                            this.props.update(
                                DropinActions.ZoneDropin.clearFilterValue,
                            )
                        }
                        disabled={!this.props.zoneDropin.open}
                        searchFieldStyle={SearchFieldStyle.BLUE_GRAY}
                    />
                </SlideInSearchFieldContainer>
                {cropped && (
                    <div style={{ padding: '13px 24px' }}>
                        <InlineInfoBox
                            titleCaption={
                                <Localized
                                    de="Liste zu lang"
                                    fr="Liste trop longue"
                                    it="Lista troppo lunga"
                                    en="List too long"
                                />
                            }
                            arrowPosition={ArrowPosition.none}
                        >
                            <p>
                                <Localized
                                    de="Die Liste unten ist zu lang und daher kann sie nicht vollständig angezeigt werden. Bitte benutze die Suche, um die Einträge zu reduzieren."
                                    fr="La liste ci-dessous est trop longue et ne peut donc pas être affichée en entier. Utilise la recherche pour réduire le nombre d'entrées."
                                    it="La lista sottostante è troppo lunga e quindi non può essere visualizzata per intero. Voglia usare la ricerca per ridurre i risultati."
                                    en="The list below is too long and therefore cannot be displayed in full. Please use the search to reduce the entries."
                                />
                            </p>
                        </InlineInfoBox>
                    </div>
                )}
                <SlideInContentHeader>
                    <SlideInContentFixedColumn width={48} spacingLeft={false}>
                        <SlideInContentHeaderSortableItem
                            label={texts.ZoneIdLabel()}
                            active={
                                this.props.zoneDropin.sortField ===
                                ZoneDropinState.SortField.ZoneId
                            }
                            sortAscending={this.props.zoneDropin.sortAscending}
                            onClick={() =>
                                this.props.update(
                                    DropinActions.ZoneDropin.sortByZoneId,
                                )
                            }
                        />
                    </SlideInContentFixedColumn>
                    <SlideInContentFlexColumn>
                        <SlideInContentHeaderSortableItem
                            label={texts.ZoneNameLabel()}
                            active={
                                this.props.zoneDropin.sortField ===
                                ZoneDropinState.SortField.ZoneName
                            }
                            sortAscending={this.props.zoneDropin.sortAscending}
                            onClick={() =>
                                this.props.update(
                                    DropinActions.ZoneDropin.sortByZoneName,
                                )
                            }
                        />
                    </SlideInContentFlexColumn>
                    <SlideInContentFixedColumn
                        width={48}
                        spacingRight={false}
                    />
                </SlideInContentHeader>
                <SlideInContentBody
                    updateScrollPosition={this.props.zoneDropin.open}
                    selectedItemRef={selectedItemRef}
                >
                    {items}
                </SlideInContentBody>
            </ParkCreatePortalSlideIn>
        );
    }
}

function ZoneTypeIcon(props: {
    variants: ZonesOfCityState.ParkOptionVariant[];
    selected: boolean;
}): JSX.Element {
    const ot = {
        zoneEnforcedCheckin: props.variants.includes(
            ZonesOfCityState.ParkOptionVariant.zoneEnforcedCheckin,
        ),
        permits: props.variants.includes(
            ZonesOfCityState.ParkOptionVariant.permit,
        ),
        barrierGateCheckin: props.variants.includes(
            ZonesOfCityState.ParkOptionVariant.barrierGateCheckin,
        ),
    };

    if (ot.zoneEnforcedCheckin && ot.permits) {
        return (
            <OptionIcon
                small={false}
                icon={Icons48.onstreetPermit}
                selected={props.selected}
            />
        );
    } else if (ot.zoneEnforcedCheckin) {
        return (
            <OptionIcon
                small={true}
                icon={Icons48.onstreetSmall}
                selected={props.selected}
            />
        );
    } else if (ot.barrierGateCheckin && ot.permits) {
        return (
            <OptionIcon
                small={false}
                icon={Icons48.offstreetPermit}
                selected={props.selected}
            />
        );
    } else if (ot.barrierGateCheckin) {
        return (
            <OptionIcon
                small={true}
                icon={Icons48.offstreet}
                selected={props.selected}
            />
        );
    } else {
        return (
            <OptionIcon
                small={true}
                icon={Icons48.permitSmall}
                selected={props.selected}
            />
        );
    }
}
