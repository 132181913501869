import { useEffect, useState } from 'react';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import * as Fields from '../../utils/Fields';
import { MobilePhone, MobilePhoneState } from '../../utils/Fields';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { EditForm } from './EditForm';
import {
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ErrorBlock } from '../root/components/ErrorBlock';
import { GenericErrorBody } from '../../ui/modals/HandleHttpStati';
import { ConfirmOtpRequest, NewNumberRequest, PhoneNumbers } from './KycTypes';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { ModalErrorBox, ModalSuccessBox } from '../../ui/modals/Confirmable';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { ParkingcardTypo } from '../../style/parkingcardTypo.ts';
import { removeWhitespace } from 'dg-web-shared/lib/StringConversions';
import { Localized } from '../../common/components/Localized';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const PhoneEditFormSlideIn = portalSlideIn(PhoneEditForm);

function mobilePhoneErrorText(field: MobilePhone) {
    if (field.states.includes(MobilePhoneState.MUST_CONTAIN_PREFIX)) {
        return (
            <Localized
                de="Die Telefonnummer muss mit + beginnen"
                fr="Le numéro de téléphone doit commencer par +"
                it="Il numero di telefono deve cominciare con +"
                en="The phone number must begin with +"
            />
        );
    } else if (field.states.includes(MobilePhoneState.TOO_SHORT)) {
        return (
            <Localized
                de="Telefonnummer ist zu kurz"
                fr="Numéro de téléphone trop court'"
                it="Numero di telefono troppo corto"
                en="Phone number too short"
            />
        );
    } else if (field.states.includes(MobilePhoneState.TOO_LONG)) {
        return (
            <Localized
                de="Telefonnummer ist zu lang"
                fr="Numéro de téléphone trop long"
                it="Numero di telefono troppo lungo"
                en="Phone number too long"
            />
        );
    } else if (field.states.includes(MobilePhoneState.INVALID)) {
        return (
            <Localized
                de="Ungültige Telefonnummer"
                fr="Numéro de téléphone non valable"
                it="Numero di telefono non valido"
                en="Invalid phone number"
            />
        );
    }
    return '';
}

enum PhoneNumberStateType {
    NONE = 'NONE',
    CONFIRMED = 'CONFIRMED',
    REQUESTED = 'REQUESTED',
    REPLACEMENT_REQUESTED = 'REPLACEMENT_REQUESTED',
}

function PhoneEditForm({
    phoneNumberState,
    onClose,
    refetch,
    setTitle,
}: {
    title: React.ReactNode;
    open: boolean;
    currentLogin: CurrentLoginState.CurrentLogin;
    phoneNumberState: PhoneNumberState;
    onClose: () => void;
    refetch: () => void;
    setTitle: (title: React.ReactNode) => void;
}) {
    useEffect(() => {
        setTitle(titleFromState(phoneNumberState.type));
    }, [phoneNumberState.type]);

    function refetchAndClose() {
        refetch();
        onClose();
    }

    switch (phoneNumberState.type) {
        case PhoneNumberStateType.NONE:
            return <AddPhoneNumber onClose={onClose} onRefetch={refetch} />;
        case PhoneNumberStateType.REQUESTED:
            return (
                <OtpForm
                    requestedPhoneNumber={phoneNumberState.requestedPhoneNumber}
                    onDone={refetchAndClose}
                    onClose={onClose}
                    onRefetch={refetch}
                />
            );
        case PhoneNumberStateType.REPLACEMENT_REQUESTED:
            return (
                <OtpForm
                    activePhoneNumber={phoneNumberState.activePhoneNumber}
                    requestedPhoneNumber={phoneNumberState.requestedPhoneNumber}
                    onDone={refetchAndClose}
                    onClose={onClose}
                    onRefetch={refetch}
                />
            );
        case PhoneNumberStateType.CONFIRMED:
            return (
                <AddPhoneNumber
                    activePhoneNumber={phoneNumberState.activePhoneNumber}
                    onClose={onClose}
                    onRefetch={refetch}
                />
            );
    }
}

function titleFromState(state: PhoneNumberStateType) {
    switch (state) {
        case PhoneNumberStateType.NONE:
            return (
                <Localized
                    de="Mobiltelefon definieren"
                    fr="Définir le portable"
                    it="Definire cellulare"
                    en="Define mobile"
                />
            );
        case PhoneNumberStateType.CONFIRMED:
            return (
                <Localized
                    de="Mobiltelefon ändern"
                    fr="Modifier le portable"
                    it="Modificare cellulare"
                    en="Modify mobile"
                />
            );
        case PhoneNumberStateType.REPLACEMENT_REQUESTED:
        case PhoneNumberStateType.REQUESTED:
            return (
                <Localized
                    de="Mobiltelefon bestätigen"
                    fr="Confirmer le portable"
                    it="Confermare cellulare"
                    en="Confirm mobile"
                />
            );
    }
}

enum OtpErrorState {
    GENERAL = 'GENERAL',
    EXPIRED = 'EXPIRED',
    INVALID = 'INVALID',
}

function OtpForm(props: {
    activePhoneNumber?: string;
    requestedPhoneNumber: string;
    onClose: () => void;
    onDone: () => void;
    onRefetch: () => void;
}) {
    const [verifyOtpState, verifyOtp] = useServerWrite<
        ConfirmOtpRequest,
        { success: boolean }
    >(() => {
        return {
            url: '/ui-api/customer-account/kyc/confirm',
        };
    });
    const [reRequestState, reRequest] = useReRequest();
    const [resetChangeState, resetChange] = useServerWrite<
        Record<string, never>,
        { success: boolean }
    >(() => {
        return {
            url: '/ui-api/customer-account/kyc/delete-request',
        };
    });

    const [otp, setOtp] = useState('');
    const otpField = new Fields.NumberField(otp);

    useServerSuccessEffect(resetChangeState, () => {
        props.onRefetch();
    });

    const [errorState, setErrorState] = useState<OtpErrorState | null>(null);

    useEffect(() => {
        if (verifyOtpState.status === RequestStatus.ERROR) {
            if (verifyOtpState.httpStatusCode === 403) {
                setErrorState(OtpErrorState.INVALID);
            } else if (verifyOtpState.httpStatusCode === 404) {
                setErrorState(OtpErrorState.EXPIRED);
            } else {
                setErrorState(OtpErrorState.GENERAL);
            }
        } else {
            setErrorState(null);
        }
    }, [verifyOtpState.status]);

    return (
        <EditForm
            isSaveable={otpField.value.length === 6}
            onClose={props.onClose}
            onSave={() => {
                verifyOtp({ otp: otpField.value });
            }}
            pending={verifyOtpState.status === RequestStatus.PENDING}
            primaryButtonLabel={
                <Localized
                    de="Bestätigen"
                    fr="Confirmer"
                    it="Conferma"
                    en="Confirm"
                />
            }
        >
            {props.activePhoneNumber && (
                <ActivePhoneNumber phoneNumber={props.activePhoneNumber} />
            )}
            <UnconfirmedPhoneNumber
                phoneNumber={props.requestedPhoneNumber}
                onDelete={resetChange}
                enabled={reRequestState.status !== RequestStatus.PENDING}
            />
            <p>
                <Localized
                    de="Sie haben Ihre neue Mobiltelefonnummer noch nicht bestätigt; zu diesem Zweck haben wir Ihnen einen Bestätigungscode geschickt."
                    fr="Vous n'avez pas encore confirmé votre nouveau numéro de portable; à cet effet, nous vous avons envoyé un code de confirmation."
                    it="Non ha ancora confermato il suo nuovo numero di cellulare; a tal fine le abbiamo inviato un codice di conferma."
                    en="You have not yet confirmed your new mobile number; for this purpose we have sent you a confirmation code."
                />
            </p>
            <TextField
                context={InputContext.form}
                value={otpField.value}
                autocomplete="one-time-code"
                labelText={
                    <Localized
                        de="Bestätigungscode"
                        fr="Code de confirmation"
                        it="Codice di conferma"
                        en="Confirmation code"
                    />
                }
                errorText={
                    otpField.value.length !== 6 ? (
                        <Localized
                            de="Muss genau sechs Ziffern haben"
                            fr="Doit comporter exactement six chiffres"
                            it="Dev'essere esattamente sei cifre"
                            en="Must be exactly six digits"
                        />
                    ) : null
                }
                onChange={v => setOtp(v)}
                required={true}
            />
            <ResendCodeBlock />

            {(errorState === OtpErrorState.GENERAL ||
                reRequestState.status === RequestStatus.ERROR) && (
                <ErrorBlock text={<GenericErrorBody />} />
            )}

            {errorState === OtpErrorState.EXPIRED && (
                <ModalErrorBox
                    confirmCallback={() => {
                        setErrorState(null);
                        setOtp('');
                        reRequest();
                    }}
                >
                    <p>
                        <Localized
                            de="Der eingegebene Bestätigungscode ist abgelaufen."
                            fr="Le code de confirmation saisi a expiré."
                            it="Il codice di conferma inserito è scaduto."
                            en="The confirmation code entered has expired."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Wir werden Ihnen automatisch einen neuen Code schicken."
                            fr="Nous vous enverrons automatiquement un nouveau code."
                            it="Le invieremo automaticamente un nuovo codice."
                            en="We will automatically send you a new code."
                        />
                    </p>
                </ModalErrorBox>
            )}

            {errorState === OtpErrorState.INVALID && (
                <ModalErrorBox confirmCallback={() => setErrorState(null)}>
                    <p>
                        <Localized
                            de="Der eingegebene Bestätigungscode ist ungültig."
                            fr="Le code de confirmation saisi n'est pas valide."
                            it="Il codice di conferma inserito non è valido."
                            en="The confirmation code entered is invalid."
                        />
                    </p>
                </ModalErrorBox>
            )}

            {verifyOtpState.status === RequestStatus.SUCCESS && (
                <ModalSuccessBox
                    confirmCallback={props.onDone}
                    titleCaption={
                        <Localized
                            de="Bestätigung"
                            fr="Confirmation"
                            it="Conferma"
                            en="Confirmation"
                        />
                    }
                >
                    <p>
                        <Localized
                            de={`Die Mobiltelefonnummer ${props.requestedPhoneNumber} wurde erfolgreich bestätigt.`}
                            fr={`Le numéro de portable ${props.requestedPhoneNumber} été confirmé avec succès.`}
                            it={`Il numero di cellulare ${props.requestedPhoneNumber} è stato confermato con successo.`}
                            en={`The mobile number ${props.requestedPhoneNumber} has been successfully confirmed.`}
                        />
                    </p>
                </ModalSuccessBox>
            )}
        </EditForm>
    );
}

function UnconfirmedPhoneNumber({
    phoneNumber,
    enabled,
    onDelete,
}: {
    phoneNumber: string;
    enabled: boolean;
    onDelete: () => void;
}) {
    return (
        <div>
            <label
                className={css({
                    color: Colors.rgba(Colors.action_f, 0.7),
                    height: '20px',
                    display: 'block',
                    ...ParkingcardTypo.caption,
                })}
            >
                <Localized
                    de="Nicht bestätigte Nummer"
                    fr="Numéro non confirmé"
                    it="Numero non confermato"
                    en="Unconfirmed number"
                />
            </label>
            <div
                className={css({
                    fontSize: '16px',
                    ...Typo.robotoMedium,
                })}
            >
                {phoneNumber}
                <span className={css({ paddingLeft: '20px' })}>
                    {'('}
                    <Clickable
                        element="span"
                        style={{
                            textDecoration: 'underline',
                            ...Typo.robotoRegular,
                        }}
                        disabled={!enabled}
                        onClick={() => onDelete()}
                    >
                        <Localized
                            de="löschen"
                            fr="supprimer"
                            it="elimina"
                            en="delete"
                        />
                    </Clickable>
                    {')'}
                </span>
            </div>
        </div>
    );
}

function useReRequest() {
    return useServerWrite<Record<string, never>, { success: boolean }>(() => {
        return {
            url: '/ui-api/customer-account/kyc/re-request',
        };
    });
}

function ResendCodeBlock() {
    const [reRequestState, reRequest] = useReRequest();

    return (
        <>
            <p>
                <Localized
                    de="Sie haben die Nachricht mit dem Bestätigungscode noch nicht erhalten?"
                    fr="Vous n'avez pas encore reçu le message avec le code de confirmation?"
                    it="Non ha ancora ricevuto il messaggio con il codice di conferma?"
                    en="Not yet received the message with the confirmation code?"
                />{' '}
                <Clickable
                    element="span"
                    style={{ textDecoration: 'underline' }}
                    disabled={reRequestState.status === RequestStatus.PENDING}
                    onClick={() => reRequest()}
                >
                    <Localized
                        de="Jetzt neuen Bestätigungscode senden"
                        fr="Envoyez maintenant un nouveau code de confirmation"
                        it="Inviare ora un nuovo codice di conferma"
                        en="Send a new confirmation code now"
                    />
                </Clickable>
            </p>
            {reRequestState.status === RequestStatus.ERROR && (
                <GenericErrorBody />
            )}
        </>
    );
}

function ActivePhoneNumber(props: { phoneNumber: string }) {
    return (
        <TextField
            context={InputContext.form}
            value={props.phoneNumber}
            labelText={
                <Localized
                    de="Aktuelle Nummer"
                    fr="Numéro actuel"
                    it="Numero attuale"
                    en="Current number"
                />
            }
            readOnly={true}
        />
    );
}

function AddPhoneNumber(props: {
    activePhoneNumber?: string | null;
    onClose: () => void;
    onRefetch: () => void;
}) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const phoneNumberField = new Fields.MobilePhone(phoneNumber);
    const [newPhoneNumberState, requestNewPhoneNumber] = useServerWrite<
        NewNumberRequest,
        { success: boolean }
    >(() => {
        return {
            url: '/ui-api/customer-account/kyc/new-number',
        };
    });
    useServerSuccessEffect(newPhoneNumberState, () => {
        props.onRefetch();
    });

    return (
        <EditForm
            isSaveable={
                Fields.noStates(phoneNumberField) && !phoneNumberField.isEmpty
            }
            onClose={props.onClose}
            onSave={() => {
                requestNewPhoneNumber({ phoneNumber: phoneNumberField.value });
            }}
            pending={newPhoneNumberState.status === RequestStatus.PENDING}
        >
            {props.activePhoneNumber && (
                <ActivePhoneNumber phoneNumber={props.activePhoneNumber} />
            )}
            <TextField
                context={InputContext.form}
                value={phoneNumberField.value}
                labelText={
                    <Localized
                        de="Neue Nummer"
                        fr="Nouveau numéro"
                        it="Nuovo numero"
                        en="New number"
                    />
                }
                errorText={mobilePhoneErrorText(phoneNumberField)}
                onChange={v => setPhoneNumber(removeWhitespace(v))}
                hintText={'+4179........'}
            />

            <p>
                <Localized
                    de="Nach dem Speichern wird ein Bestätigungscode an die neue Nummer gesendet."
                    fr="Après avoir enregistré, un code de confirmation sera envoyé au nouveau numéro."
                    it="Dopo aver salvato, un codice di conferma sarà inviato al nuovo numero."
                    en="After saving, a confirmation code will be sent to the new number."
                />
            </p>
            {newPhoneNumberState.status === RequestStatus.ERROR && (
                <ErrorBlock text={<GenericErrorBody />} />
            )}
        </EditForm>
    );
}

type PhoneNumberState =
    | PhoneNumberStateNone
    | PhoneNumberStateConfirmed
    | PhoneNumberStateRequested
    | PhoneNumberStateReplacementRequested;

type PhoneNumberStateNone = {
    type: PhoneNumberStateType.NONE;
};

type PhoneNumberStateConfirmed = {
    type: PhoneNumberStateType.CONFIRMED;
    activePhoneNumber: string;
};

type PhoneNumberStateRequested = {
    type: PhoneNumberStateType.REQUESTED;
    requestedPhoneNumber: string;
};

type PhoneNumberStateReplacementRequested = {
    type: PhoneNumberStateType.REPLACEMENT_REQUESTED;
    activePhoneNumber: string;
    requestedPhoneNumber: string;
};

export function derivePhoneNumberState(
    state: PhoneNumbers | null | undefined,
): PhoneNumberState {
    if (!state?.activePhoneNumber && !state?.requestedPhoneNumber) {
        return { type: PhoneNumberStateType.NONE };
    } else if (state?.activePhoneNumber && !state?.requestedPhoneNumber) {
        return {
            type: PhoneNumberStateType.CONFIRMED,
            activePhoneNumber: state.activePhoneNumber,
        };
    } else if (!state?.activePhoneNumber && !!state?.requestedPhoneNumber) {
        return {
            type: PhoneNumberStateType.REQUESTED,
            requestedPhoneNumber: state.requestedPhoneNumber,
        };
    } else if (!!state?.activePhoneNumber && !!state?.requestedPhoneNumber) {
        return {
            type: PhoneNumberStateType.REPLACEMENT_REQUESTED,
            activePhoneNumber: state.activePhoneNumber,
            requestedPhoneNumber: state.requestedPhoneNumber,
        };
    }
    throw new Error('Unmapped phone number state');
}
