import { Colors } from 'dg-web-shared/ui/vars';
import { css } from '@emotion/css';
import { ButtonProps } from '../../../ui/inputs/MaterialButton';
import { TwoButtons } from '../../../ui/inputs/TwoButtons';
import { responsiveVariant } from '../../../layout/utils/responsiveVariant';
import { useStore } from 'dg-web-shared/lib/Flux';
import * as LayoutState from '../../../layout/state/LayoutState';
import { Typo } from 'dg-web-shared/ui/typo.ts';

interface Props {
    children: React.ReactNode;
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
}

export function SlideInFormContainer(p: {
    children: React.ReactNode;
    bottom?: JSX.Element;
}) {
    const { storeState } = useStore(store => ({
        layout: new LayoutState.StateSlice(store).state,
    }));
    const isMobile = responsiveVariant(storeState) === 'Mobile';
    return (
        <div
            className={css({
                backgroundColor: Colors.form,
                color: Colors.action_f,
                ...Typo.robotoRegular,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                height: '100%',
            })}
        >
            <div
                className={css({
                    paddingTop: '12px',
                    display: isMobile ? 'flex' : undefined,
                    flexDirection: isMobile ? 'column' : undefined,
                    height: isMobile ? '100%' : undefined,
                })}
            >
                <div
                    className={css({
                        padding: '0 24px',
                        flexGrow: isMobile ? 1 : undefined,
                    })}
                >
                    {p.children}
                </div>
                {p.bottom && (
                    <div
                        className={css({
                            display: 'flex',
                            padding: isMobile ? 0 : undefined,
                            paddingBottom: isMobile ? 0 : '12px',
                            alignSelf: isMobile ? 'flex-end' : undefined,
                            width: isMobile ? '100%' : undefined,
                        })}
                    >
                        {p.bottom}
                    </div>
                )}
            </div>
        </div>
    );
}

export function SlideInForm(p: Props): JSX.Element {
    return (
        <SlideInFormContainer
            bottom={
                <TwoButtons
                    primaryButton={p.primaryButton}
                    secondaryButton={p.secondaryButton}
                />
            }
        >
            {p.children}
        </SlideInFormContainer>
    );
}
