import {
    ContextualState,
    generateContextualState,
    RequestGen,
} from 'dg-web-shared/lib/ContextualServerStateSlice';
import { localState, selectState, Store } from 'dg-web-shared/lib/Flux';
import {
    FormValidator,
    FormValidators,
} from 'dg-web-shared/lib/FormValidation';
import { StatusCode } from 'dg-web-shared/lib/HttpResponse';
import { stripNonNumericCharacters } from 'dg-web-shared/lib/StringConversions';
import { Translation, Translations } from 'dg-web-shared/lib/Text';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { apiGet } from '../../api/Http';
import { requestV2, RequestWrite } from '../../AsyncRequest';
import * as SettingsState from '../../common/state/SettingsState';
import {
    portalSlideIn,
    PortalSlideInProps,
} from '../root/components/PortalSlidein';
import { onBadgeSuccess } from './VehicleAddBadge';
import { VehicleDetailProps } from './VehicleDetail';
import { VehicleSlideInForm } from './VehicleSlideInForm';
import { UpdateVehicle, Vehicle, VehiclesMeta } from './VehicleState';
import { updateTransactionlistWarnings } from '../../transactions-list/state/TransactionsListState';
import { useTransactionListRefetch } from '../../transactions-list/TransactionsListContext';

export const badgeTexts: Translations<{
    orderBadgeLabel: Translation;
    useExistingBadgeLabel: Translation;
    assignBadge: Translation;
    badgeNrLabel: Translation;
}> = {
    de: {
        orderBadgeLabel: () => 'Neuen Badge bestellen',
        useExistingBadgeLabel: () => 'Vorhandenen Badge erfassen',
        assignBadge: () => 'Badge zuweisen',
        badgeNrLabel: () => 'Badge-Nummer',
    },
    fr: {
        orderBadgeLabel: () => 'Commander un nouveau badge',
        useExistingBadgeLabel: () => 'Saisir un badge éxistant',
        assignBadge: () => 'Assigner le badge',
        badgeNrLabel: () => 'Numèro de badge',
    },
    it: {
        orderBadgeLabel: () => 'Ordina nuovo badge',
        useExistingBadgeLabel: () => 'Registra un badge esistente',
        assignBadge: () => 'Assegna badge',
        badgeNrLabel: () => 'Numero di badge',
    },
    en: {
        orderBadgeLabel: () => 'Order new badge',
        useExistingBadgeLabel: () => 'Register an existing badge',
        assignBadge: () => 'Assign badge',
        badgeNrLabel: () => 'Badge number',
    },
};

export const AddBadgeForm = portalSlideIn<VehicleDetailProps & onBadgeSuccess>(
    localState<
        VehicleDetailProps & onBadgeSuccess & PortalSlideInProps,
        {
            labelNr: string | null;
            selectType: boolean;
            showError: boolean;
        }
    >(
        {
            selectType: false,
            showError: false,
            labelNr: null,
        },
        selectState(
            (store, p) => {
                const settings = new SettingsState.StateSlice(store).state;

                return {
                    settings: settings,
                    meta: VehiclesMeta.get(store),
                    selectedVehicle: UpdateVehicle.get(store),
                    badgeValidation: BadgeValidation.get(store, {
                        labelNr: p.state.labelNr,
                        lang: settings.language,
                    }),
                };
            },
            p => {
                const texts = badgeTexts[p.settings.language];
                const refetchTransactionList = useTransactionListRefetch();

                return (
                    <VehicleSlideInForm
                        onCancel={p.onClose}
                        onSave={() => {
                            const newVehicle: Vehicle = {
                                ...p.vehicle,
                                badgeId: null,
                                badgeLabelNr: p.state.labelNr,
                            };
                            return p.update(store => {
                                return UpdateVehicle.postBadge(
                                    store,
                                    newVehicle,
                                    badgeId => {
                                        refetchTransactionList();
                                        updateTransactionlistWarnings(p.update);
                                        p.onClose();
                                        p.onSuccess(badgeId);
                                    },
                                );
                            });
                        }}
                        selectedVehicle={p.selectedVehicle}
                        disabledPrimary={
                            p.badgeValidation.data?.validationError !== null ||
                            p.badgeValidation.data.local
                        }
                    >
                        <TextField
                            labelText={texts.badgeNrLabel()}
                            value={p.state.labelNr || ''}
                            onChange={v => {
                                p.setState({
                                    labelNr: stripNonNumericCharacters(v),
                                });
                            }}
                            onBlur={() => {
                                p.setState({ showError: true });
                            }}
                            errorText={
                                p.badgeValidation.data &&
                                (p.state.showError ||
                                    !p.badgeValidation.data.local)
                                    ? p.badgeValidation.data.validationError
                                    : ''
                            }
                        />
                    </VehicleSlideInForm>
                );
            },
        ),
    ),
);

namespace BadgeValidation {
    interface Context {
        labelNr: string | null;
        lang: string;
    }

    interface Data {
        local: boolean;
        validationError: string | null;
    }

    const badgeLabelNrValidators = (args: Context): string | null => {
        const validators: FormValidator[] = [FormValidators.NotEmpty];

        for (let i = validators.length; i < validators.length; i++) {
            const error = validators[i](args.labelNr, args.lang);
            if (error !== null) {
                return error();
            }
        }
        return null;
    };

    export type State = ContextualState<Data>;

    const validateReq = (args: Context) => {
        return apiGet(
            `badges/validate-label?labelNr=${args.labelNr}&lang=${args.lang}`,
        );
    };

    export const { get } = generateContextualState<Context, Data>({
        key: 'badge-validation-result',
        requestGenerator: validateReq,
        request: (reqGen: RequestGen<Context>, requestUpdate: RequestWrite) => {
            return (store: Store, args?: Context) => {
                if (!args) {
                    return 'local-badge-parsing';
                }
                const validation = badgeLabelNrValidators(args);
                if (args.labelNr !== null && validation == null) {
                    return requestV2(reqGen, requestUpdate)(store, args);
                }

                const req = reqGen(args);
                requestUpdate(store, {
                    request: req,
                    pending: false,
                    statusCode: new StatusCode(200),
                    body: { validationError: validation, local: true },
                });
                return 'local-badge-parsing';
            };
        },
        parseBody: b => ({
            validationError: b.validationError,
            local: b.local!,
        }),
    });
}
