import * as Sentry from '@sentry/browser';
import { GlobalHandlers, TryCatch } from '@sentry/browser';
import * as Configuration from './Configuration';
import { CaptureConsole } from '@sentry/integrations';

// https://github.com/getsentry/sentry-javascript/issues/3388
const isAffectedByIssue3388 = navigator.userAgent.includes('Chrome/74.0.3729');

export function init(): void {
    const dsn = Configuration.getSentryProjectDSN();
    if (dsn != null) {
        Sentry.init({
            dsn: dsn,
            tunnel: '/ui-api/sentry-tunnel',
            allowUrls: [
                /https?:\/\/parkingpay\.ch/,
                /.*\.digitalparking\.ch/,
                /.*\.digitalparking\.dev/,
            ],
            ignoreErrors: [
                // Random plugins/extensions
                'top.GLOBALS',
                // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                "Can't find variable: ZiteReader",
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'http://loading.retry.widdit.com/',
                'atomicFindClose',
                // Facebook borked
                'fb_xd_fragment',
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                // reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                'conduitPage',
            ],
            denyUrls: [
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            ],
            integrations: [
                new CaptureConsole({
                    levels: ['error'],
                }),
                new TryCatch({
                    requestAnimationFrame: !isAffectedByIssue3388,
                    eventTarget: !isAffectedByIssue3388,
                }),
                new GlobalHandlers(),
            ],
        });
    }
}
