import * as MetaServerState from '../../account/meta/state/MetaServerState';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Translation } from 'dg-web-shared/lib/Text';
import * as SettingsState from '../../common/state/SettingsState';
import * as AccountSetupState from '../state/AccountSetupState';
import { AccountSetupAddLicensePlate } from './AccountSetupAddLicensePlate';
import { AccountSetupAddress } from './AccountSetupAddress';
import { AddressCountrySlidein } from './AccountSetupSlideins';
import { AccountSetupSummary } from './AccountSetupSummary';
import { AccountSetupType } from './AccountSetupType';
import { css } from '@emotion/css';

export interface NavigationTexts {
    next: Translation;
    previous: Translation;
    finish: Translation;
    headerLabel: Translation;
    pending: Translation;
}

interface AccountSetupState {
    settings: SettingsState.State;
    accountSetupNavigation: AccountSetupState.Navigation.State;
    meta: MetaServerState.State;
}

export const AccountSetup = Flux.selectState<object, AccountSetupState>(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        accountSetupNavigation: AccountSetupState.Navigation.get(store),
        meta: new MetaServerState.StateSlice(store).state,
    }),
    p => {
        return (
            <div
                className={css({
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                })}
            >
                {p.accountSetupNavigation.currentStep === 'Type' ? (
                    <AccountSetupType />
                ) : p.accountSetupNavigation.currentStep === 'Address' ? (
                    <AccountSetupAddress />
                ) : p.accountSetupNavigation.currentStep ===
                  'AddLicensePlate' ? (
                    <AccountSetupAddLicensePlate />
                ) : p.accountSetupNavigation.currentStep === 'Summary' ? (
                    <AccountSetupSummary />
                ) : null}
                <AddressCountrySlidein.Slidein />
            </div>
        );
    },
);
