import {
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';

import { useState } from 'react';
import { css } from '@emotion/css';
import { Localized } from '../../common/components/Localized';
import { Ticket } from './TicketSlideIn';
import { ButtonText, ModalQuestionBox } from '../../ui/modals/Confirmable';
import {
    AddPaymentMethodParkCreateMenu,
    PaymentOriginType,
} from '../components/add-payment-method/AddPaymentMethodParkCreateMenu';
import { ParkingcardTypo } from '../../style/parkingcardTypo.ts';
import {
    PayTicketButton,
    TicketPlusApprovalRequired,
} from '../../common/components/ticketplus/TicketPlusApprovalRequired';
import {
    TicketFooterContainer,
    TicketLayout,
} from '../../common/components/ticketplus/TicketPlusLayout';
import { TicketPlusClaimedModal } from '../../common/components/ticketplus/TicketPlusClaimed';
import { TicketPlusTooManyApprovedTicketsModal } from '../../common/components/ticketplus/TicketPlusTooManyApprovedTickets';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import * as PaymentTypeState from '../../common/state/PaymentTypeState.ts';

export function TicketApprovalRequired({
    ticketData,
    refetchTicketState,
    onTicketApproveSuccess,
    openZoneInfo,
}: {
    ticketData: Ticket;
    refetchTicketState: () => void;
    onTicketApproveSuccess: () => void;
    openZoneInfo: () => void;
}) {
    const [ticketClearanceRequestState, fetchTicketClearanceRequest] =
        useServerWrite<{ ticketId: string }, null, TicketApprovalError>(() => ({
            url: `/ui-api/customer-account/ticket/approve`,
        }));
    const ticketClearancePending =
        ticketClearanceRequestState.status === RequestStatus.PENDING;
    useServerSuccessEffect(ticketClearanceRequestState, () => {
        onTicketApproveSuccess();
    });
    useServerErrorEffect(ticketClearanceRequestState, (_statusCode, data) => {
        if (data) {
            setTicketApprovalError(data);
        }
    });

    const [ticketApprovalError, setTicketApprovalError] =
        useState<TicketApprovalError | null>(null);
    const [showAddPaymentSlideIn, setShowAddPaymentSlideIn] = useState(false);

    return (
        <TicketLayout type="ticketplus">
            <TicketPlusApprovalRequired
                onOpenZoneInfo={openZoneInfo}
                zoneName={ticketData.zone.name}
                ticketCreatedAt={ticketData.createdAt}
            />

            {ticketApprovalError && (
                <TicketApprovalErrorModal
                    error={ticketApprovalError}
                    onClose={() => setTicketApprovalError(null)}
                    setShowAddPaymentSlideIn={() =>
                        setShowAddPaymentSlideIn(true)
                    }
                />
            )}

            <AddPaymentMethodParkCreateMenu
                open={showAddPaymentSlideIn}
                onClose={() => {
                    refetchTicketState();
                    setShowAddPaymentSlideIn(false);
                }}
                paymentOriginInfo={{
                    type: PaymentOriginType.CHECKIN_BY_TICKET,
                    ticketId: ticketData.ticketId,
                }}
            />

            <TicketFooterContainer>
                <TicketApiString ticketApiString={ticketData.apiString} />

                <PayTicketButton
                    loading={ticketClearancePending}
                    onClick={() =>
                        fetchTicketClearanceRequest({
                            ticketId: ticketData.ticketId,
                        })
                    }
                />
            </TicketFooterContainer>
        </TicketLayout>
    );
}

function TicketApprovalErrorModal({
    error,
    onClose,
    setShowAddPaymentSlideIn,
}: {
    error: TicketApprovalError;
    onClose: () => void;
    setShowAddPaymentSlideIn: () => void;
}) {
    const { storeState } = useStore(store => ({
        paymentType: new PaymentTypeState.StateSlice(store).state,
    }));

    switch (error.invalidReason) {
        case InvalidReason.ALIAS_INVALID:
        case InvalidReason.NOT_ENOUGH_SALDO:
            return (
                <ModalQuestionBox
                    titleCaption={
                        <Localized
                            de="Zahlungsmittel fehlt"
                            fr="Moyen de paiement manquant"
                            it="Mezzo di pagamento mancante"
                            en="Missing payment mean"
                        />
                    }
                    confirmCaption={ButtonText.EDIT}
                    confirmCallback={() => {
                        setShowAddPaymentSlideIn();
                        onClose();
                    }}
                    cancelCaption={ButtonText.CANCEL}
                    cancelCallback={() => onClose()}
                >
                    <PaymentValidationInvalidText
                        paymentValidationError={error}
                    />
                </ModalQuestionBox>
            );
        case InvalidReason.TICKET_ALREADY_CLAIMED:
            return <TicketPlusClaimedModal onClose={onClose} />;
        case InvalidReason.TOO_MANY_APPROVED_TICKETS:
            return (
                <TicketPlusTooManyApprovedTicketsModal
                    onClose={onClose}
                    activePaymentType={
                        storeState.paymentType.data.activePaymentMethod
                            .activePaymentType
                    }
                />
            );
    }
}

export type TicketApprovalError =
    | PaymentValidationAliasInvalid
    | PaymentValidationNotEnoughSaldo
    | TicketAlreadyClaimed
    | TooManyApprovedTickets;

interface PaymentValidationAliasInvalid {
    invalidReason: InvalidReason.ALIAS_INVALID;
}

interface PaymentValidationNotEnoughSaldo {
    invalidReason: InvalidReason.NOT_ENOUGH_SALDO;
    entryMinimalBalance: number;
}

interface TicketAlreadyClaimed {
    invalidReason: InvalidReason.TICKET_ALREADY_CLAIMED;
}

interface TooManyApprovedTickets {
    invalidReason: InvalidReason.TOO_MANY_APPROVED_TICKETS;
}

enum InvalidReason {
    ALIAS_INVALID = 'ALIAS_INVALID',
    NOT_ENOUGH_SALDO = 'NOT_ENOUGH_SALDO',
    TICKET_ALREADY_CLAIMED = 'TICKET_ALREADY_CLAIMED',
    TOO_MANY_APPROVED_TICKETS = 'TOO_MANY_APPROVED_TICKETS',
}

function PaymentValidationInvalidText({
    paymentValidationError,
}: {
    paymentValidationError:
        | PaymentValidationAliasInvalid
        | PaymentValidationNotEnoughSaldo;
}): JSX.Element {
    switch (paymentValidationError.invalidReason) {
        case InvalidReason.ALIAS_INVALID:
            return (
                <>
                    <p>
                        <Localized
                            de="Ohne gültiges Zahlungsmittel können Sie den Parking Ticket nicht bezahlen."
                            fr="Vous ne pouvez pas payer le ticket de stationnement sans moyen de paiement valide."
                            it="Non può pagare il ticket di parcheggio senza un mezzo di pagamento valido."
                            en="You cannot pay the parking ticket without a valid means of payment."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Um ein Zahlungsmittel zu definieren, wählen Sie „ÄNDERN“ aus."
                            fr="Pour en définir un, sélectionnez «MODIFIER»."
                            it="Per definirne uno, selezioni «MODIFICA»."
                            en="To define one, select «MODIFY»."
                        />
                    </p>
                </>
            );
        case InvalidReason.NOT_ENOUGH_SALDO:
            return (
                <>
                    <p>
                        <Localized
                            de="Mit dem aktuellen Saldo können Sie diese Funktion nicht benutzen."
                            fr="Vous ne pouvez pas utiliser cette fonction avec le solde actuel."
                            it="Non è possibile utilizzare questa funzione con il saldo attuale."
                            en="You cannot use this function with the current balance."
                        />
                    </p>
                    <p>
                        <Localized
                            de={`Sie müssen mindestens CHF ${paymentValidationError.entryMinimalBalance} auf dem Konto haben oder ein Zahlungsmittel hinterlegen.`}
                            fr={`Vous devez charger au moins CHF ${paymentValidationError.entryMinimalBalance} sur le compte ou enregistrer un moyen de paiement.`}
                            it={`Deve caricare almeno CHF ${paymentValidationError.entryMinimalBalance} sul conto oppure registrare un mezzo di pagamento.`}
                            en={`You must load at least CHF ${paymentValidationError.entryMinimalBalance} on the account or register a means of payment.`}
                        />
                    </p>
                    <p>
                        <Localized
                            de="Wählen Sie «ÄNDERN» aus, um die Situation zu korrigieren."
                            fr="Sélectionnez «MODIFIER» pour corriger la situation."
                            it="Selezioni «MODIFICA» per correggere la situazione."
                            en="Select «MODIFY» to correct the situation."
                        />
                    </p>
                </>
            );
    }
}

function TicketApiString({ ticketApiString }: { ticketApiString: string }) {
    return (
        <div
            className={css({
                textAlign: 'center',
                opacity: 0.8,
                ...ParkingcardTypo.tBody,
            })}
        >
            <p>Ticket-ID {ticketApiString}</p>
        </div>
    );
}
