import { useState } from 'react';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../common/components/FullWidthBottomButton';
import { Localized } from '../../common/components/Localized';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { ParkingcardTypo } from '../../style/parkingcardTypo.ts';
import {
    RequestStatus,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import * as superagent from 'superagent';
import { basicAuthHeader } from 'dg-web-shared/lib/Http';
import { HasEverLoggedInState } from '../../pages/home-unauthenticated/HasEverLoggedInState';
import * as TransactionState from '../../park-create/state/zone-transaction/TransactionState';
import { Vehicles } from '../../account/vehicles/VehicleState';
import { setAppToken } from '../../Native';
import { logAction } from '../../utils/ActionLog';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    ButtonText,
    ModalErrorBox,
    ModalQuestionBox,
    ModalSuccessBox,
} from '../../ui/modals/Confirmable';

import { GenericErrorBody } from '../../ui/modals/HandleHttpStati';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { AuthStatusType, useAuthState } from '../../app/state/AuthState';
import { Icon } from 'dg-web-shared/ui/icons/Icon';
import { Clickable } from 'dg-web-shared/ui/Clickable';

export interface LoginFormBlockProps {
    email: string;
    setEmail: (email: string) => void;
    openForgotPassword: () => void;
}

export function LoginFormBlock(p: LoginFormBlockProps) {
    const { store } = useStore(() => null);

    const [password, setPassword] = useState('');
    const [autoLogin, setAutoLogin] = useState(true);
    const [showError, setShowError] = useState(false);
    const [showResendActivationEmail, setShowResendActivationEmail] =
        useState(false);
    const { setAuthStatus } = useAuthState();

    const [httpGet, doHttpGet] = useServerWrite<
        LoginChangeRequest,
        { appToken: string },
        { token: string }
    >({
        req: body =>
            superagent
                .get('/ui-api/auth-token-v2')
                .set(
                    'Authorization',
                    basicAuthHeader(body.email, body.password),
                )
                .query({ longSession: body.autoLogin }),
        onResponse(res) {
            if (res.status === RequestStatus.SUCCESS) {
                HasEverLoggedInState.stateWrite(store, { hasEver: true });
                setAuthStatus(AuthStatusType.OK);
                TransactionState.ParkTransaction.refetchSameContext(
                    store,
                    true,
                );
                Vehicles.reset(store);
                setAppToken(res.data.appToken);
                CurrentLoginState.reset(store);
                logAction(store, 'login', null);
            }
            if (
                res.status === RequestStatus.ERROR &&
                res.httpStatusCode === 403
            ) {
                setShowResendActivationEmail(true);
            }
        },
    });

    const formIsValid = p.email.length > 0 && password.length > 0;

    const onSubmit = (): boolean => {
        if (formIsValid) {
            doHttpGet({
                email: p.email,
                password: password,
                autoLogin: autoLogin,
            });
        }
        setShowError(true);
        return false;
    };

    return (
        <>
            <form
                className={css({
                    position: 'relative',
                    padding: '0 24px',
                    overflow: 'hidden',
                })}
                action="javascript:void(0)"
                noValidate
                onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
                    if (e && e.keyCode === 13) {
                        e.preventDefault();
                        onSubmit();
                    }
                }}
            >
                <TextField
                    context={InputContext.regular}
                    value={p.email}
                    inputType="email"
                    name="email"
                    autocomplete="email"
                    maxChars={100}
                    labelText={
                        <Localized
                            de="E-Mail"
                            fr="E-mail"
                            it="E-mail"
                            en="E-mail"
                        />
                    }
                    errorText={
                        showError &&
                        httpGet.status === RequestStatus.ERROR &&
                        httpGet.httpStatusCode === 400 ? (
                            <Localized
                                de="Sie müssen ihre E-Mail als Benutzername verwenden."
                                fr="Vous devez utiliser votre adresse e-mail comme nom d‘utilisateur."
                                it="Dovete usare il vostro e-mail per accedere."
                                en="You must use your e-mail to log in."
                            />
                        ) : (
                            ''
                        )
                    }
                    onChange={(value: string): void => {
                        p.setEmail(value);
                    }}
                    onFocus={() => setShowError(false)}
                    tabIndex={0}
                />
                <TextField
                    context={InputContext.regular}
                    value={password}
                    inputType="password"
                    labelText={
                        <Localized
                            de="Passwort"
                            fr="Mot de passe"
                            it="Password"
                            en="Password"
                        />
                    }
                    errorText={
                        showError &&
                        httpGet.status === RequestStatus.ERROR &&
                        httpGet.httpStatusCode === 401 ? (
                            <Localized
                                de="Die E-Mail oder das Passwort sind falsch."
                                fr="Mauvais e-mail ou mot de passe."
                                it="L'e-mail o la password non sono corretti."
                                en="E-mail or password is incorrect."
                            />
                        ) : null
                    }
                    onChange={(value: string) => {
                        setPassword(value);
                    }}
                    tabIndex={0}
                    onFocus={() => setShowError(false)}
                />
                <div
                    className={css({
                        display: 'flex',
                        flexDirection: 'row',
                    })}
                >
                    <Clickable
                        element="div"
                        className={css({
                            ...ParkingcardTypo.caption,
                            fontSize: '15px',
                            fontWeight: 500,
                            color: Colors.blue,
                            paddingTop: '15px',
                        })}
                        onClick={() => p.openForgotPassword()}
                    >
                        <Localized
                            de="Passwort vergessen"
                            fr="Mot de passe oublié"
                            it="Password dimenticata"
                            en="Forgotten password"
                        />
                    </Clickable>
                    <div
                        className={css({
                            marginLeft: 'auto',
                        })}
                    >
                        <Clickable
                            element="div"
                            className={css({
                                ...ParkingcardTypo.caption,
                                fontSize: '15px',
                                fontWeight: 500,
                                paddingTop: '15px',
                                color: Colors.blue,
                                width: '140px',
                                cursor: 'pointer',
                                position: 'relative',
                            })}
                            onClick={() => setAutoLogin(!autoLogin)}
                        >
                            <Localized
                                de="Login speichern"
                                fr="Rester connecté"
                                it="Rimanere connessi"
                                en="Stay signed in"
                            />

                            <div
                                className={css({
                                    position: 'absolute',
                                    top: '8px',
                                    display: 'inline-block',
                                    marginLeft: '4px',
                                })}
                            >
                                {autoLogin && <Icon icon="done" />}
                            </div>
                        </Clickable>
                    </div>
                </div>
                <div
                    className={css({
                        marginTop: '30px',
                    })}
                >
                    <FullWidthBottomButton
                        color={FullWidthBottomButtonColor.BLUE}
                        onClick={() => onSubmit()}
                        label={{
                            de: 'Einloggen',
                            fr: 'Se connecter',
                            it: 'Accedi',
                            en: 'Sign in',
                        }}
                        disabled={!formIsValid}
                    />
                </div>
            </form>

            {showResendActivationEmail &&
                httpGet.status === RequestStatus.ERROR &&
                httpGet.httpStatusCode === 403 && (
                    <ResendActivationEmail
                        token={httpGet.data?.token}
                        email={p.email}
                        onClose={() => setShowResendActivationEmail(false)}
                        portal={SlideInPortalId.UNAUTH_LANDING_PAGE}
                    />
                )}
        </>
    );
}

interface ResendActivationEmailProps {
    token?: string;
    email: string;
    onClose: () => void;
    portal: SlideInPortalId;
}

function ResendActivationEmail(p: ResendActivationEmailProps) {
    const [httpPost, doHttpPost] = useServerWrite<
        { email: string },
        {
            success: boolean;
        }
    >({
        req: payload =>
            superagent
                .post(`/ui-api/resend-confirmation-email/${p.token}`)
                .send(payload),
    });

    if (!p.token) {
        return (
            <ModalErrorBox confirmCallback={p.onClose} portal={p.portal}>
                <GenericErrorBody />
            </ModalErrorBox>
        );
    }
    switch (httpPost.status) {
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.PENDING:
            return (
                <ModalQuestionBox
                    confirmCaption={ButtonText.RESEND}
                    confirmCallback={() => doHttpPost({ email: p.email })}
                    titleCaption={
                        <Localized
                            de="E-Mail nicht bestätigt"
                            fr="E-mail pas confirmée"
                            it="E-mail non confermato"
                            en="Email not confirmed"
                        />
                    }
                    cancelCaption={ButtonText.CANCEL}
                    cancelCallback={p.onClose}
                    isPending={httpPost.status === RequestStatus.PENDING}
                    portal={p.portal}
                >
                    <p>
                        <Localized
                            de="Sie haben Ihre E-Mail-Adresse noch nicht bestätigt."
                            fr="Vous n'avez pas encore confirmé votre adresse e-mail."
                            it="Non ha ancora confermato il suo indirizzo e-mail."
                            en="You have not yet confirmed your email address."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Sollten Sie die E-Mail-Mitteilung mit dem Bestätigungslink nicht erhalten haben, überprüfen Sie in Ihrem E-Mail-Programm den Spam-Ordner."
                            fr="Veuillez vérifier le dossier spam dans votre programme de messagerie si vous n'avez pas reçu le message e-mail avec le lien de confirmation."
                            it="Se non ha ricevuto il messagio e-mail con il link di conferma, verifichi per favore la cartella spam (posta indesiderata) nel suo programma di posta elettronica."
                            en="Please also check the spam folder in your email program, if you have not received the email message with the confirmation link."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Ansonsten können Sie mit «ERNEUT SENDEN» einen neuen Bestätigungslink anfordern."
                            fr="Sinon, avec «RENVOYER» vous pouvez demander un nouveau lien de confirmation."
                            it="Altrimenti può richiedere un nuovo link di conferma cliccando su «RIPETI INVIO»."
                            en="Otherwise, you can request a new confirmation link with «RESEND»."
                        />
                    </p>
                </ModalQuestionBox>
            );

        case RequestStatus.ERROR:
            return (
                <ModalErrorBox confirmCallback={p.onClose} portal={p.portal}>
                    <p>
                        <Localized
                            de="Die E-Mail-Mitteilung mit dem Bestätigungslink kann nicht erneut gesendet werden. Bitte versuchen Sie es später nochmals."
                            fr="Impossible d'envoyer à nouveau le message e-mail avec le lien de confirmation. Réessayez plus tard."
                            it="Impossibile inviare nuovamente il messaggio e-mail con il link di conferma. Riprovare più tardi."
                            en="Failed to resend the email message with the confirmation link. Please try again later."
                        />
                    </p>
                </ModalErrorBox>
            );

        case RequestStatus.SUCCESS:
            return httpPost.data.success ? (
                <ModalSuccessBox
                    confirmCallback={p.onClose}
                    titleCaption={
                        <Localized
                            de="E-Mail gesendet"
                            fr="E-mail envoyé"
                            it="E-mail inviato"
                            en="Email sent"
                        />
                    }
                    portal={p.portal}
                >
                    <p>
                        <Localized
                            de="Die E-Mail-Mitteilung mit dem Bestätigungslink wurde erneut gesendet."
                            fr="Le message e-mail avec le lien de confirmation a été envoyé à nouveau."
                            it="Il messaggio e-mail con il link di conferma è stato inviato nuovamente."
                            en="The email message with the confirmation link has been sent again."
                        />
                    </p>
                </ModalSuccessBox>
            ) : (
                <ModalErrorBox confirmCallback={p.onClose} portal={p.portal}>
                    <p>
                        <Localized
                            de="Eine neue E-Mail-Mitteilung mit dem Bestätigungslink wurde bereits versendet; es müssen mindestens 24 Stunden vergangen sein, bevor Sie eine weitere E-Mail anfordern können."
                            fr="Un nouveau message e-mail avec le lien de confirmation a déjà été envoyé; au moins 24 heures doivent s'être écoulées avant que vous puissiez en demander un autre."
                            it="È già stato inviato un nuovo messaggio e-mail con il link di conferma; devono essere trascorse almeno 24 ore prima di poterne richiedere un altro."
                            en="A new email message with the confirmation link has already been sent; at least 24 hours must elapse before another request can be made."
                        />
                    </p>
                </ModalErrorBox>
            );
    }
}

interface LoginChangeRequest {
    email: string;
    password: string;
    autoLogin: boolean;
}
