import { css } from '@emotion/css';

import { Language } from 'dg-web-shared/lib/Text';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { Localized } from '../../../common/components/Localized';
import { Colors } from 'dg-web-shared/ui/vars';
import { portalSlideIn } from '../../root/components/PortalSlidein';
import { ibanErrorMessage } from './ibanErrorMessage';
import { PaymentMethodFormBlock } from '../PaymentMethodFormBlock';
import { paymentMethodTexts } from '../PaymentMethodTexts';
import { useState } from 'react';
import { ModalErrorBox } from '../../../ui/modals/Confirmable';
import { PaymentMethodEnum } from '../../../api/Http';
import {
    InvoicePaymentNotPossibleMessage,
    LSVPaymentNotPossibleMessage,
} from '../../meta/AddressFormError';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Iban } from 'dg-web-shared/model/Iban.ts';

export const DirectDebitRequestFormSlideIn = portalSlideIn<Props>(
    DirectDebitRequestFormWrapper,
);

function DirectDebitRequestFormWrapper(props: Props) {
    return <DirectDebitRequestForm {...props} />;
}

function DirectDebitRequestForm({
    onSubmit,
    onClose,
    language,
    validBankClearingNumbers,
    customerAddressCountryCode,
    customerShippingAddressCountryCode,
}: Props) {
    const [iban, setIban] = useState<string>('');
    const [ibanEdited, setIbanEdited] = useState(false);
    const [showPostfinanceErrorModal, setShowPostfinanceErrorModal] =
        useState(false);
    const t = paymentMethodTexts[language];

    if (
        isCountryNotSupportedForPaymentMethod(customerAddressCountryCode) ||
        (customerShippingAddressCountryCode.trim() != '' &&
            isCountryNotSupportedForPaymentMethod(
                customerShippingAddressCountryCode,
            ))
    ) {
        return (
            <CountryNotSupportedForPaymentMethod
                paymentMethod={PaymentMethodEnum.LSV}
            />
        );
    }

    return (
        <PaymentMethodFormBlock
            onCancel={onClose}
            onSubmit={() => {
                !hasPostfinanceClearingNumber(iban)
                    ? onSubmit({
                          iban: new Iban(iban).valueNoSpace.toUpperCase(),
                      })
                    : setShowPostfinanceErrorModal(true);
            }}
            submitEnabled={
                ibanErrorMessage(
                    language,
                    validBankClearingNumbers,
                    '',
                    iban,
                ) === ''
            }
        >
            <div
                className={css({
                    ...Typo.robotoRegular,
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: Colors.action_f,
                })}
            >
                <p>{t.directDebitRequestP1()}</p>
                <p>{t.directDebitRequestP2()}</p>
                <p>{t.directDebitRequestP3()}</p>
            </div>

            <TextField
                onChange={iban => {
                    setIban(iban);
                }}
                onBlur={() => {
                    setIban(iban.toUpperCase().trim());
                    setIbanEdited(true);
                }}
                value={iban}
                errorText={
                    ibanEdited
                        ? ibanErrorMessage(
                              language,
                              validBankClearingNumbers,
                              '',
                              iban,
                          )
                        : ''
                }
                labelText={t.iban()}
            />
            {showPostfinanceErrorModal && (
                <PostfinanceErrorModal
                    onClose={() => setShowPostfinanceErrorModal(false)}
                />
            )}
        </PaymentMethodFormBlock>
    );
}

export function hasPostfinanceClearingNumber(ibanString: string) {
    const iban = new Iban(ibanString.toUpperCase());
    const clearingNumberPostfinance = 9000;

    return iban.clearingNumber === clearingNumberPostfinance;
}

export function PostfinanceErrorModal({ onClose }: { onClose: () => void }) {
    return (
        <ModalErrorBox
            confirmCallback={onClose}
            titleCaption={
                <Localized
                    de="Nicht mehr verfügbar"
                    fr="N'est plus disponible"
                    it="Non più disponibile"
                    en="No longer available"
                />
            }
        >
            <p>
                <Localized
                    de="Die Zahlungsweise «Lastschrift» auf PostFinance-Konten ist in Parkingpay nicht mehr verfügbar. Sie können ein Bankkonto verwenden oder eine andere Zahlungsweise wählen."
                    fr="Le mode de paiement «prélèvement automatique» sur les comptes PostFinance n'est plus disponible dans Parkingpay. Vous pouvez utiliser un compte bancaire ou choisir un autre mode de paiement."
                    it="La modalità di pagamento «addebito diretto» su conti PostFinance non è più disponibile in Parkingpay. Potete utilizzare un conto bancario oppure scegliere un’altra modalità di pagamento."
                    en="The direct debit payment method on PostFinance accounts is no longer available in Parkingpay. You can use a bank account or choose another payment method."
                />
            </p>
        </ModalErrorBox>
    );
}

export function CountryNotSupportedForPaymentMethod({
    paymentMethod,
}: {
    paymentMethod: PaymentMethodEnum;
}): JSX.Element {
    return (
        <div
            className={css({
                ...Typo.robotoRegular,
                fontSize: '16px',
                lineHeight: '22px',
                color: Colors.action_f,
                padding: '0 24px',
            })}
        >
            <p>
                {paymentMethod == PaymentMethodEnum.LSV && (
                    <LSVPaymentNotPossibleMessage />
                )}
                {paymentMethod == PaymentMethodEnum.INVOICE && (
                    <InvoicePaymentNotPossibleMessage />
                )}
            </p>

            <p>
                <Localized
                    de="Wir bitten um Entschuldigung für die Unannehmlichkeiten."
                    fr="Nous vous prions de bien vouloir nous excuser pour la gêne occasionnée."
                    it="Ci scusiamo per l'inconveniente."
                    en="We kindly apologize for the inconvenience."
                />
            </p>
        </div>
    );
}

export function isCountryNotSupportedForPaymentMethod(countryCode: string) {
    return !Object.values(SupportedCountry).map(String).includes(countryCode);
}

enum SupportedCountry {
    SWITZERLAND = 'CH',
    LIECHTENSTEIN = 'FL',
}

interface Props {
    onClose: () => void;
    language: Language;
    validBankClearingNumbers: Set<number>;
    customerAddressCountryCode: string;
    customerShippingAddressCountryCode: string;
    onSubmit: (values: { iban: string }) => void;
}
