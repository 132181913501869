import * as superagent from 'superagent';
import { Localized } from '../../common/components/Localized';
import {
    ItemBlockGroup,
    MainMenuItemBlock,
} from '../root/components/ItemBlock';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { InvoicesSettingsMenu } from './InvoicesSettingsMenu';
import { useStore } from 'dg-web-shared/lib/Flux';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { Colors } from 'dg-web-shared/ui/vars';
import { css } from '@emotion/css';
import { DateTime } from 'luxon';
import { LuxonDateStringFormats } from 'dg-web-shared/lib/LuxonDateStringFormats';
import { RedWarnIcon24 } from '../../common/components/RedWarnIcon';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { useState } from 'react';
import { Icon } from 'dg-web-shared/ui/icons/Icon';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import red = Colors.red;

function getTitle() {
    return (
        <Localized de="Rechnungen" fr="Factures" it="Fatture" en="Invoices" />
    );
}

export const InvoicesMenu = () => {
    const [invoicesMenuOpen, setInvoicesMenuOpen] = useState(false);

    const overDueLevel = useStore(s => ({
        accountBalance: new AccountBalanceState.StateSlice(s).state,
    })).storeState.accountBalance.data.overdueLevel;

    return (
        <>
            <MainMenuItemBlock
                rightTitle={overDueLevel > 0 && <RedWarnIcon24 />}
                title={getTitle()}
                onClick={() => setInvoicesMenuOpen(true)}
            />
            <InvoicesSlidein
                open={invoicesMenuOpen}
                portal={SlideInPortalId.USER_ACCOUNT}
                title={getTitle()}
                onClose={() => setInvoicesMenuOpen(false)}
                overdueLevel={overDueLevel}
            />
        </>
    );
};

export const InvoicesSlidein = portalSlideIn((p: { overdueLevel: number }) => {
    const [invoices] = useServerFetch<
        {
            customerInvoiceId: number;
            amount: string;
            createdAt: string;
            key: string;
        }[],
        object
    >(
        {
            request() {
                return superagent.get(
                    '/ui-api/customer-account/deposits/invoices',
                );
            },
        },
        {},
    );
    return (
        <div>
            <ItemBlockGroup
                title={
                    <Localized
                        de="Einstellungen"
                        fr="Réglages"
                        it="Impostazioni"
                        en="Settings"
                    />
                }
            />
            <InvoicesSettingsMenu />
            <ItemBlockGroup
                title={
                    <Localized
                        de="Dokumente"
                        fr="Documents"
                        it="Documenti"
                        en="Documents"
                    />
                }
            >
                {invoices.status !== RequestStatus.SUCCESS ? (
                    <Spinner />
                ) : invoices.data.length === 0 ? (
                    <div
                        className={css({
                            ...Typo.body,
                            color: Colors.typo1,
                            padding: '48px 24px',
                            textAlign: 'center',
                        })}
                    >
                        <Localized
                            de="Noch keine Rechnung versandt."
                            fr="Aucune facture n'a encore été envoyée."
                            it="Non è stata ancora inviata alcuna fattura."
                            en="No invoice sent yet."
                        />
                    </div>
                ) : (
                    invoices.data.map((invoice, idx) => (
                        <a
                            key={`${invoice.customerInvoiceId} - ${invoice.key}`}
                            className={css({ textDecoration: 'none' })}
                            target="_blank"
                            href={`/ui-api/customer-account/deposits/invoice/${invoice.customerInvoiceId}?key=${invoice.key}`}
                            rel="noreferrer"
                        >
                            <MainMenuItemBlock
                                key={invoice.customerInvoiceId}
                                title={DateTime.fromISO(
                                    invoice.createdAt,
                                ).toFormat(LuxonDateStringFormats.DATE)}
                                subTitle={
                                    <>
                                        <span
                                            className={
                                                p.overdueLevel > idx
                                                    ? css({ color: red })
                                                    : undefined
                                            }
                                        >
                                            {invoice.amount}
                                        </span>
                                        {p.overdueLevel > idx && (
                                            <span
                                                className={css({ color: red })}
                                            >
                                                {' '}
                                                <Localized
                                                    en=" (overdue)"
                                                    de=" (überfällig)"
                                                    it=" (scaduto)"
                                                    fr=" (en défaut)"
                                                />
                                            </span>
                                        )}
                                    </>
                                }
                                rightTitle={<Icon icon="description" />}
                                hideChevron={true}
                                onClick={() => {
                                    const url = `/ui-api/customer-account/deposits/invoice/${invoice.customerInvoiceId}?key=${invoice.key}`;
                                    window.open(url, '_blank');
                                }}
                            />
                        </a>
                    ))
                )}
            </ItemBlockGroup>
        </div>
    );
});
