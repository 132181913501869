import * as Text from '../../../common/i18n/Text';
import * as LicensePlateState from '../../../common/state/LicensePlateState';
import moment from 'moment';

import { ShippingAddress } from '../../../api/Http';
import { licensePlateInfoBoxesTexts } from '../../i18n/ParkCreateTexts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers';

import { Badge, EntityData, LicensePlate } from '../../state/shared/EntityData';
import {
    ArrowPosition,
    InlineAlertBox,
    InlineErrorBox,
} from '../../../ui/modals/Confirmable';
import { Localized } from '../../../common/components/Localized';
import { css } from '@emotion/css';

export const AddLicensePlate = -99;

const texts = (p: { language: string }): Texts => {
    return licensePlateInfoBoxesTexts[p.language];
};

export const ButtonRow = (p: { children?: React.ReactNode }): JSX.Element => {
    return (
        <div className={css({ display: 'flex', justifyContent: 'flex-end' })}>
            {p.children}
        </div>
    );
};

export interface Texts {
    NoVignetteHeader: Text.Translation;
    NoVignetteExplanation1: (lp: string) => string;
    NoVignetteExplanation1Permit: (lp: string[]) => string;
    NoVignetteExplanation2: Text.Translation;
    NoVignetteExplanation3: Text.Translation;
    NoVignetteExplanation4: Text.Translation;
    NoVignetteOrderButton: Text.Translation;
    VignetteOrderedHeader: Text.Translation;
    VignetteWasSentExplanation: (
        date: moment.Moment,
        address: boolean,
    ) => string;
    VignetteWasAddedByOperator: (
        date: moment.Moment,
        operator: string,
    ) => string;
    VignetteWillBeSentExplanation: Text.Translation;
    VignetteOnCarHeader: Text.Translation;
    VignetteExplanation: (lp: string[]) => string;
    VignetteOnCarButton: Text.Translation;
    AddLicensePlate: Text.Translation;
    WrongTypeHeader: Text.Translation;
    WrongTypeHeaderTransaction: Text.Translation;
    WrongTypeHeaderPermitOneLp: Text.Translation;
    WrongTypeHeaderPermitMultipleLp: (lp: string[]) => string;
    ClearanceNeededHeader: Text.Translation;
    ClearanceNeededTransaction: (operatorName: string) => string;
    ClearanceNeededPermitOneLp: (operatorName: string, lp: string) => string;
    ClearanceNeededPermitMultiLp: (
        operatorName: string,
        lp: string[],
    ) => string;
    ClearanceNeededPermitOneBadge: (operatorName: string, lp: string) => string;
    ClearanceNeededPermitMultiBadge: (
        operatorName: string,
        lp: string[],
    ) => string;
    CounterPermitHeader: Text.Translation;
    CounterPermitExplanation: Text.Translation;
    ContactOperator: (operator: string) => string;
}

interface LicensePlateLike {
    id: number;
    licensePlateNr: string;
}

export const CounterPermit = ({
    operatorName,
    operatorContactDetails,
}: {
    operatorName: string;
    operatorContactDetails: string | null;
}): JSX.Element => {
    return (
        <InlineErrorBox
            titleCaption={
                <Localized
                    de="Online nicht verfügbar"
                    fr="Pas disponible en ligne"
                    it="Non disponibile online"
                    en="Not available online"
                />
            }
            arrowPosition={ArrowPosition.left}
        >
            <p>
                <Localized
                    de="Die gewählte Bewilligung kann nicht online gekauft werden."
                    fr="L'autorisation sélectionnée ne peut pas être achetée en ligne."
                    it="L'autorizzazione selezionata non può essere acquistata online."
                    en="The selected permit cannot be purchased online."
                />
            </p>
            <OperatorContactText
                operatorName={operatorName}
                operatorContactDetails={operatorContactDetails}
            />
        </InlineErrorBox>
    );
};

function OperatorContactText({
    operatorName,
    operatorContactDetails,
}: {
    operatorName: string;
    operatorContactDetails: string | null;
}) {
    const lastChar = operatorContactDetails ? ':' : '.';
    const operatorContactText = (
        <p>
            <Localized
                de={`Bitte wenden Sie sich direkt an den Betreiber ${operatorName}${lastChar}`}
                fr={`Veuillez contacter directement l'exploitant ${operatorName}${lastChar}`}
                it={`Vogliate rivolgetevi direttamente al gestore ${operatorName}${lastChar}`}
                en={`Please contact the operator ${operatorName} directly${lastChar}`}
            />
        </p>
    );

    if (!operatorContactDetails) {
        return operatorContactText;
    }

    return (
        <>
            {operatorContactText}
            <p>
                <div
                    className={css({
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-all',
                        fontWeight: 'bold',
                    })}
                >
                    {operatorContactDetails}
                </div>
            </p>
        </>
    );
}

export const WrongType = (p: {
    language: string;
    lps: LicensePlateLike[];
    permit: boolean;
}) => {
    return (
        <InlineErrorBox
            titleCaption={texts(p).WrongTypeHeader()}
            arrowPosition={ArrowPosition.left}
        >
            <Conditional c={!p.permit}>
                <p>{texts(p).WrongTypeHeaderTransaction()}</p>
            </Conditional>
            <Conditional c={p.permit && p.lps.length === 1}>
                <p>{texts(p).WrongTypeHeaderPermitOneLp()}</p>
            </Conditional>
            <Conditional c={p.permit && p.lps.length > 1}>
                <p>
                    {texts(p).WrongTypeHeaderPermitMultipleLp(
                        p.lps.map(l => l.licensePlateNr),
                    )}
                </p>
            </Conditional>
        </InlineErrorBox>
    );
};

export const FlowNotYetCompleted = () => {
    return (
        <InlineAlertBox
            titleCaption={
                <Localized
                    de="Fehlendes Formular"
                    fr="Formulaire manquant"
                    it="Formulario mancante"
                    en="Missing form"
                />
            }
            arrowPosition={ArrowPosition.left}
        >
            <Localized
                de={
                    <>
                        <p>
                            Das Formular für den Nachweis fehlender privater
                            Parkplätze ist noch nicht ausgefüllt worden.
                        </p>
                        <p>
                            Den Link zu diesem Formular finden Sie in der
                            E-Mail, die Sie nach der Registrierung Ihrer Anfrage
                            erhalten haben.
                        </p>
                        <p>
                            Falls Sie dies noch nicht getan haben, leiten Sie
                            diesen Link bitte an den Eigentümer/die Verwaltung
                            Ihres Gebäudes weiter.
                        </p>
                    </>
                }
                fr={
                    <>
                        <p>
                            Le formulaire permettant de confirmer l&#39;absence
                            de places de parking privées n&#39;a pas encore été
                            rempli.
                        </p>
                        <p>
                            Vous trouverez le lien vers ce formulaire dans
                            l&#39;e-mail que vous avez reçu après avoir
                            enregistré votre demande.
                        </p>
                        <p>
                            Si vous ne l&#39;avez pas encore fait, veuillez
                            transmettre ce lien au propriétaire/administration
                            de votre bâtiment.
                        </p>
                    </>
                }
                it={
                    <>
                        <p>
                            Il formulario per la conferma della mancanza di
                            posti auto privati non è ancora stato compilato.
                        </p>
                        <p>
                            Il link a questo formulario lo trova nel mail che ha
                            ricevuto dopo aver registrato la richiesta.
                        </p>
                        <p>
                            Se non l&#39;ha ancora fatto, inoltri quest link al
                            proprietario/all&#39;amministrazione del suo
                            stabile.
                        </p>
                    </>
                }
                en={
                    <>
                        <p>
                            The form for confirming the lack of private parking
                            spaces has not yet been completed.
                        </p>
                        <p>
                            You will find the link to this form in the email you
                            received after registering your request.
                        </p>
                        <p>
                            If you have not already done so, please forward this
                            link to the owner/administration of your building.
                        </p>
                    </>
                }
            />
        </InlineAlertBox>
    );
};

type NeedsClearanceProps = {
    lps: EntityData[];
    operatorName: string;
    operatorContactDetails: string | null;
    permit: boolean;
};

export function NeedsClearance({
    lps,
    operatorName,
    operatorContactDetails,
    permit,
}: NeedsClearanceProps) {
    const lpNumbr = lps
        .filter(e => e.entityType === 'LICENSE_PLATE')
        .map(e => (e as LicensePlate).licensePlateNr);
    const badgesLabelNumbr = lps
        .filter(e => e.entityType === 'BADGE')
        .map(e => (e as Badge).labelNr);
    return (
        <InlineAlertBox
            titleCaption={
                <Localized
                    de="Freigabe-Pflicht"
                    fr="Approbation obligatoire"
                    it="Approvazione obbligatoria"
                    en="Approval mandatory"
                />
            }
            arrowPosition={ArrowPosition.left}
        >
            {!permit && (
                <p>
                    <Localized
                        de="Dieses Kennzeichen ist für diese Zone nicht freigeschaltet und muss beim Betreiber beantragt werden"
                        fr="Cette immatriculation n'est pas activée pour la zone sélectionnée. Vous devez soumettre une demande à l'exploitant."
                        it="Questa targa non è abilitata per zona selezionata. Dovete quindi inoltrare una richiesta presso il gestore."
                        en="This license plate is not allowed for this zone and must be applied by the operator."
                    />
                </p>
            )}

            {permit && lpNumbr.length === 1 && (
                <p>
                    <Localized
                        de={`Das Kennzeichen ${lpNumbr[0]} ist für die gewählte Bewilligung nicht freigeschaltet und muss beim Betreiber ${operatorName} beantragt werden.`}
                        fr={`L'immatriculation ${lpNumbr[0]} ne dispose pas de l'approbation nécessaire pour l’achat de l’autorisation. Vous devez faire une demande chez l'exploitant ${operatorName}.`}
                        it={`Il numero di targa ${lpNumbr[0]} non dispone dell'approvazione per l'acquisto dell'autorizzazione selezionata. Dovete quindi inoltrare una richiesta presso il gestore ${operatorName}.`}
                        en={`The license plate ${lpNumbr[0]} does not have the approval to purchase the selected permit. You must first submit a request to the operator ${operatorName}.`}
                    />
                </p>
            )}

            {permit && lpNumbr.length > 1 && (
                <p>
                    <Localized
                        de={`Die Kennzeichen ${lpNumbr.join(
                            ', ',
                        )} sind für die gewählte Bewilligung nicht freigeschaltet und müssen beim Betreiber ${operatorName} beantragt werden.`}
                        fr={`Les immatriculations ${lpNumbr.join(
                            ', ',
                        )} ne disposent pas de l'approbation nécessaire pour l’achat de l’autorisation. Vous devez faire une demande chez l'exploitant ${operatorName}.`}
                        it={`I numeri di targa ${lpNumbr.join(
                            ', ',
                        )} non dispongono dell'approvazione per l'acquisto dell'autorizzazione selezionata. Dovete quindi inoltrare una richiesta presso il gestore ${operatorName}.`}
                        en={`The license plates ${lpNumbr.join(
                            ', ',
                        )} do not have the approval to purchase the selected permit. You must first submit a request to the operator ${operatorName}.`}
                    />
                </p>
            )}

            {permit && badgesLabelNumbr.length === 1 && (
                <p>
                    <Localized
                        de={`Der Badge ${badgesLabelNumbr[0]} ist für die gewählte Bewilligung nicht freigeschaltet und muss beim Betreiber ${operatorName} beantragt werden.`}
                        fr={`Le badge ${badgesLabelNumbr[0]} ne dispose pas de l'approbation nécessaire pour l’achat de l’autorisation. Vous devez faire une demande chez l'exploitant ${operatorName}.`}
                        it={`Il badge ${badgesLabelNumbr[0]} non dispone dell'approvazione per l'acquisto dell'autorizzazione selezionata. Dovete quindi inoltrare una richiesta presso il gestore ${operatorName}.`}
                        en={`The badge ${badgesLabelNumbr[0]} does not have the approval to purchase the selected permit. You must first submit a request to the operator ${operatorName}.`}
                    />
                </p>
            )}

            {permit && badgesLabelNumbr.length > 1 && (
                <p>
                    <Localized
                        de={`Die Badges ${badgesLabelNumbr.join(
                            ', ',
                        )} sind für die gewählte Bewilligung nicht freigeschaltet und müssen beim Betreiber ${operatorName} beantragt werden.`}
                        fr={`Les badges ${badgesLabelNumbr.join(
                            ', ',
                        )} ne disposent pas de l'approbation nécessaire pour l’achat de l’autorisation. Vous devez faire une demande chez l'exploitant ${operatorName}.`}
                        it={`I badge ${badgesLabelNumbr.join(
                            ', ',
                        )} non dispongono dell'approvazione per l'acquisto dell'autorizzazione selezionata. Dovete quindi inoltrare una richiesta presso il gestore ${operatorName}.`}
                        en={`The badges ${badgesLabelNumbr.join(
                            ', ',
                        )} do not have the approval to purchase the selected permit. You must first submit a request to the operator ${operatorName}.`}
                    />
                </p>
            )}

            <OperatorContactText
                operatorName={operatorName}
                operatorContactDetails={operatorContactDetails}
            />
        </InlineAlertBox>
    );
}

export const AddressBlock = (p: {
    address: LicensePlateState.SentAddress;
    shippingAddress: ShippingAddress | null;
    isBillingAddress: boolean;
}): JSX.Element => {
    const shippingAddr =
        p.shippingAddress &&
        (p.isBillingAddress || !p.shippingAddress.invoiceOnly) &&
        p.shippingAddress;

    return (
        <div>
            <div>{p.address.company1 || ''}</div>
            <div>{p.address.company2 || ''}</div>
            <div>{`${p.address.firstName || ''} ${
                p.address.lastName || ''
            }`}</div>
            <div>
                {(shippingAddr && shippingAddr.line1) ||
                    p.address.street1 ||
                    ''}
            </div>
            <div>{(shippingAddr && shippingAddr.line2) || ''}</div>
            <div>{`${
                (shippingAddr && shippingAddr.zipCode) ||
                p.address.zipCode ||
                ''
            } ${
                (shippingAddr && shippingAddr.city) || p.address.city || ''
            }`}</div>
        </div>
    );
};
