import { css } from '@emotion/css';

import { Updater, useStore } from 'dg-web-shared/lib/Flux';
import { Language } from 'dg-web-shared/lib/Text';
import { Localized } from '../../common/components/Localized';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import { Colors } from 'dg-web-shared/ui/vars';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import { paymentMethodTexts } from './PaymentMethodTexts';
import { PaymentMethodStatus } from './PaymentMethodStatus';
import { DirectDebitPaymentMethodNavigation } from './direct-debit/DirectDebitPaymentMethodNavigation';
import { TopUpBalanceNavigationState } from '../top-up-balance/TopUpBalanceNavigationState';
import { PaymentMethodEnum } from '../../api/Http';
import {
    BlockStatus,
    PaymentMethodItemBlock,
} from '../root/components/ItemBlock';
import { useServerSuccessEffect } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { logAction } from '../../utils/ActionLog';
import * as PaymentTypeState from '../../common/state/PaymentTypeState';
import { usePaymentTypeChange } from './DirectMethod';
import { useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export function Prepaid(props: {
    status: PaymentMethodStatus;
    update: Updater;
    closePaymentMethodsSlideIn: () => void;
    language: Language;
}) {
    const title = {
        de: 'Vorauszahlung',
        fr: 'Prépaiement',
        it: 'Prepagata',
        en: 'Prepaid',
    };

    const [write, setPaymentTypeToPrepaid] = usePaymentTypeChange();

    useServerSuccessEffect(write, () => {
        props.update(s => {
            new PaymentTypeState.StateSlice(s).reset();
            new AccountBalanceState.StateSlice(s).reset();
            logAction(s, 'set-payment-method', 'PREPAID');
            backToList();
            return 'log-and-reset-payment-type-and-account-balance-state';
        });
    });

    function navigateToTopUpBalance() {
        props.closePaymentMethodsSlideIn();
        props.update(DirectDebitPaymentMethodNavigation.reset);
        props.update(store =>
            TopUpBalanceNavigationState.stateWrite(store, {
                displayMethods: true,
            }),
        );
    }

    const [activationSlideInOpen, setActivationSlideInOpen] = useState(false);
    const backToList = () => setActivationSlideInOpen(false);

    return (
        <>
            <PaymentMethodItemBlock
                title={<Localized {...title} />}
                status={
                    props.status === PaymentMethodStatus.ACTIVE
                        ? BlockStatus.ACTIVE
                        : BlockStatus.INACTIVE
                }
                onClick={
                    props.status === PaymentMethodStatus.REQUESTED
                        ? null
                        : () => {
                              switch (props.status) {
                                  case PaymentMethodStatus.INACTIVE:
                                      setActivationSlideInOpen(true);
                                      break;
                                  case PaymentMethodStatus.ACTIVE:
                                      navigateToTopUpBalance();
                                      break;
                                  default:
                                      null;
                              }
                          }
                }
                key={`PREPAID`}
            />
            <PrepayActivationFormSlideIn
                language={props.language}
                onCancel={backToList}
                onClose={backToList}
                onSubmit={() =>
                    setPaymentTypeToPrepaid({
                        activePaymentType: PaymentMethodEnum.PREPAID,
                        ticketId: null,
                    })
                }
                open={activationSlideInOpen}
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...title} />}
                navigateToTopUpBalance={() => navigateToTopUpBalance()}
            />
        </>
    );
}

const PrepayActivationFormSlideIn = portalSlideIn<Props>(PrepayActivationForm);

export function PrepayActivationForm(props: Props) {
    const { storeState } = useStore(s => ({
        balance: new AccountBalanceState.StateSlice(s).state,
    }));

    const isBalancePositive =
        storeState.balance.data && storeState.balance.data.balance >= 0;

    const t = paymentMethodTexts[props.language];

    return (
        <SlideInForm
            secondaryButton={{
                label: t.Cancel(),
                onClick: props.onCancel,
            }}
            primaryButton={{
                label: t.Activate(),
                disabled: !isBalancePositive,
                onClick: props.onSubmit,
            }}
        >
            <div
                className={css({
                    ...Typo.robotoRegular,
                    fontSize: '16px',
                    lineHeight: '22px',
                })}
            >
                <InfoParagraph>{t.howPrepaidWorks()}</InfoParagraph>

                <InfoParagraph>{t.howToTopUp()}</InfoParagraph>

                {isBalancePositive ? (
                    <InfoParagraph>{t.pressToActivatePrepaid()}</InfoParagraph>
                ) : (
                    <ErrorParagraph>
                        <Localized
                            de={
                                <span>
                                    Sie können nicht auf die Zahlungsweise
                                    «Vorauszahlung» wechseln, da Ihr Saldo
                                    negativ ist. Bitte{' '}
                                    <NavLink
                                        onClick={props.navigateToTopUpBalance}
                                    >
                                        laden Sie zuerst das Konto auf.
                                    </NavLink>
                                </span>
                            }
                            fr={
                                <span>
                                    Vous ne pouvez pas passer aux mode
                                    «Prépaiement», car votre solde est négatif.
                                    Veuillez{' '}
                                    <NavLink
                                        onClick={props.navigateToTopUpBalance}
                                    >
                                        d&apos;abord alimenter le compte.
                                    </NavLink>
                                </span>
                            }
                            it={
                                <span>
                                    Non può passare alla modalità «Prepagata»,
                                    perché il saldo del conto è negativo. Si
                                    prega{' '}
                                    <NavLink
                                        onClick={props.navigateToTopUpBalance}
                                    >
                                        di ricaricare prima il conto.
                                    </NavLink>
                                </span>
                            }
                            en={
                                <span>
                                    You can&apos;t switch to the «Prepaid»
                                    payment method, because your balance is
                                    negative. Please{' '}
                                    <NavLink
                                        onClick={props.navigateToTopUpBalance}
                                    >
                                        top-up the account first.
                                    </NavLink>
                                </span>
                            }
                        />
                    </ErrorParagraph>
                )}
            </div>
        </SlideInForm>
    );
}

function NavLink(props: { onClick: () => void; children: React.ReactNode }) {
    return (
        <a
            onClick={props.onClick}
            className={css({ textDecoration: 'underline', cursor: 'pointer' })}
        >
            {props.children}
        </a>
    );
}

function InfoParagraph(props: { children: React.ReactNode }) {
    return <p className={css({ color: Colors.action_f })}>{props.children}</p>;
}

function ErrorParagraph(props: { children: React.ReactNode }) {
    return <p className={css({ color: Colors.error })}>{props.children}</p>;
}

interface Props {
    language: Language;
    onCancel: () => void;
    onSubmit: () => void;
    navigateToTopUpBalance: () => void;
}
