import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { Localized } from '../../common/components/Localized';
import { Legal } from 'dg-web-shared/lib/legal/legal_module_imports.ts';
import { Colors } from 'dg-web-shared/ui/vars';

export function QuickCheckoutTosCheckbox({
    selected,
    setSelected,
}: {
    selected: boolean;
    setSelected: (v: boolean) => void;
}) {
    return (
        <LabeledToggle
            context={InputContext.quickcheckout}
            selected={selected}
            type={ToggleType.checkbox}
            label={
                <>
                    <Localized
                        de="Ich akzeptiere hiermit die "
                        fr="J'accepte par la présente les "
                        it="Con la presente accetto le "
                        en="I accept the "
                    />

                    <Localized
                        de={<PdfLink href={Legal.tosFiles.de}>AGB</PdfLink>}
                        fr={<PdfLink href={Legal.tosFiles.fr}>CGV</PdfLink>}
                        it={<PdfLink href={Legal.tosFiles.it}>CG</PdfLink>}
                        en={<PdfLink href={Legal.tosFiles.en}>ToS</PdfLink>}
                    />

                    <Localized
                        de=" und die "
                        fr=" et la "
                        it=" e l'"
                        en=" and the "
                    />
                    <Localized
                        de={
                            <PdfLink href={Legal.privacyPolicyFiles.de}>
                                Datenschutzerklärung
                            </PdfLink>
                        }
                        fr={
                            <PdfLink href={Legal.privacyPolicyFiles.fr}>
                                déclaration sur la protection des données
                            </PdfLink>
                        }
                        it={
                            <PdfLink href={Legal.privacyPolicyFiles.it}>
                                informativa sulla protezione dei dati
                            </PdfLink>
                        }
                        en={
                            <PdfLink href={Legal.privacyPolicyFiles.en}>
                                data privacy statement
                            </PdfLink>
                        }
                    />
                    <Localized
                        de=" von Digitalparking."
                        fr=" de Digitalparking."
                        it=" di Digitalparking."
                        en=" of Digitalparking."
                    />
                </>
            }
            onClick={() => setSelected(!selected)}
        />
    );
}

function PdfLink({
    href,
    children,
}: {
    href: string;
    children: React.ReactNode;
}) {
    return (
        <a
            target="_blank"
            href={href}
            style={{ textDecoration: 'underline', color: Colors.white }}
            tabIndex={-1}
            rel="noreferrer"
        >
            {children}
        </a>
    );
}
