import { Vehicle } from './VehicleState';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { rgba } from 'utils/src/Rgba';
import { ParkingcardTypo } from '../../style/parkingcardTypo.ts';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

interface Props {
    vehicle: Vehicle;
    nonAdminTransactionsVisible: boolean;
}

export const VehicleRow = (p: Props) => {
    const desc = p.nonAdminTransactionsVisible
        ? p.vehicle.description
        : p.vehicle.loginDescription || p.vehicle.description;
    const plate = `${p.vehicle.licensePlateNr} (${p.vehicle.country})`;

    return (
        <div
            className={css({
                marginTop: '-8px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
            })}
        >
            <div
                className={css({
                    width: '24px',
                    height: '48px',
                    marginRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: Colors.blue,
                    ...ParkingcardTypo.caption,
                })}
            >
                <VehicleIcon type={p.vehicle.type} />
            </div>
            <div
                className={css({
                    fontSize: '15px',
                    lineHeight: '22px',
                })}
            >
                <div
                    className={css({
                        marginTop: '5px',
                        color: rgba(Colors.black, 0.5),
                    })}
                >
                    {desc}
                </div>
                <div>{plate}</div>
            </div>
        </div>
    );
};

export const VehicleIcon = (p: { type: string }) => (
    <Icon icon={p.type === LicensePlateType.CAR ? 'car' : 'bike'} />
);
