import * as ReactDOM from 'react-dom';
import { UserAgentInfo } from 'dg-web-shared/lib/UserAgentInfo';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import React from 'react';
import { Icon as Icon1 } from 'dg-web-shared/ui/icons/Icon';
import { Clickable } from 'dg-web-shared/ui/Clickable';

export function getSelectedDisplayText<I>(
    options: Option<I>[],
    selection: I,
): string {
    if (selection === undefined) {
        return '';
    }
    const index = options.map((o: Option<I>) => o.id).indexOf(selection);

    return options[index].displayText;
}

export interface Option<I> {
    id: I;
    displayText: string;
}

interface OptionsMenuProps<I> {
    selection: I;
    onChange: ChangeHandler<I>;
    options: Option<I>[];
    open: boolean;
    compatibility: boolean;
}

class OptionsMenu<I> extends React.Component<OptionsMenuProps<I>, object> {
    handleOnClick(option: Option<I>): void {
        this.props.onChange(option.id);
    }

    renderOptions(): React.ReactNode {
        return this.props.options.map((option: Option<I>) => {
            const optionIsSelected = this.props.selection === option.id;
            return (
                <Clickable
                    element="div"
                    onClick={(): void => this.handleOnClick(option)}
                    key={(option.id as string).toString()}
                >
                    <div
                        className={css({
                            ...Typo.navbutton,
                            color: Colors.blue,
                            paddingTop: '10px',
                            paddingBottom: '11px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        })}
                        ref={optionIsSelected ? 'selectedOption' : ''}
                    >
                        {option.displayText}
                    </div>
                </Clickable>
            );
        });
    }

    renderMenu(): React.ReactNode {
        if (!this.props.open) {
            return null;
        }
        return (
            <div
                className={css({
                    position: 'relative',
                    top: 0,
                    left: 0,
                    right: 0,
                    maxHeight: '225px',
                    ...paper(1),
                    opacity: 1,
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    background: Colors.white,
                    transformOrigin: 'right 15px',
                })}
                key="optionsMenu"
                ref="optionsContainer"
            >
                <div key="optionsContainer">{this.renderOptions()}</div>
            </div>
        );
    }

    render() {
        return (
            <div
                className={css({
                    position: this.props.compatibility
                        ? 'relative'
                        : 'absolute',
                    top: '-10px',
                    right: '4px',
                    transformOrigin: 'right 15px',
                    zIndex: 20,
                    WebkitOverflowScrolling: 'touch',
                })}
            >
                {this.renderMenu()}
            </div>
        );
    }

    componentDidMount(): void {
        this.updateScrollPosition();
    }

    componentDidUpdate(): void {
        this.updateScrollPosition();
    }

    updateScrollPosition(): void {
        if (this.props.open) {
            /* tslint:disable:no-string-literal */
            // eslint-disable-next-line react/no-find-dom-node
            const selectedOption = ReactDOM.findDOMNode(
                // eslint-disable-next-line react/no-string-refs
                this.refs['selectedOption'],
            ) as HTMLElement;
            // eslint-disable-next-line react/no-find-dom-node
            const optionsContainer = ReactDOM.findDOMNode(
                // eslint-disable-next-line react/no-string-refs
                this.refs['optionsContainer'],
            ) as HTMLElement;
            /* tslint:enable:no-string-literal */

            // 25 is a compensation value s.t. the item is not aligned exatly to the top of the
            // container. the value depends on margin and padding of a list item
            if (optionsContainer !== null && selectedOption !== null) {
                optionsContainer.scrollTop = selectedOption.offsetTop - 25;
            }
        }
    }
}

const OptionMenuFactory = React.createFactory(OptionsMenu);

export interface SelectionIconProps {
    singleSelection: boolean;
}

class SelectionIcon extends React.Component<SelectionIconProps> {
    static displayName = 'SelectionIcon';

    render() {
        if (this.props.singleSelection) {
            return null;
        } else {
            return React.createElement(
                'div',
                {
                    className: css({
                        color: Colors.red,
                        position: 'absolute',
                        top: '-50%',
                        right: 0,
                    }),
                } as object,
                Icon1({ icon: 'arrowDropdown' }),
            );
        }
    }
}

const SelectionIconFactory = React.createFactory(SelectionIcon);

interface SelectionComponentProps {
    selectionText: string;
    singleSelection: boolean;
    key?: string;
}

class Selection extends React.Component<SelectionComponentProps> {
    static displayName = 'Selection';

    render() {
        return React.createElement(
            'div',
            {
                className: css({
                    position: 'relative',
                    marginTop: '12px',
                    ...Typo.navbutton,
                }),
                'data-single-selection': this.props.singleSelection,
            } as object,
            React.createElement(
                'div',
                {
                    className: css({
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        marginRight: '24px',
                        color: Colors.blue,
                    }),
                } as object,
                this.props.selectionText,
            ),
            SelectionIconFactory({
                singleSelection: this.props.singleSelection,
            }),
        );
    }
}

export interface ChangeHandler<I> {
    (selection: I): void;
}

export interface OptionsGenerator<O, I> {
    (e: O, i?: number): Option<I>;
}

export interface Props<O, I> {
    errorText?: string;
    labelText?: string;
    hintText?: string;
    compatibility?: boolean;
    onChange: ChangeHandler<I>;
    optionsGenerator: OptionsGenerator<O, I>;
    selection: I;
    data: O[];
    key?: string;
}

interface State {
    open: boolean;
}

function MouseTrapFactory(
    open: boolean,
    closeCb: () => void,
): JSX.Element | null {
    if (open) {
        return (
            <Clickable
                element="div"
                className={css({
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 10,
                })}
                onClick={closeCb}
            />
        );
    }
    return null;
}

export class GenericDropdown<O, I> extends React.Component<Props<O, I>, State> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static defaultProps: Partial<Props<any, any>> = {
        errorText: '',
        labelText: '',
        hintText: '',
        compatibility: new UserAgentInfo(null).isProblematicAndroidStockBrowser,
        data: [],
    };

    constructor(props: Props<O, I>) {
        super(props);
        this.state = { open: false };
    }

    _handleOnClick(): void {
        if (!this.isSingleSelection()) {
            this.setState({ open: !this.state.open });
        }
    }

    _handleChange(o: I): void {
        this.close();
        this.props.onChange(o);
    }

    close(): void {
        this.setState({ open: false });
    }

    renderClosedDropdown(options: Option<I>[]): React.ReactNode {
        const p = this.props;
        if (this.state.open && p.compatibility) {
            return null;
        } else {
            return [
                <Selection
                    singleSelection={this.isSingleSelection()}
                    selectionText={getSelectedDisplayText<I>(
                        options,
                        p.selection,
                    )}
                    key="SelectionComponent"
                />,
            ];
        }
    }

    render() {
        const p = this.props;
        const options = p.optionsGenerator
            ? p.data.map(p.optionsGenerator)
            : [];
        return (
            <Clickable
                element="div"
                className={css({
                    marginTop: 0,
                    display: 'block',
                    position: 'relative',
                })}
                onClick={(): void => this._handleOnClick()}
            >
                {this.renderClosedDropdown(options)}
                {MouseTrapFactory(this.state.open, (): void => this.close())}
                {
                    /* need of factory because jsx and generic don't work */
                    OptionMenuFactory({
                        selection: p.selection,
                        open: this.state.open,
                        onChange: ((o: I): void =>
                            this._handleChange(o)) as ChangeHandler<unknown>,
                        options: options,
                        compatibility: this.props.compatibility || false,
                    })
                }
            </Clickable>
        );
    }

    private isSingleSelection(): boolean {
        return !(this.props.data.length > 1);
    }
}

export function StringIdFactory<O>(
    props: Props<O, string>,
): React.ReactElement<Props<O, string>> {
    return React.createElement<Props<O, string>>(GenericDropdown, props);
}
