import { css } from '@emotion/css';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter';
import { Colors } from 'dg-web-shared/ui/vars';
import { PermittypeDuration } from './QuickCheckoutPermittypeTypes';
import { QuickCheckoutConstants } from '../common/QuickCheckoutConstants';
import { permitDurationText } from 'dg-web-shared/common/models/PermitDurationType';
import { Localized } from '../../common/components/Localized';
import { Typo } from 'dg-web-shared/ui/typo.ts';

const DURATION_GAP_PX = '10px';

export function DurationSelectionInput({
    selectedDuration,
    setSelectedDuration,
    options,
}: {
    selectedDuration: PermittypeDuration | null;
    setSelectedDuration: (duration: PermittypeDuration) => void;
    options: PermittypeDuration[];
}) {
    const numColumns = options.length === 2 || options.length === 4 ? 2 : 3;
    return (
        <div
            className={css({
                display: 'flex',
                flexShrink: 0,
                marginRight: '-' + DURATION_GAP_PX,
                justifyContent: 'stretch',
                rowGap: DURATION_GAP_PX,
                alignContent: 'stretch',
                flexWrap: 'wrap',
                overflow: 'hidden',
            })}
        >
            {options.map(duration => (
                <DurationInput
                    key={`permit-${duration.quantity}-${duration.type}`}
                    onSelect={() => setSelectedDuration(duration)}
                    duration={duration}
                    columns={numColumns}
                    selected={
                        selectedDuration?.type === duration.type &&
                        selectedDuration?.quantity === duration.quantity
                    }
                />
            ))}
        </div>
    );
}

function DurationInput({
    duration,
    onSelect,
    selected,
    columns,
}: {
    duration: PermittypeDuration;
    onSelect: () => void;
    selected: boolean;
    columns: 2 | 3;
}) {
    return (
        <div
            className={css({
                display: 'relative',
                width: columns == 3 ? '33.3%' : '50%',
                '@media screen and (max-width: 400px)': {
                    width: '50%',
                },
                paddingRight: DURATION_GAP_PX,
            })}
        >
            <div
                className={css({
                    width: '100%',
                    height: '76px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    textAlign: 'center',
                    border: '1px solid',
                    borderColor: Colors.rgba(Colors.white, 0.8),
                    padding: '0px 5px',
                    borderRadius: QuickCheckoutConstants.BORDER_RADIUS,
                    backgroundColor: selected ? Colors.white : 'transparent',
                    cursor: selected ? undefined : 'pointer',
                    color: selected ? Colors.black : Colors.white,
                    ...Typo.robotoRegular,
                })}
                onClick={selected ? undefined : onSelect}
            >
                <div className={css({ marginBottom: '5px' })}>
                    <b>
                        <span>{duration.quantity} </span>
                        <Localized
                            {...permitDurationText(
                                duration.type,
                                duration.quantity,
                            )}
                        />
                    </b>
                </div>
                <div>
                    <div>{NumberFormatter.numberToPrice(duration.price)}</div>
                </div>
            </div>
        </div>
    );
}
